import { Context } from '@nuxt/types';
import { RouteMeta } from 'vue-router';
import { ThemeName } from '~/store/types';

export default function ({ route, store }: Context) {
  if (!route?.meta || route.meta.length <= 0) {
    return;
  }

  // Take the last value (latest route child)
  const theme = (route.meta as RouteMeta[]).reduce(
    (themeName: ThemeName, meta: RouteMeta) => meta.theme || themeName,
    ThemeName.DARK,
  );

  if (theme !== store.state.theme) {
    store.commit('updateTheme', theme);
  }
}
