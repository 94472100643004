import { ContractInterface } from 'ethers';
import { EthereumTokenStandard } from '~/types/chain';
import erc1155Abi from './abi/erc1155.json';
import erc721Abi from './abi/erc721.json';
import erc1155AbiV3 from './abi/v3erc1155.json';

export interface ItemContractConfig {
  name: string;
  contractType: EthereumTokenStandard.ERC1155 | EthereumTokenStandard.ERC721;
  contractAddress: string;
  contractInterface: ContractInterface;
}

export const itemContractConfigs: ItemContractConfig[] = [
  {
    name: 'Gala Items',
    contractType: EthereumTokenStandard.ERC1155,
    contractAddress: process.env.galaItemContractAddress as string,
    contractInterface: erc1155Abi as ContractInterface,
  },
  {
    name: 'Vox Series 1',
    contractType: EthereumTokenStandard.ERC721,
    contractAddress: process.env.voxContractAddress as string,
    contractInterface: erc721Abi as ContractInterface,
  },
  {
    name: 'The Walking Dead Vox',
    contractType: EthereumTokenStandard.ERC721,
    contractAddress: process.env.twdVoxContractAddress as string,
    contractInterface: erc721Abi as ContractInterface,
  },
  {
    name: 'Genesis Curry Flow',
    contractType: EthereumTokenStandard.ERC1155,
    contractAddress: process.env.curryContractAddress as string,
    contractInterface: erc1155Abi as ContractInterface,
  },
  {
    name: 'Mirandus Vox',
    contractType: EthereumTokenStandard.ERC721,
    contractAddress: process.env.mirandusVoxContractAddress as string,
    contractInterface: erc721Abi as ContractInterface,
  },
  {
    name: 'Blue Realm',
    contractType: EthereumTokenStandard.ERC721,
    contractAddress: process.env.blueRealmContractAddress as string,
    contractInterface: erc721Abi as ContractInterface,
  },
  {
    name: 'Spider Tanks Item',
    contractType: EthereumTokenStandard.ERC1155,
    contractAddress: process.env.spiderTanksItemContractAddress as string,
    contractInterface: erc1155AbiV3 as ContractInterface,
  },
];
