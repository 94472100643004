import { CachePersistor } from 'apollo-cache-persist';

export async function resetCache(cache: any, purge = true) {
  await cache.reset();
  if (purge) {
    const persistor = new CachePersistor({
      cache,
      // @ts-ignore
      storage: window.sessionStorage,
    });
    return persistor.purge();
  }
}
