
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import {
  SanityDocumentSchema,
  ISanityDocuments,
} from '~/types/sanity-documents';
import { SanityObjectSchema, ISanityObjects } from '~/types/sanity-objects';

type TSlide = ISanityObjects[SanityObjectSchema.PROMO_CAROUSEL_ITEM] & {
  _key: string;
};

@Component
export default class PromoCarousel extends Vue {
  @Prop(String)
  readonly id!: string;
  @Prop(String)
  readonly slug!: string;
  @Prop(String)
  readonly filterSlug!: string;

  docType = SanityDocumentSchema.PROMO_CAROUSEL;
  filteredSlides: TSlide[] = [];

  async fetch() {
    if (!this.slug && !this.id) {
      return;
    }

    await this.$sanityLookup.fetchDocument({
      _type: this.docType,
      _id: this.id,
      slug: this.slug,
      fetchReferences: true,
    });
  }

  get docState() {
    return (
      this.$sanityLookup.getDocumentFromIdOrSlug<
        ISanityDocuments[SanityDocumentSchema.PROMO_CAROUSEL]
      >({
        _type: this.docType,
        _id: this.id,
        slug: this.slug,
      }) || {
        isLoading: false,
        hasError: false,
      }
    );
  }

  get slugState() {
    return (
      this.$sanityLookup.getSlug({
        _type: this.docType,
        slug: this.slug,
      }) || {
        isLoading: false,
        hasError: false,
      }
    );
  }

  @Watch('docState.data.slides', { immediate: true })
  handleSlidesChange(current: TSlide[] | undefined) {
    if (!current?.length) {
      return;
    }

    const filteredSlides: TSlide[] = [];
    current?.forEach((slide) => {
      if (!(slide.startsAt || slide.endsAt) && !this.filterSlug) {
        filteredSlides.push(slide);
        return;
      }

      if (slide.documentReference) {
        const doc = this.$sanityLookup.getDocumentFromReference({
          _ref: slide.documentReference?._ref,
        });
        if (doc?.data?.slug && this.filterSlug === doc.data.slug) {
          return;
        }
      }

      const now = new Date();
      if (slide.startsAt) {
        const startsAtDate = new Date(slide.startsAt);
        if (startsAtDate > now) {
          return;
        }
      }

      if (slide.endsAt) {
        const endsAtDate = new Date(slide.endsAt);
        if (endsAtDate < now) {
          return;
        }
      }
      filteredSlides.push(slide);
    });

    this.filteredSlides = filteredSlides;
  }
}
