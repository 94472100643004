
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { CopyToClipboard } from '~/mixins';
import ShareButton from './ShareButton.vue';

@Component({
  components: { ShareButton },
})
export default class ShareButtons extends Mixins(CopyToClipboard) {
  @Prop(String) readonly shareUrl!: string;
  @Prop(String) readonly referralCode!: string;
  @Prop({ type: Object, default: {} }) readonly currentUser!: {
    firstName?: string;
    lastName?: string;
    displayName: string;
  };

  get invitationText() {
    return this.$t('components.share.shareButtons.invitation') as string;
  }

  get inviteCopyText() {
    if (!this.referralCode || !this.shareUrl) {
      return '';
    }
    const { firstName, lastName, displayName } = this.currentUser;
    const defaultGamerName = firstName === 'Gamer' && lastName === 'Gamer';
    const incompleteName = !firstName || !lastName;

    const name =
      defaultGamerName || incompleteName
        ? displayName || 'Your friend'
        : `${firstName} ${lastName}`;

    return this.$t('components.share.shareButtons.inviteCopyText', {
      name,
      referralCode: this.referralCode,
      shareUrl: this.shareUrl,
    }) as string;
  }

  get inviteNativeShareText() {
    if (!this.referralCode || !this.shareUrl) {
      return '';
    }
    const { firstName, lastName, displayName } = this.currentUser;
    const defaultGamerName = firstName === 'Gamer' && lastName === 'Gamer';
    const incompleteName = !firstName || !lastName;

    const name =
      defaultGamerName || incompleteName
        ? displayName || 'Your friend'
        : `${firstName} ${lastName}`;

    return this.$t('components.share.shareButtons.inviteNativeShareText', {
      name,
      referralCode: this.referralCode,
    }) as string;
  }
}
