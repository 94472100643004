import Vue from 'vue';
import { MutationTree } from 'vuex';
import { IProjectState } from './types';
import { IProject, IProjectViews, IProjectTimeline } from '~/types/project';
import {
  IEpisode,
  IEpisodeWatchProgress,
  IEpisodeWatchSuggestion,
} from '~/types/series';
import { IPromotion } from '~/types/promotion';
import { deepSet } from '~/store/store-utils';

export const mutations: MutationTree<IProjectState> = {
  updateProject(projectState: IProjectState, project: IProject) {
    if (project) {
      const currentProject = projectState.projects[project.slug] || {};
      const updatedProject = { ...currentProject, ...project };
      Vue.set(projectState.projects, project.slug, updatedProject);
    }
  },
  updateProjectTimeline(
    projectState: IProjectState,
    payload: { projectSlug: string; timeline?: IProjectTimeline },
  ) {
    Vue.set(
      projectState.projectTimeline,
      payload.projectSlug,
      payload.timeline,
    );
  },
  updateProjectEpisodeWatchProgress(
    projectState: IProjectState,
    payload: {
      watchProgress: Record<string, IEpisodeWatchProgress>;
      projectSlug: string;
    },
  ) {
    const seriesState = getOrCreateProjectSeriesState(
      projectState,
      payload.projectSlug,
    );
    seriesState.episodeWatchProgress = payload.watchProgress;
  },
  updateProjectWatchSuggestion(
    projectState: IProjectState,
    payload: {
      episodeWatchSuggestion: IEpisodeWatchSuggestion;
      projectSlug: string;
    },
  ) {
    const seriesState = getOrCreateProjectSeriesState(
      projectState,
      payload.projectSlug,
    );
    seriesState.episodeWatchSuggestion = payload.episodeWatchSuggestion;
  },
  updateCurrentEpisode(
    projectState: IProjectState,
    payload: { episode: IEpisode },
  ) {
    projectState.currentEpisode = payload.episode;
  },
  updateNextEpisode(
    projectState: IProjectState,
    payload: { episode?: IEpisode },
  ) {
    projectState.nextEpisode = payload.episode;
  },
  updateShowingVideo(projectState: IProjectState, showVideo: boolean) {
    if (projectState.showingVideo !== showVideo) {
      projectState.showingVideo = showVideo;
    }
  },
  updateViewingRestrictions(
    projectState: IProjectState,
    newViewingRestrictions: Record<string, boolean>,
  ) {
    projectState.viewingRestrictions = {
      ...projectState.viewingRestrictions,
      ...newViewingRestrictions,
    };
  },
  updateContinueRzrEp1AfterUA(
    projectState: IProjectState,
    continueVideo: boolean,
  ) {
    projectState.continueRzrEp1AfterUA = continueVideo;
  },
  updateCurrentNftPromotion(
    projectState: IProjectState,
    nftPromotion: IPromotion,
  ) {
    projectState.nftPromotionsState.promotion = nftPromotion;
    projectState.nftPromotionsState.shownPromotionIds.push(nftPromotion.id);
  },
  updateShowingNftPromotion(projectState: IProjectState, showing: boolean) {
    projectState.nftPromotionsState.show = showing;
  },
  updateProjectsAutoPlayed(
    projectState: IProjectState,
    projectsToAdd: string[],
  ) {
    projectState.projectsAutoPlayed =
      projectState.projectsAutoPlayed.concat(projectsToAdd);
  },
  updateProjectStatsOverview(
    projectState: IProjectState,
    payload: { projectSlug: string; views: IProjectViews },
  ) {
    if (payload.projectSlug) {
      const currentStats =
        projectState.projects[payload.projectSlug]?.views || {};
      const updatedStats = { ...currentStats, ...(payload.views || {}) };
      const supporterKey = `projects.${payload.projectSlug}.views`;
      deepSet(projectState, supporterKey, updatedStats);
    }
  },
};

function getOrCreateProjectSeriesState(
  projectState: IProjectState,
  projectSlug: string,
) {
  if (projectState?.projectSeriesState?.[projectSlug]) {
    return projectState.projectSeriesState[projectSlug];
  }

  Vue.set(projectState.projectSeriesState, projectSlug, {
    episodeWatchProgress: undefined,
    episodeWatchSuggestion: undefined,
    currentEpisode: undefined,
    nextEpisode: undefined,
  });

  return projectState.projectSeriesState[projectSlug];
}
