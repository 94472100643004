
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { InputValidationRules } from 'vuetify/types';

export enum LabelPosition {
  ABOVE = 'above',
  HIDDEN = 'hidden',
  INLINE = 'inline',
}

type TextFieldPropsMutable = {
  -readonly [Key in keyof TextField]: TextField[Key];
};

@Component({
  inheritAttrs: false,
})
export default class TextField extends Vue {
  @Prop({ type: String, default: 'accentPurple' })
  readonly color!: string;
  @Prop([String, Number]) readonly inputValue!: string | number;
  @Prop({ type: String }) readonly label!: string;
  @Prop({ type: String, default: LabelPosition.INLINE })
  readonly labelPosition!: LabelPosition;
  @Prop({ type: Array, default: () => [] })
  readonly rules!: InputValidationRules;

  value: string | null = null;
  labelPositions = LabelPosition;

  @Watch('inputValue')
  handleInputValueChange() {
    this.value = `${this.inputValue}`;
  }

  @Watch('value')
  handleValueChange(val: string) {
    this.$emit(
      'update:inputValue',
      this.$attrs?.type === 'number' ? this.toNumber(val) : val,
    );
  }

  created() {
    this.value = `${this.inputValue}`;
  }

  get passThroughProps() {
    const props = { ...this.$attrs, ...this.$props };
    ['inputValue', 'labelPosition'].forEach((key) => delete props[key]);
    return props;
  }

  get isRequired() {
    return this.$attrs.hasOwnProperty('required');
  }

  get validationRules() {
    return this.isRequired ? [this.requiredField, ...this.rules] : this.rules;
  }

  requiredField(val: string) {
    return !!val || 'This field is required';
  }

  toNumber(val: string) {
    const n = parseFloat(val);
    return isNaN(n) ? val : n;
  }
}
