import { EState } from '~/types/collections';
import { IPoster, IShard, IScene, IMoment } from 'src/types/collections';
import { IFilmMomentStatsOverview } from '~/types/my-collection';
import {
  SanityDocumentSchema,
  ISanityDocuments,
} from '~/types/sanity-documents';
import { TAsyncStateWithDoc } from '~/store/documents/types';

export function getMergedPosterMap(
  oldList: IPoster[] = [],
  newList: IPoster[] = [],
): Record<string, IPoster> {
  const posters = [...oldList, ...newList];
  const posterListMap = posters.reduce((acc, poster) => {
    if (!acc[poster.posterId]) {
      acc[poster.posterId] = [];
    }
    acc[poster.posterId].push(poster);
    return acc;
  }, {} as Record<string, IPoster[]>);

  const mergedPosterMap: Record<string, IPoster> = {};
  Object.keys(posterListMap).forEach(
    (posterId: string) =>
      (mergedPosterMap[posterId] = mergePosters(posterListMap[posterId])),
  );

  return mergedPosterMap;
}

export function mergePosters(posters: IPoster[]): IPoster {
  return posters.reduce((acc, nextPoster) => {
    const prevPoster = acc || {};
    const mergedShardMap = getMergedShardMap(
      prevPoster.shards,
      nextPoster.shards,
    );
    const shards = nextPoster.shards.map(
      (shard) => mergedShardMap[shard.gyriId] || shard,
    );
    acc = {
      ...prevPoster,
      ...nextPoster,
      // isCompleted: prevPoster.isCompleted || nextPoster.isCompleted,
      shards,
    };
    return acc;
  }, {} as IPoster);
}

export function getMergedSceneMap(
  oldList: IScene[] = [],
  newList: IScene[] = [],
): Record<string, IScene> {
  const scenes = [...oldList, ...newList];
  const sceneListMap = scenes.reduce((acc, scene) => {
    if (!acc[scene.sceneSlug]) {
      acc[scene.sceneSlug] = [];
    }
    acc[scene.sceneSlug].push(scene);
    return acc;
  }, {} as Record<string, IScene[]>);

  const mergedSceneMap: Record<string, IScene> = {};
  Object.keys(sceneListMap).forEach(
    (sceneSlug: string) =>
      (mergedSceneMap[sceneSlug] = mergeScenes(sceneListMap[sceneSlug])),
  );

  return mergedSceneMap;
}

export function mergeScenes(scenes: IScene[]): IScene {
  return scenes.reduce((acc, nextScene) => {
    const prevScene = acc || {};
    const mergedMomentMap = getMergedMomentMap(
      prevScene.moments,
      nextScene.moments,
    );
    const moments = nextScene.moments.map(
      (moment) => mergedMomentMap[moment.gyriId] || moment,
    );
    acc = {
      ...prevScene,
      ...nextScene,
      isCompleted: prevScene.isCompleted || nextScene.isCompleted,
      moments,
    };
    return acc;
  }, {} as IScene);
}

export function getMergedShardMap(
  oldList: IShard[] = [],
  newList: IShard[] = [],
): Record<string, IShard> {
  const shards = [...oldList, ...newList];
  const shardListMap = shards.reduce((acc, shard) => {
    if (!acc[shard.gyriId]) {
      acc[shard.gyriId] = [];
    }
    acc[shard.gyriId].push(shard);
    return acc;
  }, {} as Record<string, IShard[]>);

  const mergedShardMap: Record<string, IShard> = {};
  Object.keys(shardListMap).forEach(
    (gyriId: string) =>
      (mergedShardMap[gyriId] = mergeShards(shardListMap[gyriId])),
  );

  return mergedShardMap;
}

export function mergeShards(shards: IShard[]): IShard {
  return shards.reduce((acc, nextShard) => {
    const prevShard = acc || {};
    acc = {
      ...prevShard,
      ...nextShard,
      // isOwned: prevShard.isOwned || nextShard.isOwned,
    };
    return acc;
  }, {} as IShard);
}

export function getMergedMomentMap(
  oldList: IMoment[] = [],
  newList: IMoment[] = [],
): Record<string, IMoment> {
  const moments = [...oldList, ...newList];
  const momentListMap = moments.reduce((acc, moment) => {
    if (!acc[moment.gyriId]) {
      acc[moment.gyriId] = [];
    }
    acc[moment.gyriId].push(moment);
    return acc;
  }, {} as Record<string, IMoment[]>);

  const mergedMomentMap: Record<string, IMoment> = {};
  Object.keys(momentListMap).forEach(
    (gyriId: string) =>
      (mergedMomentMap[gyriId] = mergeMoments(momentListMap[gyriId])),
  );

  return mergedMomentMap;
}

export function mergeMoments(moments: IMoment[]): IMoment {
  return moments.reduce((acc, nextMoment) => {
    const prevMoment = acc || {};
    acc = {
      ...prevMoment,
      ...nextMoment,
      isOwned: prevMoment.isOwned || nextMoment.isOwned,
    };
    return acc;
  }, {} as IMoment);
}

export function parseRestrictionResponseForCompletedSets(res: any) {
  const { reasonDetails } = res || {};
  const { ruleResults } = reasonDetails || {};
  const rules =
    ruleResults?.filter?.(
      (rule: any) =>
        rule.success && rule?.filterResults?.completedSetCount?.success,
    ) || [];
  const successClauses =
    rules?.[0]?.filterResults?.completedSetCount?.reasonDetails
      ?.successClauses || [];

  const newlyCompletedSetsClause =
    successClauses.find?.(
      (clause: any) =>
        clause?.success &&
        clause?.reason ===
          'NEWLY_COMPLETED_SETS_COUNT_SATISFIES_MIN_QUANTITY_THRESHOLD',
    ) || {};

  const newlyCompletedSets =
    newlyCompletedSetsClause?.reasonDetails?.newlyCompletedSets || [];

  return newlyCompletedSets;
}

export function parseRestrictionResponseForUnopenedMyseryBoxes(
  isQualified: any,
) {
  const { reasonDetails } = isQualified || {};
  const { ruleResults } = reasonDetails || {};
  const rules =
    ruleResults?.filter?.(
      (rule: any) =>
        rule.success && rule?.filterResults?.completedSetCount?.success,
    ) || [];
  const successClauses =
    rules?.[0]?.filterResults?.completedSetCount?.reasonDetails
      ?.successClauses || [];

  const newlyCompletedSetsClause =
    successClauses.find?.(
      (clause: any) =>
        clause?.success &&
        clause?.reason ===
          'COMPLETED_SETS_COUNT_SATISFIES_MIN_QUANTITY_THRESHOLD',
    ) || {};

  const completedSets =
    newlyCompletedSetsClause?.reasonDetails?.completedSets || [];

  return completedSets;
}

export function getOverlayImgInput(url: string) {
  return [
    {
      src: url,
      state: EState.COMPLETE,
    },
  ];
}

export function getOverlayImgRef(entity: any = {}) {
  const { _type } = entity;
  switch (_type) {
    case SanityDocumentSchema.SCENE:
      return entity?.image?.asset?._ref;
    case SanityDocumentSchema.POSTER:
      return entity.completedImage?.asset?._ref;
    default:
      return '';
  }
}

export function shardImagesFromPoster(
  poster: IPoster,
  videoThumbnail?: string,
) {
  if (poster.isCompleted) {
    return [
      {
        src: videoThumbnail || poster.completedImageUrl,
        state: EState.COMPLETE,
      },
    ];
  }
  return (
    poster?.shards?.map((shard: IShard) => ({
      src: shard.overlayImageUrl,
      state: shard.isOwned ? EState.COMPLETE : EState.INCOMPLETE,
    })) ?? []
  );
}

export function imageFromScene(scene: IScene) {
  if (!scene?.imageUrl) {
    return [];
  }
  return [{ src: scene.imageUrl }];
}

export function getPosterSidenote(poster?: IPoster) {
  const completeCount =
    poster?.shards?.reduce?.((sum, shard) => sum + +(shard.isOwned ?? 0), 0) ??
    0;
  const totalCount = poster?.shards?.length ?? 0;

  return `${completeCount} / ${totalCount}`;
}

export function getSceneSidenote(scene: IScene) {
  const completeCount =
    scene?.moments?.reduce(
      (sum: number, moment: IMoment) => sum + +(moment.isOwned ?? 0),
      0,
    ) ?? 0;
  const totalCount = scene?.moments?.length ?? 0;
  return `${completeCount} / ${totalCount}`;
}

export function getTotalMomentsOwned(scene: IScene) {
  const ownedMoments = scene?.moments?.reduce(
    (sum: number, moment: IMoment) => sum + +(moment.isOwned ?? 0),
    0,
  );
  return ownedMoments;
}

export function getMomentProgressMode(scene?: IScene | null) {
  return !scene?.isCompleted ? 'disabled' : 'enabled';
}

export function getMomentTableRows(
  moments: IMoment[],
  momentStats: Record<string, IFilmMomentStatsOverview>,
) {
  const rows: any[] = [];

  for (const moment of moments) {
    if (!moment?.items?.length) {
      const gyriId = moment?.gyriId;
      const lastDayRewards =
        (Number(momentStats?.[gyriId]?.lastDayRewards) || 0)?.toFixed?.(2) || 0;
      const item: any = {
        rowClass: { 'cursor-default': true },
        tdClass: { 'py-3 px-4': true },
        cellClass: { 'table-inactive-row': !moment.isOwned },
        item: {
          image: moment?.imageUrl,
          title: moment?.title,
          subtitle: moment?.sceneTitle,
          rarity: moment?.rarity,
          // isOwned: moment?.isOwned,
          // instance: item?.nonFungibleInstanceId,
          // totalOwned: moment?.items?.length, // moment?.totalOwned
          // chain: moment?.chain,
          instancesAccordion: false,
        },
      };
      if (+lastDayRewards > 0) {
        item.item.lastDayRewards = lastDayRewards;
      }
      rows.push(item);
    } else if (moment?.items?.length === 1) {
      const gyriId = moment?.gyriId;
      const lastDayRewards =
        (Number(momentStats?.[gyriId]?.lastDayRewards) || 0)?.toFixed?.(2) || 0;
      const item = moment?.items?.[0];
      rows.push({
        tdClass: { 'py-3 px-4': true },
        item: {
          image: moment?.imageUrl,
          title: moment?.title,
          subtitle: moment?.sceneTitle,
          rarity: moment?.rarity,
          isOwned: moment?.isOwned,
          instance: item?.nonFungibleInstanceId,
          totalOwned: moment?.items?.length, // moment?.totalOwned
          chain: moment?.chain,
          lastDayRewards,
          instancesAccordion: false,
          item, // used for redirect link
        },
      });
    } else {
      const gyriId = moment?.gyriId;
      const lastDayRewards =
        (Number(momentStats?.[gyriId]?.lastDayRewards) || 0)?.toFixed?.(2) || 0;
      rows.push({
        tdClass: { 'py-3 px-4': true },
        item: {
          image: moment?.imageUrl,
          title: moment?.title,
          subtitle: moment?.sceneTitle,
          rarity: moment?.rarity,
          isOwned: moment?.isOwned,
          instance: '',
          totalOwned: moment?.items?.length, // moment?.totalOwned
          chain: '',
          lastDayRewards,
          instancesAccordion: true,
          // item, // used for redirect link
        },
      });
      for (const item of moment.items) {
        rows.push({
          tdClass: { 'py-3 px-4 no-top-border': true },
          item: {
            ...moment,
            image: '',
            title: moment?.title,
            subtitle: '',
            rarity: '',
            isOwned: '',
            instance: item?.nonFungibleInstanceId,
            totalOwned: '',
            chain: moment?.chain,
            lastDayRewards: '',
            instancesAccordion: false,
            item, // used for redirect link
          },
        });
      }
    }
  }
  return rows;
}

export function getShardTableRows(shards: IShard[]) {
  const rows: any[] = [];

  for (const shard of shards) {
    if (!shard?.items?.length) {
      rows.push({
        rowClass: { 'cursor-default': true },
        tdClass: { 'py-3 px-4': true },
        cellClass: { 'table-inactive-row': !shard.isOwned },
        item: {
          image: shard?.overlayImageUrl,
          title: shard?.title,
          subtitle: shard?.posterTitle,
          rarity: shard?.rarity,
          // isOwned: shard?.isOwned,
          // instance: item?.nonFungibleInstanceId,
          // totalOwned: shard?.items?.length, // shard?.totalOwned
          // chain: shard?.chain,
          instancesAccordion: false,
        },
      });
    } else if (shard?.items?.length === 1) {
      const item = shard?.items?.[0];
      rows.push({
        tdClass: { 'py-3 px-4': true },
        item: {
          image: shard?.overlayImageUrl,
          title: shard?.title,
          subtitle: shard?.posterTitle,
          rarity: shard?.rarity,
          isOwned: shard?.isOwned,
          instance: item?.nonFungibleInstanceId,
          totalOwned: shard?.items?.length, // shard?.totalOwned
          chain: shard?.chain,
          instancesAccordion: false,
          item, // used for redirect link
        },
      });
    } else {
      rows.push({
        tdClass: { 'py-3 px-4': true },
        item: {
          image: shard?.overlayImageUrl,
          title: shard?.title,
          subtitle: shard?.posterTitle,
          rarity: shard?.rarity,
          isOwned: shard?.isOwned,
          instance: '',
          totalOwned: shard?.items?.length, // shard?.totalOwned
          chain: '',
          instancesAccordion: true,
          // item, // used for redirect link
        },
      });
      for (const item of shard.items) {
        rows.push({
          tdClass: { 'py-3 px-4 no-top-border': true },
          item: {
            ...shard,
            image: '',
            title: shard?.title,
            subtitle: '',
            rarity: '',
            isOwned: '',
            instance: item?.nonFungibleInstanceId,
            totalOwned: '',
            chain: shard?.chain,
            instancesAccordion: false,
            item, // used for redirect link
          },
        });
      }
    }
  }
  return rows;
}

export function trimDecimals(num: number, decimalPlaces = 2) {
  return (Number(num) || 0)?.toFixed?.(decimalPlaces) || 0;
}

export function shouldShowUnopenedBoxesDot(
  statuses: Record<string, boolean>,
  projects: Array<
    TAsyncStateWithDoc<ISanityDocuments[SanityDocumentSchema.PROJECT]>
  >,
) {
  const showDot = Object.keys(statuses).some((projectSlug) => {
    const project = projects?.find((proj) => proj?.data?.slug === projectSlug);
    return statuses[projectSlug] && project?.data?.showUnopenedBoxesPulsingDot;
  });
  return showDot;
}
