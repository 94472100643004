import { render, staticRenderFns } from "./SanityCta.vue?vue&type=template&id=96fb3e06&scoped=true"
import script from "./SanityCta.vue?vue&type=script&lang=ts&setup=true"
export * from "./SanityCta.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./SanityCta.vue?vue&type=style&index=0&id=96fb3e06&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96fb3e06",
  null
  
)

export default component.exports