import { Context } from '@nuxt/types';
import { IInventoryState } from '~/store/inventory/types';

export default async function (context: Context) {
  const { redirect, route, store } = context;
  const inventory = store.state.inventory as IInventoryState;

  const redirectTo = '/collection';

  if (!route.query) {
    redirect(redirectTo);
    return;
  }

  if (!inventory.userItems.length) {
    await store.dispatch('inventory/getUserItems');
  }

  if (!inventory.userItems.length) {
    redirect(redirectTo);
    return;
  }

  const { selector } = route.query;

  const userItem: any = inventory.userItems.find(
    (i) => i.uniqueInventoryPath === selector,
  );

  if (!userItem) {
    redirect(redirectTo);
    return;
  }
}
