import { Route } from 'vue-router';
import { ISanityImageBuilder } from '~/plugins/sanityImage';
import { ISanityLoader } from '~/plugins/sanityLookup';
import { IAuth0ExtParams, Auth0ThemeBackgroundType } from '~/types/authTheme';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';
import {
  IColor,
  ISanityObjects,
  SanityObjectSchema,
} from '~/types/sanity-objects';

const getHexColor = (color: IColor | undefined) => {
  return color?.hex?.replace('#', '');
};

export const getAuthThemeBackground = (
  theme: ISanityObjects[SanityObjectSchema.AUTH_THEME] | undefined,
  sanityImage: ISanityImageBuilder | undefined,
) => {
  if (!theme) {
    return;
  }

  if (
    theme.backgroundType === Auth0ThemeBackgroundType.IMAGE &&
    theme.imageBackground &&
    sanityImage
  ) {
    return (
      sanityImage.urlFor(theme.imageBackground).url().split('?')[0] || undefined
    );
  }

  if (
    theme.backgroundType === Auth0ThemeBackgroundType.VIDEO &&
    theme.videoBackground
  ) {
    return theme.videoBackground;
  }
};

export const getAuthRedirectPathFromRoute = async (
  route: Route,
  sanityLookup: ISanityLoader,
) => {
  const pageDoc = sanityLookup.getRouteDocument(route);
  const siteDoc = sanityLookup.getSiteConfig();
  const redirectConfig = sanityLookup.getDocument<
    ISanityDocuments[SanityDocumentSchema.AUTH_CONFIG]
  >({
    _type: SanityDocumentSchema.AUTH_CONFIG,
    _id: pageDoc?.data?.auth?._ref || siteDoc?.data?.auth?._ref,
  })?.data?.redirect;
  if (!redirectConfig) {
    return;
  }
  if (redirectConfig?.linkType === 'url' && redirectConfig?.href) {
    return redirectConfig.href;
  }
  if (
    redirectConfig?.linkType === 'docRef' &&
    redirectConfig?.documentReference?._ref
  ) {
    return sanityLookup.getPageRoutePathFromRef(
      redirectConfig?.documentReference?._ref,
    );
  }
};

export const getAuthThemeDataFromRoute = (
  route: Route,
  sanityLookup: ISanityLoader,
) => {
  const pageDoc = sanityLookup.getRouteDocument(route);
  const siteDoc = sanityLookup.getSiteConfig();
  return sanityLookup.getDocument<
    ISanityDocuments[SanityDocumentSchema.AUTH_CONFIG]
  >({
    _type: SanityDocumentSchema.AUTH_CONFIG,
    _id: pageDoc?.data?.auth?._ref || siteDoc?.data?.auth?._ref,
  })?.data?.theme;
};

export const getAuthThemeParams = (
  theme: ISanityObjects[SanityObjectSchema.AUTH_THEME] | undefined,
  sanityImage: ISanityImageBuilder | undefined,
) => {
  if (!theme) {
    return;
  }

  const params: IAuth0ExtParams = {
    'ext-title': theme.title || undefined,
    'ext-background-type': theme.backgroundType || undefined,
    'ext-background': getAuthThemeBackground(theme, sanityImage),
    'ext-title-color': getHexColor(theme.titleColor) || undefined,
    'ext-link-color': getHexColor(theme.linkColor) || undefined,
    'ext-position': theme.loginPosition || undefined,
    'ext-overlay': theme.overlay?.toString() || undefined,
  };

  if (theme.backgroundType === Auth0ThemeBackgroundType.DEFAULT) {
    params['ext-bg-colorstop-1'] =
      getHexColor(theme.gradientColor1) || undefined;
    params['ext-bg-colorstop-2'] =
      getHexColor(theme.gradientColor2) || undefined;
  }

  return params;
};
