import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import copy from 'copy-to-clipboard';
import { ISnackbarOptions, ISnackbarPayload } from '~/store/types';

// You can declare a mixin as the same style as components.
@Component
export default class CopyToClipboard extends Vue {
  @Mutation toggleSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbar!: (args?: string | ISnackbarPayload) => void;

  copyToClipboard(
    value: string,
    label = '',
    messagePosition = 'centered' as ISnackbarOptions['position'],
  ) {
    try {
      copy(value, {
        debug: process.env.nodeEnv !== 'production',
        message: this.$t('mixins.copyToClipboard.pressKeyToCopy') as string,
        format: 'text/plain',
      });

      const message = (
        label
          ? this.$t('mixins.copyToClipboard.copied', {
              label,
            })
          : (this.$t('mixins.copyToClipboard.copiedDefault') as string)
      ) as string;

      this.updateSnackbar({
        message,
        color: 'accentPurpleLight',
        light: true,
        icon: 'mdi-content-copy',
        position: messagePosition,
        rounded: 'lg',
      });
      this.toggleSnackbar(true);
    } catch (err) {
      this.$sentry.captureException(err);
      const message = (
        label
          ? this.$t('mixins.copyToClipboard.unableToCopy', {
              label,
            })
          : this.$t('mixins.copyToClipboard.unableToCopyDefault')
      ) as string;
      this.updateSnackbar({
        message,
        color: 'error',
        position: messagePosition,
        rounded: 'lg',
      });
      this.toggleSnackbar(true);
    }
  }
}
