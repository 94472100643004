
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

const _debounce = require('lodash.debounce');

@Component({
  inheritAttrs: false,
})
export default class NumberInput extends Vue {
  @Prop(Number) readonly value!: number;
  @Prop(Number) readonly max!: number;
  @Prop(String) readonly maxExceededMessage!: string;
  @Prop(String) readonly minExceededMessage!: string;
  @Prop(String) readonly notDivisibleByStepMessage!: string;
  @Prop({ type: Number, default: 1 }) readonly min!: number;
  @Prop({ type: Number, default: 1 }) readonly step!: number;
  @Prop({ type: Boolean, default: false }) readonly fullWidth!: boolean;
  @Prop({ type: Boolean, default: false }) readonly smallSize!: boolean;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: string) => void;

  proposedValue = this.value;
  debounceTime = 1000;

  handleClick(direction: string) {
    if (direction === 'inc') {
      if (this.value < this.max) {
        this.$emit('input', this.value + this.step);
      }
    } else {
      if (this.value > this.min) {
        this.$emit('input', this.value - this.step);
      }
    }
  }

  handleInput(value: string | number) {
    const val = +value;
    if (val > this.max) {
      this.debounceHandler(() => {
        if (+this.proposedValue === val) {
          this.$emit('input', this.max);
          if (this.value === this.max) {
            this.handleValueChange(this.max);
          }
          this.updateSnackbarErrorText(this.maxExceededMessage);
          this.toggleErrorSnackbar();
        }
      });
    } else if (val < this.min) {
      this.debounceHandler(() => {
        if (+this.proposedValue === val) {
          this.$emit('input', this.min);
          if (this.value === this.min) {
            this.handleValueChange(this.min);
          }
          this.updateSnackbarErrorText(this.minExceededMessage);
          this.toggleErrorSnackbar();
        }
      });
    } else if (val % this.step) {
      this.debounceHandler(() => {
        if (+this.proposedValue === val) {
          const adjusted = val - (val % this.step);
          this.$emit('input', adjusted);
          if (this.value === adjusted) {
            this.handleValueChange(adjusted);
          }
          this.updateSnackbarErrorText(this.notDivisibleByStepMessage);
          this.toggleErrorSnackbar();
        }
      });
    } else {
      this.$emit('input', val);
    }
  }

  debounceHandler = _debounce((cb: Function) => {
    cb();
  }, this.debounceTime);

  @Watch('value')
  handleValueChange(newVal: number) {
    this.proposedValue = newVal;
  }
}
