import { MutationTree } from 'vuex';
import {
  INodesState,
  IUserNode,
  IWorkloadStreakRewards,
  NodeLicenseNames,
  NodeTypes,
  nodeLicenseTypeMap,
} from './types';

export const mutations: MutationTree<INodesState> = {
  updateUserNodes(nodesState: INodesState, userNodes: IUserNode[]) {
    const newUserNodes: IUserNode[] = [];

    userNodes
      .filter((node) =>
        Object.values(NodeLicenseNames).includes(
          node.licenseName as NodeLicenseNames,
        ),
      )
      .forEach((node) => {
        let name = '';

        switch (node.licenseName) {
          case NodeLicenseNames.film:
            name = 'Film';
            break;
        }

        const nodeType =
          nodeLicenseTypeMap[node.licenseName as NodeLicenseNames];
        nodesState.nodesOwnedByType[nodeType] = node.quantity;

        newUserNodes.push({
          licenseName: node.licenseName,
          name,
          quantity: node.quantity,
          type: nodeType,
        });
      });

    nodesState.userNodes = newUserNodes;
    nodesState.nodesOwned = nodesState.userNodes.reduce(
      (total, node) => total + node.quantity,
      0,
    );
  },

  updateActiveUserNodes(
    nodesState: INodesState,
    activeNodes: Record<string, number>,
  ) {
    let totalActive = 0;
    Object.keys(activeNodes)
      .filter((nodeType) =>
        Object.values(NodeTypes).includes(nodeType as NodeTypes),
      )
      .forEach((nodeType) => {
        nodesState.nodesActiveByType[nodeType as NodeTypes] =
          activeNodes[nodeType];
        totalActive += activeNodes[nodeType];
      });
    nodesState.nodesActive = totalActive;
  },

  updateWorkloadStreakRewards(
    nodesState: INodesState,
    payload: IWorkloadStreakRewards,
  ) {
    nodesState.workloadStreakRewards = payload;
  },
};
