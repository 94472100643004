import { ICtaCard, PosterOverview } from './collections';
import { IPromotion } from './promotion';
import { ISeries } from './series';

export interface IProject {
  slug: string;
  logo?: string;
  title?: string;
  series?: ISeries | null;
  ctaCards?: ICtaCard[];
  currentNftPromotion?: IPromotion;
  posters?: PosterOverview[];
  watchNextProjectSlugs?: string[];
  views?: IProjectViews;
}

export interface IProjectTimeline {
  steps: IProjectTimelineStep[];
  currentStepIndex: number;
}

export interface IProjectTimelineStep {
  title: string;
}

export enum EProjectStatTypes {
  LAST_DAY_VIEWS = 'LAST_DAY_VIEWS',
  TOTAL_VIEWS = 'TOTAL_VIEWS',
  TOTAL_FILM_BOOST = 'TOTAL_FILM_BOOST',
  LAST_DAY_REWARDS = 'LAST_DAY_REWARDS',
  TOTAL_REWARDS = 'TOTAL_REWARDS',
}

export interface IProjectsStatsRequest {
  slugs: string[];
  types: EProjectStatTypes[];
}

export interface IProjectViews {
  totalViews?: number;
  lastDayViews?: number;
}
