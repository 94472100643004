var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":_vm.$vuetify.breakpoint.xs ? 'sliding-x-transition' : 'fade'},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({class:{
        'd-inline-block font-weight-medium underline': true,
        [`${_vm.buttonColor}--text`]: true,
      },attrs:{"role":"button","tabindex":"0"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return on.click.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return on.click.apply(null, arguments)}]}},'span',attrs,false),on),[_vm._t("default")],2)]}}],null,true),model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_vm._v(" "),_c('FullScreenDialog',{attrs:{"cardColor":"secondary","closeDialog":() => (_vm.dialogOpen = false),"closeOnClickAway":_vm.closeOnClickAway}},[(_vm.sanitizedHtmlContent)?_c('div',{class:{
        'mobile-agreement-container': _vm.$vuetify.breakpoint.xs,
        'agreement-container': _vm.$vuetify.breakpoint.smAndUp,
      },domProps:{"innerHTML":_vm._s(_vm.sanitizedHtmlContent)}}):_c('v-layout',{staticClass:"progress-spinner",attrs:{"justify-center":"","align-center":"","py-12":""}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }