/**
 * @enum Auth0ThemeType
 */
export enum Auth0ThemeBackgroundType {
  DEFAULT = 'default',
  IMAGE = 'image',
  VIDEO = 'video',
}

/**
 * @enum Auth0ThemePosition
 */
export enum Auth0ThemePosition {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface IAuth0ExtParams {
  'ext-background-type'?: Auth0ThemeBackgroundType;
  'ext-background'?: string;
  'ext-bg-colorstop-1'?: string;
  'ext-bg-colorstop-2'?: string;
  'ext-link-color'?: string;
  'ext-overlay'?: string;
  'ext-position'?: Auth0ThemePosition;
  'ext-title'?: string;
  'ext-title-color'?: string;
}
