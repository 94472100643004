
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { State, Mutation, Action } from 'vuex-class';
import { IUser } from '~/store/profile/types';
import VerifyEmailBlock from '~/components/EmailVerification/VerifyEmailBlock.vue';
import AcceptTermsAndConditions from '~/components/Home/AcceptTermsAndConditions.vue';
import AuthActions from '~/mixins/AuthActions';

@Component({
  components: {
    VerifyEmailBlock,
    AcceptTermsAndConditions,
  },
})
export default class LoginAndRegister extends Mixins(AuthActions) {
  @State((profile) => profile.user, { namespace: 'profile' })
  currentUser!: IUser;

  @Prop({ type: Boolean, default: false })
  readonly registerOnly!: boolean;
  @Prop({ type: String, default: '' })
  readonly ctaText!: string;

  showAcceptTermsAndConditions = false;
  showVerifyEmailBlock = false;

  @Mutation('updateUser', { namespace: 'profile' })
  private updateUser!: (args: any) => void;

  @Action('getGameJWT', { namespace: 'profile' })
  private getGameJwt!: () => void;

  mounted() {
    const { register, login } = this.$route.query;
    if (login) {
      this.goToLoginService();
    } else if (register) {
      this.goToRegisterService();
    }
  }

  goToLoginService() {
    this.promptToLogin({
      redirectAfterLoginPath: this.$route.fullPath,
    });
  }

  goToRegisterService() {
    this.$router.push('/earn-rewards-register');
  }

  handleSignedIn() {
    this.updateUser({ loggedIn: true });
    this.getGameJwt();
  }

  handleTermsAndConditionsAccepted() {
    this.showAcceptTermsAndConditions = false;
    setTimeout(() => (this.showVerifyEmailBlock = true), 300);
  }

  handleCloseVerifyEmailBlock() {
    this.showVerifyEmailBlock = true;
    this.updateUser({ loggedIn: true });
  }
}
