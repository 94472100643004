
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import { Action, State } from 'vuex-class';
import HoverActivation from '~/mixins/HoverActivation';
import { IUserNode } from '~/store/nodes/types';
import { IUser } from '~/store/profile/types';
import ProfileProgressIcon from './ProfileProgressIcon.vue';
import QuickBalance from '~/components/Home/QuickBalance.vue';
import QuickWalletAddress from '~/components/Home/QuickWalletAddress.vue';
import { ThemeName } from '~/store/types';

@Component({
  components: {
    ProfileProgressIcon,
    QuickBalance,
    QuickWalletAddress,
  },
  meta: {
    theme: ThemeName.DARK,
  },
})
export default class ProfileControls extends mixins(HoverActivation) {
  @Prop(Boolean)
  readonly isDisabled!: boolean;
  @Prop(Boolean)
  readonly drawerControl!: boolean;
  @Prop(Boolean)
  readonly showQuickBalance!: boolean;
  @Prop(String)
  readonly ethWalletAddress!: string;
  @Prop(String)
  readonly gyriWalletAddress!: string;

  @State((profile) => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State((nodes) => nodes.userNodes, { namespace: 'nodes' })
  userNodes!: IUserNode[];

  @Action('userLogout', { namespace: 'profile' })
  private userLogout!: () => void;

  get siteConfig() {
    return this.$sanityLookup.getSiteConfig()?.data;
  }

  get menuLinks() {
    return [
      {
        title: this.$t('common.misc.account'),
        href: '/account',
        requirement: true,
      },
      {
        title: this.$t('common.navigation.dashboard'),
        href: '/collection/dashboard',
        requirement: !!this.currentUser.loggedIn,
      },
      {
        title: this.$t('common.navigation.collection'),
        href: '/collection',
        requirement: !!this.currentUser?.loggedIn,
      },
      {
        title: this.$t('common.navigation.theater'),
        href: '/theater',
        requirement: this.userNodes.length,
      },
      {
        title: this.$t('common.cta.getGala'),
        href: '/get-gala',
        requirement: this.siteConfig?.shouldShowGetGala,
      },
      {
        title: this.$t('common.misc.orderHistory'),
        href: '/notifications?filter=purchase',
        requirement: true,
      },
      {
        title: this.$t('common.navigation.contactSupport'),
        href: '/contact-support',
        requirement: true,
      },
    ];
  }

  async handleLogout() {
    this.drawerControlState = false;
    await this.userLogout();
  }

  handleBlur(event: FocusEvent) {
    if (!this.drawerControlState) {
      return;
    }

    if (!event.relatedTarget) {
      this.drawerControlState = false;
      return;
    }

    const hasFocus = (this.$refs.menu as HTMLElement).contains(
      event.relatedTarget as Node,
    );
    if (!hasFocus) {
      this.drawerControlState = false;
    }
  }

  handleClick() {
    this.cancelHoverActivationEvent();
  }

  get drawerControlState() {
    return this.drawerControl || this.isHoverActivated;
  }

  set drawerControlState(value: boolean) {
    this.$emit('update:drawerControl', value);
    this.isHoverActivated = value;
  }
}
