import { Context } from '@nuxt/types';
import axios, { Method } from 'axios';
import {
  IPoster,
  IShard,
  IMysteryBox,
  IPrize,
  IScene,
  IMoment,
  IPack,
  ICollectible,
} from '~/types/collections';
import {
  ICollectionsResponse,
  IFilmCollectionOverview,
  ISceneMomentsRewardDetails,
  IProjectsRewardDetails,
  IFilmSceneStatsOverview,
  IUserPopcornBuckets,
  IUserRewardsDistribution,
  IFilmRewardMetrics,
} from '~/types/my-collection';
import { IProject, IProjectsStatsRequest } from '~/types/project';
import { RecaptchaActionType } from '~/types/recaptcha';
import { IUFCEvent } from '~/types/ufc';
import {
  IUpsertBoostItemAndGrantLockAllowanceRequest,
  IUnlockBoostItemRequest,
  IRequestHoldRequest,
  IRequestHoldCancelRequest,
} from '~/store/boosts/types';

export interface IFilmApiGetOptions {
  params?: Record<string, unknown>;
  requireAuth?: boolean;
  recaptchaAction?: RecaptchaActionType;
}

export class FilmApiService {
  context: Context;
  collectionsUrl: string;
  rewardsUrl: string;
  apiUrl: string;
  walletUrl: string;
  boostUrl: string;
  defaultGetOptions: IFilmApiGetOptions;

  constructor(context: Context) {
    this.context = context;
    this.apiUrl = context.env.filmApiServiceEndpoint;
    this.collectionsUrl = `${this.apiUrl}/collection`;
    this.rewardsUrl = `${this.apiUrl}/rewards`;
    this.walletUrl = `${this.apiUrl}/wallet`;
    this.boostUrl = `${this.apiUrl}/boost`;
    this.defaultGetOptions = {
      requireAuth: true,
    };
  }

  private async getAuthHeader() {
    const token = await this.context.$auth.getTokenSilently();
    if (!token) {
      return {};
    }

    return { Authorization: 'Bearer ' + token };
  }

  private async getRecaptchaToken(action: RecaptchaActionType) {
    const token = await this.context.$recaptchaService.execute(action);
    return { 'X-BOT-TOKEN': token };
  }

  async getItems(
    projectSlug: string,
  ): Promise<Partial<IFilmCollectionOverview>> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/items`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.items ?? {};
    } catch (err) {
      throw err;
    }
  }

  async get<T>(url: string, options?: IFilmApiGetOptions): Promise<T> {
    try {
      const requestOptions: IFilmApiGetOptions = options
        ? {
            ...this.defaultGetOptions,
            ...options,
          }
        : this.defaultGetOptions;

      const response = await axios.get(`${this.apiUrl}/${url}`, {
        headers: {
          ...(requestOptions.requireAuth ? await this.getAuthHeader() : {}),
          ...(requestOptions.recaptchaAction
            ? await this.getRecaptchaToken(requestOptions.recaptchaAction)
            : {}),
        },
        params: requestOptions.params,
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async post<T>(url: string, payload: any): Promise<T> {
    try {
      const response = await axios.post(`${this.apiUrl}/${url}`, payload, {
        headers: {
          ...(await this.getAuthHeader()),
        },
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async getCollectionOverview(
    projectSlug: string,
  ): Promise<Partial<IFilmCollectionOverview>> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/collection-overview`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data ?? {};
    } catch (err) {
      throw err;
    }
  }

  async getPosters(projectSlug: string): Promise<IPoster[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/posters`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.posters ?? [];
    } catch (err) {
      throw err;
    }
  }

  async getPoster(projectSlug: string, posterSlug: string): Promise<IPoster> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/posters/${posterSlug}`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.poster;
    } catch (err) {
      throw err;
    }
  }

  async getShards(projectSlug: string): Promise<IShard[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/shards`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.shards ?? [];
    } catch (err) {
      throw err;
    }
  }

  async getMysteryBoxes(projectSlug: string): Promise<IMysteryBox[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/mystery-boxes`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.mysteryBoxes ?? [];
    } catch (err) {
      throw err;
    }
  }

  async getPrizes(projectSlug: string): Promise<IPrize[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/prizes`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results.data?.prizes ?? [];
    } catch (err) {
      throw err;
    }
  }

  async getUFCEvent(ufcEventSlug: string): Promise<IUFCEvent> {
    try {
      const results = await axios.get(
        `${this.apiUrl}/ufc/event/${ufcEventSlug}`,
        {
          headers: {
            ...this.getAuthHeader(),
          },
        },
      );
      return results.data;
    } catch (err) {
      throw err;
    }
  }

  async getProject(projectSlug: string): Promise<IProject> {
    try {
      const results = await axios.get(`${this.apiUrl}/projects/${projectSlug}`);
      return results?.data;
    } catch (err) {
      throw err;
    }
  }

  async isItemRedeemed(projectSlug: string, itemId: string): Promise<boolean> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/is-item-redeemed/${itemId}`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results.data;
    } catch (err) {
      throw err;
    }
  }

  async redeemItem(
    projectSlug: string,
    itemId: string,
    transferCode: string,
    email: string,
  ): Promise<boolean> {
    try {
      const redeemItemPayload = {
        itemTokenInstanceId: itemId,
        email,
        transferCode,
      };
      const results = await axios.post(
        `${this.collectionsUrl}/${projectSlug}/redeem-item`,
        redeemItemPayload,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results.data;
    } catch (err) {
      throw err;
    }
  }

  async clearUserItems(): Promise<boolean> {
    try {
      const results = await axios.post(
        `${this.collectionsUrl}/clear-user-items`,
        {},
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results.data;
    } catch (err) {
      throw err;
    }
  }

  async getScenes(projectSlug: string): Promise<IScene[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/scenes`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.scenes ?? [];
    } catch (err) {
      throw err;
    }
  }

  async getMoments(projectSlug: string): Promise<IMoment[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/moments`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.moments ?? [];
    } catch (err) {
      throw err;
    }
  }

  async getPacks(projectSlug: string): Promise<IPack[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/moment-packs`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.momentPacks ?? [];
    } catch (err) {
      throw err;
    }
  }

  async getCollectibles(projectSlug: string): Promise<ICollectible[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/collectibles`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.collectibles ?? [];
    } catch (err) {
      throw err;
    }
  }

  async getSceneStats(
    projectSlug: string,
    sceneSlug: string,
  ): Promise<Partial<IFilmSceneStatsOverview>> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/scene/${sceneSlug}/stats`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.stats ?? {};
    } catch (err) {
      throw err;
    }
  }

  async loadAllCollections(): Promise<ICollectionsResponse> {
    try {
      const results = await axios.get(`${this.collectionsUrl}/items`, {
        headers: {
          ...(await this.getAuthHeader()),
        },
      });
      const {
        projectCollections,
        miscCollection,
        items: filmItems,
      } = results?.data ?? {};
      return { projectCollections, miscCollection, filmItems };
    } catch (err) {
      throw err;
    }
  }

  async getProjectRewardDetailsForUser(
    projectSlug: string,
  ): Promise<IProjectsRewardDetails> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/project/${projectSlug}/reward-details`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data ?? {};
    } catch (err) {
      throw err;
    }
  }

  async getProjectScenesRewardDetails(
    projectSlug: string,
  ): Promise<Record<string, IFilmSceneStatsOverview>> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/project/${projectSlug}/scenes/reward-details`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data ?? {};
    } catch (err) {
      throw err;
    }
  }

  async getSceneRewardDetails(
    projectSlug: string,
    sceneSlug: string,
  ): Promise<ISceneMomentsRewardDetails> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/project/${projectSlug}/scene/${sceneSlug}/reward-details`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data ?? {};
    } catch (err) {
      throw err;
    }
  }

  async getUserRewardDistributionSimpleOverview(): Promise<
    IUserRewardsDistribution[]
  > {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/reward-distribution/overview`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.items || [];
    } catch (err) {
      throw err;
    }
  }

  async getUserPopcornBuckets(): Promise<IUserPopcornBuckets[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/reward-distribution/user-popcorn-buckets`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.items || [];
    } catch (err) {
      throw err;
    }
  }

  async getUserTotalRewardsDistribution(): Promise<{ totalRewards: number }> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/reward-distribution/total`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data ?? {};
    } catch (err) {
      throw err;
    }
  }

  async getUserLastDayRewardsDistribution(): Promise<{
    lastDayRewards: number;
  }> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/reward-distribution/last-day`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data ?? {};
    } catch (err) {
      throw err;
    }
  }

  async setCompletedSetsForProject(
    projectSlug: string,
  ): Promise<IUserRewardsDistribution[]> {
    try {
      const results = await axios.get(
        `${this.collectionsUrl}/${projectSlug}/completed-set`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data?.rule || {};
    } catch (err) {
      throw err;
    }
  }

  async getProjectRewardStats(
    projectSlug: string,
  ): Promise<IFilmRewardMetrics> {
    try {
      const results = await axios.get(
        `${this.rewardsUrl}/project/${projectSlug}`,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results?.data || {};
    } catch (err) {
      throw err;
    }
  }

  async getWalletItems(): Promise<any> {
    try {
      const results = await axios.get(`${this.walletUrl}/items`, {
        headers: {
          ...(await this.getAuthHeader()),
        },
      });
      return results?.data;
    } catch (err) {
      throw err;
    }
  }

  public async filmApiCall(
    body: any = {},
    url: string,
    method: Method,
  ): Promise<any> {
    try {
      const results = await axios({
        method,
        url,
        data: body,
        headers: {
          ...(await this.getAuthHeader()),
        },
      });
      return results;
    } catch (err) {
      throw err;
    }
  }

  async upsertFilmBoostItemAndGrantLockAllowance(
    body: IUpsertBoostItemAndGrantLockAllowanceRequest,
  ): Promise<any> {
    return this.filmApiCall(body, `${this.boostUrl}/lock`, 'POST');
  }

  async upsertFilmBoostItemUnlock(body: IUnlockBoostItemRequest): Promise<any> {
    return this.filmApiCall(body, `${this.boostUrl}/unlock`, 'POST');
  }

  async getSupporters(projectSlug: string): Promise<any> {
    return this.filmApiCall(
      {},
      `${this.boostUrl}/${projectSlug}/leaderboard`,
      'GET',
    );
  }

  async getProjectFilmBoostStats(projectSlug: string): Promise<any> {
    return this.filmApiCall({}, `${this.boostUrl}/${projectSlug}/stats`, 'GET');
  }

  async getUsersFilmBoostHold(projectSlug: string): Promise<any> {
    return this.filmApiCall({}, `${this.boostUrl}/${projectSlug}/hold`, 'GET');
  }

  async getBoostsForProjects(): Promise<any> {
    return this.filmApiCall({}, `${this.boostUrl}/projects`, 'GET');
  }

  async requestFilmBoostHold(payload: IRequestHoldRequest): Promise<any> {
    return this.filmApiCall(payload, `${this.boostUrl}/request-hold`, 'PUT');
  }

  async cancelFilmBoostHold(payload: IRequestHoldCancelRequest): Promise<any> {
    return this.filmApiCall(payload, `${this.boostUrl}/cancel-hold`, 'PUT');
  }

  async fetchTokenBalance(): Promise<any> {
    return this.filmApiCall({}, `${this.boostUrl}/token-balance`, 'GET');
  }

  async getProjectsStatsOverview(body: IProjectsStatsRequest): Promise<any> {
    return this.filmApiCall(
      body,
      `${this.apiUrl}/projects/stats-overview`,
      'POST',
    );
  }

  async getOrderById(orderId: string): Promise<any> {
    return this.filmApiCall({}, `${this.apiUrl}/orders/${orderId}`, 'GET');
  }
}
