import { render, staticRenderFns } from "./AcceptTermsAndConditions.vue?vue&type=template&id=fc7ccb10"
import script from "./AcceptTermsAndConditions.vue?vue&type=script&lang=ts"
export * from "./AcceptTermsAndConditions.vue?vue&type=script&lang=ts"
import style0 from "./AcceptTermsAndConditions.vue?vue&type=style&index=0&id=fc7ccb10&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LegalAgreements: require('/builds/gala-games/gala-film/gala-film/src/components/LegalAgreements/LegalAgreements.vue').default,FullScreenDialog: require('/builds/gala-games/gala-film/gala-film/src/components/FullScreenDialog.vue').default})
