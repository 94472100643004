
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ThumbnailButton extends Vue {
  @Prop(String) readonly label!: string;
  @Prop({ type: String, default: '' }) readonly thumbnailAlt!: string;
  @Prop(String) readonly imageUrl!: string;
  @Prop([String, Number]) readonly width!: number | string;
  @Prop([String, Number]) readonly height!: number | string;

  get thumbWidth() {
    return !this.width
      ? 'auto'
      : typeof this.width === 'string'
      ? this.width
      : `${this.width}px`;
  }

  get thumbHeight() {
    return !this.height
      ? 'auto'
      : typeof this.height === 'string'
      ? this.height
      : `${this.height}px`;
  }
}
