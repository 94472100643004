import {
  IHydratedAdaptor,
  IHydrateAdaptorPayload,
  TTrackEventMethodKeys,
  TGenerateTrackPurchaseEventFallback,
} from './types';
import { generateHydrateAdaptorHelpers } from './generateHydrateAdaptorHelpers';

const purchaseEvents: TTrackEventMethodKeys = [
  'trackPurchaseItemEvent',
  'trackPurchaseNodeEvent',
  'trackPurchaseGalaGoldEvent',
  'trackPurchaseBridgeEvent',
];

type THydrateAdaptor = (adaptor: IHydrateAdaptorPayload) => IHydratedAdaptor;
export const hydrateAdaptor: THydrateAdaptor = (adaptor) => {
  const { partiallyHydratedAdaptor, applyCustomFallback } =
    generateHydrateAdaptorHelpers(adaptor);

  const generateTrackPurchaseEventFallback: TGenerateTrackPurchaseEventFallback =
    (eventName) => (payload) => {
      const finalPayload = {
        eventValue: {
          ...payload,
          items: [payload.item],
        },
        eventName,
      };
      partiallyHydratedAdaptor.trackEvent(finalPayload);
    };
  applyCustomFallback(
    purchaseEvents,
    generateTrackPurchaseEventFallback,
    partiallyHydratedAdaptor,
  );

  return partiallyHydratedAdaptor as IHydratedAdaptor;
};

export default hydrateAdaptor;
