import { ChainNetwork, EthereumTokenStandard } from '~/types/chain';

import { TokenChainId } from '../types/token-chain-id';

export enum UserItemsDisplayState {
  BRIDGE = 'BRIDGE',
  DETAILS = 'DETAILS',
  EXCHANGE = 'EXCHANGE',
  SEND = 'SEND',
}

interface IUserItemRarity {
  icon: string;
  label: string;
  hexcode: string;
  supplyLimit?: number;
}

export interface ICanBridgeTo {
  network: 'ethereum' | 'gyri';
  symbol: string;
  productBaseId?: string;
  destinationChainIds: TokenChainId[];
}

interface IUserItem {
  network: ChainNetwork;
  ethereumBaseId?: string;
  ethereumContractAddress?: string;
  ethereumFullId?: string;
  ethereumTokenStandard?: EthereumTokenStandard;
  fungible: boolean;
  name: string;
  description: string;
  collection: string;
  image: string;
  quantity: number;
  animation_url?: string;
  isExchangeable: boolean;
  uniqueInventoryPath?: string;
  fullName?: string;
  sendId?: string;
  canBridgeTo?:
    | [{ network: 'gyri' | 'ethereum'; destinationChainIds: TokenChainId[] }]
    | [];
  rarity: {
    icon: string;
    label: string;
    hexcode: string;
    supplyLimit?: number;
  };
  exchangeRevealVideo?: string;
  logo?: string;
  icon?: string;
  filmNftSlug?: string;
}

interface IEthTreasureChestUserItem extends IUserItem {
  network: ChainNetwork.ETH_TREASURE_CHEST;
  ethereumBaseId: string;
  fungible: true;
  ccLockedCount?: number;
  treasureChestClaimDetails: Array<{
    claimType: string;
    quantity: number;
  }>;
  treasureChestExportDetails: Array<{
    network: ChainNetwork.ETHEREUM;
    contractType: EthereumTokenStandard;
    contractAddress: string;
    nonFungible: boolean;
  }>;
}

export interface IGalaChainItemAllowance extends IUserItem {
  network: ChainNetwork.GYRI_TREASURE_CHEST;
  gyriTokenClassKey: {
    collection: string;
    category: string;
    type: string;
    additionalKey: string;
  };
  fungible: true;
  quantity: number;
  allowanceType: string;
  allowanceTransferFrom?: string;
}

export interface IGyriUserItem extends IUserItem {
  network: ChainNetwork.GYRI;
  gyriTokenClassKey: {
    collection: string;
    category: string;
    type: string;
    additionalKey: string;
  };
  fungible: false;
  nonFungibleInstanceId: string;
  quantity: 1;
  isExchangeable: false;
  canBridgeTo?:
    | [{ network: 'ethereum'; destinationChainIds: TokenChainId[] }]
    | [];
  gyriExchanges: IGyriExchange[];
}

export interface IGyriExchange {
  id: number;
  odds: IItemOdds[];
}

export interface IItemOdds {
  metadata: {
    name: string;
    rarity: IUserItemRarity;
    image: string;
  };
  quantity: number;
  odds: number;
  collection: string;
  category: string;
  type: string;
  additionalKey: string;
}

export interface IOffchainExchange extends IGyriExchange {
  exchangeVariantId: string;
}

export interface IOffchainUserItem extends Omit<IGyriUserItem, 'network'> {
  network: ChainNetwork.OFF_CHAIN_TOKEN;
  offChainTokenInstanceId: string;
  gyriExchanges: IOffchainExchange[];
}

export type TCustomRevealUserItem = IGyriUserItem | IOffchainUserItem;

export interface IErc721UserItem extends IUserItem {
  network: ChainNetwork.ETHEREUM;
  ethereumTokenStandard: EthereumTokenStandard.ERC721;
  ethereumContractAddress: string;
  model: string;
  fungible: false;
  nonFungibleInstanceId: string;
  properties: Array<{
    property: string;
    value: string;
    colors: Array<{
      name: string;
      value: string;
    }>;
  }>;
  canBridgeTo?: [{ network: 'gyri'; destinationChainIds: TokenChainId[] }] | [];
}

export interface IFungibleERC1155UserItem extends IUserItem {
  network: ChainNetwork.ETHEREUM;
  ethereumTokenStandard: EthereumTokenStandard.ERC1155;
  ethereumContractAddress: string;
  ethereumBaseId: string;
  ethereumFullId: string;
  fungible: true;
  canBridgeTo?: [{ network: 'gyri'; destinationChainIds: TokenChainId[] }] | [];
}

export interface INonFungibleERC1155UserItem extends IUserItem {
  network: ChainNetwork.ETHEREUM;
  ethereumTokenStandard: EthereumTokenStandard.ERC1155;
  ethereumContractAddress: string;
  ethereumBaseId: string;
  ethereumFullId: string;
  fungible: false;
  nonFungibleInstanceId: string;
  canBridgeTo?: [{ network: 'gyri'; destinationChainIds: TokenChainId[] }] | [];
}

export type UserItem =
  | IFungibleERC1155UserItem
  | INonFungibleERC1155UserItem
  | IEthTreasureChestUserItem
  | IGyriUserItem
  | IErc721UserItem
  | IGalaChainItemAllowance
  | IOffchainUserItem;

export type EthUserItem =
  | IFungibleERC1155UserItem
  | INonFungibleERC1155UserItem
  | IErc721UserItem;
export type GyriUserItem = IGyriUserItem;

export const isEthUserItem = (item: UserItem): item is EthUserItem => {
  return item.network === ChainNetwork.ETHEREUM;
};

export const isErc1155UserItem = (
  item: UserItem,
): item is IFungibleERC1155UserItem | INonFungibleERC1155UserItem => {
  return (
    item.network === ChainNetwork.ETHEREUM &&
    item.ethereumTokenStandard === EthereumTokenStandard.ERC1155
  );
};

export const isGyriUserItem = (item: UserItem): item is GyriUserItem => {
  return item.network === ChainNetwork.GYRI;
};

export interface ISortOption {
  id: string;
  title: string;
  property: string;
  direction: number;
  value?: any;
  displayTitle?: string;
}

export interface IFilterOption extends Omit<ISortOption, 'direction'> {}

export enum ActionType {
  NONE = '',
  SEND = 'SEND',
  EXPORT = 'EXPORT',
  EXCHANGE = 'EXCHANGE',
  BRIDGE = 'BRIDGE',
  FULFILL_ALLOWANCE = 'FULFILL_ALLOWANCE',
}

export interface ILockedItem {
  locked?: boolean;
  lockedUntil?: string;
  allowedActions?: Array<
    | ActionType.BRIDGE
    | ActionType.EXCHANGE
    | ActionType.SEND
    | ActionType.FULFILL_ALLOWANCE
  >;
}

export interface IBulkLockedItem extends ILockedItem {
  tokenInstanceId?: string;
}

export const isRedeemableFilmUserItem = (item?: UserItem) => {
  return (
    item?.network === ChainNetwork.GYRI &&
    item?.gyriTokenClassKey?.collection?.toLowerCase() === 'galafilm' &&
    [
      'RedeemableDigitalItem',
      'RedeemablePhysicalItem',
      'RedeemableExperience',
    ].includes(item?.gyriTokenClassKey?.type)
  );
};
