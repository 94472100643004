import { ActionTree } from 'vuex';
import { SanityDocument } from '@sanity/client';
import { groq } from '@nuxtjs/sanity';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import { SupportedLocales } from '~/utils/locales';
import { IRootState } from '~/store/types';
import { getLookupIds } from '~/store/documents/actions';
import {
  TDocStoreMutationPayloads,
  ISanityDocumentState,
} from '~/store/documents/types';
import { IReferencesState, TReferencePayloads } from './types';
import { RecaptchaActionType } from '~/types/recaptcha';

interface IFetchParams {
  id?: string[];
  type?: SanityDocumentSchema[];
}

export const actions: ActionTree<IReferencesState, IRootState> = {
  async clearCache({ commit }, payload: TReferencePayloads['clearCache']) {
    commit<TReferencePayloads['clearCache']>({
      type: 'clearCache',
      key: payload?.key,
    });
  },

  async fetchReferences(
    { commit, dispatch, rootGetters, state },
    payload: TReferencePayloads['fetchReferences'],
  ) {
    if (!payload._id?.length || !this.app.$sanity?.client) {
      return;
    }

    const params: IFetchParams = {};
    const filters: string[] = [];
    const ids: string[] = [];
    const sanityLookup = this.app.$sanityLookup;

    if (payload?._type?.length) {
      params.type = Array.isArray(payload._type)
        ? payload._type
        : [payload._type];
      filters.push(`_type in $type`);
    }

    const loadingState: Partial<IReferencesState> = {};

    getLookupIds(payload._id)?.forEach((id) => {
      const publishedId = id.replace(/^drafts./, '');
      if (
        !payload?.bypassCache &&
        !!(
          state.references?.[publishedId] &&
          'data' in state.references[publishedId]
        )
      ) {
        return;
      }

      if (!!state.references[publishedId]?.isLoading) {
        return;
      }

      ids.push(id);

      if (sanityLookup.isDraftId(id)) {
        return;
      }

      if (!loadingState.references) {
        loadingState.references = {};
      }

      loadingState.references[id] = {
        isLoading: true,
        hasError: false,
      };
    });

    if (!ids.length) {
      return;
    }

    params.id = ids;
    filters.push(`_id in $id`);

    if (loadingState.references) {
      commit<TReferencePayloads['updateReferences']>({
        type: 'updateReferences',
        references: loadingState.references,
      });
    }

    const query = groq`*[${filters.join(' && ')}]|order(_updatedAt desc)`;
    let res: SanityDocument[];
    try {
      res = await this.$filmApiService.get<SanityDocument[]>('cms', {
        params: { query, params },
        requireAuth: false,
        recaptchaAction: RecaptchaActionType.SANITY_FILM_CONTENT,
      });
    } catch (err) {
      // fallback to calling sanity directly
      res = await this.app.$sanity.client.fetch<SanityDocument[]>(
        query,
        params,
      );
    }

    const newState: Partial<IReferencesState> &
      Partial<Record<SanityDocumentSchema, Partial<ISanityDocumentState>>> & {
        refsToFetch?: Record<string, boolean>;
      } = {};

    if (res && res.length) {
      res.forEach((result) => {
        const docType = result?._type as SanityDocumentSchema;
        if (!result || !sanityLookup.storeTypes[docType]) {
          return;
        }

        const data = sanityLookup.transformDocument(
          result,
          this.app.i18n.locale as SupportedLocales,
        );
        if (!data._id || !data._type) {
          return;
        }

        const referencedIds = !!payload.fetchReferences
          ? sanityLookup.getDocumentRefIds(data)
          : undefined;

        if (referencedIds) {
          if (!newState?.refsToFetch) {
            newState.refsToFetch = {};
          }
          Object.assign(newState.refsToFetch, referencedIds);
        }

        if (!newState[docType]) {
          newState[docType] = {};
        }

        if (!newState[docType]!.documents) {
          newState[docType]!.documents = {};
        }

        newState[docType]!.documents![data._id] = {
          data,
          isLoading: false,
          hasError: false,
        };

        if (!newState.references) {
          newState.references = {};
        }

        newState.references[data._id.replace(/^drafts./, '')] = {
          data: {
            _id: data._id,
            _type: docType,
          },
          isLoading: false,
          hasError: false,
        };

        if (data.slug && typeof data.slug === 'string') {
          if (!newState[docType]!.slugs) {
            newState[docType]!.slugs = {};
          }

          newState[docType]!.slugs![data.slug] = {
            key: data._id,
            isLoading: false,
            hasError: false,
          };
        }
      });
    }

    // clean up loading state for anything that was queried for but didn't exist
    params.id?.forEach((id) => {
      if (!state.references?.[id]?.isLoading) {
        return;
      }

      if (!sanityLookup.isDraftId(id) && !newState.references?.[id]) {
        if (!newState.references) {
          newState.references = {};
        }
        newState.references[id] = {
          data: undefined,
          isLoading: false,
          hasError: false,
        };
      }
    });

    if (newState.refsToFetch) {
      dispatch<TReferencePayloads['fetchReferences']>({
        type: 'fetchReferences',
        _id: Object.keys(newState.refsToFetch).filter(
          (key) => !!newState.refsToFetch?.[key],
        ),
      });
    }

    Object.keys(newState).forEach((key) => {
      const docType = key as SanityDocumentSchema;
      const newStateForType = newState[docType];
      if (!newStateForType) {
        return;
      }

      if (newStateForType.documents) {
        commit<TDocStoreMutationPayloads['updateDocuments']>(
          {
            type: `documents.${docType}/updateDocuments`,
            documents: newStateForType.documents,
          },
          { root: true },
        );
      }

      if (newStateForType.slugs) {
        commit<TDocStoreMutationPayloads['updateSlugs']>(
          {
            type: `documents.${docType}/updateSlugs`,
            slugs: newStateForType.slugs,
          },
          { root: true },
        );
      }
    });

    if (newState.references) {
      commit<TReferencePayloads['updateReferences']>({
        type: 'updateReferences',
        references: newState.references,
      });
    }
  },
};
