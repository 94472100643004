
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import TriggerWalletCreationBtn from '~/components/NewWallet/TriggerWalletCreationBtn.vue';
import WalletCreationModalFlow from '~/components/NewWallet/WalletCreationModalFlow.vue';
import ConnectWeb3Wallet from '~/components/Wallet/ConnectWeb3Wallet.vue';
import ResponsiveDialog from '~/components/ResponsiveDialog.vue';
import { ICreateWalletPrompt, WalletType } from '~/store/types';
import { IWalletConnectionError } from '~/store/web3_wallet/types';
import { ModalType } from '~/types/modals';

@Component({
  components: {
    ConnectWeb3Wallet,
    ResponsiveDialog,
    TriggerWalletCreationBtn,
    WalletCreationModalFlow,
  },
})
export default class CreateWalletModalPrompt extends Vue {
  @State('createWalletPrompt') createWalletPromptState!: ICreateWalletPrompt;
  @State((profile) => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;
  @State((profile) => profile.user.walletConnected, { namespace: 'profile' })
  walletConnected!: boolean;
  @State((profile) => profile.user.hasGyriPassphrase, { namespace: 'profile' })
  hasGyriPassphrase!: boolean;
  @State((w3w) => w3w.connectedExternalWallet.ethAddress, {
    namespace: 'web3Wallet',
  })
  externalWalletAddress!: string;
  @State((state) => state.showWeb3WalletErrorDialog)
  showWeb3WalletErrorDialog!: boolean;

  @State((w3w) => w3w.walletConnectionError, { namespace: 'web3Wallet' })
  walletConnectionError!: IWalletConnectionError | null;

  @Mutation updateSnackbarSuccessText!: (args: any) => void;
  @Mutation toggleSuccessSnackbar!: (payload: boolean) => void;
  @Mutation toggleCreateWalletPrompt!: (
    payload?: Partial<ICreateWalletPrompt>,
  ) => void;
  @Mutation toggleWeb3WalletErrorDialog!: (payload?: boolean) => void;

  walletTypes = WalletType;

  get showCreateWalletPrompt() {
    return (
      !!this.createWalletPromptState.show &&
      !this.createWalletPromptState.isStarted
    );
  }

  set showCreateWalletPrompt(value) {
    this.toggleCreateWalletPrompt({ show: value, walletType: this.walletType });
  }

  get showGalaWalletModals() {
    return (
      !!this.createWalletPromptState.show &&
      !!this.createWalletPromptState.isStarted &&
      (this.walletType === WalletType.GALA ||
        this.walletType === WalletType.GYRI)
    );
  }

  set showGalaWalletModals(value) {
    this.handleSecondaryModalStateChange(value);
  }

  get showWeb3WalletModals() {
    return (
      !!this.createWalletPromptState.show &&
      !!this.createWalletPromptState.isStarted &&
      this.walletType === WalletType.ETH &&
      !this.hasWeb3WalletError
    );
  }

  set showWeb3WalletModals(value) {
    this.handleSecondaryModalStateChange(value);
  }

  get hasWeb3WalletError() {
    return !!this.walletConnectionError && !!this.externalWalletAddress;
  }

  get shouldShowWalletErrorModal() {
    return (
      !!this.createWalletPromptState.show &&
      !!this.createWalletPromptState.isStarted &&
      this.walletType === WalletType.ETH &&
      this.hasWeb3WalletError
    );
  }

  get walletType() {
    if (this.createWalletPromptState.walletType) {
      return this.createWalletPromptState.walletType;
    }
    if (!this.walletConnected) {
      return WalletType.ETH;
    }
    if (!this.hasGyriPassphrase) {
      return WalletType.GYRI;
    }
  }

  get headingText() {
    if (this.createWalletPromptState.title) {
      return this.createWalletPromptState.title;
    }
    return this.walletType === WalletType.GYRI
      ? this.$t('components.newWallet.createWalletModalPrompt.gyriHeading')
      : this.$t('components.newWallet.createWalletModalPrompt.ethHeading');
  }

  get bodyText() {
    if (this.createWalletPromptState.body) {
      return this.createWalletPromptState.body;
    }
    return this.walletType === WalletType.GYRI
      ? this.$t(
          'components.newWallet.createWalletModalPrompt.setGyriPassphrase',
        )
      : this.$t('components.newWallet.createWalletModalPrompt.setUpWallet');
  }

  get modalType() {
    return this.walletType === WalletType.GYRI
      ? ModalType.SET_TRANSFER_CODE
      : ModalType.CONNECT_WALLET;
  }

  handleCloseModal() {
    this.toggleCreateWalletPrompt({
      show: false,
      body: this.createWalletPromptState.body,
      title: this.createWalletPromptState.title,
      walletType: this.walletType,
    });
  }

  handleSecondaryModalStateChange(value: boolean) {
    if (
      value &&
      !(
        this.createWalletPromptState.show &&
        this.createWalletPromptState.isStarted
      )
    ) {
      this.toggleCreateWalletPrompt({
        show: true,
        isStarted: true,
        walletType: this.walletType,
      });
    }
    if (
      !value &&
      this.createWalletPromptState.show &&
      this.createWalletPromptState.isStarted
    ) {
      this.toggleCreateWalletPrompt({
        show: false,
        isStarted: false,
        walletType: this.walletType,
      });
    }
  }

  handleCancel() {
    this.$emit('cancel');
    this.$ua.trackPress({
      interactionTargetId: 'cancel-button',
      context: this.modalType,
    });

    this.handleCloseModal();
  }

  handleTriggerWalletCreationBtnClicked() {
    this.$ua.trackPress({
      interactionTargetId: 'continue-button',
      context: this.modalType,
    });
  }

  handleSuccess(value: boolean, snackbarMessage = '') {
    this.$emit('success', value);
    this.handleCloseModal();

    if (!snackbarMessage) {
      return;
    }

    if (value) {
      this.updateSnackbarSuccessText(snackbarMessage);
      this.toggleSuccessSnackbar(true);
    }
  }

  handleGalaWalletSuccess(value: boolean) {
    this.showGalaWalletModals = false;
    this.handleSuccess(value);
  }

  handleWeb3WalletSuccess(value: boolean) {
    this.showWeb3WalletModals = false;
    this.handleSuccess(value, value ? 'Wallet connected successfully' : '');
  }

  @Watch('hasWeb3WalletError', { immediate: true })
  handleHasWeb3WalletErrorChange(newValue?: boolean, oldValue?: boolean) {
    if (oldValue && !newValue) {
      this.handleSuccess(true, 'Wallet connected successfully');
    }
  }

  @Watch('shouldShowWalletErrorModal', { immediate: true })
  handleShowWalletErrorModalChange(newValue?: boolean, oldValue?: boolean) {
    this.toggleWeb3WalletErrorDialog(!!newValue);
  }

  @Watch('showWeb3WalletErrorDialog', { immediate: true })
  handleShowWeb3WalletErrorDialogChange(
    newValue?: boolean,
    oldValue?: boolean,
  ) {
    if (oldValue && !newValue && !this.showWeb3WalletModals) {
      this.handleCloseModal();
    }
  }

  @Watch('showCreateWalletPrompt', { immediate: true })
  handleShowCreateWalletPromptChange(newValue?: boolean, oldValue?: boolean) {
    if (oldValue === newValue) return;

    if (newValue) {
      this.$ua.trackModalViewEvent({ type: this.modalType });
      return;
    }
  }
}
