import { IEpisode } from '~/types/series';
import { IProjectState } from './types';
import { Dispatch } from 'vuex';

export function getNextWatchableEpisodeInSeries(
  currentEpisode: IEpisode,
  projectState: IProjectState,
): IEpisode | undefined {
  const episodes =
    projectState.projects?.[currentEpisode.projectSlug]?.series?.episodes;
  if (!episodes) {
    return;
  }

  const episodeIndex = episodes.findIndex(
    (ep) => ep.slug === currentEpisode.slug,
  );
  const nextEpisodeIndex = episodeIndex + 1;
  if (nextEpisodeIndex <= 0 || nextEpisodeIndex >= episodes.length) {
    return;
  }

  const nextEpisode = episodes[nextEpisodeIndex];
  return canEpisodeBeWatched(nextEpisode, projectState)
    ? nextEpisode
    : undefined;
}

export async function getNextWatchableEpisodeInSuggestedProjects(
  currentEpisode: IEpisode,
  projectState: IProjectState,
  dispatch: Dispatch,
): Promise<IEpisode | undefined> {
  const watchNextProjectSlugs =
    projectState.projects?.[currentEpisode.projectSlug]?.watchNextProjectSlugs;
  if (watchNextProjectSlugs) {
    for (const projectSlug of watchNextProjectSlugs) {
      // make sure we don't go in a loop of suggesting the same projects over and over
      if (projectState.projectsAutoPlayed.includes(projectSlug)) {
        continue;
      }
      await dispatch('getProject', { projectSlug });
      const firstEpisodeOfProject =
        projectState.projects?.[projectSlug]?.series?.episodes?.[0];
      if (
        firstEpisodeOfProject &&
        canEpisodeBeWatched(firstEpisodeOfProject, projectState)
      ) {
        return firstEpisodeOfProject;
      }
    }
  }
}

export function canEpisodeBeWatched(
  episode: IEpisode,
  projectState: IProjectState,
): boolean {
  if (!episode.canSuggestWatching) {
    return false;
  }

  const restrictionId = episode.restrictionId;
  return !restrictionId || projectState.viewingRestrictions[restrictionId];
}
