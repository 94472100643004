import { GetterTree } from 'vuex';
import { ICustomRevealState } from './types';
import { IRootState } from '../types';

export const getters: GetterTree<ICustomRevealState, IRootState> = {
  currentVideoState: (state) => {
    return state.currentVideoState;
  },
  currentExchangeState: (state) => {
    return state.currentExchangeState;
  },
  videoReady: (state) => {
    return state.videoReady;
  },
  videoBoxType: (state) => {
    return state.videoBoxType;
  },
  loop: (state) => {
    return state.shouldLoop;
  },
  rewards(state) {
    return state.rewards;
  },
  cardsLoaded(state) {
    return state.loaded;
  },
  cardsRevealed(state) {
    return state.revealed;
  },
  totalCards(state) {
    return state.totalCards;
  },
  areCardsLoaded(state) {
    return state.loaded.length === state.totalCards;
  },
  areCardsRevealed(state) {
    return state.revealed.length === state.totalCards;
  },
  shouldShowCards(state) {
    return state.showCards;
  },
};
