import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { INodesState, NodeTypes } from './types';
import { IRootState } from '../types';

export const state: INodesState = {
  nodesActive: 0,
  nodesActiveByType: {
    [NodeTypes.film]: 0,
  },
  nodesOwned: 0,
  nodesOwnedByType: {
    [NodeTypes.film]: 0,
  },
  userNodes: [],
  workloadStreakRewards: {
    totalQuantity: 0,
    dailyQuantity: 0,
    rewards: [],
    thresholds: [],
    additionalRewards: [],
    rewardsFrequency: {},
  },
};

const namespaced = true;

const nodes: Module<INodesState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default nodes;
