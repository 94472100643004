import { LocaleObject } from '@nuxtjs/i18n';
import { Locale } from 'date-fns';
import {
  enUS as dateFormatEn,
  ja as dateFormatJa,
  ko as dateFormatKo,
  zhCN as dateFormatZh,
} from 'date-fns/locale';
import dateFormatEnAbbrev from '../data/date-fns/locale-abbrev/en';

export enum SupportedLocales {
  EN = 'en',
  JA = 'ja',
  KO = 'ko',
  ZH = 'zh',
}

export const LOCALE_DATE_FORMATS: Record<SupportedLocales, Locale> = {
  [SupportedLocales.EN]: dateFormatEn,
  [SupportedLocales.JA]: dateFormatJa,
  [SupportedLocales.KO]: dateFormatKo,
  [SupportedLocales.ZH]: dateFormatZh,
};

export const LOCALE_DATE_FORMATS_ABBREV: Record<SupportedLocales, Locale> = {
  [SupportedLocales.EN]: dateFormatEnAbbrev,
  [SupportedLocales.JA]: dateFormatJa,
  [SupportedLocales.KO]: dateFormatKo,
  [SupportedLocales.ZH]: dateFormatZh,
};

export interface ILocale extends LocaleObject {
  flagCode: string;
  displayText: string;
}

export const DEFAULT_LOCALE: ILocale = {
  code: SupportedLocales.EN,
  flagCode: 'us',
  file: 'en_US.json',
  name: 'English',
  displayText: 'EN',
};

export function availableLocales(locales: LocaleObject[]): ILocale[] {
  for (const locale of locales) {
    switch (locale.code) {
      case SupportedLocales.EN:
        locale.flagCode = 'us';
        locale.displayText = 'EN';
        break;
      case SupportedLocales.KO:
        locale.flagCode = 'kr';
        locale.displayText = 'KR';
        break;
      case SupportedLocales.JA:
        locale.flagCode = 'jp';
        locale.displayText = 'JP';
        break;
      case SupportedLocales.ZH:
        locale.flagCode = 'cn';
        locale.displayText = 'CH';
        break;
    }
  }

  return locales as ILocale[];
}

export function getCurrentLocale(localeInfo: {
  localeCookie: string | undefined;
  browserLocale: string | undefined;
  locales: any;
}) {
  const { localeCookie, browserLocale, locales } = localeInfo;
  if (localeCookie) {
    for (const locale of availableLocales(locales)) {
      if (locale.code === localeCookie) {
        return locale;
      }
    }
  }

  if (browserLocale) {
    for (const locale of availableLocales(locales)) {
      if (locale.code === browserLocale) {
        return locale;
      }
    }
  }

  return DEFAULT_LOCALE;
}

export function getSanityLocaleField(
  field: string,
  locale: SupportedLocales,
  defaultLocale = DEFAULT_LOCALE.code,
) {
  return `coalesce(${field}.${locale}, ${field}.${defaultLocale}, ${field})`;
}

export function getDateFormatsFromLocale(
  locale: SupportedLocales,
  withAbbreviation = false,
) {
  const locale_formats = withAbbreviation
    ? LOCALE_DATE_FORMATS_ABBREV
    : LOCALE_DATE_FORMATS;
  return (
    locale_formats[locale] ||
    locale_formats[DEFAULT_LOCALE.code as SupportedLocales]
  );
}
