var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{directives:[{name:"ripple",rawName:"v-ripple",value:(_vm.showRipple),expression:"showRipple"}],staticClass:"rounded-xl fill-height mx-auto overlaycard-container",class:{
    'g-background-secondary': _vm.showFooter,
    transparent: !_vm.showFooter,
  },style:(_setup.styles)},[_c('v-responsive',{staticClass:"overflow-visible mx-auto full-width fill-height",attrs:{"aspect-ratio":_setup.imgAspectRatio}},[(_setup.isLoading)?_c('v-skeleton-loader',{staticClass:"full-width fill-height rounded-xl",attrs:{"type":"image"}}):_vm._e(),_vm._v(" "),_vm._l((_vm.overlayImgs),function(img,idx){return _c('img',{directives:[{name:"show",rawName:"v-show",value:(!_setup.isLoading),expression:"!isLoading"}],key:idx,staticClass:"overlaycard-img full-width fill-height",class:{
        locked: img?.state === _setup.EState.INCOMPLETE,
        'rounded-t-xl': _vm.showFooter,
        'rounded-xl': !_vm.showFooter,
      },attrs:{"src":img.src},on:{"load":_setup.onImgLoaded,"click":($event) => _setup.emit('imgClick', $event)}})}),_vm._v(" "),_c('div',{staticClass:"overlaycard-img play-icon justify-center full-width fill-height d-flex align-center"},[_vm._t("video")],2),_vm._v(" "),_c(_setup.Lock,{directives:[{name:"show",rawName:"v-show",value:(_vm.showCenterImg && !_setup.isLoading),expression:"showCenterImg && !isLoading"}],staticClass:"overlaycard-img overlaycard-img-lock full-width fill-height"}),_vm._v(" "),(_vm.labelText)?_c('div',{staticClass:"overlaycard-img-label px-4 py-1 font--button rounded-lg",class:{
        'overlaycard-img-label-bottom-left': _vm.labelDirection === 'left',
        'overlaycard-img-label-bottom-right': _vm.labelDirection === 'right',
      }},[_vm._v("\n      "+_vm._s(_vm.labelText)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"overlaycard-img-label overlaycard-img-label-top-left rounded"},[_vm._t("topLeftLabel")],2),_vm._v(" "),_c('div',{staticClass:"overlaycard-img-label-top-right"},[_vm._t("topRightLabel")],2),_vm._v(" "),_c('div',{staticClass:"overlaycard-img-label-bottom-left"},[_vm._t("bottomLeftLabel")],2),_vm._v(" "),_c('div',{staticClass:"overlaycard-img-label-bottom-right"},[_vm._t("bottomRightLabel")],2),_vm._v(" "),_c('div',{staticClass:"overlaycard-img-label-bottom full-width"},[_vm._t("bottomLabel")],2)],2),_vm._v(" "),(_vm.showFooter)?_c('div',{staticClass:"overlaycard-footer pa-4 full-width d-flex flex-row justify-space-between items-center",on:{"click":($event) => _setup.emit('footerClick', $event)}},[_c('div',{staticClass:"d-flex flex-column"},[_vm._t("title",function(){return [_c('span',{staticClass:"font--title-medium white--text pb-1"},[_vm._v("\n          "+_vm._s(_vm.footerTitle)+"\n        ")])]}),_vm._v(" "),_vm._t("footer-subtitle",function(){return [_c('span',{staticClass:"font--title-small g-text-tertiary"},[_vm._v("\n          "+_vm._s(_vm.footerSubtitle)+"\n        ")])]})],2),_vm._v(" "),_vm._t("footerSidenote",function(){return [_c('div',[_c('span',{staticClass:"d-flex justify-end font--title-small white--text"},[_vm._v("\n          "+_vm._s(_vm.footerSidenote)+"\n        ")])])]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }