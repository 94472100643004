import { Context } from '@nuxt/types';
import { captureUtmVariables } from '~/utils';

export default function (context: Context) {
  const { route, from } = context;

  if (route.path === from.path) {
    captureUtmVariables(route.query);
  }
}
