export default function getCookie(cookieName: string) {
  const cookieString = document.cookie
    .split(';')
    .find((cookie) => cookie.trim().startsWith(cookieName));
  return cookieString ? cookieString.split('=')[1] : undefined;
}

// Only use on JSON.stringified cookie values
export function getParsedCookie(cookieName: string) {
  const cookieString = getCookie(cookieName);
  if (cookieString) {
    try {
      return JSON.parse(cookieString);
    } catch {
      return undefined;
    }
  }
}
