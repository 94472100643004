import { Context } from '@nuxt/types';
import { ISanityObjects, SanityObjectSchema } from '~/types/sanity-objects';

export default async function (context: Context) {
  const { $sanityLookup, from, store } = context;

  const siteConfigSlug = store.state.siteConfigSlug;
  if (!siteConfigSlug) {
    return;
  }

  if (!store.state.redirects) {
    await store.dispatch('getSiteConfig');
  }

  const redirects = store.state.redirects as
    | Record<string, ISanityObjects[SanityObjectSchema.REDIRECT_TO]>
    | false;
  if (!redirects || !redirects[from.path]) {
    return;
  }

  const redirectTo = redirects[from.path];

  const path =
    redirectTo.linkType === 'docRef' && redirectTo.documentReference?._ref
      ? await $sanityLookup.getPageRoutePathFromRef(
          redirectTo.documentReference._ref,
        )
      : redirectTo.linkType === 'url' && redirectTo.href
      ? redirectTo.href
      : '';

  const isValidSuffix =
    redirectTo.reflinkSuffix?.[0] === '/' ||
    redirectTo.reflinkSuffix?.[0] === '?' ||
    redirectTo.reflinkSuffix?.[0] === '#';
  const suffix =
    redirectTo.linkType === 'docRef' && isValidSuffix
      ? redirectTo.reflinkSuffix
      : '';

  if (!path) {
    return;
  }

  try {
    const redirectUrl = new URL(path + suffix, window.location.origin);
    if (redirectUrl.href) {
      window.location.assign(redirectUrl.href);
    }
  } catch (e) {}
}
