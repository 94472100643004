import { GetterTree } from 'vuex';
import { IRootState, TAsyncStateWithData } from '~/store/types';
import { IGetterParams, IRefData, IReferencesState } from './types';

export const getters: GetterTree<IReferencesState, IRootState> = {
  getReference:
    (state) =>
    (opts: IGetterParams): TAsyncStateWithData<IRefData> | undefined => {
      return opts?._ref ? state.references?.[opts._ref] : undefined;
    },
};
