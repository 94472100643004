import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { ICustomRevealState, EExchangeState, EVideoState } from './types';
import { IRootState } from '../types';

export const state: ICustomRevealState = {
  currentVideoState: EVideoState.LOADING,
  currentExchangeState: EExchangeState.NOT_EXCHANGING,
  videoBoxType: 'bronze',
  shouldLoop: false,
  totalCards: 0,
  rewards: [],
  loaded: [],
  revealed: [],
  showCards: false,
  videoReady: false,
  newlyUnlockedSets: [],
  showCompletedSets: false,
};

const namespaced = true;

const nodes: Module<ICustomRevealState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default nodes;
