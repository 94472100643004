import { getHoursSinceTimestamp } from '~/utils/checkTimeDifference';

// Indicates whether a modal should be shown, based on the amount of time that has passed since the modal was last dismissed
export default function shouldNag(
  userSpecificPrefix: string,
  modalType: string,
  duration: number = 24,
) {
  const modalLastClosedDateString = localStorage.getItem(
    `${userSpecificPrefix}_${modalType}`,
  ) as string;
  let hoursSinceClosed;

  if (modalLastClosedDateString) {
    hoursSinceClosed = getHoursSinceTimestamp(modalLastClosedDateString);
  }

  return (
    !modalLastClosedDateString ||
    (hoursSinceClosed && hoursSinceClosed >= duration)
  );
}

export function setTimeModalWasDismissed(
  userSpecificPrefix: string,
  modalType: string,
) {
  localStorage.setItem(
    `${userSpecificPrefix}_${modalType}`,
    Date.now().toString(),
  );
}
