var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"max-width":"550px","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{class:{
      'pa-6 d-md-flex justify-space-between gc-migration-modal': true,
      modalBackground: !_vm.galaChainMigrationIsTomorrow,
      tomorrow: _vm.galaChainMigrationIsTomorrow,
    }},[_c('v-icon',{staticClass:"close-icon",attrs:{"color":_vm.galaChainMigrationIsTomorrow ? 'white' : 'accentLight'},on:{"click":_vm.closeModal}},[_vm._v("\n      close\n    ")]),_vm._v(" "),_c('div',{class:{
        'mr-6 pt-3': _vm.$vuetify.breakpoint.mdAndUp,
        'mb-6': _vm.$vuetify.breakpoint.smAndDown,
      }},[_c('GradientHexagon',{staticClass:"mx-auto d-block",attrs:{"uniqueId":"introducing-gala-chain","imgSrc":_vm.whiteHex,"width":"100px","minWidth":"100px","imgScale":70}})],1),_vm._v(" "),_c('div',[_c('h2',{class:{
          'mb-6': true,
          'accent--text': _vm.galaChainMigrationIsTomorrow,
        }},[_vm._v("\n        "+_vm._s(_vm.$t(
            `components.home.galaChainMigrationAlert.${
              _vm.galaChainMigrationIsTomorrow
                ? 'movingTomorrow'
                : 'introducingGC'
            }`,
          ))+"\n      ")]),_vm._v(" "),(_vm.galaChainMigrationIsTomorrow)?_c('p',{class:{ 'accent--text': _vm.galaChainMigrationIsTomorrow }},[_vm._v("\n        "+_vm._s(_vm.$t(
            'components.home.galaChainMigrationAlert.galaChainIsHereTomorrow',
          ))+"\n      ")]):_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('components.home.galaChainMigrationAlert.galaChainIsHere', {
            date: _vm.migrationDateLocaleString,
          }))+"\n      ")]),_vm._v(" "),_c('p',{class:{ 'accent--text': _vm.galaChainMigrationIsTomorrow }},[_vm._v("\n        "+_vm._s(_vm.$t('components.home.galaChainMigrationAlert.noActionNeeded'))+"\n      ")]),_vm._v(" "),_c('button',{staticClass:"gala-btn",on:{"click":_vm.learnMore}},[_vm._v("\n        "+_vm._s(_vm.$t('common.cta.learnMore'))+"\n      ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }