import { computed, ref, watchEffect } from 'vue';
import { MaybeComputedRef, resolveUnref } from '@vueuse/core';
import { useStore } from '~/store/composable';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import { ISanityObjects, SanityObjectSchema } from '~/types/sanity-objects';
import {
  buildShareLink,
  IShareLinkConfig,
  utmContentValuesToString,
} from '~/utils/shareLinks';
import { useContext } from '@nuxtjs/composition-api';

export const useShareLinks = (
  config?: MaybeComputedRef<Omit<IShareLinkConfig, 'utmSource'>>,
) => {
  const { state } = useStore();
  const userId = computed(() => state.profile?.user?.id);
  const referralCode = computed(() => state.profile?.user?.referralCode);

  const url = ref('');
  const facebook = ref('');
  const twitter = ref('');

  watchEffect(() => {
    const resolvedConfig = resolveUnref(config ?? {});
    const shareLinkConfig = {
      ...resolvedConfig,
      utmReferralCode: resolvedConfig.utmReferralCode || referralCode.value,
      utmUserId: resolvedConfig.utmUserId || userId.value,
      utmSource: '',
    };
    url.value = buildShareLink(shareLinkConfig) ?? '';
    facebook.value =
      buildShareLink({ ...shareLinkConfig, utmSource: 'facebook' }) ?? '';
    twitter.value =
      buildShareLink({ ...shareLinkConfig, utmSource: 'twitter' }) ?? '';
  });

  return {
    url,
    facebook,
    twitter,
  };
};

export const useSanityShareLinks = (
  config: MaybeComputedRef<
    Omit<IShareLinkConfig, 'utmSource' | 'utmContent'> & {
      slug?: string;
      schema?: SanityDocumentSchema;
      utmContent?:
        | string
        | ISanityObjects[SanityObjectSchema.UTM_CONTENT]['keyValuePairs'];
    }
  >,
) => {
  const { $sanityLookup } = useContext();

  const shareLinksConfig = computed(() => {
    const { slug, schema, ...resolvedConfig } = resolveUnref(config);
    const utmContent =
      typeof resolvedConfig.utmContent === 'string'
        ? resolvedConfig.utmContent
        : resolvedConfig.utmContent
        ? utmContentValuesToString(resolvedConfig.utmContent)
        : '';
    const url =
      resolvedConfig.url ||
      (slug && schema ? $sanityLookup.getPageRoutePath({ slug, schema }) : '');
    return {
      ...resolvedConfig,
      url,
      utmContent,
    };
  });
  return useShareLinks(shareLinksConfig);
};
