import { Locale } from 'date-fns';
import locale from 'date-fns/locale/en-US';

const formatDistanceLocaleAbbrev = {
  lessThanXSeconds: '{{count}}S',
  xSeconds: '{{count}}S',
  halfAMinute: '30S',
  lessThanXMinutes: '{{count}}M',
  xMinutes: '{{count}}M',
  aboutXHours: '{{count}}H',
  xHours: '{{count}}H',
  xDays: '{{count}}D',
  aboutXWeeks: '{{count}}W',
  xWeeks: '{{count}}W',
  aboutXMonths: '{{count}}M',
  xMonths: '{{count}}M',
  aboutXYears: '{{count}}Y',
  xYears: '{{count}}Y',
  overXYears: '{{count}}Y',
  almostXYears: '{{count}}Y',
};

const formatDistance = (
  token: keyof typeof formatDistanceLocaleAbbrev,
  count: number,
  options: { addSuffix?: boolean; comparison?: number },
) => {
  const result = formatDistanceLocaleAbbrev[token].replace(
    '{{count}}',
    count.toString(),
  );

  if (options !== null && options !== void 0 && options.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }

  return result;
};

const localeWithAbbrev: Locale = {
  ...locale,
  formatDistance,
};

export default localeWithAbbrev;
