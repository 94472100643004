export interface IBoostState {
  byProject: Record<string, IProjectBoost>;
  balance: INormalizedChainBalance | null;
}

export interface IProjectBoost {
  stats: IProjectBoostStats;
  userHold: IUserBoostHold | IUserBoostHoldRank;
  supporters: IFilmBoostSupporter[];
}

export interface IProjectBoostItem {
  projectSlug: string;
  totalAmountHeld: number;
  currentFilmBoostPerc: number;
  maxFilmBoostPerc: number;
  unlockingPeriodInSecs: number;
  instantUnlockFeePerc: number | null;
  tokenToFilmBoostPercMultiplier: number;
}

export interface IProjectBoostStats extends IProjectBoostItem {
  championsCount: number;
  userBoostPercentiles: number[];
}

export interface IUserBoostHold {
  userId: string;
  projectSlug: string;
  holdExpiresAt: Date | string | null;
  userAmountHeld: number;
  userFilmBoostPerc: number;
  holdingPeriodUnlockAmount: number | null;
}

export interface IUserBoostHoldRank extends IUserBoostHold {
  userRanking: number;
  userBoostPercentile: number;
}

export interface IFilmBoostSupporter
  extends Omit<
    IUserBoostHold,
    | 'holdingPeriodUnlockAmount'
    | 'holdExpiresAt'
    | 'userFilmBoostPerc'
    | 'projectSlug'
  > {
  profilePhotoUrl?: string | null;
  displayName?: string | null;
}

export interface IBoostItem {
  projectSlug: string;
  userId: string;
  amount: number;
  holdExpiresAt: Date | string | null;
  holdingPeriodUnlockAmount: number | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface IUpsertBoostItemRequest {
  projectSlug: string;
  amount: number;
}

export interface IUpsertBoostItemAndGrantLockAllowanceRequest {
  boost: IUpsertBoostItemRequest;
  transferCode: string;
  twoFactorCode: string;
  gyriInstanceId: string;
}

export interface IRequestHoldRequest {
  projectSlug: string;
  holdingPeriodUnlockAmount: number;
}

export interface IRequestHoldCancelRequest {
  projectSlug: string;
}

export enum EUnlockType {
  INSTANT = 'instant',
  HOLD = 'hold',
}
export interface IUnlockBoostItemRequest {
  projectSlug: string;
  gyriId: string;
  transferCode: string;
  quantity?: number;
  unlockType?: EUnlockType;
}

export enum LockState {
  REQUEST_UNLOCK = 'REQUEST_UNLOCK',
  UNLOCK_REQUEST_INPROGRESS = 'UNLOCK_REQUEST_INPROGRESS',
  FINALIZE_UNLOCK = 'FINALIZE_UNLOCK',
  INSTANTLY_UNLOCK = 'INSTANTLY_UNLOCK',
}

export interface INormalizedChainBalance {
  quantity: number;
  instanceIds: string[];
  collection: string;
  channel: string;
  category: string;
  additionalKey: string;
  lockedHolds: string;
  owner: string;
  type: string;
  quantityLocked: number;
  quantityAvailable: number;
  userId: string;
  gyriId: string;
}

export interface ILockedHold {
  name?: string;
  created: Date;
  expires: Date | null;
  quantity: number;
  createdBy: string;
  instanceId: string;
  lockAuthority: string;
}

export interface ILeaderboardResponse {
  projectSlug: string;
  users: IFilmBoostSupporter[];
  percentiles: number[];
}
