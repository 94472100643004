import { Context } from '@nuxt/types';
import axios from 'axios';
import { IRestrictionUpsertEvent } from '~/types/event-restriction';

export class RestrictionsService {
  context: Context;
  eventsUrl: string;
  qualifiedUrl: string;

  constructor(context: Context) {
    this.context = context;
    this.eventsUrl = `${context.env.restrictionsServiceEndpoint}/api/v1/events`;
    this.qualifiedUrl = `${context.env.restrictionsServiceEndpoint}/api/v1/restrictions`;
  }

  private async getAuthHeader() {
    const token = await this.context.$auth.getTokenSilently();
    if (!token) {
      return {};
    }

    return { Authorization: `Bearer ${token}` };
  }

  async getEvent(eventId: string) {
    try {
      const results = await axios.get(`${this.eventsUrl}/${eventId}`, {
        headers: {
          ...(await this.getAuthHeader()),
        },
      });
      return results.data;
    } catch (err) {
      throw err;
    }
  }

  async upsertEvent(event: IRestrictionUpsertEvent) {
    try {
      const { id, ...restOfEvent } = event;
      const url = id ? `${this.eventsUrl}/${id}` : this.eventsUrl;
      const results = await axios.post(url, restOfEvent, {
        headers: {
          ...(await this.getAuthHeader()),
        },
      });
      return results.data;
    } catch (err) {
      throw err;
    }
  }

  async isQualified(restrictionId: string, params: any = {}) {
    try {
      const url = `${this.qualifiedUrl}/${restrictionId}/is_qualified`;
      const results = await axios.get(url, {
        headers: {
          ...(await this.getAuthHeader()),
        },
        params,
      });
      return results.data;
    } catch (err) {
      throw err;
    }
  }
}
