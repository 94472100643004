
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import RequirePasscode from '~/components/Wallet/RequirePasscode.vue';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import ThemeProvider from '~/components/ThemeProvider.vue';
import CustomExchangeRevealBackgroundVideo from '~/components/Items/CustomExchangeRevealBackgroundVideo.vue';
import CustomExchangeRevealItemCard from '~/components/Items/CustomExchangeRevealItemCard.vue';
import { ThemeName } from '~/store/types';
import {
  EExchangeState,
  EVideoState,
  ICustomRevealState,
  IExchangeReward,
  ISet,
} from '~/store/customReveal/types';
import ImgOverlayCard from '~/components/Collections/ImgOverlayCard.vue';
import { getOverlayImgRef, getOverlayImgInput } from '~/utils/collections';
import {
  getEventBucket,
  getRestrictionUpsertEventId,
} from '~/utils/custom-reveal';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import { CustomRevealEventTypes } from '~/types/event-restriction';

@Component({
  components: {
    RequirePasscode,
    FullScreenDialog,
    CustomExchangeRevealBackgroundVideo,
    CustomExchangeRevealItemCard,
    TwoFactorAuthInputPrompt,
    ThemeProvider,
    ImgOverlayCard,
  },
})
export default class CustomExchangeReveal extends Vue {
  @Prop({ type: String, default: '' })
  readonly buyMoreLink!: string;

  @Prop({ type: String, default: '' })
  readonly collectionLink!: string;

  @Prop({ type: Boolean, default: true })
  readonly showMiddleCol!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly canExchange!: boolean;

  @Prop(String) readonly noItemsBG!: string;

  @Prop(String) readonly logo!: string;

  @Prop(String) readonly logoAlt!: string;

  @State((customReveal) => customReveal, { namespace: 'customReveal' })
  readonly customReveal!: ICustomRevealState;

  @State((profile) => profile.user.id, { namespace: 'profile' })
  userId!: string;

  @Getter('currentExchangeState', { namespace: 'customReveal' })
  readonly exchangeState!: EExchangeState;

  @Getter('rewards', { namespace: 'customReveal' })
  readonly rewards!: IExchangeReward[];

  @Getter('videoReady', { namespace: 'customReveal' }) videoReady!: boolean;

  @Getter('areCardsLoaded', { namespace: 'customReveal' })
  readonly areCardsLoaded!: boolean;

  @Getter('areCardsRevealed', { namespace: 'customReveal' })
  readonly areCardsRevealed!: boolean;

  @Action('setShowCompletedSets', { namespace: 'customReveal' })
  private setShowCompletedSets!: (payload: boolean) => void;

  @Action('setExchangeState', { namespace: 'customReveal' })
  private setExchangeState!: (payload: EExchangeState) => void;

  @Action('setVideoState', { namespace: 'customReveal' })
  private setVideoState!: (payload: EVideoState) => void;

  @Action('setTotalCards', { namespace: 'customReveal' })
  private setTotalCards!: (payload: number) => void;

  @Action('resetCustomReveal', { namespace: 'customReveal' })
  private resetCustomReveal!: () => void;

  themes = ThemeName;
  maxRewards = 4;

  @Watch('exchangeState', { immediate: true })
  handleExchangeStateChange(
    newState: EExchangeState,
    oldState: EExchangeState,
  ) {
    if (newState === oldState) {
      return;
    }

    switch (newState) {
      case EExchangeState.EXCHANGE_IN_FLIGHT:
        this.setVideoState(EVideoState.OPENING);
        break;
      case EExchangeState.EXCHANGE_COMPLETE:
        this.setTotalCards(
          Math.min(this.rewards?.length ?? 0, this.maxRewards),
        );
        break;

      case EExchangeState.NOT_EXCHANGING:
        this.setVideoState(EVideoState.LOADING);
        break;
    }
  }

  unmounted() {
    this.resetBox();
  }

  closeExchange(e: Event) {
    this.$emit('close');
    this.resetBox();
  }

  closeDialog(e: Event) {
    this.setExchangeState(EExchangeState.NOT_EXCHANGING);
  }

  goTo(e: Event, route: string) {
    this.closeExchange(e);
    this.resetBox();
    this.$router.push(`${route}`);
  }

  revealCompletedSet() {
    this.setShowCompletedSets(true);
  }

  getItemStyle(i: number, total: number) {
    const isInFirstHalf = i < total / 2;
    const chunks = !total || total <= 1 ? 1 : total - 1;
    const translate = `translate3d(${50 + i * (-100 / chunks)}%, 100%, -100px)`;
    const scale = `scale3d(0, 0, 0)`;
    const rotate = `rotateX(90deg) rotateY(${
      -195 + i * (30 / chunks)
    }deg) rotateZ(${isInFirstHalf ? 0 : -10}deg)`;
    const animationDelay = (i + 1) * 0.5;
    const style = {
      '--card-animation-delay': `${animationDelay}s,${animationDelay + 0.4}s`,
      '--card-transform': `${translate} ${scale} ${rotate}`,
      '--card-title-translateY': isInFirstHalf ? '-120%' : '-80%',
    };
    return style;
  }

  resetBox() {
    this.resetCustomReveal();
    this.$emit('reset');
  }

  onExchangeClick() {
    this.$emit('exchange');
  }

  handleCompletedSetClick(e: Event, route: string) {
    const set: ISet = {
      id: this.unlockedSet?.id || '',
      items: this.unlockedSet?.items || [],
    };
    const event = CustomRevealEventTypes.COMPLETED_SET_REDIRECT;
    this.recordEvent({
      event,
      value: { set },
      id: getRestrictionUpsertEventId(event, set.id),
      bucket: getEventBucket(set.id),
    });
    this.goTo(e, route);
  }

  recordEvent(params: {
    event: string;
    id: string;
    bucket: string;
    value?: any;
  }) {
    try {
      this.$restrictionsService.upsertEvent({
        id: params.id,
        type: params.event,
        userId: this.userId,
        bucket: params.bucket,
        value: params.value || {},
        persist: true,
      });
    } catch (err) {
      console.log(err);
    }
  }

  get unlockedSetUrl() {
    return (
      this.unlockedSet?.redirectUrl ||
      this.unlockedSet?.document?.unlockedSetUrl ||
      ''
    );
  }

  get unlockedSet() {
    return this.customReveal.newlyUnlockedSets?.[0];
  }

  get getUnlockedImageUrl() {
    let imgUrl = '';
    try {
      const set = this.customReveal.newlyUnlockedSets?.[0];
      const imageRef = getOverlayImgRef(set?.document as any) || '';
      imgUrl = imageRef ? this.$sanityImage?.urlFor?.(imageRef)?.url?.() : '';
    } catch (error) {
      console.error(error);
    }
    return imgUrl;
  }

  get overlayImgInput() {
    return getOverlayImgInput(this.getUnlockedImageUrl);
  }

  get isVideoReady() {
    return this.videoReady;
  }

  get shouldDisableInteractions() {
    return (
      this.exchangeState === EExchangeState.EXCHANGE_IN_FLIGHT ||
      this.exchangeState === EExchangeState.ENTER_PASSWORD
    );
  }

  get rewardsCards() {
    const rewards =
      this.rewards.length > this.maxRewards
        ? this.rewards.slice(0, this.maxRewards)
        : this.rewards;
    return rewards.map((r) =>
      parseInt(r.quantity) > 1 ? { ...r, name: `${r.quantity} ${r.name}` } : r,
    );
  }

  get allCardsLoaded() {
    return this.areCardsLoaded && !this.areCardsRevealed;
  }

  get allCardsRevealed() {
    return this.areCardsLoaded && this.areCardsRevealed;
  }

  get openBtnText() {
    return !this.isVideoReady
      ? this.$t('common.misc.loading')
      : this.exchangeState === EExchangeState.EXCHANGE_IN_FLIGHT ||
        this.exchangeState === EExchangeState.ENTER_PASSWORD
      ? this.$t('common.misc.opening')
      : this.$t(
          'components.items.customExchangeReveal.customExchangeReveal.openItem',
        );
  }

  get showCards() {
    return this.customReveal.showCards && !this.customReveal.showCompletedSets;
  }

  get isUnlockedText() {
    const text = this.$t(
      'components.items.customExchangeReveal.unlockedSet.isUnlocked',
      {
        setName: this.unlockedSet?.document?.title || '',
      },
    );
    return text;
  }

  get getCongratsUnlockedText() {
    const type = this.unlockedSet?.document?._type || '';
    switch (type) {
      case SanityDocumentSchema.POSTER:
        return this.$t(
          'components.items.customExchangeReveal.unlockedSet.congratsExpansion',
        );
      case SanityDocumentSchema.SCENE:
        return this.$t(
          'components.items.customExchangeReveal.unlockedSet.congratsScene',
        );
      default:
        break;
    }
    return '';
  }

  get allRewardsAddedText() {
    return this.$t(
      'components.items.customExchangeReveal.customExchangeReveal.allRewardsAdded',
    );
  }
}
