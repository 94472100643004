import axios from 'axios';
import getS3PresignedPost from '~/queries/getS3PresignedPost.gql';

class AwsService {
  getS3PresignedPost(fileName: string, fileType: string, client: any) {
    try {
      return client.query({
        query: getS3PresignedPost,
        variables: {
          pictureInfo: {
            fileName,
            fileType,
          },
        },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async postProfilePictureToS3(
    signedRequest: { url: string; fields: string },
    file: File,
  ) {
    try {
      const form = new FormData();
      const fields = JSON.parse(signedRequest.fields);
      Object.keys(fields).forEach((key) => form.append(key, fields[key]));
      form.append('Content-Type', file.type);
      form.append('file', file);

      const response = await axios.post(signedRequest.url, form);

      return response;
    } catch (err) {
      console.log(err);
      throw new Error(err as string);
    }
  }
}
export default new AwsService();
