export enum GraphQLErrorCodes {
  InvalidTwoFa = 'INVALID2FA',
  TwoFaRateLimited = '2FARATELIMIT',
  PriceMismatch = 'PRICEMISMATCH',
  PriceAdjustment = 'PRICEADJUSTMENTFAIL',
}

export const twoFaErrorCodes = [
  GraphQLErrorCodes.InvalidTwoFa,
  GraphQLErrorCodes.TwoFaRateLimited,
];

export function hasErrorCode(error: any, code: GraphQLErrorCodes) {
  return (
    error?.graphQLErrors?.[0]?.extensions?.code === code ||
    error?.message?.includes?.(code) ||
    error?.response?.data?.message?.includes(code)
  );
}

export function isInvalidTwoFaError(error: any) {
  return hasErrorCode(error, GraphQLErrorCodes.InvalidTwoFa);
}

export function isTwoFaRateLimitError(error: any) {
  return hasErrorCode(error, GraphQLErrorCodes.TwoFaRateLimited);
}

export function isTwoFaError(error: any) {
  return isInvalidTwoFaError(error) || isTwoFaRateLimitError(error);
}

export function isPriceMismatchError(error: any) {
  return hasErrorCode(error, GraphQLErrorCodes.PriceMismatch);
}

export function isPriceAdjustmentError(error: any) {
  return hasErrorCode(error, GraphQLErrorCodes.PriceAdjustment);
}

export function getNewPriceFromError(error: any) {
  const newPrice = error?.graphQLErrors?.[0]?.extensions?.newPrice;
  if (typeof newPrice !== 'number') {
    throw new Error('Invalid new price');
  }

  return newPrice;
}
