import { NuxtApp } from '@nuxt/types/app';
import { MetaInfo } from 'vue-meta';
import { TranslateResult } from 'vue-i18n';
import { ISanityImageBuilder } from '~/plugins/sanityImage';
import { ISanityObjects, SanityObjectSchema } from '~/types/sanity-objects';

interface INuxtI18nHeadOptions {
  /**
   * Adds a `dir` attribute to the HTML element.
   * @default false
   */
  addDirAttribute?: boolean;
  /**
   * Adds various SEO attributes.
   * @default false
   */
  addSeoAttributes?: boolean | ISeoAttributesOptions;
}

interface ISeoAttributesOptions {
  /**
   * An array of strings corresponding to query params you would like to include in your canonical URL.
   * @default []
   */
  canonicalQueries?: string[];
}

type NuxtI18nMeta = Required<Pick<MetaInfo, 'htmlAttrs' | 'link' | 'meta'>>;

interface IContext {
  $nuxt: NuxtApp;
  $nuxtI18nHead: (args: INuxtI18nHeadOptions) => NuxtI18nMeta;
  $sanityImage: ISanityImageBuilder;
  $t: (
    key: string,
    params?: Record<string, string>,
  ) => string | TranslateResult;
  $te: (key: string) => boolean;
}

export interface IMetaData {
  description?: string;
  image?: string;
  imageAlt?: string;
  ogDescription?: string;
  ogTitle?: string;
  title: string;
  url: string;
}

const DEFAULT_PATH_PREFIX = `common.htmlMeta`;

/**
 * Build head metadata for a page.
 * @param params - The values for head metatags.
 * @param params.description - The meta description for the page.
 * @param params.image - A url for the image to use for social media sharing.
 * @param params.imageAlt - Alt text for the image for social media sharing.
 * @param params.ogDescription - A description for social media sharing. Will use meta description if empty.
 * @param params.ogTitle - A title for social media sharing. Will use head title if empty.
 * @param params.title - The head title for the page.
 * @returns Head metadata
 * @see MetaInfo https://vue-meta.nuxtjs.org/api/#metainfo-properties
 */
export const getMetatags = ({
  description = '',
  image = 'https://static.gala.games/gala-film/images/gala_film_social.png',
  imageAlt = '',
  ogDescription = '',
  ogTitle = '',
  title,
  url,
}: IMetaData): MetaInfo => {
  return {
    title,
    meta: [
      {
        hid: 'description',
        property: 'description',
        content: description,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: ogTitle || title,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: ogDescription || description,
      },
      {
        hid: 'og:image[0]',
        property: 'og:image',
        content: image,
      },
      {
        hid: 'og:image[0]:width',
        property: 'og:image:width',
        content: '1200',
      },
      {
        hid: 'og:image[0]:height',
        property: 'og:image:height',
        content: '630',
      },
      {
        hid: 'og:image[0]:alt',
        property: 'og:image:alt',
        content: imageAlt,
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: url,
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        hid: 'twitter:url',
        property: 'twitter:url',
        content: url,
      },
      {
        hid: 'twitter:title',
        property: 'twitter:title',
        content: ogTitle || title,
      },
      {
        hid: 'twitter:description',
        property: 'twitter:description',
        content: ogDescription || description,
      },
      {
        hid: 'twitter:image[0]',
        property: 'twitter:image',
        content: image,
      },
      {
        hid: 'twitter:image[0]:alt',
        property: 'twitter:image:alt',
        content: imageAlt,
      },
    ],
  };
};

/**
 * Get the url for the og:image meta tag for a sanity document page.
 * @param $sanityImage - The sanity image builder
 * @param seo - The sanity SEO object
 * @returns A url for the og:image meta tag or undefined if there is no image.
 */
export const getSanitySeoImageUrl = (
  $sanityImage: ISanityImageBuilder,
  seo: ISanityObjects[SanityObjectSchema.SEO],
) => {
  return seo?.ogImage?.asset?._ref
    ? $sanityImage
        .urlFor(seo?.ogImage)
        .width(1200)
        .height(630)
        .fit('crop')
        .url()
    : undefined;
};

/**
 * Build the head title for a page
 * @param context - The component context (this).
 * @param title - The page title
 * @returns - The head title for the page with the site title appended if not on the home page.
 */
export const buildHeadTitle = (context: IContext, title: string) => {
  const siteTitle = context.$t(`${DEFAULT_PATH_PREFIX}.siteTitle`) as string;
  if (!title) {
    return siteTitle;
  }
  return context.$nuxt.$route.name === 'index' || title === siteTitle
    ? title
    : (context.$t(`${DEFAULT_PATH_PREFIX}.pageTitleWithSiteTitle`, {
        title,
        siteTitle,
      }) as string);
};

/**
 * Get head metadata from nuxt-i18n
 * @param context - The component context (this).
 * @param paths - The i18n paths for looking up head metadata values.
 * @param paths.description - The path for the meta description.
 * @param paths.imageAlt - The path for image alt text.
 * @param paths.ogDescription - The path for the description for social media sharing. Will use meta description if empty.
 * @param paths.ogTitle - The path for the title for social media sharing. Will use head title if empty.
 * @param paths.title - The path for the head title for the page.
 * @param params - Params to be passed to $t.
 * @returns Values from nuxt-i18n for head metadata.
 */
export const getMetadataFromI18n = (
  context: IContext,
  paths: Omit<IMetaData, 'image' | 'url'>,
  params: Record<string, string> = {},
) => {
  const siteTitle = context.$t(`${DEFAULT_PATH_PREFIX}.siteTitle`) as string;
  const title = context.$te(`${paths.title}`)
    ? (context.$t(`${paths.title}`, params) as string)
    : '';
  const metadata = {
    title: buildHeadTitle(context, title),
    description: context.$te(`${paths.description}`)
      ? (context.$t(`${paths.description}`, params) as string)
      : '',
    ogTitle: (context.$te(`${paths.ogTitle}`)
      ? context.$t(`${paths.ogTitle}`, params)
      : title || siteTitle) as string,
    ogDescription: (context.$te(`${paths.ogDescription}`)
      ? context.$t(`${paths.ogDescription}`, params)
      : context.$te(`${paths.description}`)
      ? context.$t(`${paths.description}`, params)
      : '') as string,
    imageAlt: context.$te(`${paths.imageAlt}`)
      ? (context.$t(`${paths.imageAlt}`, params) as string)
      : '',
  };
  return metadata;
};

/**
 * Get head metadata for routes from nuxt-i18n
 * @param context - The component context (this)
 * @param image - A url for the image to use for social media sharing.
 * @param params - Params to pass to $t
 * @returns Head metadata
 * @see MetaInfo https://vue-meta.nuxtjs.org/api/#metainfo-properties
 */
export const buildI18nRouteMeta = (
  context: IContext,
  image?: string,
  params?: Record<string, string>,
) => {
  const {
    $nuxt: { $route },
  } = context;
  const pathPrefix = $route?.name
    ? `common.htmlMetaByRoute.${$route.name}`
    : `${DEFAULT_PATH_PREFIX}.default`;
  const options = {
    title: `${pathPrefix}.title`,
    description: `${pathPrefix}.description`,
    ogTitle: `${pathPrefix}.ogTitle`,
    ogDescription: `${pathPrefix}.ogDescription`,
    imageAlt: `${pathPrefix}.imageAlt`,
  };
  const metadata = getMetadataFromI18n(context, options, params);
  const url = window.location.origin + window.location.pathname;
  return getMetatags({ ...metadata, image, url });
};

/**
 * Build head metadata for a sanity document page.
 * @param context - The component context (this)
 * @param seo - The sanity SEO object
 * @returns Head metadata
 * @see MetaInfo https://vue-meta.nuxtjs.org/api/#metainfo-properties
 */
export const buildSanityRouteMeta = (
  context: IContext,
  seo: ISanityObjects[SanityObjectSchema.SEO],
): MetaInfo => {
  const options = {
    title: buildHeadTitle(context, seo.title || ''),
    description: seo.description,
    image: getSanitySeoImageUrl(context.$sanityImage, seo),
    imageAlt: seo?.ogImage?.alt || '',
    ogTitle: seo.ogTitle || seo.title,
    ogDescription: seo.ogDescription,
    url: window.location.origin + window.location.pathname,
  };
  return getMetatags(options);
};

/**
 * Get head metadata for the whole app, including locales and site name.
 * This includes fallback content for pages that don't have a custom head, but most
 * pages should override this data with page specific title, description, and image.
 * @param context - The component context (this)
 * @returns Head metadata for layout components
 */
export const buildLayoutMeta = (context: IContext) => {
  const i18nHead = context.$nuxtI18nHead({ addSeoAttributes: true });
  const routeMeta = buildI18nRouteMeta(context);
  return {
    ...i18nHead,
    title: routeMeta.title,
    meta: [
      ...i18nHead.meta,
      ...(routeMeta.meta || []),
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: context.$t('common.htmlMeta.siteTitle') as string,
      },
    ],
  };
};
