
import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator';
const _shuffle = require('lodash.shuffle');

interface IShuffledWord {
  confirmed?: boolean;
  word: string;
  order: number;
}

@Component({
  components: {},
})
export default class ConfirmMnemonic extends Vue {
  @Prop(Number) readonly step!: number;
  @Prop(Number) readonly assignedStep!: number;
  @Prop(Array) readonly mnemonic!: string[];
  @Prop(Boolean) readonly saving!: boolean;

  currentWordToConfirmIndex = 0;
  wordsToConfirm: IShuffledWord[] = [];
  randomWords = [];
  incorrectGuess = '';
  shuffledMnemonic: IShuffledWord[] = [];

  get confirmedWords() {
    return this.wordsToConfirm.filter((word) => word.confirmed);
  }

  get valid() {
    return this.wordsToConfirm.every((word) => word.confirmed);
  }

  formattedNumber(order: number) {
    const filters = this.$options.filters;
    return filters && filters.formatNumber(order, '0o');
  }

  checkIfCorrect(val: IShuffledWord) {
    if (
      this.wordsToConfirm[this.currentWordToConfirmIndex].order === val.order
    ) {
      this.wordsToConfirm[this.currentWordToConfirmIndex].confirmed = true;
      if (this.currentWordToConfirmIndex < this.wordsToConfirm.length - 1) {
        this.currentWordToConfirmIndex++;
        this.setRandomWords();
      }
    } else {
      this.incorrectGuess = val.word;
    }
  }

  tryAgain() {
    this.setRandomWords();
    this.incorrectGuess = '';
  }

  getRandomValues(quantity: number, showConfirmed?: boolean, filter?: string) {
    const values: [IShuffledWord] = _shuffle(
      this.mnemonic.map((word, order) => {
        const value: IShuffledWord = { word, order };
        if (showConfirmed) value.confirmed = false;
        return value;
      }),
    );
    const filtered = filter
      ? values.filter((el) => el.word !== filter)
      : values;
    return filtered.slice(0, quantity);
  }

  setRandomWords() {
    const wordToConfirm = this.wordsToConfirm[this.currentWordToConfirmIndex];
    this.randomWords = _shuffle([
      wordToConfirm,
      ...this.getRandomValues(2, false, wordToConfirm.word),
    ]);
  }

  created() {
    this.shuffleMnemonic(this.mnemonic);
  }

  @Emit('mnemonic-confirmed')
  acknowledgementsValidChange(boxChecked: boolean) {
    return boxChecked;
  }

  @Watch('valid')
  handleValid(isValid: boolean) {
    this.acknowledgementsValidChange(isValid);
  }

  @Watch('mnemonic')
  shuffleMnemonic(mnemonic: string[]) {
    if (mnemonic.length) {
      this.wordsToConfirm = this.getRandomValues(3, true);
      this.setRandomWords();
    }
  }
}
