export default function checkCreditCardKeydown(
  event: KeyboardEvent,
  inputLength: number,
  maxLength: number,
) {
  if (
    event.keyCode !== 8 &&
    event.keyCode !== 9 &&
    (inputLength >= maxLength ||
      !(
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105)
      ))
  ) {
    event.preventDefault();
  }
}
