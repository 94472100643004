
import '@mux/mux-player';
import type MuxPlayerElement from '@mux/mux-player';
import { StreamTypes } from '@mux/playback-core';
import { VideoEvents } from '@mux/mux-video';
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  IVideoPlayerOptions,
  IVideoMetadata,
  TMuxToggleControls,
} from '~/types/mux-player';

@Component({
  inheritAttrs: false,
})
export default class VideoPlayerRaw extends Vue {
  @Prop({ type: String, default: '' })
  readonly playbackId!: string;
  @Prop(String)
  readonly playbackToken!: string;
  @Prop(String)
  readonly thumbnailToken!: string;
  @Prop({
    type: Object,
    default: () => ({
      shouldShowControls: true,
      shouldShowTitle: false,
    }),
  })
  readonly playerOptions!: IVideoPlayerOptions;
  @Prop({
    type: Object,
    default: () => ({
      player_name: 'Gala-Film-Player',
      player_version: '0.1',
    }),
  })
  readonly videoMetadata!: Partial<IVideoMetadata>;

  // See full list of attributes here:
  // https://github.com/muxinc/elements/tree/main/packages/mux-player#attributes

  defaultAttrs: Record<string, string | number> = {
    'backward-seek-offset': 15,
    'forward-seek-offset': 15,
    'start-time': 0,
    'stream-type': StreamTypes.ON_DEMAND,
    'min-resolution': '480p',
  };

  eventListeners: Array<{ name: string; handler: (e: Event) => void }> = [];
  muxPlayer: MuxPlayerElement | undefined = undefined;
  playerInitTime = 0;

  mounted() {
    if (!this.$refs.player) {
      return;
    }

    this.playerInitTime = Date.now();
    this.muxPlayer = this.$refs.player as unknown as MuxPlayerElement;
    this.attachMetadata();
    this.attachEvents();
    this.$emit('mounted');
  }

  beforeDestroy() {
    this.eventListeners.forEach(({ name, handler }) => {
      (this.muxPlayer as unknown as HTMLElement).removeEventListener(
        name,
        handler,
      );
    });
  }

  attachMetadata() {
    if (this.videoMetadata && this.muxPlayer) {
      this.muxPlayer.metadata = {
        ...this.videoMetadata,
        player_init_time: this.playerInitTime,
      };
    }
  }

  attachEvents() {
    if (VideoEvents.length && this.muxPlayer) {
      VideoEvents.forEach((eventName) => {
        const eventHandler = (event: Event) => this.$emit(eventName, event);
        (this.muxPlayer as unknown as HTMLElement).addEventListener(
          eventName,
          eventHandler,
        );
        this.eventListeners.push({ name: eventName, handler: eventHandler });
      });
    }
  }

  getVideoDuration() {
    return this.muxPlayer?.duration;
  }

  getVideoCurrentTime() {
    return this.muxPlayer?.currentTime || 0;
  }

  isPaused() {
    return this.muxPlayer?.paused;
  }

  play() {
    this.muxPlayer?.play();
  }

  pause() {
    this.muxPlayer?.pause();
  }

  setCurrentTime(time: number) {
    if (this.muxPlayer) {
      this.muxPlayer.currentTime = time;
    }
  }

  get playerAttrs() {
    const attrs: Record<string, string | number> = {
      ...this.defaultAttrs,
      ...this.$attrs,
    };

    if (this.playbackToken) {
      attrs['playback-token'] = this.playbackToken;
    }

    if (this.playbackId) {
      attrs['playback-id'] = this.playbackId;
    }

    if (this.thumbnailToken) {
      attrs['thumbnail-token'] = this.thumbnailToken;
    }

    if (this.playerOptions.shouldShowTitle && this.videoMetadata.video_title) {
      attrs.title = this.videoMetadata.video_title;
    }

    if (process.env.muxDebug) {
      const { muxdebug, minres, muxreverse } = this.$route.query;
      if (muxdebug) {
        attrs['debug'] = 'true';
      }
      if (minres) {
        attrs['min-resolution'] = minres as string;
      }
      if (muxreverse) {
        attrs['rendition-order'] = 'desc';
        attrs['rendition_order'] = 'desc';
        console.log('reversing order');
      }
    }

    return attrs;
  }

  get style() {
    let style: Partial<CSSStyleDeclaration> & Partial<TMuxToggleControls> = {
      height: 'auto',
      width: '100%',
    };

    if (!this.playerOptions.shouldShowControls) {
      style['--controls'] = 'none';
    }

    if (this.playerOptions.aspectRatio) {
      const [w, h] = this.playerOptions.aspectRatio.split(':');
      style.aspectRatio = `${w} / ${h}`;
    }

    return style;
  }
}
