// Returns the amount of time elapsed between two date times, rounded down to the nearest whole unit (e.g. 1.2 hours will be rounded down to 1 if 'hours' is the unit)
export default function checkTimeDifference(
  laterDate: number,
  earlierDate: number,
  unitOfTime: 'minutes' | 'hours' | 'days',
) {
  let millisecondsPerUnit = null;

  switch (unitOfTime) {
    case 'days':
      millisecondsPerUnit = 1000 * 60 * 60 * 24;
      break;

    case 'hours':
      millisecondsPerUnit = 1000 * 60 * 60;
      break;

    case 'minutes':
      millisecondsPerUnit = 1000 * 60;
      break;
  }

  return millisecondsPerUnit
    ? Math.floor((laterDate - earlierDate) / millisecondsPerUnit)
    : millisecondsPerUnit;
}

export function getHoursSinceTimestamp(timeInMilliseconds: string | number) {
  const modalLastClosedDate =
    typeof timeInMilliseconds === 'string'
      ? Number(timeInMilliseconds)
      : timeInMilliseconds;
  const currentDate = Date.now();

  return checkTimeDifference(currentDate, modalLastClosedDate, 'hours');
}
