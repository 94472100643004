const key = 'redirectAfterLogin';

export function setRedirectAfterLogin(route: string) {
  localStorage.setItem(key, route);
}

export function consumeRedirectAfterLogin() {
  const value = localStorage.getItem(key);
  localStorage.removeItem(key);
  return value;
}
