import { useContext } from '@nuxtjs/composition-api';
import copy from 'copy-to-clipboard';
import { useStore } from '~/store/composable';
import { ISnackbarOptions, ISnackbarPayload } from '~/store/types';

export const useCopyToClipboard = () => {
  const { i18n, $sentry } = useContext();
  const { commit } = useStore();
  const toggleSnackbar = (payload?: boolean) => {
    commit('toggleSnackbar', payload);
  };

  const updateSnackbar = (args?: string | ISnackbarPayload) => {
    commit('updateSnackbar', args);
  };

  const copyToClipboard = (
    value: string,
    label = '',
    messagePosition = 'centered' as ISnackbarOptions['position'],
  ) => {
    try {
      copy(value, {
        debug: process.env.nodeEnv !== 'production',
        message: i18n.t('mixins.copyToClipboard.pressKeyToCopy').toString(),
        format: 'text/plain',
      });

      const message = (
        label
          ? i18n.t('mixins.copyToClipboard.copied', {
              label,
            })
          : i18n.t('mixins.copyToClipboard.copiedDefault').toString()
      ).toString();

      updateSnackbar({
        message,
        color: 'accentPurpleLight',
        light: true,
        icon: 'mdi-content-copy',
        position: messagePosition,
        rounded: 'lg',
      });
      toggleSnackbar(true);
    } catch (err) {
      $sentry.captureException(err);
      const message = (
        label
          ? i18n.t('mixins.copyToClipboard.unableToCopy', {
              label,
            })
          : i18n.t('mixins.copyToClipboard.unableToCopyDefault')
      ).toString();
      updateSnackbar({
        message,
        color: 'error',
        position: messagePosition,
        rounded: 'lg',
      });
      toggleSnackbar(true);
    }
  };

  return {
    copyToClipboard,
  };
};
