import { Plugin } from '@nuxt/types';
import { FilmApiService } from './filmApiService';

export type IFilmApiServiceLoader = InstanceType<typeof FilmApiService>;

declare module 'vue/types/vue' {
  // tslint:disable-next-line: interface-name
  interface Vue {
    $filmApiService: IFilmApiServiceLoader;
  }
}

declare module '@nuxt/types' {
  // tslint:disable-next-line: interface-name
  interface NuxtAppOptions {
    $filmApiService: IFilmApiServiceLoader;
  }

  // tslint:disable-next-line: interface-name
  interface Context {
    $filmApiService: IFilmApiServiceLoader;
  }
}

declare module 'vuex/types/index' {
  // tslint:disable-next-line: interface-name
  interface Store<S> {
    $filmApiService: IFilmApiServiceLoader;
  }
}

const filmApiServicePlugin: Plugin = (ctx, inject) => {
  const plugin = new FilmApiService(ctx);
  inject('filmApiService', plugin);
};

export default filmApiServicePlugin;
