
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import SignUp from '~/components/UIComponents/SignUp.vue';
import LanguageSwitcher from '~/components/LanguageSwitcher.vue';
import { IRootState, TSiteConfigState, ThemeName } from '~/store/types';

@Component({
  components: { SignUp, LanguageSwitcher },
})
export default class GalaFooter extends Vue {
  @Prop(Boolean) readonly uaPage!: boolean;
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;

  @State('discordLink') readonly discordLink!: string;

  @Getter('siteConfig')
  readonly siteConfigState!: TSiteConfigState;

  routesToHideFooterOn = ['games-buy-item-id'];
  theme = ThemeName.DARK;
  footerSignupTagId = '228';

  get shouldShowLanguageSwitcher() {
    return this.siteConfigState?.data?.shouldShowLanguageSwitcher;
  }

  get hideFooter() {
    return this.routesToHideFooterOn.includes(this.$route.path);
  }

  get galaCompanyLink() {
    return process.env.galaCompanyLink;
  }

  onTrackNavigation(url: string) {
    this.$ua.trackNavigationEvent({
      to: url,
      from: this.$route.fullPath,
    });
  }
}
