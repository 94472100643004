
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import LegalAgreementsQuery from '~/queries/legalAgreements.gql';
import AgreementDialog from './AgreementDialog.vue';
import AgreementStatement from './AgreementStatement.vue';
import ScrollToAccept from './ScrollToAccept.vue';
import {
  DEFAULT_LEGAL_DOCUMENT as defaultLegalDocument,
  DEFAULT_LEGAL_AGREEMENTS_PAYLOAD as defaultLegalAgreementsPayload,
  DocumentTypes,
  IDocumentsAccepted,
  IDocumentAccepted,
} from '~/types/legal-documents';

export interface ILegalAgreementsPayload {
  documentsAccepted: IDocumentsAccepted;
  allAccepted?: boolean;
}

@Component({
  components: { AgreementDialog, ScrollToAccept, AgreementStatement },
})
export default class LegalAgreements extends Vue {
  @Prop({ type: String, default: 'accentPurple' })
  readonly checkboxColor!: string;

  @Prop({ type: String, default: 'accentPurple' })
  readonly dialogButtonColor!: string;

  @Prop({ type: String })
  readonly termsAndConditions!: 'scroll-to-accept' | 'checkbox';

  @Prop({ type: String })
  readonly privacyPolicy!: 'checkbox';

  @Prop({ type: String })
  readonly termsAndConditionsWithPrivacyPolicy!: 'checkbox';

  @Prop({ type: String })
  readonly nodeNonInvestmentStatement!: 'checkbox';

  @Prop(String)
  readonly gameName: string | undefined;

  @Prop(String)
  readonly gameFAQLink: string | undefined;

  @Prop({ type: Boolean })
  readonly isGameNodePurchase: false | undefined;

  @Prop({ type: Object, default: () => defaultLegalAgreementsPayload })
  readonly value!: ILegalAgreementsPayload;

  @Prop({ type: Boolean, default: false }) readonly closeOnClickAway!: boolean;

  fetching = true;

  termsAndConditionsData = defaultLegalDocument;
  privacyPolicyData = defaultLegalDocument;
  nodeNonInvestmentStatementData = defaultLegalDocument;

  termsAndConditionsVersionAccepted = '';
  privacyPolicyVersionAccepted = '';
  nodeNonInvestmentStatementVersionAccepted = '';
  creditCardTermsAndConditionVersionAccepted = '';

  initVars(payload: ILegalAgreementsPayload) {
    this.termsAndConditionsVersionAccepted = this.getVersionByDocumentType(
      DocumentTypes.TERMS_AND_CONDITIONS,
      payload.documentsAccepted,
    );
    this.privacyPolicyVersionAccepted = this.getVersionByDocumentType(
      DocumentTypes.PRIVACY_POLICY,
      payload.documentsAccepted,
    );
    this.nodeNonInvestmentStatementVersionAccepted =
      this.getVersionByDocumentType(
        DocumentTypes.NODE_NON_INVESTMENT_STATEMENT,
        payload.documentsAccepted,
      );
    this.creditCardTermsAndConditionVersionAccepted =
      this.getVersionByDocumentType(
        DocumentTypes.CREDIT_CARD_TERMS_AND_CONDITIONS,
        payload.documentsAccepted,
      );
  }

  documentTypeFilter(docType: DocumentTypes) {
    return (doc: IDocumentAccepted) => doc.documentType === docType;
  }

  findByDocumentType(
    docType: DocumentTypes,
    arr?: IDocumentsAccepted,
  ): IDocumentAccepted | undefined {
    if (!arr) arr = this.acceptedDocuments;
    return arr.find(this.documentTypeFilter(docType));
  }

  getVersionByDocumentType(
    docType: DocumentTypes,
    arr?: IDocumentsAccepted,
  ): IDocumentAccepted['versionAccepted'] {
    const doc = this.findByDocumentType(docType, arr);
    if (!doc) return '';

    return doc.versionAccepted;
  }

  mounted() {
    this.fetchTermsAndConditions();
    this.initVars(this.value);
    this.emitInput();
  }

  async fetchTermsAndConditions() {
    const { data } = await this.$apollo.query({
      query: LegalAgreementsQuery,
    });

    this.termsAndConditionsData = data.filmTermsAndConditions;
    this.privacyPolicyData = data.privacyPolicy;
    this.nodeNonInvestmentStatementData = data.nodeNonInvestmentStatement;

    this.fetching = false;
  }

  emitInput(): void {
    this.$emit('input', this.emitPayload);
  }

  get emitPayload(): ILegalAgreementsPayload {
    return {
      documentsAccepted: this.documentsAccepted,
      allAccepted: this.allRequiredDocumentsAccepted,
    };
  }

  get acceptedDocuments() {
    return [
      {
        required:
          !!this.termsAndConditions ||
          !!this.termsAndConditionsWithPrivacyPolicy,
        documentType: DocumentTypes.TERMS_AND_CONDITIONS,
        versionAccepted: this.termsAndConditionsVersionAccepted,
      },
      {
        required:
          !!this.privacyPolicy || !!this.termsAndConditionsWithPrivacyPolicy,
        documentType: DocumentTypes.PRIVACY_POLICY,
        versionAccepted: this.privacyPolicyVersionAccepted,
      },
      {
        required: !!this.nodeNonInvestmentStatement,
        documentType: DocumentTypes.NODE_NON_INVESTMENT_STATEMENT,
        versionAccepted: this.nodeNonInvestmentStatementVersionAccepted,
      },
    ];
  }

  get allRequiredDocumentsAccepted() {
    return this.acceptedDocuments.every(({ required, versionAccepted }) => {
      return !required || versionAccepted;
    });
  }

  get documentsAccepted() {
    return this.acceptedDocuments.filter(({ required, versionAccepted }) => {
      return required && versionAccepted;
    });
  }

  get termsAndConditionsWithPrivacyPolicyStatus(): boolean {
    return (
      this.privacyPolicyVersionAccepted !== '' &&
      this.termsAndConditionsVersionAccepted !== ''
    );
  }

  set termsAndConditionsWithPrivacyPolicyStatus(isAccepted: boolean) {
    this.termsAndConditionsVersionAccepted = isAccepted
      ? this.termsAndConditionsData.version
      : '';

    this.privacyPolicyVersionAccepted = isAccepted
      ? this.privacyPolicyData.version
      : '';

    this.emitInput();
  }

  get termsAndConditionsStatus(): boolean {
    return this.termsAndConditionsVersionAccepted !== '';
  }

  set termsAndConditionsStatus(isAccepted: boolean) {
    this.termsAndConditionsVersionAccepted = isAccepted
      ? this.termsAndConditionsData.version
      : '';

    this.emitInput();
  }

  get privacyPolicyStatus(): boolean {
    return this.privacyPolicyVersionAccepted !== '';
  }

  set privacyPolicyStatus(isAccepted: boolean) {
    this.privacyPolicyVersionAccepted = isAccepted
      ? this.privacyPolicyData.version
      : '';

    this.emitInput();
  }

  get nodeNonInvestmentStatus(): boolean {
    return this.nodeNonInvestmentStatementVersionAccepted !== '';
  }

  set nodeNonInvestmentStatus(isAccepted: boolean) {
    this.nodeNonInvestmentStatementVersionAccepted = isAccepted
      ? this.nodeNonInvestmentStatementData.version
      : '';

    this.emitInput();
  }

  @Watch('value')
  onValueChanged(n: ILegalAgreementsPayload) {
    this.initVars(n);
  }
}
