import axios from 'axios';

class Auth0Service {
  baseUrl = `https://${process.env.auth0Domain}`;

  private generateRandomString() {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
      '',
    );
  }

  async getUser(accessToken: string) {
    try {
      const results = await axios.get(`${this.baseUrl}/userinfo`, {
        headers: {
          Authorization: accessToken,
        },
      });
      return results.data;
    } catch (err) {
      throw err;
    }
  }

  async authenticateUser(
    connection: string,
    redirectUri: string,
    stateName: string,
  ) {
    const audience = process.env.auth0Audience ?? '';
    const state = stateName + this.generateRandomString();

    localStorage.setItem(stateName, state);

    window.location.href =
      `${this.baseUrl}/authorize` +
      '?response_type=code' +
      `&audience=${audience}` +
      `&client_id=${process.env.auth0ClientId}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&connection=${connection}` +
      '&scope=openid profile email offline_access' +
      `&state=${state}` +
      '&screen_hint=signup';
  }

  async getAccessTokenFromCode(code: string) {
    const clientId = process.env.auth0ClientId ?? '';
    const audience = process.env.auth0Audience ?? '';
    const redirectUri = `${window.location.origin}/account`;

    const results = await axios.post(
      `${this.baseUrl}/oauth/token`,
      {
        code,
        client_id: clientId,
        response_type: 'code',
        grant_type: 'authorization_code',
        redirect_uri: redirectUri,
        audience,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return results;
  }
}
export default new Auth0Service();
