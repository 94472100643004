
import { Component, Prop, Emit, Watch, Mixins } from 'vue-property-decorator';
import { State, Mutation, Getter } from 'vuex-class';
import { ICreditCards } from '~/store/profile/types';
import { IWallet } from '~/store/inventory/types';
import { IGameItemProductPrice } from '~/store/games/types';
import BottomDrawerDialog from '~/components/UIComponents/BottomDrawerDialog.vue';
import CreditCards from '~/components/CreditCards/CreditCards.vue';
import CreditCardIcon from '~/components/CreditCards/Icon.vue';
import CreditCardInfo from '~/components/CreditCards/CreditCardInfo.vue';
import { ICreateWalletPrompt, TSiteConfigState } from '~/store/types';
import TextOutput from '~/mixins/TextOutput';
import { FormattedSymbol } from '~/utils/wallets';
import { FiatIcons, FiatProvider } from '~/types/fiat-provider';

@Component({
  components: {
    BottomDrawerDialog,
    CreditCards,
    CreditCardIcon,
    CreditCardInfo,
  },
})
export default class PaymentPicker extends Mixins(TextOutput) {
  @State((inventory) => inventory.wallets, { namespace: 'inventory' })
  wallets!: IWallet[];
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State((profile) => profile.user.creditCards, { namespace: 'profile' })
  creditCards!: ICreditCards[];
  @State((inventory) => inventory.isFetchingWallets, { namespace: 'inventory' })
  isFetchingWallets!: boolean;
  @State((profile) => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;
  @State((profile) => profile.user.walletConnected, { namespace: 'profile' })
  walletConnected!: boolean;
  @State((profile) => profile.user.hasGyriPassphrase, { namespace: 'profile' })
  hasGyriPassphrase!: boolean;

  @Prop(Array) readonly prices!: IGameItemProductPrice[];
  @Prop(Object) readonly selectedPrice!: IGameItemProductPrice;
  @Prop(String) readonly productId!: string;

  @Mutation('toggleCreateWalletPrompt')
  private toggleCreateWalletPrompt!: (
    payload?: Partial<ICreateWalletPrompt>,
  ) => void;

  @Getter('siteConfig')
  readonly siteConfigState!: TSiteConfigState;

  selectedCoin: Partial<IWallet> | null = null;
  selectedItem: Partial<IWallet> | null = null;
  creditCardsAllowed = false;
  walletPurchasesAllowed = true;
  optionsDrawer = false;
  isLoading = true;

  fiatWallets: Partial<IWallet>[] = [];

  created() {
    this.fiatWallets.push({
      name: this.$t('components.wallet.paymentPicker.cc').toString(),
      icon: FiatIcons.stripe,
      symbol: 'CC-STRIPE',
      fiatProvider: FiatProvider.STRIPE,
    });

    if (this.selectedPrice) {
      this.onPricesAvailable(this.selectedPrice);
    }
  }

  get paymentOptionWallets() {
    const priceSymbols = this.prices.reduce((acc, price) => {
      if (this.$walletHelper.isCreditCard(price.symbol)) {
        this.creditCardsAllowed = true;
        return acc;
      }

      const formatted = this.$walletHelper.formatSymbol(price.symbol);
      if (!acc[formatted]) {
        acc[formatted] = true;
      }

      return acc;
    }, {} as Partial<Record<FormattedSymbol, boolean>>);

    const wallet = this.wallets.filter(
      (wallet) =>
        priceSymbols[this.$walletHelper.formatSymbol(wallet.symbol)] ?? false,
    );

    return wallet;
  }

  get showWalletOptions() {
    return this.loggedIn && !this.isFetchingWallets;
  }

  handleOpen() {
    this.optionsDrawer = !this.optionsDrawer;

    if (this.optionsDrawer) {
      this.$ua.trackCurrencySelectorEnteredEvent({
        selectedCurrency: this.selectedCoin?.symbol ?? 'none',
        currencyOptions: this.paymentOptionWallets.map(
          (o) => o.symbol ?? 'unknown',
        ),
        productId: this.productId ?? 'unknown',
      });
    }
  }

  coinBalance(wallet: Partial<IWallet>) {
    if (!wallet?.balance?.confirmed || !wallet?.balance?.unconfirmed) return 0;
    return Math.min(+wallet.balance.confirmed, +wallet.balance.unconfirmed);
  }

  handlePaymentTypesAllowed(prices: IGameItemProductPrice[]) {
    const creditCardPrice = prices.find((price) =>
      this.$walletHelper.isCreditCard(price.symbol),
    );
    const cyptoPrice = prices.find(
      (price) => !this.$walletHelper.isCreditCard(price.symbol),
    );
    this.creditCardsAllowed = !!creditCardPrice;
    this.walletPurchasesAllowed = !!cyptoPrice;
  }

  @Emit('coin-selected')
  handleOptionSelect(data: IWallet) {
    this.optionsDrawer = false;
    if (this.$walletHelper.isCreditCard(data.symbol)) {
      return this.handleCreditSelect(data);
    }

    const previousSelection = this.selectedCoin?.symbol ?? 'unknown';
    this.$ua.trackCurrencyChangedEvent({
      newSelection: data.symbol,
      previousSelection,
      productId: this.productId ?? 'unknown',
    });

    return this.$walletHelper.findPriceBySymbol(data.symbol, this.prices);
  }

  handleCreditSelect(wallet: Partial<IWallet>) {
    this.selectedCoin = null;
    this.optionsDrawer = false;
    this.selectedItem = wallet;

    const price =
      this.prices.find((p) => p.symbol === wallet.symbol) ?? this.prices[0];

    const priceValue = price.usdPriceInCents
      ? price.usdPriceInCents
      : price.usdBasePriceInCents;
    const priceString = (Math.round(priceValue * 100) / 100 / 100)
      .toFixed(2)
      .toString();
    const basePriceValue = price.usdBasePriceInCents;
    const basePriceString = (Math.round(basePriceValue * 100) / 100 / 100)
      .toFixed(2)
      .toString();

    return {
      price: priceString,
      basePrice: basePriceString,
      usdPriceInCents: priceValue,
      usdBasePriceInCents: basePriceValue,
      symbol: wallet.symbol,
      imageURL: wallet.icon,

      creditCard: true,
      selectedCredit: wallet.fiatProvider,
    };
  }

  @Watch('prices', { immediate: true })
  onPricesUpdated(
    current?: IGameItemProductPrice[],
    previous?: IGameItemProductPrice[],
  ) {
    this.handlePaymentTypesAllowed(current ?? []);
    // Here we're updating the selected payment method, which also contains price info, so that if a discount code is applied
    // after the payment method has already been selected, the selected price info gets updated correctly
    if (this.selectedItem?.symbol) {
      if (this.$walletHelper.isCreditCard(this.selectedItem?.symbol)) {
        const selectedPaymentInfo = this.handleCreditSelect(this.selectedItem);
        this.$emit('coin-selected', selectedPaymentInfo);
      } else {
        this.$emit(
          'coin-selected',
          this.$walletHelper.findPriceBySymbol(
            this.selectedItem.symbol,
            current ?? [],
          ),
        );
      }
    }
  }

  @Watch('selectedPrice', { immediate: true })
  onPricesAvailable(price?: IGameItemProductPrice) {
    if (this.$walletHelper.isCreditCard(price?.symbol ?? '')) {
      if (
        this.selectedItem?.symbol &&
        this.$walletHelper.isCreditCard(this.selectedItem.symbol)
      ) {
        // already have credit card selected, so just return
        return;
      }
      this.selectedCoin = null;
      const selectedItem =
        this.fiatWallets.find((w) => w.symbol === price?.symbol) ??
        this.fiatWallets[0];

      if (
        selectedItem?.symbol &&
        this.$walletHelper.isCreditCard(selectedItem.symbol)
      ) {
        const selectedPaymentInfo = this.handleCreditSelect(selectedItem);
        this.$emit('coin-selected', selectedPaymentInfo);
      }
      return;
    }

    const wallet = this.$walletHelper.findWalletBySymbol(
      price?.symbol ?? '',
      this.wallets,
    );
    if (wallet?.symbol) {
      this.selectedCoin = wallet;
    }
    this.selectedItem = wallet ?? null;
  }

  @Watch('selectedItem', { immediate: true })
  handleSelectedItemChange(
    current: Partial<IWallet> | null | undefined,
    previous: Partial<IWallet> | null | undefined,
  ) {
    if (!previous && current && this.isLoading) {
      this.isLoading = false;
    }
  }

  @Watch('wallets')
  onWalletsFetched(wallets: IWallet[]) {
    if (
      wallets.length &&
      !this.selectedCoin &&
      this.prices.length &&
      this.selectedPrice
    ) {
      this.selectedCoin =
        this.$walletHelper.findWalletBySymbol(
          this.selectedPrice.symbol,
          wallets,
        ) ?? null;
      this.selectedItem = this.selectedCoin;
    }
  }
}
