import { Context } from '@nuxt/types';
import { Route } from 'vue-router';
import { IUser } from '~/store/profile/types';
import { IUserAgentInfo } from '~/store/types';
import { GamePlatform } from '~/store/games/types';
import { UserItem } from '~/types/user-items';
import { VideoWatchEventTypes } from '~/types/event-restriction';
import { ModalType } from '~/types/modals';

export enum EAdaptors {
  DEBUG = 'debugAdaptor',
  GTM = 'gtmAdaptor',
  ACTIVE_CAMPAIGN = 'activeCampaignAdaptor',
  ADD_SHOPPERS = 'addShoppersAdaptor',
  GALA_ANALYTICS = 'galaAnalyticsAdaptor',
  FIREBASE = 'firebaseAdaptor',
  META = 'metaAdaptor',
  X = 'xAdaptor',
}

export enum EEventTypes {
  PURCHASE_SWAP = 'PURCHASE_SWAP',
  LAUNCHER_INITIAL_DOWNLOAD_BEGIN = 'LAUNCHER_INITIAL_DOWNLOAD_BEGIN',
  CHECKOUT_FLOW_ENTERED = 'CHECKOUT_FLOW_ENTERED',
  CHECKOUT_FLOW_EXITED = 'CHECKOUT_FLOW_EXITED',
  CURRENCY_SELECTION_CHANGED = 'CURRENCY_SELECTION_CHANGED',
  CURRENCY_SELECTOR_ENTERED = 'CURRENCY_SELECTOR_ENTERED',
  PAGE_VIEW = 'PAGE_VIEW',
  PRESS = 'PRESS',
  PURCHASE = 'PURCHASE',
  PURCHASE_COMPLETE = 'PURCHASE_COMPLETE',
  PURCHASE_INSUFFICIENT_FUNDS = 'PURCHASE_INSUFFICIENT_FUNDS',
  PURCHASE_PRICE_MISMATCH = 'PURCHASE_PRICE_MISMATCH',
  PURCHASE_ERROR = 'PURCHASE_ERROR',
  PURCHASE_ITEM = 'PURCHASE_ITEM',
  PURCHASE_NODE = 'PURCHASE_NODE',
  PURCHASE_BRIDGE = 'PURCHASE_BRIDGE',
  GAME_DOWNLOAD = 'GAME_DOWNLOAD',
  PURCHASE_GALA_GOLD = 'PURCHASE_GALA_GOLD',
  REGISTRATION_COMPLETE = 'REGISTRATION_COMPLETE',
  REGISTRATION_ERROR = 'REGISTRATION_ERROR',
  SET_USER = 'SET_USER',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  WALLET_CREATION_COMPLETE = 'WALLET_CREATION_COMPLETE',
  WALLET_CREATION_ERROR = 'WALLET_CREATION_ERROR',
  APK_DOWNLOADED = 'APK_DOWNLOADED',
  IOS_DOWNLOADED = 'IOS_DOWNLOADED',
  EARLY_ACCESS_REQUIRED = 'EARLY_ACCESS_REQUIRED',
  GAME_PLAY_BEGIN = 'GAME_PLAY_BEGIN',
  SEND_EMAIL_VERIFICATION_EMAIL = 'SEND_EMAIL_VERIFICATION_EMAIL',
  EMAIL_VERIFICATION_COMPLETE = 'EMAIL_VERIFICATION_COMPLETE',
  IP_VERIFICATION_COMPLETE = 'IP_VERIFICATION_COMPLETE',
  IP_VERIFICATION_INVALID_CODE = 'IP_VERIFICATION_INVALID_CODE',
  IP_VERIFICATION_EXPIRED_TOKEN = 'IP_VERIFICATION_EXPIRED_TOKEN',
  IP_VERIFICATION_ERROR = 'IP_VERIFICATION_ERROR',
  TWO_FA_SETUP_COMPLETE = 'TWO_FA_SETUP_COMPLETE',
  TWO_FA_VERIFICATION_COMPLETE = 'TWO_FA_VERIFICATION_COMPLETE',
  TWO_FA_VERIFICATION_INVALID_CODE = 'TWO_FA_VERIFICATION_INVALID_CODE',
  TWO_FA_VERIFICATION_ERROR = 'TWO_FA_VERIFICATION_UNKNOWN_ERROR',
  GAME_ACTION_ERROR = 'GAME_ACTION_ERROR',
  GAME_GIVE_ALLOWANCE_COMPLETE = 'GAME_GIVE_ALLOWANCE_COMPLETE',
  GAME_GIVE_ALLOWANCE_ERROR = 'GAME_GIVE_ALLOWANCE_ERROR',
  GAME_AWAITING_INSTALL = 'GAME_AWAITING_INSTALL',
  GAME_DOWNLOAD_BEGIN = 'GAME_DOWNLOAD_BEGIN',
  GAME_DOWNLOAD_PROGRESS = 'GAME_DOWNLOAD_PROGRESS',
  GAME_DOWNLOAD_COMPLETE = 'GAME_DOWNLOAD_COMPLETE',
  GAME_INSTALL_BEGIN = 'GAME_INSTALL_BEGIN',
  GAME_INSTALL_PROGRESS = 'GAME_INSTALL_PROGRESS',
  GAME_INSTALL_COMPLETE = 'GAME_INSTALL_COMPLETE',
  GAME_INSTALL_ERROR = 'GAME_INSTALL_ERROR',
  GAME_UPDATE_BEGIN = 'GAME_UPDATE_BEGIN',
  GAME_UPDATE_PROGRESS = 'GAME_UPDATE_PROGRESS',
  GAME_UPDATE_COMPLETE = 'GAME_UPDATE_COMPLETE',
  GAME_UPDATE_ERROR = 'GAME_UPDATE_ERROR',
  SEND_TRANSACTION_COMPLETE = 'SEND_TRANSACTION_COMPLETE',
  SEND_TRANSACTION_ERROR = 'SEND_TRANSACTION_ERROR',
  PRODUCT_RESTRICTION_SATISFIED = 'PRODUCT_RESTRICTION_SATISFIED',
  PRODUCT_RESTRICTION_NOT_SATISFIED = 'PRODUCT_RESTRICTION_NOT_SATISFIED',
  EXCHANGE_RESTRICTION_SATISFIED = 'EXCHANGE_RESTRICTION_SATISFIED',
  EXCHANGE_RESTRICTION_NOT_SATISFIED = 'EXCHANGE_RESTRICTION_NOT_SATISFIED',
  EXCHANGE_CLAIM_ITEMS_COMPLETE = 'EXCHANGE_CLAIM_ITEMS_COMPLETE',
  EXCHANGE_CLAIM_ITEMS_ERROR = 'EXCHANGE_CLAIM_ITEMS_ERROR',
  EXCHANGE_SEND_ITEMS_COMPLETE = 'EXCHANGE_SEND_ITEMS_COMPLETE',
  EXCHANGE_SEND_ITEMS_ERROR = 'EXCHANGE_SEND_ITEMS_ERROR',
  EXCHANGE_EXCHANGE_ITEMS_COMPLETE = 'EXCHANGE_EXCHANGE_ITEMS_COMPLETE',
  EXCHANGE_EXCHANGE_ITEMS_ERROR = 'EXCHANGE_EXCHANGE_ITEMS_ERROR',
  EXCHANGE_INSUFFICIENT_FUNDS = 'EXCHANGE_INSUFFICIENT_FUNDS',
  DISCORD_PROFILE_LINK_COMPLETE = 'DISCORD_PROFILE_LINK_COMPLETE',
  DISCORD_PROFILE_ALREADY_LINKED_TO_ANOTHER_USER = 'DISCORD_PROFILE_ALREADY_LINKED_TO_ANOTHER_USER',
  DISCORD_PROFILE_LINK_ERROR = 'DISCORD_PROFILE_LINK_ERROR',
  DISCORD_PROFILE_LINK_USER_CANCEL = 'DISCORD_PROFILE_USER_CANCEL',
  CONTACT_SUPPORT_REQUEST_COMPLETE = 'CONTACT_SUPPORT_REQUEST_COMPLETE',
  CONTACT_SUPPORT_REQUEST_ERROR = 'CONTACT_SUPPORT_REQUEST_ERROR',
  REDEEM_TOKEN_CODE_COMPLETE = 'REDEEM_TOKEN_CODE_COMPLETE',
  REDEEM_TOKEN_CODE_ERROR = 'REDEEM_TOKEN_CODE_ERROR',
  UPDATE_USER_COMPLETE = 'UPDATE_USER_COMPLETE',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',
  UPLOAD_USER_PHOTO_COMPLETE = 'UPLOAD_USER_PHOTO_COMPLETE',
  UPLOAD_USER_PHOTO_ERROR = 'UPLOAD_USER_PHOTO_ERROR',
  EMAIL_SIGNUP_COMPLETE = 'EMAIL_SIGNUP_COMPLETE',
  EMAIL_SIGNUP_ERROR = 'EMAIL_SIGNUP_ERROR',
  EVERGREEN_UA_REGISTRATION_COMPLETE = 'EVERGREEN_UA_REGISTRATION_COMPLETE',
  EVERGREEN_UA_EMAIL_SIGNUP_COMPLETE = 'EVERGREEN_UA_EMAIL_SIGNUP_COMPLETE',
  PROJECT_SPECIFIC_UA_REGISTRATION_COMPLETE = 'PROJECT_SPECIFIC_UA_REGISTRATION_COMPLETE',
  PROJECT_SPECIFIC_UA_EMAIL_SIGNUP_COMPLETE = 'PROJECT_SPECIFIC_UA_EMAIL_SIGNUP_COMPLETE',
  FORM_RESTRICTION_SATISFIED = 'FORM_RESTRICTION_SATISFIED',
  FORM_RESTRICTION_NOT_SATISIFIED = 'FORM_RESTRICTION_NOT_SATISFIED',
  HOMEPAGE_TAKEOVER_CLICK = 'HOMEPAGE_TAKEOVER_CLICK',
  NAVIGATION_EVENT = 'NAVIGATION',
  GALA_PACK_SHOW_OFFER = 'PROMO_PACK_OFFER_SHOWN',
  GALA_PACK_CLOSED = 'PROMO_PACK_CLOSED',
  GALA_PACK_SIGN_UP_CLICKED = 'PROMO_PACK_SIGN_UP_CLICKED',
  TRANSACTION_FEE_SLIDER_MOVED = 'TRANSACTION_FEE_SLIDER_MOVED',
  FTUE_START_EVENT = 'FTUE_START_EVENT',
  VIDEO_WATCH_EVENT = 'VIDEO_WATCH_EVENT',
  MODAL_VIEW_EVENT = 'MODAL_VIEW_EVENT',
}

export type TTrackScopedEventWithoutPayload = () => void;
export type TTrackScopedEventWithPayload<T = any> = (payload: T) => void;
export type TTrackScopedEvent<T = any> =
  | TTrackScopedEventWithoutPayload
  | TTrackScopedEventWithPayload<T>;

export interface ITrackEventPayload {
  eventName: EEventTypes;
  eventValue?: Record<string, any>;
  [key: string]: any;
}

export interface ITrackSendTransactionCompleteEventPayload {
  coinSymbol: string;
  amount: string;
  transactionFee: string;
  sendMax: boolean;
}

export interface ITrackSendTransactionErrorEventPayload {
  payload: ITrackSendTransactionCompleteEventPayload;
  error: any;
}

export interface ITrackGalaPackShowOfferPayload {
  path: string;
}

export interface ITrackGalaPackClosedPayload {
  path: string;
}

export interface ITrackGalaPackSignUpClickPayload {
  loginMethod: string;
  path: string;
}

export interface ITrackRegistrationCompleteEventPayload {
  id: IUser['id'];
  firstName: IUser['firstName'];
  lastName: IUser['lastName'];
  displayName: IUser['displayName'];
  email: IUser['email'];
  profilePhotoUrl: IUser['profilePhotoUrl'];
  phone: IUser['phone'];
  phoneCountry: string;
  language: IUser['preferences']['language'];
  currency: IUser['preferences']['currency'];
  created: any;
  communicationConsent: boolean;
  referralContext: any;
  userAgentInfo: IUserAgentInfo;
  clientRoute: string;
  skipEmailConfirmation: boolean;
}

export interface ITrackPageViewEventPayload {
  url: string;
  name: Route['name'];
  fullPath: Route['name'];
  path: Route['path'];
  query: Route['query'];
  params: Route['params'];
  hash: Route['hash'];
  referrer: Route['redirectedFrom'];
}

export interface ITrackNavigationEventPayload {
  from: string;
  to: string;
}

export interface ITrackModalViewEventPayload {
  type: ModalType;
  view?: string;
  step?: number;
}

export interface ITrackHomepageTakeoverEventPayload {
  takeover: string;
}

export interface ITrackCurrencySelectorEnteredEventPayload {
  selectedCurrency: string;
  currencyOptions: string[];
  productId: string;
}

export interface ITrackCurrencyChangedEventPayload {
  previousSelection: string;
  newSelection: string;
  productId: string;
}

export interface ITrackPressEventPayload {
  interactionTargetId: string;
  context?: string;
}

export interface ITrackTransactionSliderMovedEventPayload {
  previousSelection: number;
  newSelection: number;
}

export interface ITrackCheckoutFlowEnteredExitedEventPayload {
  quantity: number;
  currency: string;
  productId: string;
  unitPrice: number;
}

export interface ITrackAPKDownloadPayload {
  game: string;
}
export interface ITrackIOSDownloadPayload {
  game: string;
}

export interface IPurchaseItem {
  productId: string;
  unitPrice: number;
  quantity: number;
  transactionFee: string;
  extras?: any;
}

export interface ITrackPurchaseEventPayload {
  total: number | string;
  currency: string;
  items: IPurchaseItem[];
  eventName?: EEventTypes;
}

export interface ITrackPurchaseItemEventPayload {
  total: ITrackPurchaseEventPayload['total'];
  currency: ITrackPurchaseEventPayload['currency'];
  item: IPurchaseItem;
  orderId: string;
  restrictionCode?: string;
  priceAdjustmentCode?: string;
}

export interface IGameEventBasePayload {
  game: any;
  launcherCurrentVersion?: ILauncherEventBasePayload['currentVersion'];
  error?: any;
  message?: any;
  platform?: GamePlatform;
  progress?: number;
  isWindows?: boolean;
  isMac?: boolean;
}
interface ILauncherEventBasePayload {
  currentVersion: string | null;
  progress?: number;
  lastConnected?: any;
  error?: any;
  message?: any;
  isWindows?: boolean;
  isMac?: boolean;
}

export interface ITrackExchangeRestrictionEventPayload {
  item: UserItem;
  restrictionId: string;
  restrictionResults: any;
}

export interface ITrackProductRestrictionEventPayload {
  item: {
    productId: IPurchaseItem['productId'];
    quantity: IPurchaseItem['quantity'];
  };
  restrictionId: string;
  restrictionResults: any;
}

export interface ITrackPurchasePriceMismatchEventPayload {
  selectedPrice: number | string;
  newPrice: number | string;
}

export interface ITrackDiscordProfileLinkCompleteEventPayload {
  discordProfile: IUser['discordProfile'];
}

export interface ITrackRedeemTokenCodeCompleteEventPayload {
  tokenRedeemedType: string;
  tokenRedeemedSuccessfully: boolean;
}

export interface ITrackUploadUserPhotoCompleteEventPayload {
  url: string;
  filename: string;
}

export interface ITrackPurchaseCompleteEventPayload {
  isSuccessful: boolean;
  paymentHash: string;
  results: any;
}

export interface ITrackInsufficientFundsEventPayload {
  neededAmount: number;
  currentBalance: number;
  currency: string;
}

export interface ITrackLauncherConnectionEventPayload {
  lastConnected: any;
  error?: any;
}

export interface ILauncherAgreementAcceptanceEventPayload
  extends ILauncherEventBasePayload {
  acceptedDocuments: any;
  error?: any;
  message?: any;
}

export interface ISetUserPayload {
  id: IUser['id'];
  email?: IUser['email'];
}

export interface ITrackFirstTimeUserExperiencePayload {
  userAction: string;
}

export interface ITrackVideoWatchPayload {
  videoWatchEventType: VideoWatchEventTypes;
  videoSlug: string;
  videoCurrentTimeSeconds?: number;
}

export type TSetUser = (payload: ISetUserPayload) => void;
export type TInit = () => void;

export type TTrackEvent = (payload: ITrackEventPayload) => void;
type TTrackPurchaseItemEvent =
  TTrackScopedEventWithPayload<ITrackPurchaseItemEventPayload>;

type TTrackGameEvent = TTrackScopedEventWithPayload<IGameEventBasePayload>;
type TTrackLauncherEvent =
  TTrackScopedEventWithPayload<ILauncherEventBasePayload>;
type TTrackExchangeRestrictionEvent =
  TTrackScopedEventWithPayload<ITrackExchangeRestrictionEventPayload>;
type TTrackProductRestrictionEvent =
  TTrackScopedEventWithPayload<ITrackProductRestrictionEventPayload>;
type TTrackInsufficientFundsEvent =
  TTrackScopedEventWithPayload<ITrackInsufficientFundsEventPayload>;

type TTrackLauncherConnectionEvent =
  TTrackScopedEventWithPayload<ITrackLauncherConnectionEventPayload>;

type TTrackLauncherAgreementAcceptanceEvent =
  TTrackScopedEventWithPayload<ILauncherAgreementAcceptanceEventPayload>;

type TTrackHomepageTakeoverEvent =
  TTrackScopedEventWithPayload<ITrackHomepageTakeoverEventPayload>;

type TTrackGalaPackSignUpClickEvent =
  TTrackScopedEventWithPayload<ITrackGalaPackSignUpClickPayload>;
type TTrackGalaPackCloseClickEvent =
  TTrackScopedEventWithPayload<ITrackGalaPackClosedPayload>;
type TTrackGalaPackShowOfferEvent =
  TTrackScopedEventWithPayload<ITrackGalaPackShowOfferPayload>;

export type TClose = () => void;

export interface IHydratedAdaptor {
  init: TInit;
  close: TClose;
  trackEvent: TTrackEvent;
  trackPurchaseEvent: TTrackScopedEventWithPayload<ITrackPurchaseEventPayload>;
  trackPurchaseCompleteEvent: TTrackScopedEventWithPayload<ITrackPurchaseCompleteEventPayload>;
  trackPurchasePriceMismatchEvent: TTrackScopedEventWithPayload<ITrackPurchasePriceMismatchEventPayload>;
  trackPurchaseInsufficientFundsEvent: TTrackInsufficientFundsEvent;
  trackPurchaseErrorEvent: TTrackScopedEventWithPayload;
  trackPurchaseItemEvent: TTrackPurchaseItemEvent;
  trackPurchaseNodeEvent: TTrackPurchaseItemEvent;
  trackPurchaseBridgeEvent: TTrackPurchaseItemEvent;
  trackPurchaseSwapEvent: TTrackPurchaseItemEvent;
  trackPageViewEvent: TTrackScopedEventWithPayload<ITrackPageViewEventPayload>;
  trackLoginEvent: TTrackScopedEventWithPayload<{ utmCampaign?: string }>;
  trackLogoutEvent: TTrackScopedEventWithoutPayload;
  trackRegistrationCompleteEvent: TTrackScopedEventWithPayload<ITrackRegistrationCompleteEventPayload>;
  trackPurchaseGalaGoldEvent: TTrackPurchaseItemEvent;
  setUser: TSetUser;
  trackWalletCreationCompleteEvent: TTrackScopedEventWithoutPayload;
  trackWalletCreationErrorEvent: TTrackScopedEventWithPayload;
  trackSendEmailVerificationEmailEvent: TTrackScopedEventWithoutPayload;
  trackEmailVerificationCompleteEvent: TTrackScopedEventWithoutPayload;
  trackTwoFASetupCompleteEvent: TTrackScopedEventWithoutPayload;
  trackTwoFAVerificationCompleteEvent: TTrackScopedEventWithoutPayload;
  trackTwoFAVerificationInvalidCodeEvent: TTrackScopedEventWithoutPayload;
  trackTwoFAVerificationErrorEvent: TTrackScopedEventWithPayload;
  trackIpVerificationCompleteEvent: TTrackScopedEventWithoutPayload;
  trackIpVerificationErrorEvent: TTrackScopedEventWithPayload;
  trackIpVerificationInvalidCodeEvent: TTrackScopedEventWithoutPayload;
  trackIpVerificationExpiredTokenEvent: TTrackScopedEventWithoutPayload;
  trackGamePlayBeginEvent: TTrackGameEvent;
  trackApkDownloadEvent: TTrackScopedEventWithPayload<ITrackAPKDownloadPayload>;
  trackIosDownloadEvent: TTrackScopedEventWithPayload<ITrackIOSDownloadPayload>;
  trackLauncherInitialDownloadBeginEvent: TTrackLauncherEvent;
  trackGameInstallBeginEvent: TTrackGameEvent;
  trackGameInstallProgressEvent: TTrackGameEvent;
  trackGameInstallCompleteEvent: TTrackGameEvent;
  trackGameInstallErrorEvent: TTrackGameEvent;
  trackGameUpdateBeginEvent: TTrackGameEvent;
  trackGameUpdateProgressEvent: TTrackGameEvent;
  trackGameUpdateCompleteEvent: TTrackGameEvent;
  trackGameUpdateErrorEvent: TTrackGameEvent;
  trackEarlyAccessRequiredEvent: TTrackScopedEventWithoutPayload;
  trackSendTransactionCompleteEvent: TTrackScopedEventWithPayload<ITrackSendTransactionCompleteEventPayload>;
  trackSendTransactionErrorEvent: TTrackScopedEventWithPayload<ITrackSendTransactionErrorEventPayload>;
  trackExchangeRestrictionSatisfiedEvent: TTrackExchangeRestrictionEvent;
  trackExchangeRestrictionNotSatisfiedEvent: TTrackExchangeRestrictionEvent;
  trackProductRestrictionSatisfiedEvent: TTrackProductRestrictionEvent;
  trackProductRestrictionNotSatisfiedEvent: TTrackProductRestrictionEvent;
  trackExchangeClaimItemsCompleteEvent: TTrackScopedEventWithPayload;
  trackExchangeClaimItemsErrorEvent: TTrackScopedEventWithPayload;
  trackExchangeSendItemsCompleteEvent: TTrackScopedEventWithPayload;
  trackExchangeSendItemsErrorEvent: TTrackScopedEventWithPayload;
  trackExchangeExchangeItemsCompleteEvent: TTrackScopedEventWithPayload;
  trackExchangeExchangeItemsErrorEvent: TTrackScopedEventWithPayload;
  trackExchangeInsufficientFundsEvent: TTrackInsufficientFundsEvent;
  trackDiscordProfileLinkCompleteEvent: TTrackScopedEventWithPayload<ITrackDiscordProfileLinkCompleteEventPayload>;
  trackDiscordProfileAlreadyLinkedToAnotherUserEvent: TTrackScopedEventWithoutPayload;
  trackDiscordProfileLinkErrorEvent: TTrackScopedEventWithPayload;
  trackDiscordProfileLinkUserCancelEvent: TTrackScopedEventWithoutPayload;
  trackRedeemTokenCodeCompleteEvent: TTrackScopedEventWithPayload<ITrackRedeemTokenCodeCompleteEventPayload>;
  trackRedeemTokenCodeErrorEvent: TTrackScopedEventWithPayload;
  trackContactSupportRequestCompleteEvent: TTrackScopedEventWithPayload;
  trackContactSupportRequestErrorEvent: TTrackScopedEventWithPayload;
  trackUpdateUserCompleteEvent: TTrackScopedEventWithPayload;
  trackUpdateUserErrorEvent: TTrackScopedEventWithPayload;
  trackUploadUserPhotoCompleteEvent: TTrackScopedEventWithPayload<ITrackUploadUserPhotoCompleteEventPayload>;
  trackUploadUserPhotoErrorEvent: TTrackScopedEventWithPayload;
  trackRegistrationErrorEvent: TTrackScopedEventWithPayload;
  trackSetUserEvent: TTrackScopedEventWithPayload;
  trackGameAwaitingInstallEvent: TTrackGameEvent;
  trackGameDownloadBeginEvent: TTrackGameEvent;
  trackGameDownloadProgressEvent: TTrackGameEvent;
  trackGameDownloadCompleteEvent: TTrackGameEvent;
  trackGameActionErrorEvent: TTrackScopedEventWithPayload;
  trackGameGiveAllowanceCompleteEvent: TTrackGameEvent;
  trackGameGiveAllowanceErrorEvent: TTrackGameEvent;
  trackEmailSignupCompleteEvent: TTrackScopedEventWithPayload;
  trackEmailSignupErrorEvent: TTrackScopedEventWithPayload;
  trackEvergreenUaRegistrationCompleteEvent: TTrackScopedEventWithoutPayload;
  trackEvergreenUaEmailSignUpCompleteEvent: TTrackScopedEventWithoutPayload;
  trackProjectSpecificUaRegistrationCompleteEvent: TTrackScopedEventWithPayload;
  trackProjectSpecificUaEmailSignupCompleteEvent: TTrackScopedEventWithPayload;
  trackFormRestrictionSatisfiedEvent: TTrackScopedEventWithPayload;
  trackFormRestrictionNotSatisfiedEvent: TTrackScopedEventWithPayload;
  trackHomepageTakeoverEvent: TTrackHomepageTakeoverEvent;
  trackGalaPackShowOfferEvent: TTrackGalaPackShowOfferEvent;
  trackGalaPackClosedEvent: TTrackGalaPackCloseClickEvent;
  trackGalaPackSignUpClickEvent: TTrackGalaPackSignUpClickEvent;
  trackNavigationEvent: TTrackScopedEventWithPayload<ITrackNavigationEventPayload>;
  trackCheckoutFlowEnteredEvent: TTrackScopedEventWithPayload<ITrackCheckoutFlowEnteredExitedEventPayload>;
  trackCheckoutFlowExitedEvent: TTrackScopedEventWithPayload<ITrackCheckoutFlowEnteredExitedEventPayload>;
  trackCurrencySelectorEnteredEvent: TTrackScopedEventWithPayload<ITrackCurrencySelectorEnteredEventPayload>;
  trackCurrencyChangedEvent: TTrackScopedEventWithPayload<ITrackCurrencyChangedEventPayload>;
  trackPress: TTrackScopedEventWithPayload<ITrackPressEventPayload>;
  trackTransactionFeeSliderMovedEvent: TTrackScopedEventWithPayload<ITrackTransactionSliderMovedEventPayload>;
  trackFirstTimeUserExperienceEvent: TTrackScopedEventWithPayload<ITrackFirstTimeUserExperiencePayload>;
  trackVideoWatchEvent: TTrackScopedEventWithPayload<ITrackVideoWatchPayload>;
  trackModalViewEvent: TTrackScopedEventWithPayload<ITrackModalViewEventPayload>;
}

type _omit = 'init' | 'close' | 'setUser' | 'trackEvent';

export type TTrackEventMethodToEventNameMap = Omit<
  {
    [k in keyof IHydratedAdaptor]: EEventTypes;
  },
  _omit
>;

export type TTrackEventMethodKeys = Array<
  keyof TTrackEventMethodToEventNameMap
>;

export type TAllMethodKeys = Array<keyof IHydratedAdaptor>;

export const TRACK_EVENT_METHOD_TO_EVENT_NAME_MAP: TTrackEventMethodToEventNameMap =
  {
    trackTransactionFeeSliderMovedEvent:
      EEventTypes.TRANSACTION_FEE_SLIDER_MOVED,
    trackHomepageTakeoverEvent: EEventTypes.HOMEPAGE_TAKEOVER_CLICK,
    trackPress: EEventTypes.PRESS,
    trackPurchaseSwapEvent: EEventTypes.PURCHASE_SWAP,
    trackNavigationEvent: EEventTypes.NAVIGATION_EVENT,
    trackGalaPackShowOfferEvent: EEventTypes.GALA_PACK_SHOW_OFFER,
    trackGalaPackClosedEvent: EEventTypes.GALA_PACK_CLOSED,
    trackGalaPackSignUpClickEvent: EEventTypes.GALA_PACK_SIGN_UP_CLICKED,
    trackCheckoutFlowEnteredEvent: EEventTypes.CHECKOUT_FLOW_ENTERED,
    trackCheckoutFlowExitedEvent: EEventTypes.CHECKOUT_FLOW_EXITED,
    trackCurrencyChangedEvent: EEventTypes.CURRENCY_SELECTION_CHANGED,
    trackCurrencySelectorEnteredEvent: EEventTypes.CURRENCY_SELECTOR_ENTERED,
    trackGamePlayBeginEvent: EEventTypes.GAME_PLAY_BEGIN,
    trackApkDownloadEvent: EEventTypes.APK_DOWNLOADED,
    trackIosDownloadEvent: EEventTypes.IOS_DOWNLOADED,
    trackLauncherInitialDownloadBeginEvent:
      EEventTypes.LAUNCHER_INITIAL_DOWNLOAD_BEGIN,
    trackPurchaseEvent: EEventTypes.PURCHASE,
    trackEarlyAccessRequiredEvent: EEventTypes.EARLY_ACCESS_REQUIRED,
    trackPurchaseCompleteEvent: EEventTypes.PURCHASE_COMPLETE,
    trackPurchasePriceMismatchEvent: EEventTypes.PURCHASE_PRICE_MISMATCH,
    trackPurchaseInsufficientFundsEvent:
      EEventTypes.PURCHASE_INSUFFICIENT_FUNDS,
    trackPurchaseErrorEvent: EEventTypes.PURCHASE_ERROR,
    trackPurchaseItemEvent: EEventTypes.PURCHASE_ITEM,
    trackPurchaseNodeEvent: EEventTypes.PURCHASE_NODE,
    trackPurchaseBridgeEvent: EEventTypes.PURCHASE_BRIDGE,
    trackPageViewEvent: EEventTypes.PAGE_VIEW,
    trackLoginEvent: EEventTypes.LOGIN,
    trackLogoutEvent: EEventTypes.LOGOUT,
    trackRegistrationCompleteEvent: EEventTypes.REGISTRATION_COMPLETE,
    trackPurchaseGalaGoldEvent: EEventTypes.PURCHASE_GALA_GOLD,
    trackWalletCreationCompleteEvent: EEventTypes.WALLET_CREATION_COMPLETE,
    trackWalletCreationErrorEvent: EEventTypes.WALLET_CREATION_ERROR,
    trackSendEmailVerificationEmailEvent:
      EEventTypes.SEND_EMAIL_VERIFICATION_EMAIL,
    trackEmailVerificationCompleteEvent:
      EEventTypes.EMAIL_VERIFICATION_COMPLETE,
    trackTwoFASetupCompleteEvent: EEventTypes.TWO_FA_SETUP_COMPLETE,
    trackTwoFAVerificationCompleteEvent:
      EEventTypes.TWO_FA_VERIFICATION_COMPLETE,
    trackTwoFAVerificationInvalidCodeEvent:
      EEventTypes.TWO_FA_VERIFICATION_INVALID_CODE,
    trackTwoFAVerificationErrorEvent: EEventTypes.TWO_FA_VERIFICATION_ERROR,
    trackIpVerificationCompleteEvent: EEventTypes.IP_VERIFICATION_COMPLETE,
    trackIpVerificationErrorEvent: EEventTypes.IP_VERIFICATION_ERROR,
    trackIpVerificationInvalidCodeEvent:
      EEventTypes.IP_VERIFICATION_INVALID_CODE,
    trackIpVerificationExpiredTokenEvent:
      EEventTypes.IP_VERIFICATION_EXPIRED_TOKEN,
    trackGameInstallBeginEvent: EEventTypes.GAME_INSTALL_BEGIN,
    trackGameInstallProgressEvent: EEventTypes.GAME_INSTALL_PROGRESS,
    trackGameInstallCompleteEvent: EEventTypes.GAME_INSTALL_COMPLETE,
    trackGameInstallErrorEvent: EEventTypes.GAME_INSTALL_ERROR,
    trackGameUpdateBeginEvent: EEventTypes.GAME_PLAY_BEGIN,
    trackGameUpdateProgressEvent: EEventTypes.GAME_UPDATE_PROGRESS,
    trackGameUpdateCompleteEvent: EEventTypes.GAME_UPDATE_COMPLETE,
    trackGameUpdateErrorEvent: EEventTypes.GAME_UPDATE_ERROR,
    trackSendTransactionCompleteEvent: EEventTypes.SEND_TRANSACTION_COMPLETE,
    trackSendTransactionErrorEvent: EEventTypes.SEND_TRANSACTION_ERROR,
    trackExchangeRestrictionSatisfiedEvent:
      EEventTypes.EXCHANGE_RESTRICTION_SATISFIED,
    trackExchangeRestrictionNotSatisfiedEvent:
      EEventTypes.EXCHANGE_RESTRICTION_NOT_SATISFIED,
    trackProductRestrictionSatisfiedEvent:
      EEventTypes.PRODUCT_RESTRICTION_SATISFIED,
    trackProductRestrictionNotSatisfiedEvent:
      EEventTypes.PRODUCT_RESTRICTION_NOT_SATISFIED,
    trackExchangeClaimItemsCompleteEvent:
      EEventTypes.EXCHANGE_CLAIM_ITEMS_COMPLETE,
    trackExchangeClaimItemsErrorEvent: EEventTypes.EXCHANGE_CLAIM_ITEMS_ERROR,
    trackExchangeSendItemsCompleteEvent:
      EEventTypes.EXCHANGE_SEND_ITEMS_COMPLETE,
    trackExchangeSendItemsErrorEvent: EEventTypes.EXCHANGE_SEND_ITEMS_ERROR,
    trackExchangeExchangeItemsCompleteEvent:
      EEventTypes.EXCHANGE_EXCHANGE_ITEMS_COMPLETE,
    trackExchangeExchangeItemsErrorEvent:
      EEventTypes.EXCHANGE_EXCHANGE_ITEMS_ERROR,
    trackExchangeInsufficientFundsEvent:
      EEventTypes.EXCHANGE_INSUFFICIENT_FUNDS,
    trackDiscordProfileLinkCompleteEvent:
      EEventTypes.DISCORD_PROFILE_LINK_COMPLETE,
    trackDiscordProfileAlreadyLinkedToAnotherUserEvent:
      EEventTypes.DISCORD_PROFILE_ALREADY_LINKED_TO_ANOTHER_USER,
    trackDiscordProfileLinkErrorEvent: EEventTypes.DISCORD_PROFILE_LINK_ERROR,
    trackDiscordProfileLinkUserCancelEvent:
      EEventTypes.DISCORD_PROFILE_LINK_USER_CANCEL,
    trackRedeemTokenCodeCompleteEvent: EEventTypes.REDEEM_TOKEN_CODE_COMPLETE,
    trackRedeemTokenCodeErrorEvent: EEventTypes.REDEEM_TOKEN_CODE_ERROR,
    trackContactSupportRequestCompleteEvent:
      EEventTypes.CONTACT_SUPPORT_REQUEST_COMPLETE,
    trackContactSupportRequestErrorEvent:
      EEventTypes.CONTACT_SUPPORT_REQUEST_ERROR,
    trackUpdateUserCompleteEvent: EEventTypes.UPDATE_USER_COMPLETE,
    trackUpdateUserErrorEvent: EEventTypes.UPDATE_USER_ERROR,
    trackUploadUserPhotoCompleteEvent: EEventTypes.UPLOAD_USER_PHOTO_COMPLETE,
    trackUploadUserPhotoErrorEvent: EEventTypes.UPLOAD_USER_PHOTO_ERROR,
    trackRegistrationErrorEvent: EEventTypes.REGISTRATION_ERROR,
    trackSetUserEvent: EEventTypes.SET_USER,
    trackGameAwaitingInstallEvent: EEventTypes.GAME_AWAITING_INSTALL,
    trackGameDownloadBeginEvent: EEventTypes.GAME_DOWNLOAD_BEGIN,
    trackGameDownloadProgressEvent: EEventTypes.GAME_DOWNLOAD_PROGRESS,
    trackGameDownloadCompleteEvent: EEventTypes.GAME_DOWNLOAD_COMPLETE,
    trackGameActionErrorEvent: EEventTypes.GAME_ACTION_ERROR,
    trackGameGiveAllowanceCompleteEvent:
      EEventTypes.GAME_GIVE_ALLOWANCE_COMPLETE,
    trackGameGiveAllowanceErrorEvent: EEventTypes.GAME_GIVE_ALLOWANCE_ERROR,
    trackEmailSignupCompleteEvent: EEventTypes.EMAIL_SIGNUP_COMPLETE,
    trackEmailSignupErrorEvent: EEventTypes.EMAIL_SIGNUP_ERROR,
    trackEvergreenUaRegistrationCompleteEvent:
      EEventTypes.EVERGREEN_UA_REGISTRATION_COMPLETE,
    trackEvergreenUaEmailSignUpCompleteEvent:
      EEventTypes.EVERGREEN_UA_EMAIL_SIGNUP_COMPLETE,
    trackProjectSpecificUaRegistrationCompleteEvent:
      EEventTypes.PROJECT_SPECIFIC_UA_REGISTRATION_COMPLETE,
    trackProjectSpecificUaEmailSignupCompleteEvent:
      EEventTypes.PROJECT_SPECIFIC_UA_EMAIL_SIGNUP_COMPLETE,
    trackFormRestrictionSatisfiedEvent: EEventTypes.FORM_RESTRICTION_SATISFIED,
    trackFormRestrictionNotSatisfiedEvent:
      EEventTypes.FORM_RESTRICTION_NOT_SATISIFIED,
    trackFirstTimeUserExperienceEvent: EEventTypes.FTUE_START_EVENT,
    trackVideoWatchEvent: EEventTypes.VIDEO_WATCH_EVENT,
    trackModalViewEvent: EEventTypes.MODAL_VIEW_EVENT,
  };

const BASE_METHOD_KEYS = ['init', 'setUser', 'trackEvent'];
export const TRACK_SPECIFIC_EVENT_METHOD_KEYS = Object.keys(
  TRACK_EVENT_METHOD_TO_EVENT_NAME_MAP,
);
export const ALL_METHOD_KEYS = [
  ...BASE_METHOD_KEYS,
  ...TRACK_SPECIFIC_EVENT_METHOD_KEYS,
] as TAllMethodKeys;

export type TGenerateTrackPurchaseEventFallback = (
  eventName: EEventTypes,
) => TTrackScopedEventWithPayload<any>;

export interface IHydrateAdaptorPayload extends Partial<IHydratedAdaptor> {}

// tslint:disable-next-line: no-empty-interface
export interface IPlugin extends IHydratedAdaptor {}

export type TInject = (bindName: string, payload: object) => void;

export type TOnError = (err: any) => void;

export interface IGetCurrentUserReturn {
  id: IUser['id'];
  firstName: IUser['firstName'];
  lastName: IUser['lastName'];
  displayName: IUser['displayName'];
  discordProfile: IUser['discordProfile'];
  email: IUser['email'];
  emailVerified: IUser['emailVerified'];
}

export type TGetCurrentUser = () => IGetCurrentUserReturn | undefined;

export type TGetCurrentUserId = () => IGetCurrentUserReturn['id'] | undefined;

export type TGetCurrentUserEmail = () =>
  | IGetCurrentUserReturn['email']
  | undefined;

export type TGetCurrentDate = () => Date;

export type TGetCurrentISODate = () => string;

export interface IAdaptorPayload {
  config: any;
  onError: TOnError;
  getCurrentUser: TGetCurrentUser;
  getCurrentUserId: TGetCurrentUserId;
  getCurrentUserEmail: TGetCurrentUserEmail;
  getCurrentDate: TGetCurrentDate;
  getCurrentISODate: TGetCurrentISODate;
  ctx: Context;
}

interface IAdaptorStatic {
  NAME: EAdaptors;
}

export type TInstantiatedAdaptor = IHydratedAdaptor | undefined;

type TAdaptorInterim = (payload: IAdaptorPayload) => TInstantiatedAdaptor;

export type TAdaptor = TAdaptorInterim & IAdaptorStatic;

export type TTRACKED_EVENTS = {
  [key in EEventTypes]?: any;
};
