
import { Vue, Component, Mixins } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { IWallet } from '~/store/inventory/types';
import { TextOutput } from '~/mixins';

@Component
export default class QuickBalance extends Mixins(TextOutput) {
  @State('wallets', { namespace: 'inventory' })
  private wallets!: IWallet[];

  get quickBalanceWallets() {
    const visibleWallets = this.wallets.filter((wallet) => {
      return [
        'GALA[ETH]',
        'GALA[GYRI]',
        'POPCORN[GalaChainAllowance]',
      ].includes(wallet.symbol);
    });

    return visibleWallets.map((wallet) => {
      const { symbol, name, icon } = wallet;
      const minBalance = Math.min(
        +wallet.balance?.unconfirmed || 0,
        +wallet.balance?.confirmed || 0,
      );
      const balanceFormatted =
        this.$options.filters?.formatNumber(minBalance, '0.0[00000] a') ||
        minBalance;

      return {
        symbol,
        name,
        icon,
        balanceFormatted,
        detailsUrl: `/my-wallet/${symbol}`,
      };
    });
  }
}
