
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LogoPoweredByGala extends Vue {
  @Prop([String, Number])
  readonly height!: string | number;
  @Prop({ type: [String, Number], default: 115 })
  readonly width!: string | number;
  @Prop(String) readonly title!: string;
}
