
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class BrandCard extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly showLogo!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly darkInnerCard!: boolean;
  @Prop({ type: String, default: '340px' })
  readonly maxWidth!: string;
}
