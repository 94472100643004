import {
  TAdaptor,
  IHydrateAdaptorPayload,
  IHydratedAdaptor,
  EAdaptors,
  TInit,
  TTrackEvent,
  EEventTypes,
  TTRACKED_EVENTS,
  TSetUser,
  ITrackPurchaseItemEventPayload,
} from '../types';
import hydrateAdaptor from '../hydrateAdaptor';
import { initializeApp } from 'firebase/app';
import {
  getAnalytics,
  setUserId,
  logEvent as _logEvent,
} from 'firebase/analytics';
import { hashData } from '../../../utils/hashData';

const TRACKED_EVENTS: TTRACKED_EVENTS = {
  [EEventTypes.REGISTRATION_COMPLETE]: true,
  [EEventTypes.PURCHASE_ITEM]: true,
  [EEventTypes.PURCHASE_NODE]: true,
  [EEventTypes.SET_USER]: true,
};

export const firebaseAdaptor: TAdaptor = (adaptorPayload) => {
  const {
    enabled,
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
  } = adaptorPayload.config;

  if (!enabled) {
    return undefined;
  }

  const { getCurrentUserId, getCurrentUserEmail } = adaptorPayload;

  let logEvent: any;
  let analytics: any;
  const init: TInit = () => {
    const firebaseConfig = {
      apiKey,
      authDomain,
      projectId,
      storageBucket,
      messagingSenderId,
      appId,
      measurementId,
    };

    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);

    logEvent = (eventName: EEventTypes, eventParams: any) =>
      _logEvent(analytics, eventName, eventParams);
    (window as any).LOG_EVENT = logEvent;
  };

  const trackEvent: TTrackEvent = async (payload) => {
    const { eventName } = payload;
    if (!(eventName in TRACKED_EVENTS)) {
      return;
    }

    const { eventValue } = payload;

    switch (eventName) {
      case EEventTypes.SET_USER: {
        setUserId(analytics, getCurrentUserId() as string);
        logEvent('login', {
          user_id: getCurrentUserId() as string,
          user_data: {
            sha256_email_address: [
              hashData((getCurrentUserEmail() as string).trim().toLowerCase()),
            ],
          },
          method: 'AUTH0',
        });
        break;
      }
      case EEventTypes.REGISTRATION_COMPLETE: {
        setUserId(analytics, eventValue?.id as string);
        logEvent('sign_up', {
          user_id: eventValue?.id as string,
          user_data: {
            sha256_email_address: [
              hashData((eventValue?.email as string).trim().toLowerCase()),
            ],
          },
          method: 'AUTH0',
        });
        break;
      }
      case EEventTypes.PURCHASE_NODE: {
        const { total, currency, item, orderId } =
          eventValue as ITrackPurchaseItemEventPayload;

        logEvent('purchase', {
          user_id: getCurrentUserId(),
          user_data: {
            sha256_email_address: [
              hashData((getCurrentUserEmail() as string).trim().toLowerCase()),
            ],
          },
          transaction_id: orderId,
          value: total,
          currency,
          items: [
            {
              item_id: item.productId,
              item_name: item.productId,
              item_category: 'FILM-NODE',
              quantity: item.quantity,
              price: item.unitPrice,
            },
          ],
        });
        break;
      }
      case EEventTypes.PURCHASE_ITEM: {
        const typedEventValue = eventValue as ITrackPurchaseItemEventPayload;
        const { total, currency, item, orderId } = typedEventValue;

        logEvent('purchase', {
          user_id: getCurrentUserId(),
          user_data: {
            sha256_email_address: [
              hashData((getCurrentUserEmail() as string).trim().toLowerCase()),
            ],
          },
          transaction_id: orderId,
          value: total,
          currency,
          items: [
            {
              item_id: item.productId,
              item_name: item.productId,
              item_category: 'FILM-ITEM',
              quantity: item.quantity,
              price: item.unitPrice,
            },
          ],
        });
        break;
      }
    }
  };

  const hydrateAdaptorPayload: IHydrateAdaptorPayload = {
    init,
    trackEvent,
  };

  const baseAdaptor: IHydratedAdaptor = hydrateAdaptor(hydrateAdaptorPayload);

  const setUser: TSetUser = (currentUser) => {
    baseAdaptor.trackSetUserEvent(currentUser);
  };

  const finalAdaptor: IHydratedAdaptor = {
    ...baseAdaptor,
    setUser,
  };

  return finalAdaptor;
};

firebaseAdaptor.NAME = EAdaptors.FIREBASE;

export default firebaseAdaptor;
