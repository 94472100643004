var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FullScreenMediaDialog',{key:_vm.key,attrs:{"title":_vm.title,"dialogState":_vm.isOpen},on:{"update:dialogState":function($event){_vm.isOpen=$event},"update:dialog-state":function($event){_vm.isOpen=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_vm._t("activator",function(){return [(_vm.thumbUrl && _vm.activatorType === 'thumbnail')?_c('ThumbnailButton',_vm._g(_vm._b({attrs:{"image-url":_vm.thumbUrl,"label":_vm.activatorLabel,"width":_vm.thumbWidth,"height":_vm.thumbHeight}},'ThumbnailButton',attrs,false),on),[_c('IconPlay',{staticClass:"white--text"})],1):_c('v-btn',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple",value:(_vm.enableActivatorBtnRipple),expression:"enableActivatorBtnRipple"}],staticClass:"font--button rounded-pill test-class",class:{
          'pl-1 pr-4': _vm.playLogoType !== 'cta',
          'pa-0': _vm.playLogoType === 'cta',
        },attrs:{"text":"","large":"","color":"white"}},'v-btn',attrs,false),on),[(_vm.playLogoType === 'cta')?[_c('img',{style:({
              width: `${_vm.playIconSize}px`,
              height: `${_vm.playIconSize}px`,
            }),attrs:{"alt":"","src":require("@/assets/icons/play-btn.png")}})]:[_c('IconPlay',{staticClass:"mr-2"}),_vm._v("\n          "+_vm._s(_vm.activatorLabel)+"\n        ")]],2)]},{"attrs":attrs,"on":on})]}}],null,true)},[_vm._v(" "),_c('VideoPlayer',{key:_vm.key,ref:"video",staticClass:"video-player",attrs:{"id":_vm.id,"slug":_vm.slug},on:{"canplay":_vm.handleCanPlay,"ended":_vm.handleEnded,"mounted":_vm.handlePlayerMounted}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }