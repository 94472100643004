import { render, staticRenderFns } from "./CreditCards.vue?vue&type=template&id=ee759990&scoped=true"
import script from "./CreditCards.vue?vue&type=script&lang=ts"
export * from "./CreditCards.vue?vue&type=script&lang=ts"
import style0 from "./CreditCards.vue?vue&type=style&index=0&id=ee759990&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee759990",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CreditCardInfo: require('/builds/gala-games/gala-film/gala-film/src/components/CreditCards/CreditCardInfo.vue').default})
