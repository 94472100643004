import { onBeforeMount } from 'vue';

const uidPrefix = 'uniqId';
let uidCounter = 0;

export const useId = (id: string) => {
  onBeforeMount(() => {
    uidCounter += 1;
  });
  return `${uidPrefix}-${uidCounter}-${id}`;
};
