import { Context } from '@nuxt/types';
import { groq } from '@nuxtjs/sanity';
import { IProfileState } from '~/store/profile/types';
import {
  TPromptToLoginDispatch,
  TPromptToRegisterDispatch,
} from '~/store/types';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';
import { ISanityObjects, SanityObjectSchema } from '~/types/sanity-objects';
import { getPageRoutePath } from '~/utils/sanity';

interface IRedirectWithExpandedRef
  extends Omit<
    ISanityObjects[SanityObjectSchema.RELATIVE_URL_OR_REFLINK],
    'documentReference'
  > {
  documentReference?: null | {
    _type: SanityDocumentSchema;
    _id: string;
    slug?: string;
  };
}

interface IAuthConfigFromRoute
  extends Omit<ISanityDocuments[SanityDocumentSchema.AUTH_CONFIG], 'redirect'> {
  redirect?: IRedirectWithExpandedRef;
}

export default async function (context: Context) {
  const { redirect, route, store, app } = context;

  const loginRoutes: Record<string, boolean> = {
    login: true,
  };

  const registerRoutes: Record<string, boolean> = {
    register: true,
    'external-register': true,
  };

  if (!route.name || !(loginRoutes[route.name] || registerRoutes[route.name])) {
    return;
  }

  if ((store.state?.profile as IProfileState)?.user?.loggedIn) {
    return redirect('/');
  }

  const { s } = route.query;
  const { dispatch } = store;
  const slug = !s ? '' : typeof s === 'string' ? s : s.toString();
  const authConfig = slug
    ? await app.$sanity.client.fetch<IAuthConfigFromRoute>(
        groq`*[_type == $type && slug.current == $slug && !(_id in path('drafts.**'))][0] {
          ...,
          "redirect": {
            ...redirect,
            "documentReference": redirect.documentReference->{
              _type,
              _id,
              "slug": slug.current,
            }
          }
        }`,
        {
          slug,
          type: SanityDocumentSchema.AUTH_CONFIG,
        },
      )
    : undefined;

  const redirectPath =
    authConfig?.redirect?.linkType === 'docRef' &&
    authConfig?.redirect?.documentReference?.slug
      ? getPageRoutePath({
          slug: authConfig?.redirect?.documentReference?.slug,
          schema: authConfig?.redirect?.documentReference?._type,
        })
      : authConfig?.redirect?.linkType === 'url' && authConfig?.redirect?.href
      ? authConfig?.redirect?.href
      : undefined;

  if (loginRoutes[route.name]) {
    dispatch<TPromptToLoginDispatch>({
      type: 'promptToLogin',
      theme: authConfig?.theme,
      redirectAfterLoginPath: redirectPath,
    });
  }

  if (registerRoutes[route.name]) {
    dispatch<TPromptToRegisterDispatch>({
      type: 'promptToRegister',
      theme: authConfig?.theme,
      redirectAfterLoginPath: redirectPath,
    });
  }
}
