import { IGame, GamePlatform } from '../store/games/types';

export const featureFlaggedGames: IGame[] = [
  {
    name: 'Town Crush',
    displayName: 'Town Crush',
    codeName: 'town-crush',
    collection: 'TownCrush',
    showGamePage: Boolean(process.env.showTownCrushPage),
    developer: 'Gala Games',
    genre: 'Match-Three',
    developmentStatus: 'Test Game',
    platform: [GamePlatform.Browser],
    isReleased: Boolean(process.env.townCrushIsReleased),
    isReleasedInternally: Boolean(process.env.townCrushIsReleasedInternally),
    isMacReleased: false,
    isWinReleased: false,
    latestVersion: '',
    installerUrlMac: '',
    installerUrlWin: '',
    route: 'town-crush',
    discordLink: 'http://galagames.chat',
    playLink: process.env.townCrushLink as string,
    websiteLink: '',
    activeStartTimestamp: undefined,
    activeEndTimestamp: undefined,
    coin: 'GALA',
    logo: 'https://static.gala.games/images/games/town-crush/tc_logo_400x240.png',
    croppedLogo:
      'https://static.gala.games/images/games/town-crush/tc_logo_400x240.png',
    bannerImageCarouselMin:
      'https://static.gala.games/images/interactiveHomeBanner/town-crush/3-10-22/tc_carousel_700x940.jpg',
    bannerImageCarousel:
      'https://static.gala.games/images/interactiveHomeBanner/town-crush/3-10-22/tc_carousel_1800x800.jpg',
    bannerImageHome:
      'https://static.gala.games/images/interactiveHomeBanner/town-crush/3-10-22/tc_carousel_1800x800.jpg',
    bannerImage:
      'https://static.gala.games/images/interactiveHomeBanner/town-crush/3-10-22/tc_carousel_1800x800.jpg',
    bannerImageDesktop:
      'https://static.gala.games/images/interactiveHomeBanner/town-crush/3-10-22/tc_carousel_1800x800.jpg',
    gamePageBackground:
      'https://static.gala.games/images/games/town-crush/game-page/tc_gamePg_2000x1000.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games/town-crush/tc_logo_400x240.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen1_256x144.jpg',
        medium:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen1_1000x563.jpg',
        large:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen1_1000x563.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen2_256x144.jpg',
        medium:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen2_1000x563.jpg',
        large:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen2_1000x563.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen3_256x144.jpg',
        medium:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen3_1000x563.jpg',
        large:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen3_1000x563.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen4_256x144.jpg',
        medium:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen4_1000x563.jpg',
        large:
          'https://static.gala.games/images/games/town-crush/screenshots/tc_screen4_1000x563.jpg',
        type: 'img',
      },
    ],
    inventoryImage: Boolean(process.env.townCrushIsReleased)
      ? 'https://tokens.gala.games/images/inventory-images/TownCrush.png'
      : 'https://static.gala.games/images/games/town-crush/tc_construction_icon.png',
    aboutUsImage:
      'https://static.gala.games/images/games/town-crush/about/tc_about_600x900.jpg',
    linkImage:
      'https://static.gala.games/images/games/town-crush/tc_mainGame_700x394.jpg',
    longDescription:
      'Town Crush is a Town Star themed match-three puzzle game, created by the Gala Games team and designed as the first provable use case for our proprietary blockchain, Project GYRI. Members of the Gala Games community will have access to this fully playable test for a limited time, allowing them to witness the power of Project GYRI and play to earn TEST, a test token that cannot be transferred or sold.',
    descriptionText:
      'Complete all 12 levels before July 8th for a share of $400k in GALA rewards!',
    currency: 'GALA',
    screenshots: [],
    showNodePromotion: true,
    taglineHome: 'Win GALA Challenge!',
    bannerCtaText: 'Learn More',
    requireAuthToPlay: true,
    usesGalaChain: true,
    inventoryDisclaimer:
      'Returning soon. True inventory count for Town Crush may not be reflected here.',
    requiredAllowances: [
      // {
      //   type: 'TEST',
      //   tokenId: 'town-crush_test',
      //   tokenImg: require('~/assets/logos/TEST-icon.png'),
      //   action: 3,
      //   amount: '40000', // TODO: change this when we have the ability to give unlimited allowances
      //   ownerKey: 'client|admin',
      //   uses: '10000',
      //   category: 'Currency',
      //   additionalKey: 'none',
      // },
    ],
    allowancesGiven: [],
    bannerImageClass: 'light-bg',
    showCarouselBanner: false,
    updatedAt: new Date('2022-06-29').getTime(),
  },
];

// TODO: Move these somwhere else?--they aren't games, so they probably shouldn't go in the 'games' store
export const nonGameGames: IGame[] = [
  {
    name: 'Gala Film',
    collection: 'Gala Film',
    showGamePage: false,
    codeName: 'gala-film',
    displayName: 'Gala Film',
    route: 'gala-film',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Gala_Film.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Gala Music',
    collection: 'Gala Music',
    showGamePage: false,
    codeName: 'gala-music',
    displayName: 'Gala Music',
    route: 'gala-music',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Gala_Music.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Gacha',
    collection: 'Gacha',
    showGamePage: false,
    codeName: 'gacha',
    displayName: 'Gacha',
    route: 'gacha',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Gacha.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'VOX',
    collection: 'VOX',
    showGamePage: false,
    codeName: 'vox',
    displayName: 'VOX',
    route: 'vox',
    inventoryImage: 'https://tokens.gala.games/images/inventory-images/VOX.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Vox Series 1',
    collection: 'Vox Series 1',
    showGamePage: false,
    codeName: 'vox-series-1',
    displayName: 'Vox Series 1',
    route: 'vox-series-1',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Vox-Series-1.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Mirandus Vox',
    collection: 'Mirandus Vox',
    showGamePage: false,
    codeName: 'mirandus-vox',
    displayName: 'Mirandus Vox',
    route: 'mirandus-vox',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Mirandus_VOX.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'The Walking Dead Vox',
    collection: 'The Walking Dead Vox',
    showGamePage: false,
    codeName: 'twd-vox',
    displayName: 'The Walking Dead Vox',
    route: 'twd-vox',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/The_Walking_Dead_Vox.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'DreamWorks Trolls Vox',
    collection: 'DreamWorks Trolls Vox',
    showGamePage: false,
    codeName: 'dreamworks-trolls-vox',
    displayName: 'DreamWorks Trolls Vox',
    route: 'dreamworks-trolls-vox',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/DreamWorks_Trolls_VOX.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Eternal Paradox Land',
    collection: 'Eternal Paradox Land',
    showGamePage: false,
    codeName: 'eternal-paradox-land',
    displayName: 'Eternal Paradox Land',
    route: 'eternal-paradox-land',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Eternal_Paradox_Land.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Galaverse',
    collection: 'Galaverse',
    showGamePage: false,
    displayName: 'Galaverse',
    codeName: 'galaverse',
    route: 'galaverse',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Galaverse_Tickets.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: Boolean(process.env.galaverseTicketsIsReleased),
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Galaverse Tickets',
    collection: 'Galaverse Tickets',
    showGamePage: false,
    displayName: 'Galaverse Tickets',
    codeName: 'galaverse-tickets',
    route: 'galaverse-tickets',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Galaverse_Tickets.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: Boolean(process.env.galaverseTicketsIsReleased),
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Fuzzles',
    collection: 'Fuzzles',
    showGamePage: false,
    displayName: 'Fuzzles',
    codeName: 'fuzzles',
    route: 'fuzzles',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Fuzzles.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: Boolean(process.env.galaverseTicketsIsReleased),
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Genesis Curry Flow',
    collection: 'Genesis Curry Flow',
    showGamePage: false,
    displayName: 'Genesis Curry Flow',
    codeName: 'genesis-currry-flow',
    route: 'genesis-currry-flow',
    inventoryImage: 'https://static.gala.games/images/curry/curry-shoebox.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: true,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Frank Miller: 30th Anniversary',
    collection: 'Frank Miller: 30th Anniversary',
    showGamePage: false,
    codeName: 'frank-miller-30th-anniversary',
    displayName: 'Frank Miller: 30th Anniversary',
    route: 'frank-miller-30th-anniversary',
    inventoryImage:
      'https://static.gala.games/images/Frank-Miller/frank-miller.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: true,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Frank Miller Noir Hero',
    collection: 'Frank Miller Noir Hero',
    showGamePage: false,
    displayName: 'Frank Miller Noir Hero',
    codeName: 'frank-miller-noir-hero',
    route: 'frank-miller-noir-hero',
    inventoryImage:
      'https://static.gala.games/images/Frank-Miller/frank-miller.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: true,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Blue Realm',
    collection: 'Blue Realm',
    showGamePage: false,
    codeName: 'blue-realm',
    displayName: 'Blue Realm',
    route: 'blue-realm',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Blue_Realm.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
  {
    name: 'Collectibles',
    collection: 'Collectibles',
    showGamePage: false,
    codeName: 'collectibles',
    displayName: 'Collectibles',
    route: 'collectibles',
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Collectibles.png',
    developer: '',
    genre: '',
    developmentStatus: '',
    platform: [],
    isReleased: false,
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    descriptionAssets: [],
    aboutUsImage: '',
    linkImage: '',
    descriptionText: '',
    taglineHome: '',
    longDescription: '',
    screenshots: [],
    currency: '',
    playLink: '',
    coin: '',
    logo: '',
    croppedLogo: '',
  },
];

export const games: IGame[] = [
  {
    name: 'GRIT',
    updatedAt: new Date('2022-05-31').getTime(),
    collection: 'GRIT',
    displayName: 'GRIT',
    codeName: 'grit',
    showGamePage: Boolean(process.env.showGrit),
    developer: 'Team GRIT',
    genre: 'Battle Royale',
    developmentStatus: '',
    platform: [GamePlatform.Win],
    isReleased: Boolean(process.env.gritIsReleased),
    isMacReleased: Boolean(process.env.gritIsReleased),
    isWinReleased: Boolean(process.env.gritIsReleased),
    latestVersion: process.env.gritLatestVersion as string,
    installerUrlMac: '',
    installerUrlWin: '',
    route: 'grit',
    discordLink: 'http://galagames.chat',
    playLink: '',
    websiteLink: 'https://grit.game',
    coin: 'GALA',
    logo: 'https://static.gala.games/grit/images/game-page-title.png',
    bannerImageCarouselMin:
      'https://static.gala.games/grit/images/main-carousel-mobile.png',
    bannerImageCarousel:
      'https://static.gala.games/grit/images/main-carousel.png',
    taglineHome: 'Ride or Die',
    bannerCtaText: 'Play',
    descriptionText: 'Ride or die in the ultimate Wild West battle royale.',
    gamePageBackground:
      'https://static.gala.games/grit/images/game-page-background.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/grit/images/game-page-header-logo.png',
    descriptionAssets: [
      {
        thumbnail: 'https://static.gala.games/grit/images/carousel-1-small.png',
        medium: 'https://static.gala.games/grit/images/carousel-1-med.png',
        large: 'https://static.gala.games/grit/images/carousel-1-large.png',
        type: 'img',
      },
      {
        thumbnail: 'https://static.gala.games/grit/images/carousel-2-small.png',
        medium: 'https://static.gala.games/grit/images/carousel-2-med.png',
        large: 'https://static.gala.games/grit/images/carousel-2-large.png',
        type: 'img',
      },
      {
        thumbnail: 'https://static.gala.games/grit/images/carousel-3-small.png',
        medium: 'https://static.gala.games/grit/images/carousel-3-med.png',
        large: 'https://static.gala.games/grit/images/carousel-3-large.png',
        type: 'img',
      },
      {
        thumbnail: 'https://static.gala.games/grit/images/carousel-4-small.png',
        medium: 'https://static.gala.games/grit/images/carousel-4-med.png',
        large: 'https://static.gala.games/grit/images/carousel-4-large.png',
        type: 'img',
      },
      {
        thumbnail: 'https://static.gala.games/grit/images/carousel-5-small.png',
        medium: 'https://static.gala.games/grit/images/carousel-5-med.png',
        large: 'https://static.gala.games/grit/images/carousel-5-large.png',
        type: 'img',
      },
    ],
    showNodePromotion: true,
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/GRIT.png',
    aboutUsImage: 'https://static.gala.games/grit/images/about.png',
    linkImage: 'https://static.gala.games/grit/images/game-page-title.png',
    longDescription:
      "This town ain't big enough for the two of us, and it ain't just the two of us looking for a fight. Ride or die in the ultimate Wild West battle royale, where there's more to winning than aiming true. Saddle up and ride across town, building the best poker hand from found weapons to make a killing. Grab the best shooter to-hand and gun your way to infamy on the new frontier. Explore the towns, oil fields and plains of the untamed west as you try to hitch a ride on the last man alive train. But hold on to your 10 gallon hat - it'll protect you as you're shootin' up a storm! If a gunslinger shoots you down, keep your hat on - a 1v1 Wild West showdown could put you back in the game if you got a quick draw.",
    currency: 'GALA',
    screenshots: [],
    requireAuthToPlay: true,
    showCarouselBanner: false,
  },
  {
    name: 'Battlestar Galactica',
    updatedAt: new Date('2022-05-27').getTime(),
    collection: 'Battlestar Galactica',
    displayName: 'Battlestar Galactica',
    codeName: 'battlestar-galactica',
    showGamePage: Boolean(process.env.showBattlestarGalactica),
    developer: 'Revolving Games',
    genre: 'Strategy, 4x',
    developmentStatus: 'In Development',
    platform: [GamePlatform.Win],
    isReleased: Boolean(process.env.battlestarGalacticaIsReleased),
    isMacReleased: Boolean(process.env.battlestarGalacticaIsReleased),
    isWinReleased: Boolean(process.env.battlestarGalacticaIsReleased),
    latestVersion: process.env.battlestarGalacticaLatestVersion as string,
    installerUrlMac: '',
    installerUrlWin: '',
    route: 'battlestar-galactica',
    discordLink: 'http://galagames.chat',
    playLink: '',
    websiteLink: '',
    coin: 'GALA',
    logo: 'https://static.gala.games/battlestar-galactica/images/game-title-page.png',
    croppedLogo:
      'https://static.gala.games/battlestar-galactica/images/game-title-page.png',
    bannerImageCarouselMin: '',
    bannerImageCarousel: '',
    gamePageBackground:
      'https://static.gala.games/battlestar-galactica/images/game-page-background.png',
    gamePageHeaderLogo:
      'https://static.gala.games/battlestar-galactica/images/game-page-logo.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/battlestar-galactica/images/carousel-1.png',
        medium:
          'https://static.gala.games/battlestar-galactica/images/carousel-1.png',
        large:
          'https://static.gala.games/battlestar-galactica/images/carousel-1.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/battlestar-galactica/images/carousel-2.png',
        medium:
          'https://static.gala.games/battlestar-galactica/images/carousel-2.png',
        large:
          'https://static.gala.games/battlestar-galactica/images/carousel-2.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/battlestar-galactica/images/carousel-3.png',
        medium:
          'https://static.gala.games/battlestar-galactica/images/carousel-3.png',
        large:
          'https://static.gala.games/battlestar-galactica/images/carousel-3.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/battlestar-galactica/images/carousel-4.png',
        medium:
          'https://static.gala.games/battlestar-galactica/images/carousel-4.png',
        large:
          'https://static.gala.games/battlestar-galactica/images/carousel-4.png',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Battlestar_Galactica.png',
    aboutUsImage:
      'https://static.gala.games/battlestar-galactica/images/about-page.png',
    linkImage:
      'https://static.gala.games/battlestar-galactica/images/game-title-page.png',
    longDescription:
      "Battlestar Galactica is a MMO 4X strategy game that takes place after The Fall. Players find themselves scattered throughout the galaxy following the attack from the Cylons with the goal of retaking their home system as a surviving member of one of the Twelve Colonies. Rebuild your resources, reunite with your faction, and reclaim the Twelve Colonies. Humanity's children are returning home. So say we all.",
    descriptionText: '',
    currency: 'GALA',
    screenshots: [],
    showNodePromotion: true,
    taglineHome: '',
    bannerCtaText: '',
    requireAuthToPlay: true,
    showCarouselBanner: false,
  },
  {
    name: 'Eternal Paradox',
    updatedAt: new Date('2022-05-27').getTime(),
    collection: 'Eternal Paradox',
    displayName: 'Eternal Paradox',
    codeName: 'eternal-paradox',
    showGamePage: Boolean(process.env.showEternalParadox),
    developer: 'Ndream',
    genre: '4x, Turn-Based RPG',
    developmentStatus: 'In Development',
    platform: [GamePlatform.Win, GamePlatform.Android, GamePlatform.iOS],
    isReleased: Boolean(process.env.eternalParadoxIsReleased),
    isMacReleased: Boolean(process.env.eternalParadoxIsReleased),
    isWinReleased: Boolean(process.env.eternalParadoxIsReleased),
    latestVersion: process.env.eternalParadoxLatestVersion as string,
    installerUrlMac: '',
    installerUrlWin: '',
    route: 'eternal-paradox',
    discordLink: 'http://galagames.chat',
    playLink: '',
    websiteLink: 'https://eternal-paradox.game/',
    coin: 'GALA',
    logo: 'https://static.gala.games/eternal-paradox/images/logo.png',
    bannerImageCarouselMin:
      'https://static.gala.games/eternal-paradox/images/main-carousel-mobile.jpg',
    bannerImageCarousel:
      'https://static.gala.games/eternal-paradox/images/main-carousel.jpg',
    gamePageBackground:
      'https://static.gala.games/eternal-paradox/images/game-page-background.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/eternal-paradox/images/game-page-header-logo.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/eternal-paradox/images/carousel-5.png',
        medium:
          'https://static.gala.games/eternal-paradox/images/carousel-5.png',
        large:
          'https://static.gala.games/eternal-paradox/images/carousel-5.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/eternal-paradox/images/carousel-1.jpg',
        medium:
          'https://static.gala.games/eternal-paradox/images/carousel-1.jpg',
        large:
          'https://static.gala.games/eternal-paradox/images/carousel-1.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/eternal-paradox/images/carousel-2.jpg',
        medium:
          'https://static.gala.games/eternal-paradox/images/carousel-2.jpg',
        large:
          'https://static.gala.games/eternal-paradox/images/carousel-2.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/eternal-paradox/images/carousel-3.jpg',
        medium:
          'https://static.gala.games/eternal-paradox/images/carousel-3.jpg',
        large:
          'https://static.gala.games/eternal-paradox/images/carousel-3.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/eternal-paradox/images/carousel-4.jpg',
        medium:
          'https://static.gala.games/eternal-paradox/images/carousel-4.jpg',
        large:
          'https://static.gala.games/eternal-paradox/images/carousel-4.jpg',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Eternal_Paradox.png',
    aboutUsImage: 'https://static.gala.games/eternal-paradox/images/game.png',
    linkImage: 'https://static.gala.games/eternal-paradox/images/logo.png',
    longDescription:
      'Eternal Paradox follows the standard 4X basics: Explore, Exploit, Expand, and Exterminate. One major difference is the addition of RPG-style turn-based combat. This blend of two genres makes Eternal Paradox especially interesting.',
    currency: 'GALA',
    screenshots: [],
    showNodePromotion: true,
    taglineHome: 'Build, Expand and Claim Victory!',
    bannerCtaText: 'Start Exploration!',
    descriptionText:
      'A new hybrid 4X/RPG with epic adventures and massive battles to be fought. Develop your land, train your mercenaries and rise to the challenges of your opponents.',
    requireAuthToPlay: true,
    showCarouselBanner: false,
  },
  {
    name: 'Superior',
    collection: 'Superior',
    displayName: 'Superior',
    codeName: 'superior',
    showGamePage: true,
    showCarouselBanner: false,
    developer: 'Drifter',
    genre: 'Roguelite, Third-Person Shooter',
    developmentStatus: 'In Development',
    platform: [GamePlatform.Win],
    isReleased: Boolean(process.env.superiorIsReleased),
    isMacReleased: Boolean(process.env.superiorIsReleasedMac),
    isWinReleased: Boolean(process.env.superiorIsReleasedWin),
    latestVersion: process.env.superiorLatestVersion as string,
    installerUrlMac: 'api/v1/launcher/installer/superior/mac',
    installerUrlWin: 'api/v1/launcher/installer/superior/win',
    route: 'superior',
    discordLink: 'http://galagames.chat',
    playLink: '',
    coin: 'GALA',
    websiteLink: 'https://www.superior.game/',
    comingSoon: '',
    logo: 'https://static.gala.games/images/games/superior/logo-superior.png',
    croppedLogo:
      'https://static.gala.games/images/games/superior/logo-superior.png',
    bannerImageCarouselMin:
      'https://static.gala.games/images/interactiveHomeBanner/superior/superior-mobile.jpg',
    bannerImageCarousel:
      'https://static.gala.games/images/interactiveHomeBanner/superior/superior-desktop.jpg',
    gamePageBackground:
      'https://static.gala.games/images/games/superior/background-superior.png',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games/superior/logo-superior.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_256x144_01.jpg',
        medium:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_1000x563_01.jpg',
        large:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_2560x1440_01.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_256x144_02.jpg',
        medium:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_1000x563_02.jpg',
        large:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_2560x1440_02.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_256x144_03.jpg',
        medium:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_1000x563_03.jpg',
        large:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_2560x1440_03.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_256x144_04.jpg',
        medium:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_1000x563_04.jpg',
        large:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_2560x1440_04.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_256x144_05.jpg',
        medium:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_1000x563_05.jpg',
        large:
          'https://static.gala.games/images/games/superior/game-page-carousel/SH_SUPERIOR_2560x1440_05.jpg',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Superior.png',
    aboutUsImage: `https://static.gala.games/images/games/superior/superior-about-page.jpg`,
    linkImage:
      'https://static.gala.games/images/games/superior/superior-game-tile-image.png',
    longDescription:
      "Superior is a co-op, roguelite, third-person shooter where all the superheroes have turned into evil abominations. Every session you'll start out unpowered and outgunned, as you hunt the evil superheroes down, steal their powers and take on even tougher challenges. Gain levels to permanently upgrade your character with a branching skill tree that will give you an edge in future attempts. Explore a sprawling hyper-stylized superhero universe through multiple biomes and dynamically configured missions. Hunt superheroes, steal their powers, and save the world when Superior launches Q4 2022!",
    descriptionText: `Hunt superheroes, steal their powers, save the world. Fight alone or with friends in this third person shooter, roguelite!`,
    taglineHome: `Hunt Evil Superheroes`,
    bannerCtaLink: '/games/superior',
    bannerCtaText: 'Save the World',
    currency: 'GALA',
    screenshots: [],
    requireAuthToPlay: true,
    narrowText: true,
  },
  {
    name: 'The Walking Dead: Empires',
    collection: 'The Walking Dead: Empires',
    displayName: 'The Walking Dead: Empires',
    codeName: 'thewalkingdead',
    showGamePage: true,
    developer: 'Ember Entertainment',
    genre: 'Survival MMORPG',
    developmentStatus: 'In Development',
    platform: [GamePlatform.Win],
    isReleased: Boolean(process.env.walkingDeadIsReleased),
    isMacReleased: Boolean(process.env.walkingDeadIsReleasedMac),
    isWinReleased: Boolean(process.env.walkingDeadIsReleasedWin),
    latestVersion: process.env.walkingDeadLatestVersion as string,
    installerUrlMac: 'api/v1/launcher/installer/thewalkingdead/mac',
    installerUrlWin: 'api/v1/launcher/installer/thewalkingdead/win',
    route: 'the-walking-dead-empires',
    discordLink: 'http://galagames.chat',
    playLink: '',
    coin: 'GALA',
    websiteLink: 'https://twdempires.game',
    comingSoon:
      'Coming soon...play to win BIG rewards that you can own and trade.',
    logo: 'https://static.gala.games/images/games/the-walking-dead/logos/twd-horizontal-logo-compressed.png',
    croppedLogo:
      'https://static.gala.games/images/games/the-walking-dead/logos/twd-horizontal-logo-compressed.png',
    bannerImageCarouselMin:
      'https://static.gala.games/images/interactiveHomeBanner/the-walking-dead/crafting-carousel-mobile.jpg',
    bannerImageCarousel:
      'https://static.gala.games/images/interactiveHomeBanner/the-walking-dead/crafting-desktop-carousel.jpg',
    gamePageBackground:
      'https://static.gala.games/images/games/the-walking-dead/gameBG_2000x1200.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games/the-walking-dead/logos/twd-horizontal-logo-compressed.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/twd_banner.jpg',
        medium:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/TWD-Trailer.mp4',
        poster:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/twd_banner.jpg',
        type: 'video',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/4-TWDE_screenshot_misty_forest.jpeg',
        medium:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/4-TWDE_screenshot_misty_forest.jpeg',
        large:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/4-TWDE_screenshot_misty_forest.jpeg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/5-TWDE_screenshot_powerlines.jpeg',
        medium:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/5-TWDE_screenshot_powerlines.jpeg',
        large:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/5-TWDE_screenshot_powerlines.jpeg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/3-TWDE_screenshot_gas_station.jpeg',
        medium:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/3-TWDE_screenshot_gas_station.jpeg',
        large:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/3-TWDE_screenshot_gas_station.jpeg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/6-TWDE_screenshot_rocks.jpeg',
        medium:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/6-TWDE_screenshot_rocks.jpeg',
        large:
          'https://static.gala.games/images/games/the-walking-dead/game-page-carousel-images/6-TWDE_screenshot_rocks.jpeg',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/The_Walking_Dead__Empires.png',
    aboutUsImage: `https://static.gala.games/images/games/the-walking-dead/twd-about-page.png`,
    linkImage:
      'https://static.gala.games/images/games/the-walking-dead/TWDE_game_image_title_banner.png',
    longDescription:
      "The Walking Dead:Empires is a multiplayer survival game set in the treacherous world of AMC's The Walking Dead. Survive in this harsh reality by doing whatever it takes. Scavenge for supplies and construct your new home. Team up with allies, compete against maniacal foes, and always beware the dead.",
    descriptionText: `Use your own Crafting Station to craft high quality items and supplies in the game!`,
    taglineHome: `LIMITED AVAILABILITY`,
    bannerCtaLink: '/games/the-walking-dead-empires',
    bannerCtaText: 'BUY NOW',
    currency: 'GALA',
    screenshots: [],
    requireAuthToPlay: true,
    showCarouselBanner: false,
  },
  {
    name: 'Legends Reborn',
    collection: 'Legends Reborn',
    displayName: 'Legends Reborn',
    codeName: 'legends-reborn',
    showGamePage: true,
    developer: 'Kung Fu Factory',
    genre: 'Card, Strategy',
    developmentStatus: 'In Development',
    platform: [GamePlatform.Mac, GamePlatform.Win],
    isReleased: Boolean(process.env.legendsRebornIsReleased),
    isMacReleased: Boolean(process.env.legendsRebornIsReleasedMac),
    isWinReleased: Boolean(process.env.legendsRebornIsReleasedWin),
    latestVersion: process.env.legendsRebornLatestVersion as string,
    installerUrlMac: 'api/v1/launcher/installer/legends-reborn/mac',
    installerUrlWin: 'api/v1/launcher/installer/legends-reborn/win',
    route: 'legends-reborn',
    discordLink: 'http://galagames.chat',
    playLink: '',
    bannerCtaLink: '/games/legends-reborn',
    coin: 'GALA',
    websiteLink: 'https://www.legendsreborn.game/',
    comingSoon:
      'Coming soon...play to win BIG rewards that you can own and trade.',
    logo: 'https://static.gala.games/images/games/legends-reborn/LEGENDS REBORN_primary-logo-optimized.png',
    croppedLogo:
      'https://static.gala.games/images/games/legends-reborn/LEGENDS REBORN_primary-logo-optimized.png',
    bannerImageCarouselMin:
      'https://static.gala.games/images/interactiveHomeBanner/legends-reborn/carousel_mobile.jpg',
    bannerImageCarousel:
      'https://static.gala.games/images/interactiveHomeBanner/legends-reborn/landsale_newbanner.jpg',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    gamePageBackground:
      'https://static.gala.games/images/games/legends-reborn/GamePageBackgroundImageGradient.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games/legends-reborn/LEGENDS REBORN_primary-logo-optimized.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/games/legends-reborn/GamePageTItleImage_02.jpg',
        medium:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/LegendsRebornTeaser.mp4',
        poster:
          'https://static.gala.games/images/games/legends-reborn/GamePageTItleImage_02.jpg',
        type: 'video',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/ScreenShot_1-optimized.png',
        medium:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/ScreenShot_1-optimized.png',
        large:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/LG_ScreenShot_1.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/ScreenShot_2-optimized.png',
        medium:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/ScreenShot_2-optimized.png',
        large:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/LG_ScreenShot_2.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/ScreenShot_3-optimized.jpg',
        medium:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/ScreenShot_3-optimized.jpg',
        large:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/LG_ScreenShot_3-opt.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/ScreenShot_4-optimized.png',
        medium:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/ScreenShot_4-optimized.png',
        large:
          'https://static.gala.games/images/games/legends-reborn/game-page-carousel-items/LG_ScreenShot_4.jpg',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Legends_Reborn.png',
    aboutUsImage:
      'https://static.gala.games/images/games/legends-reborn/AboutPage.png',
    linkImage:
      'https://static.gala.games/images/games/legends-reborn/GamePageTItleImage_02.jpg',
    longDescription:
      'Legends Reborn is a strategic card game about building decks and taking on all challengers. Amass a collection of cards and creatures which allows you to build, re-build, and tweak decks to meet any challenge that comes your way.',
    descriptionText: `Be among the first to own land in Tolkhiem! PvP Venues from Dive Bar to Grand Coliseum are up for grabs, so don't delay!`,
    taglineHome: `Own the Battleground!`,
    bannerCtaText: 'View Land',
    currency: 'GALA',
    screenshots: [],
    requireAuthToPlay: true,
  },
  {
    name: 'Spider Tanks',
    updatedAt: new Date('2022-04-25').getTime(),
    collection: 'Spider Tanks',
    displayName: 'Spider Tanks',
    codeName: 'spider-tanks',
    showGamePage: true,
    developer: 'GAMEDIA',
    genre: 'PvP Brawler',
    developmentStatus: 'Beta',
    platform: [GamePlatform.Win],
    isReleased: Boolean(process.env.spiderTanksIsReleased),
    isMacReleased: Boolean(process.env.spiderTanksIsReleasedMac),
    isWinReleased: Boolean(process.env.spiderTanksIsReleasedWin),
    latestVersion: process.env.spiderTanksLatestVersion as string,
    installerUrlMac: 'api/v1/launcher/installer/spider-tanks/mac',
    installerUrlWin: 'api/v1/launcher/installer/spider-tanks/win',
    route: 'spider-tanks',
    discordLink: 'http://galagames.chat',
    playLink: '',
    websiteLink: 'http://www.spidertanks.game/',
    activeStartTimestamp: process.env.spiderTanksEarlyAccessStart
      ? +process.env.spiderTanksEarlyAccessStart
      : undefined,
    activeEndTimestamp: process.env.spiderTanksEarlyAccessEnd
      ? +process.env.spiderTanksEarlyAccessEnd
      : undefined,
    coin: 'GALA',
    logo: 'https://static.gala.games/images/spider-tanks/st-logo.png',
    croppedLogo: 'https://static.gala.games/images/spider-tanks/st-logo.png',
    bannerImageCarouselMin:
      'https://static.gala.games/images/interactiveHomeBanner/Mobile_Spider_Tanks_Carousel_Mobile_350x569v2.png',
    bannerImageCarousel:
      'https://static.gala.games/images/interactiveHomeBanner/Desktop_Spider_Tanks_Carousels_900x400v2.png',
    bannerImageHome:
      'https://static.gala.games/images/spider-tanks/spidertanks-transparent-banner-centered.png',
    bannerImage:
      'https://static.gala.games/images/spider-tanks/spidertanks-transparent-banner-centered.png',
    bannerImageDesktop:
      'https://static.gala.games/images/spider-tanks/spidertanks-transparent-banner-centered.png',
    gamePageBackground:
      'https://static.gala.games/images/games-page/bg_stp.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/spider-tanks/st-logo.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/spider-tanks/gallery/thumbnails/st_trailer_tn.jpg',
        medium:
          'https://static.gala.games/images/spider-tanks/gallery/videos/TANKS_PROMO.mp4',
        poster:
          'https://static.gala.games/images/spider-tanks/gallery/posters/spidertank_trailer_poster.jpg',
        type: 'video',
      },
      {
        thumbnail:
          'https://static.gala.games/images/spider-tanks/gallery/medium/st_promo1.jpg',
        medium:
          'https://static.gala.games/images/spider-tanks/gallery/medium/st_promo1.jpg',
        large:
          'https://static.gala.games/images/spider-tanks/gallery/medium/st_promo1.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/spider-tanks/gallery/medium/st_promo2.jpg',
        medium:
          'https://static.gala.games/images/spider-tanks/gallery/medium/st_promo2.jpg',
        large:
          'https://static.gala.games/images/spider-tanks/gallery/medium/st_promo2.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/spider-tanks/gallery/medium/st_promo3.jpg',
        medium:
          'https://static.gala.games/images/spider-tanks/gallery/medium/st_promo3.jpg',
        large:
          'https://static.gala.games/images/spider-tanks/gallery/medium/st_promo3.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/spider-tanks/gallery/thumbnails/Screenshot.png',
        medium:
          'https://static.gala.games/images/spider-tanks/gallery/medium/Screenshot.png',
        large:
          'https://static.gala.games/images/spider-tanks/gallery/large/Screenshot.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/spider-tanks/gallery/thumbnails/Screenshot2.png',
        medium:
          'https://static.gala.games/images/spider-tanks/gallery/medium/Screenshot2.png',
        large:
          'https://static.gala.games/images/spider-tanks/gallery/large/Screenshot2.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/spider-tanks/gallery/thumbnails/Screenshot3.png',
        medium:
          'https://static.gala.games/images/spider-tanks/gallery/medium/Screenshot3.png',
        large:
          'https://static.gala.games/images/spider-tanks/gallery/large/Screenshot3.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/spider-tanks/gallery/thumbnails/Screenshot4.png',
        medium:
          'https://static.gala.games/images/spider-tanks/gallery/medium/Screenshot4.png',
        large:
          'https://static.gala.games/images/spider-tanks/gallery/large/Screenshot4.png',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Spider_Tanks.png',
    aboutUsImage:
      'https://static.gala.games/images/spider-tanks/spidertanks-about.png',
    linkImage:
      'https://static.gala.games/images/spider-tanks/st_gamebanner.png',
    longDescription:
      'Spider Tanks is a PVP Brawler designed and developed by award-winning Netherlands based GAMEDIA. Players choose between a number of Tank Bodies and Weapons in the garage, then battle it out in one of the many Spider Tanks arena maps. Spider Tanks is free to play, but also incorporates a variety of play-to-earn mechanics, centering around skill-based competition, resource collection, and a player-driven upgrade cycle. Whether you want to play with a small group of close friends, or test your skill in high-stakes competition, Spider Tanks will be the worldwide play-to-earn esport for you. Purchase fully tradable Weapons, Tanks and Bodies in the Spider Tanks store for advantages to the upgrade cycle, rarity head starts and more. Coming in 2022.',
    descriptionText:
      'Spider Tanks is ready to rock, so get in the fast-paced battle action now for free.',
    currency: 'GALA',
    screenshots: [],
    showNodePromotion: true,
    taglineHome: 'SILK Test Release!',
    bannerCtaText: 'PLAY NOW',
    requireAuthToPlay: true,
    showCarouselBanner: false,
  },
  {
    name: 'Town Star',
    collection: 'Town Star',
    displayName: 'Town Star',
    codeName: 'townstar',
    showGamePage: true,
    developer: 'Gala Games',
    genre: 'Simulation',
    developmentStatus: 'beta',
    platform: [GamePlatform.Browser],
    isReleased: Boolean(process.env.townStarIsReleased),
    route: 'town-star',
    discordLink: 'http://galagames.chat',
    playLink: process.env.townStarLink as string,
    coin: 'BoxCoin',
    websiteLink: 'https://bit.ly/3BEls4l',
    comingSoon:
      'Coming soon...play to win BIG rewards that you can own and trade.',
    logo: 'https://tokens.gala.games/images/sandbox-games/town-star/logo_townStar.png',
    croppedLogo:
      'https://tokens.gala.games/images/sandbox-games/town-star/logo_townStar.png',
    bannerImageCarouselMin:
      'https://static.gala.games/images/interactiveHomeBanner/town-star/rolling-through/TS_Rolling_Through_700x940.jpg',
    bannerImageCarousel:
      'https://static.gala.games/images/interactiveHomeBanner/town-star/rolling-through/TS_Rolling_Through_1800x800.jpg',
    bannerImageHome:
      'https://tokens.gala.games/images/sandbox-games/town-star/npc/rancher-chem-engineer.png',
    bannerImage:
      'https://tokens.gala.games/compressed-images/banners/TS_January_Mobile.jpg',
    bannerImageDesktop:
      'https://tokens.gala.games/compressed-images/banners/TS_January.png',
    gamePageBackground: 'https://static.gala.games/images/games-page/bg_TS.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games-page/ts_horizontal_logo.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/town-star/videos/thumbnails/ts_P2E_tn.jpg',
        medium:
          'https://static.gala.games/images/town-star/videos/ts_P2E_vid.mp4',
        poster:
          'https://static.gala.games/images/town-star/videos/posters/ts_P2E_cov.jpg',
        type: 'video',
      },
      {
        thumbnail:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/thumbnails/TS_Farm.jpg',
        medium:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/medium/TS_Farm.jpg',
        large:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/large/TS_Farm.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/thumbnails/TS_Industrial.jpg',
        medium:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/medium/TS_Industrial.jpg',
        large:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/large/TS_Industrial.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/thumbnails/TS_NFTS.jpg',
        medium:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/medium/TS_NFTS.jpg',
        large:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/large/TS_NFTS.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/thumbnails/TS_World.jpg',
        medium:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/medium/TS_World.jpg',
        large:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/large/TS_World.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/thumbnails/TS_World2.jpg',
        medium:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/medium/TS_World2.jpg',
        large:
          'https://tokens.gala.games/images/sandbox-games/town-star/screenshots/large/TS_World2.jpg',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Town_Star.png',
    aboutUsImage: `https://static.gala.games/images/about-page/portfolio_item_01.jpg`,
    linkImage: 'https://static.gala.games/images/banners/tsp2e_game.jpg',
    longDescription:
      "600 million people have played town and farming games, but who's the best? Town Star will find out! Create a productive town and see how you fare against our emerging global champions!",
    descriptionText:
      'In honor of the launch of Gala Music, get yourself some new Town Star NFTs and roll in style.',
    taglineHome: 'Beyond Music',
    bannerCtaText: 'Shop',
    currency: 'BoxCoin',
    screenshots: [],
    emphasizedStoreCategory: process.env.townStarEmphasizedStoreCategory,
    requireAuthToPlay: true,
    showCarouselBanner: false,
  },
  {
    name: 'Mirandus',
    collection: 'Mirandus',
    displayName: 'Mirandus',
    codeName: 'mirandus',
    showGamePage: true,
    developer: 'Gala Games',
    genre: 'Fantasy RPG',
    developmentStatus: 'In Development',
    platform: [GamePlatform.Mac, GamePlatform.Win],
    isReleased: Boolean(process.env.mirandusIsReleased),
    isMacReleased: Boolean(process.env.mirandusIsReleasedMac),
    isWinReleased: Boolean(process.env.mirandusIsReleasedWin),
    latestVersion: process.env.mirandusLatestVersion as string,
    installerUrlMac: 'api/v1/launcher/installer/mirandus/mac',
    installerUrlWin: 'api/v1/launcher/installer/mirandus/win',
    route: 'mirandus',
    websiteLink: 'https://www.mirandus.game/',
    discordLink: 'http://galagames.chat',
    playLink: '',
    coin: 'GALA',
    comingSoon:
      'Coming soon...play to win BIG rewards that you can own and trade.',
    logo: 'https://tokens.gala.games/images/sandbox-games/mirandus/logo_mirandus.png',
    croppedLogo:
      'https://static.gala.games/images/mirandus/logos/logotype_mirandus_white.png',
    bannerImageCarousel:
      'https://static.gala.games/images/interactiveHomeBanner/mirandus/d_1800x80.jpg',
    bannerImageCarouselMin:
      'https://static.gala.games/images/interactiveHomeBanner/mirandus/m_700x940.jpg',
    bannerImageHome:
      'https://static.gala.games/images/banners/mirandus_exemplar_hp.png',
    bannerImage:
      'https://tokens.gala.games/compressed-images/banners/Mirandus_Mobile_tall.jpg',
    bannerImageDesktop:
      'https://tokens.gala.games/compressed-images/banners/desktop_MirandusBanner_wide_tall.jpg',
    gamePageBackground:
      'https://static.gala.games/images/games-page/bg_mirandus.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games-page/logotype_mirandus_white.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/mirandus/gallery/thumbnails/mirandus_trailer_tn.jpg',
        medium:
          'https://static.gala.games/images/mirandus/gallery/videos/MIRANDUS EXEMPLAR_COMPRESSED.mp4',
        poster:
          'https://static.gala.games/images/mirandus/gallery/videos/poster-images/mirandus_trailer.jpg',
        type: 'video',
      },
      {
        thumbnail:
          'https://tokens.gala.games/images/sandbox-games/mirandus/screenshots/thumbnails/Mirandus_Teaser.jpg',
        medium:
          'https://tokens.gala.games/images/sandbox-games/mirandus/screenshots/medium/Mirandus_Teaser.jpg',
        large:
          'https://tokens.gala.games/images/sandbox-games/mirandus/screenshots/large/Mirandus_Teaser.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://tokens.gala.games/images/sandbox-games/mirandus/screenshots/thumbnails/Mirandus_BirdsEye.jpg',
        medium:
          'https://tokens.gala.games/images/sandbox-games/mirandus/screenshots/medium/Mirandus_BirdsEye.jpg',
        large:
          'https://tokens.gala.games/images/sandbox-games/mirandus/screenshots/large/Mirandus_BirdsEye.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://tokens.gala.games/images/sandbox-games/mirandus/screenshots/thumbnails/Mirandus_FirstPerson.jpg',
        medium:
          'https://tokens.gala.games/images/sandbox-games/mirandus/screenshots/medium/Mirandus_FirstPerson.jpg',
        large:
          'https://tokens.gala.games/images/sandbox-games/mirandus/screenshots/large/Mirandus_FirstPerson.jpg',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Mirandus.png',
    aboutUsImage: `https://static.gala.games/images/about-page/portfolio_item_02.jpg`,
    linkImage:
      'https://static.gala.games/images/mirandus/mirandus_game_image_boats_docks.png',
    longDescription:
      'Mirandus is an epic fantasy RPG set in a massive world ruled by five player-monarchs. In game, players have absolute freedom of choice: they can set out into the wilderness alone to try their fortunes against the monsters of the deep woods and dungeons, join with one of the monarchs to serve as a knight in their court, or set up shop in one of the five great cities of the realm. Player ownership of in-game assets is a central mechanic, with players being able to hold land deeds which allow them to claim parts of the wilderness and set up holdfasts ranging from small farms to massive cities. In Mirandus, you decide who you will be and choose your own quest in the epic fight against evil.',
    descriptionText: `Explore the blue yonder with a Boat, or create a safe haven for seafaring vessels with a Dock. Be ready for your great voyage! `,
    taglineHome: `Now Available`,
    bannerCtaLink: '/store/?games=Mirandus',
    bannerCtaText: 'SET SAIL',
    currency: 'GALA',
    screenshots: [],
    requireAuthToPlay: true,
    showCarouselBanner: false,
  },
  {
    name: 'Legacy',
    collection: 'Legacy',
    displayName: 'Legacy',
    codeName: 'legacy',
    showGamePage: true,
    developer: '22cans',
    genre: 'Business Sim',
    developmentStatus: 'In Development',
    platform: [GamePlatform.Mac, GamePlatform.Win],
    isReleased: Boolean(process.env.legacyIsReleased),
    isMacReleased: Boolean(process.env.legacyIsReleasedMac),
    isWinReleased: Boolean(process.env.legacyIsReleasedWin),
    latestVersion: process.env.legacyLatestVersion as string,
    installerUrlMac: 'api/v1/launcher/installer/legacy/mac',
    installerUrlWin: 'api/v1/launcher/installer/legacy/win',
    route: 'legacy',
    discordLink: 'http://galagames.chat',
    playLink: '',
    coin: 'GALA',
    websiteLink: 'https://playlegacy.game',
    comingSoon:
      'Coming soon...play to win BIG rewards that you can own and trade.',
    logo: 'https://static.gala.games/images/games/legacy/Logo_Legacy_Final.png',
    croppedLogo:
      'https://static.gala.games/images/games/legacy/Logo_Legacy_Final.png',
    bannerImageCarouselMin:
      'https://static.gala.games/images/interactiveHomeBanner/legacy/legacy-carousel-mobile.jpg',
    bannerImageCarousel:
      'https://static.gala.games/images/interactiveHomeBanner/legacy/legacy-carousel-desktop.png',
    gamePageBackground:
      'https://static.gala.games/images/games/legacy/bg_Legacy-01.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games/legacy/Logo_Legacy_Final.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_1.jpg',
        medium:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_1.jpg',
        large:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_1.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_2.jpg',
        medium:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_2.jpg',
        large:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_2.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_3.jpg',
        medium:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_3.jpg',
        large:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_3.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_4.jpg',
        medium:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_4.jpg',
        large:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_4.jpg',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_5.jpg',
        medium:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_5.jpg',
        large:
          'https://static.gala.games/images/games/legacy/game-page-carousel/Legacy_Hero_Carousel_5.jpg',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Legacy.png',
    aboutUsImage: `https://static.gala.games/images/games/legacy/game_images_Legacy-01.png`,
    linkImage:
      'https://static.gala.games/images/games/legacy/Title_Banner-01.png',
    longDescription:
      "Legacy is a game about growing and developing your own business through the power of creativity. Design and manufacture your own products, sell and trade on the market and maximise your profits! Manage every aspect of your town. Customise to your hearts content, from your products, to your buildings — if it's in the game, you can have it your way. Make it unique, make it rare, make it profitable!",
    descriptionText: `Legacy's Land sale is live! Grab a slice of London, one of the world's top economic hubs. Design, make, trade and compete to earn!`,
    taglineHome: `Live Land Drop!`,
    bannerCtaLink: '/games/legacy',
    bannerCtaText: 'BUY NOW!',
    currency: 'GALA',
    screenshots: [],
    requireAuthToPlay: true,
  },
  {
    name: 'Last Expedition',
    collection: 'Last Expedition',
    displayName: 'Last Expedition',
    codeName: 'lastexpedition',
    showGamePage: true,
    developer: 'Certain Affinity',
    genre: 'Survival FPS',
    developmentStatus: 'Pending Node Vote',
    platform: [GamePlatform.Win],
    isReleased: Boolean(process.env.lastExpeditionIsReleased),
    route: 'last-expedition',
    discordLink: 'http://galagames.chat',
    playLink: '',
    coin: 'GALA',
    websiteLink: 'https://lastexpedition.game',
    comingSoon:
      'Coming soon...play to win BIG rewards that you can own and trade.',
    logo: 'https://static.gala.games/images/games/last-expedition/last-expedition-logo-updated.png',
    croppedLogo:
      'https://static.gala.games/images/games/last-expedition/last-expedition-logo-updated.png',
    bannerImageCarouselMin: '',
    bannerImageCarousel: '',
    gamePageBackground:
      'https://static.gala.games/images/games/last-expedition/last-expedition-game-background.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games/last-expedition/last-expedition-logo-updated.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/games/last-expedition/game-title-banner.jpg',
        medium:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/last-expedition-trailer.mp4',
        poster:
          'https://static.gala.games/images/games/last-expedition/game-title-banner.jpg',
        type: 'video',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/Last_Expedition_04-nologo.png',
        medium:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/Last_Expedition_04-nologo.png',
        large:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/Last_Expedition_04-nologo.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/Last_Expedition_03-nologo.png',
        medium:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/Last_Expedition_03-nologo.png',
        large:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/Last_Expedition_03-nologo.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/Last_Expedition_02-nologo.png',
        medium:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/Last_Expedition_02-nologo.png',
        large:
          'https://static.gala.games/images/games/last-expedition/game-carousel-images/Last_Expedition_02-nologo.png',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Last_Expedition.png',
    aboutUsImage: `https://static.gala.games/images/games/last-expedition/about-last-expedition.jpg`,
    linkImage:
      'https://static.gala.games/images/games/last-expedition/game-title-banner.jpg',
    longDescription:
      'Last Expedition is the world’s first AAA first-person shooter powered by blockchain and player ownership. Created by the renowned development studio Certain Affinity led by Halo 2 veteran Max Hoberman, Last Expedition will bring world class AAA gaming and play-to-earn empowerment together in a historic and explosive combination. In Last Expedition, players will face off against a hostile alien world in which everything and everyone wants to annihilate them. In addition to the intense focus and skill required for successful FPS gaming, blockchain ownership will allow for cutting edge layers of resourcefulness and strategy, requiring players to build, grow and survive in new ways.',
    descriptionText: ``,
    taglineHome: ``,
    currency: 'GALA',
    screenshots: [],
    requireAuthToPlay: true,
  },
  {
    name: 'Echoes of Empire',
    collection: 'Echoes of Empire',
    displayName: 'Echoes of Empire',
    codeName: 'echoesofempire',
    showGamePage: true,
    developer: 'Ion Games',
    genre: 'Strategy, 4X',
    developmentStatus: 'In Development',
    platform: [GamePlatform.Mac, GamePlatform.Win],
    isReleased: Boolean(process.env.echosOfEmpireIsReleased),
    isMacReleased: Boolean(process.env.echosOfEmpireIsReleasedMac),
    isWinReleased: Boolean(process.env.echosOfEmpireIsReleasedWin),
    latestVersion: process.env.echosOfEmpireLatestVersion as string,
    installerUrlMac: 'api/v1/launcher/installer/echoes-of-empire/mac',
    installerUrlWin: 'api/v1/launcher/installer/echoes-of-empire/win',
    route: 'echoes-of-empire',
    playLink: '',
    coin: 'GALA',
    comingSoon:
      'Coming soon...play to win BIG rewards that you can own and trade.',
    logo: 'https://tokens.gala.games/images/echoes-of-empire/eoe_logo.png',
    croppedLogo:
      'https://static.gala.games/images/echoes-of-empire/logos/logo_eoe.png',
    bannerImageCarouselMin:
      'https://static.gala.games/images/interactiveHomeBanner/echoes-of-empire/eoe_d_700x940.jpg',
    bannerImageCarousel:
      'https://static.gala.games/images/interactiveHomeBanner/echoes-of-empire/eoe-auroras-edge-desktop.jpg',
    bannerImageHome:
      'https://tokens.gala.games/images/echoes-of-empire/banners/eoe_transparent_banner_2.png',
    bannerImage:
      'https://tokens.gala.games/images/echoes-of-empire/banners/eoe_transparent_banner_2.png',
    bannerImageDesktop:
      'https://tokens.gala.games/images/echoes-of-empire/banners/eoe_transparent_banner_2.png',
    gamePageBackground:
      'https://static.gala.games/images/games-page/bg_EoE_dark.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games-page/logo_eoe.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_0.png',
        medium:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_0.png',
        large:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_0.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_1.png',
        medium:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_1.png',
        large:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_1.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_1a.png',
        medium:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_1a.png',
        large:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_1a.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_2.png',
        medium:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_2.png',
        large:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_2.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_3.png',
        medium:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_3.png',
        large:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_3.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_4.png',
        medium:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_4.png',
        large:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_4.png',
        type: 'img',
      },
      {
        thumbnail:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_5.png',
        medium:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_5.png',
        large:
          'https://static.gala.games/images/echoes-of-empire/game-page-carousel/eoe_screenshot_5.png',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Echoes_of_Empire.png',
    aboutUsImage: `https://static.gala.games/images/about-page/portfolio_item_04.jpg`,
    linkImage:
      'https://static.gala.games/images/echoes-of-empire/eoe_game_page_title.png',
    longDescription:
      'Echoes of Empire is an epic, 4X sci-fi strategy game steeped in mystery, intrigue and a thousand years of Royal rule. Factions warring against one another on a galactic scale, setting the stage for an upstart hero (you the player) and your meteoric ascent to power! You will recruit Heroes and Knights to your banner, while building, upgrading, and owning a fleet of starships all in an effort to bring glory to your house and control to your sector of the galaxy. Go solo or join up in massive guilds to mine or fight over resource laden star systems. Once your coffers are flush with riches and resources, muster and master all aspects of your Command Station where you can upgrade warships, craft new weapons, and repair and refit your fleets. Player ownership of in-game assets is a core mechanic, and you will have the ability to own major sectors of the galactic system and the resources that go along with it. What will you leave behind as your legacy? Find out in Echoes of Empire!',
    descriptionText:
      "Claim your own piece of galactic property in round 3 of the presale, Aurora's Edge... But hurry, supplies and time are limited!",
    taglineHome: `On Sale Now`,
    currency: 'GALA',
    bannerCtaLink: '/games/echoes-of-empire',
    bannerCtaText: 'View Claims',
    screenshots: [],
    showNodePromotion: true,
    requireAuthToPlay: true,
  },
  {
    updatedAt: new Date('2022-06-14').getTime(),
    name: 'Fortitude',
    collection: 'Fortitude',
    displayName: 'Fortitude',
    codeName: 'fortitude',
    showGamePage: true,
    developer: 'ROUND2',
    genre: 'Tower Defense',
    developmentStatus: 'In Development',
    platform: [GamePlatform.Win],
    isReleased: Boolean(process.env.fortitudeIsReleased),
    isMacReleased: Boolean(process.env.fortitudeIsReleasedMac),
    isWinReleased: Boolean(process.env.fortitudeIsReleasedWin),
    latestVersion: process.env.fortitudeLatestVersion as string,
    installerUrlMac: 'api/v1/launcher/installer/fortitude/mac',
    installerUrlWin: 'api/v1/launcher/installer/fortitude/win',
    route: 'fortitude',
    discordLink: 'http://galagames.chat',
    playLink: '',
    coin: 'GALA',
    comingSoon:
      'Coming soon...play to win BIG rewards that you can own and trade.',
    logo: 'https://static.gala.games/images/games/fortitude/fortitude-logo-cropped.png',
    croppedLogo:
      'https://static.gala.games/images/games/fortitude/fortitude-logo-cropped.png',
    taglineHome: 'COMING SOON',
    bannerImageCarousel: '',
    bannerImageCarouselMin: '',
    bannerImageHome: '',
    bannerImage: '',
    bannerImageDesktop: '',
    gamePageBackground:
      'https://static.gala.games/images/games/fortified/game-page-background.jpg',
    gamePageHeaderLogo:
      'https://static.gala.games/images/games/fortitude/fortitude-logo-cropped.png',
    descriptionAssets: [
      {
        thumbnail:
          'https://static.gala.games/fortitude/images/fortitude_trailer_thumb.png',
        medium: 'https://static.gala.games/fortitude/images/carousel-1.mp4',
        poster:
          'https://static.gala.games/fortitude/images/fortitude_trailer_thumb.png',
        type: 'video',
      },
      {
        thumbnail: 'https://static.gala.games/fortitude/images/carousel-1.jpg',
        medium: 'https://static.gala.games/fortitude/images/carousel-1.jpg',
        large: 'https://static.gala.games/fortitude/images/carousel-1.jpg',
        type: 'img',
      },
      {
        thumbnail: 'https://static.gala.games/fortitude/images/carousel-2.jpg',
        medium: 'https://static.gala.games/fortitude/images/carousel-2.jpg',
        large: 'https://static.gala.games/fortitude/images/carousel-2.jpg',
        type: 'img',
      },
      {
        thumbnail: 'https://static.gala.games/fortitude/images/carousel-3.jpg',
        medium: 'https://static.gala.games/fortitude/images/carousel-3.jpg',
        large: 'https://static.gala.games/fortitude/images/carousel-3.jpg',
        type: 'img',
      },
      {
        thumbnail: 'https://static.gala.games/fortitude/images/carousel-4.jpg',
        medium: 'https://static.gala.games/fortitude/images/carousel-4.jpg',
        large: 'https://static.gala.games/fortitude/images/carousel-4.jpg',
        type: 'img',
      },
    ],
    inventoryImage:
      'https://tokens.gala.games/images/inventory-images/Fortitude.png',
    aboutUsImage: `https://static.gala.games/images/games/fortified/fortified-about-page.png`,
    linkImage:
      'https://static.gala.games/images/games/fortitude/game-page-tile.png',
    longDescription:
      'Fortitude is a PvP tower defense game set in a dark fantasy world where players compete against one another to both invade and defend villages. As you build and grow your village, you must craft and strategically place a variety of defensive structures like cannon towers, arrow towers and powerful magic buildings, all designed to protect you from invasion. While defending your village, you may also train soldiers and heroes to form an army and invade other villages to plunder precious resources. The loot obtained in these invasions will create the foundation for your own village’s expansion and development. Make sure you’re well fortified for this intense and action-packed strategy adventure! Coming in Q4 2022!',
    descriptionText: `An epic game set in a fantastical medieval world, build out your tower network, and train your NFT-based troops to attack!`,
    currency: 'GALA',
    screenshots: [],
    showNodePromotion: true,
    requireAuthToPlay: true,
  },
];

export const combineGameArrays = (flaggedGameNamesToInclude: string = '') => {
  const flaggedGameNamesArray = flaggedGameNamesToInclude.split(',');
  const flaggedGamesToInclude = featureFlaggedGames.filter((game) =>
    flaggedGameNamesArray.some(
      (gameToInclude) =>
        gameToInclude.toLowerCase() === game.name.toLowerCase(),
    ),
  );

  return [...flaggedGamesToInclude, ...games, ...nonGameGames].sort(
    (a, b) => (b.updatedAt ?? 0) - (a.updatedAt ?? 0),
  );
};
