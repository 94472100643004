class DiscordService {
  private storageKey = 'discord_state';

  createStateParam(): string {
    const existingState = localStorage.getItem(this.storageKey);
    if (existingState) {
      return btoa(existingState);
    } else {
      const state = Date.now().valueOf().toString();
      localStorage.setItem(this.storageKey, state);
      return btoa(state);
    }
  }

  checkStateParam(encodedState: string): boolean {
    const state = localStorage.getItem(this.storageKey);
    localStorage.removeItem(this.storageKey);
    return state === atob(encodedState);
  }
}

export default new DiscordService();
