
import LegalDocument from '~/components/Settings/LegalDocument.vue';
import { Component, Vue } from 'vue-property-decorator';
import termsAndConditionsQuery from '~/queries/termsAndConditions.gql';

@Component({
  components: { LegalDocument },
})
export default class TermsAndConditions extends Vue {
  private readonly query = termsAndConditionsQuery;

  contentSelector(queryResponse: any) {
    return queryResponse?.filmTermsAndConditions?.content;
  }
}
