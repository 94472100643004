import { GetterTree } from 'vuex';
import { IRootState } from '~/store/types';
import {
  ISanityDocumentState,
  IGetterParams,
  TAsyncStateWithDoc,
} from './types';

export const getStoreGetters = (): GetterTree<
  ISanityDocumentState,
  IRootState
> => {
  return {
    getDocument:
      (state) =>
      (opts: IGetterParams): TAsyncStateWithDoc | undefined => {
        if (!opts._id && !opts.slug) {
          return;
        }

        let id;
        if (opts.slug && !opts._id) {
          const slugState = state.slugs?.[opts.slug];
          if (slugState?.hasError || slugState?.isLoading) {
            return {
              hasError: slugState.hasError,
              isLoading: slugState.isLoading,
              error: slugState.error,
            };
          }
          if (slugState?.key) {
            id = slugState.key;
          }
        } else {
          id = opts._id;
        }

        return id ? state.documents?.[id] : undefined;
      },

    getSlug:
      (state) =>
      (opts: Pick<IGetterParams, 'slug'>): TAsyncStateWithDoc | undefined => {
        return opts.slug ? state.slugs?.[opts.slug] : undefined;
      },

    getDocumentsBySlugs:
      (state) =>
      (slugs: string[]): TAsyncStateWithDoc[] | undefined => {
        const documents = state.documents;
        const docs = slugs
          .filter((slug) => state.slugs[slug])
          .map((slug) => state.slugs[slug].key)
          .map((key) => documents[key as string])
          .filter((doc) => doc?.data);
        return docs;
      },
  };
};
