
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component
export default class FullScreenError extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(String) readonly message!: string;
  @Prop(String) readonly mainButtonText!: string;
  @Prop({ type: Array, default: [] })
  readonly extraButtons!: Array<{ key: string; text: string; link: string }>;
  @Prop({ type: String, default: 'accentPurple' })
  readonly buttonColor!: string;
  @Prop({ type: String, default: 'white' }) readonly textColor!: string;
}
