import { ActionTree } from 'vuex';
import {
  IGetProjectPayload,
  IProjectState,
  RZR_EPISODE_1_SLUG,
  RZR_PROJECT_SLUG,
} from './types';
import { IRootState } from '../types';
import {
  IProject,
  IProjectTimeline,
  IProjectsStatsRequest,
  EProjectStatTypes,
} from '~/types/project';
import {
  EpisodeAction,
  IEpisode,
  IEpisodeWatchProgress,
  ISeries,
} from '~/types/series';
import { VideoWatchEventTypes } from '~/types/event-restriction';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import { convertVideoTimeToSecs } from '~/utils/videoTime';
import isQualified from '~/queries/isQualified.gql';
import { isRestrictionSuccess } from '~/utils/parseRestrictionResults';
import { PosterOverview } from '~/types/collections';
import {
  getNextWatchableEpisodeInSeries,
  getNextWatchableEpisodeInSuggestedProjects,
} from './helpers';

export const actions: ActionTree<IProjectState, IRootState> = {
  async getProject({ commit, dispatch, state }, payload: IGetProjectPayload) {
    const apiCalls: Array<Promise<any>> = [
      this.$filmApiService.getProject(payload.projectSlug),
    ];

    apiCalls.push(
      this.$filmApiService.get<{ posters: PosterOverview[] }>(
        `projects/${payload.projectSlug}/posters/overview`,
        { requireAuth: false },
      ),
    );

    apiCalls.push(
      this.$filmApiService.get<{ series: ISeries | null }>(
        `projects/${payload.projectSlug}/series`,
        { requireAuth: false },
      ),
    );

    const results = await Promise.allSettled(apiCalls);
    const [projectResult, postersResult, seriesResult] = results;

    const project: IProject =
      projectResult.status === 'fulfilled' ? projectResult.value : null;
    if (!project) {
      return;
    }

    project.posters =
      postersResult && postersResult.status === 'fulfilled'
        ? postersResult.value.posters
        : null;
    project.series =
      seriesResult && seriesResult.status === 'fulfilled'
        ? seriesResult.value.series
        : null;

    commit('updateProject', project);

    if (project.series) {
      dispatch('updateProjectSeriesWatchProgress', project.slug);
      await dispatch('updateViewingRestrictions', project.series);
    }
  },
  async loadProjectTimeline({ commit }, projectSlug: string) {
    const timeline = (
      await this.$filmApiService.get<{ timeline: IProjectTimeline | null }>(
        `projects/${projectSlug}/timeline`,
        { requireAuth: false },
      )
    )?.timeline;
    commit('updateProjectTimeline', { projectSlug, timeline });
  },
  async updateProjectSeriesWatchProgress(
    { commit, dispatch, state, rootState },
    projectSlug: string,
  ) {
    const project: IProject | undefined = state.projects[projectSlug];
    const series = project.series;

    const episodeWatchProgress: Record<string, IEpisodeWatchProgress> = {};
    if (series && series.episodes) {
      const getEpisodeWatchTimePromises = series.episodes.map(
        async (episode) => {
          const episodeRuntimeSecs = episode.runTime
            ? convertVideoTimeToSecs(episode.runTime)
            : 0;
          const completionTimeSecs = episode.completionTime
            ? convertVideoTimeToSecs(episode.completionTime)
            : 0;

          const userWatchProgress: IEpisodeWatchProgress = {
            percentageWatched: 0,
            watched: false,
            secondsRemaining: episodeRuntimeSecs,
          };
          if (rootState.profile?.user?.id) {
            let userWatchedSeconds = 0;
            try {
              const eventId = `${VideoWatchEventTypes.VIDEO_WATCH_PROGRESS}|${SanityDocumentSchema.VIDEO}|${episode.videoSlug}|${rootState.profile.user.id}`;
              const viewRestrictionEvent =
                await this.$restrictionsService.getEvent(eventId);
              userWatchedSeconds =
                viewRestrictionEvent?.value?.videoCurrentTime ?? 0;
            } catch (err) {}

            if (episode.runTime && episode.completionTime) {
              userWatchProgress.secondsRemaining =
                episodeRuntimeSecs - userWatchedSeconds;
              userWatchProgress.percentageWatched =
                (userWatchedSeconds / episodeRuntimeSecs) * 100;
              userWatchProgress.watched =
                userWatchedSeconds >= completionTimeSecs;
            }
          }
          episodeWatchProgress[episode.slug] = userWatchProgress;
        },
      );

      await Promise.all(getEpisodeWatchTimePromises);

      commit('updateProjectEpisodeWatchProgress', {
        projectSlug,
        watchProgress: episodeWatchProgress,
      });

      dispatch('updateProjectWatchSuggestion', projectSlug);
    }
  },
  async updateProjectWatchSuggestion(
    { commit, state, rootState, getters },
    projectSlug: string,
  ) {
    const project: IProject | undefined = state.projects[projectSlug];
    const series = project.series;
    const suggestion = {
      episodeNumber: 1,
      episodeAction: EpisodeAction.START,
    };

    if (series && rootState.profile?.user?.id) {
      const projectEpisodeWatchProgress =
        getters.getProjectEpisodeWatchProgress(projectSlug);
      const firstUnfinishedEpisodeIndex = series.episodes.findIndex(
        (episode) => {
          const episodeWatchProgress =
            projectEpisodeWatchProgress?.[episode.slug];
          if (episode.canSuggestWatching && !episodeWatchProgress?.watched) {
            return true;
          }
        },
      );

      if (firstUnfinishedEpisodeIndex > -1) {
        suggestion.episodeNumber = firstUnfinishedEpisodeIndex + 1;

        const episodeSlug = series.episodes[firstUnfinishedEpisodeIndex].slug;
        const episodeStarted =
          projectEpisodeWatchProgress?.[episodeSlug] &&
          projectEpisodeWatchProgress[episodeSlug].percentageWatched > 0;
        suggestion.episodeAction = episodeStarted
          ? EpisodeAction.RESUME
          : EpisodeAction.START;
      }
    }

    commit('updateProjectWatchSuggestion', {
      projectSlug,
      episodeWatchSuggestion: suggestion,
    });
  },
  async showEpisode({ commit, dispatch }, payload: { episode: IEpisode }) {
    const { episode } = payload;

    commit('updateCurrentEpisode', { episode });
    commit('updateNextEpisode', { episode: undefined });
    commit('updateShowingVideo', true);

    dispatch('determineNextEpisode', { currentEpisode: episode });
  },
  async determineNextEpisode(
    { commit, state, dispatch },
    payload: { currentEpisode: IEpisode },
  ) {
    const { currentEpisode } = payload;

    const nextEpisodeInSeries = getNextWatchableEpisodeInSeries(
      currentEpisode,
      state,
    );
    if (nextEpisodeInSeries) {
      commit('updateNextEpisode', {
        episode: nextEpisodeInSeries,
      });
      return;
    }

    const nextWatchableEpisodeInAnotherProject =
      await getNextWatchableEpisodeInSuggestedProjects(
        currentEpisode,
        state,
        dispatch,
      );
    if (nextWatchableEpisodeInAnotherProject) {
      commit('updateNextEpisode', {
        episode: nextWatchableEpisodeInAnotherProject,
      });
    }
  },
  async closeVideo({ commit }) {
    commit('updateShowingVideo', false);
  },
  async showWatchSuggestionEpisode(
    { dispatch, getters, state },
    projectSlug: string,
  ) {
    const projectEpisodeWatchSuggestion =
      getters.getProjectEpisodeWatchSuggestion(projectSlug);

    const episodeNumber = projectEpisodeWatchSuggestion?.episodeNumber ?? 1;
    const episodes = state.projects[projectSlug]?.series?.episodes;
    if (episodes && episodes.length > 0) {
      let episodeIndex =
        episodeNumber <= episodes.length ? Math.max(episodeNumber - 1, 0) : 0;

      const restrictionID = episodes[episodeIndex].restrictionId;
      if (restrictionID && state.viewingRestrictions[restrictionID] !== true) {
        episodeIndex = episodes.findIndex(
          (e) =>
            !e.restrictionId ||
            state.viewingRestrictions[e.restrictionId] === true,
        );
      }
      if (episodeIndex > -1 && episodeIndex < episodes.length) {
        dispatch('showEpisode', {
          episode: episodes[episodeIndex],
        });
      }
    }
  },
  async updateViewingRestrictions({ commit, state }, series: ISeries) {
    const newRestrictions: string[] = [];
    series.episodes.forEach((episode) => {
      const restrictionId = episode.restrictionId;
      if (
        restrictionId &&
        !newRestrictions.includes(restrictionId) &&
        state.viewingRestrictions[restrictionId] === undefined
      ) {
        newRestrictions.push(restrictionId);
      }
    });

    if (newRestrictions.length === 0) {
      return;
    }
    if (!this.app.apolloProvider) {
      return;
    }

    const newViewingRestrictions: Record<string, boolean> = {};
    const client = this.app.apolloProvider.defaultClient;
    const restrictionPromises = newRestrictions.map(async (restrictionId) => {
      try {
        const { data } = await client.query({
          query: isQualified,
          variables: {
            id: restrictionId,
            payload: {},
          },
          fetchPolicy: 'network-only',
        });
        const isSuccess = isRestrictionSuccess(data.isQualified);
        newViewingRestrictions[restrictionId] = isSuccess ?? false;
        return;
      } catch (e) {
        console.log(e);
      }
    });

    await Promise.all(restrictionPromises);

    if (Object.keys(newViewingRestrictions).length > 0) {
      commit('updateViewingRestrictions', newViewingRestrictions);
    }
  },
  async showFirstEpisode({ dispatch, state }, projectSlug: string) {
    const firstEpisode = state.projects[projectSlug]?.series?.episodes?.[0];

    if (firstEpisode) {
      dispatch('showEpisode', {
        episode: firstEpisode,
      });
    }
  },
  async showRZREpisode1({ dispatch, state }) {
    const rzr_ep_1 = state.projects[RZR_PROJECT_SLUG]?.series?.episodes?.find(
      (ep) => ep.videoSlug === RZR_EPISODE_1_SLUG,
    );
    if (rzr_ep_1) {
      dispatch('showEpisode', {
        episode: rzr_ep_1,
      });
    }
  },
  async setContinueRzrEp1AfterUA({ commit, dispatch, state }) {
    commit('updateContinueRzrEp1AfterUA', true);
    dispatch('showRZREpisode1');
  },
  async clearUAFlags({ commit }) {
    commit('updateContinueRzrEp1AfterUA', false);
  },
  async showProjectNftPromotion(
    { commit, dispatch, state },
    projectSlug: string,
  ) {
    const project = state.projects[projectSlug];
    if (!project) {
      await dispatch('getProject', { projectSlug, getSeries: true });
    }
    const currentNftPromotion =
      state.projects[projectSlug]?.currentNftPromotion;
    const shownPromotionIds = state.nftPromotionsState.shownPromotionIds;
    if (
      currentNftPromotion &&
      !shownPromotionIds.includes(currentNftPromotion.id)
    ) {
      commit('updateCurrentNftPromotion', currentNftPromotion);
      commit('updateShowingNftPromotion', true);
    }
  },
  async hideProjectNftPromotion({ commit }) {
    commit('updateShowingNftPromotion', false);
  },
  async updateProjectsAutoPlayRedirect(
    { commit, state },
    payload: { fromProjectSlug: string; toProjectSlug: string },
  ) {
    const { fromProjectSlug, toProjectSlug } = payload;
    const projectsAutoPlayed = state.projectsAutoPlayed;
    const projectsToAdd: string[] = [];
    if (!projectsAutoPlayed.includes(fromProjectSlug)) {
      projectsToAdd.push(fromProjectSlug);
    }
    if (!projectsAutoPlayed.includes(toProjectSlug)) {
      projectsToAdd.push(toProjectSlug);
    }
    commit('updateProjectsAutoPlayed', projectsToAdd);
  },

  async getProjectsViews({ commit, state }, payload: { projectSlug: string }) {
    try {
      const body: IProjectsStatsRequest = {
        slugs: [payload.projectSlug],
        types: [
          EProjectStatTypes.TOTAL_VIEWS,
          EProjectStatTypes.LAST_DAY_VIEWS,
          EProjectStatTypes.TOTAL_REWARDS,
          EProjectStatTypes.LAST_DAY_REWARDS,
        ],
      };
      const ret = await this.$filmApiService.getProjectsStatsOverview(body);
      const { data } = ret || {};
      for (const [slug, views] of Object.entries(data)) {
        commit('updateProjectStatsOverview', { projectSlug: slug, views });
      }
    } catch (err) {
      console.error(err);
    }
  },
};
