import { SanityDocument } from '@sanity/client';
import {
  Reference as SanityReference,
  Image as SanityImageObject,
} from '@sanity/types';
import {
  SanityObjectSchema,
  ISanityObjects,
  TSanityBlockContent,
  IPageAvailability,
  ISanityImageWithObjectPosition,
} from './sanity-objects';
import { IMuxAssetData } from './mux-asset';
import { IFeatureFlags } from '~/utils/featureFlags';
import { ThemeName } from '~/store/types';
import { IPoster, IScene } from '~/types/collections';

export enum SanityDocumentSchema {
  AUTH_CONFIG = 'authConfig',
  ARTICLE = 'article',
  ASSET_SET = 'assetSet',
  COLLECTION = 'collection',
  CUSTOM_PAGE = 'customPage',
  FAQ_LIST = 'faqList',
  FAQ_LIST_ITEM = 'faqListItem',
  FIGURE = 'figure',
  FILM = 'film',
  GENRE = 'genre',
  LANGUAGE = 'language',
  LIVE_STREAM = 'liveStream',
  MARKETING_BANNER = 'marketingBanner',
  MEDIA_TYPE = 'mediaType',
  MUX_ASSET = 'mux.videoAsset',
  NFT_CARD = 'nftCard',
  PERIOD = 'period',
  PERSON = 'person',
  PROJECT = 'project',
  PROJECT_PRIZE = 'projectPrize',
  PROMO_BOX = 'promoBox',
  PROMO_CAROUSEL = 'promoCarousel',
  RARITY = 'rarity',
  RATING = 'rating',
  REGION = 'region',
  RESOLUTION = 'resolution',
  ROLE = 'role',
  SERIES = 'series',
  SITE_CONFIG = 'siteConfig',
  TAG = 'tag',
  TIMELINE = 'timeline',
  TRANSCRIPT_TYPE = 'transcriptType',
  TYPE_FORM = 'typeForm',
  UA_PAGE = 'uaPage',
  VIDEO = 'video',
  VIDEO_LIVE_STREAM = 'videoLiveStream',
  POSTER = 'poster',
  SCENE = 'scene',
}

export type TSanityDocumentPageSchemas =
  | SanityDocumentSchema.CUSTOM_PAGE
  | SanityDocumentSchema.FILM
  | SanityDocumentSchema.LIVE_STREAM
  | SanityDocumentSchema.PROJECT
  | SanityDocumentSchema.PROMO_BOX
  | SanityDocumentSchema.SERIES
  | SanityDocumentSchema.TYPE_FORM
  | SanityDocumentSchema.VIDEO;

export type TSanityDocumentSellableSchemas = SanityDocumentSchema.PROJECT;

interface ISanityDocumentSchemaInterfaceMap {
  [SanityDocumentSchema.ARTICLE]: IArticle;
  [SanityDocumentSchema.AUTH_CONFIG]: IAuthConfig;
  [SanityDocumentSchema.ASSET_SET]: IAssetSet;
  [SanityDocumentSchema.COLLECTION]: TCollection;
  [SanityDocumentSchema.CUSTOM_PAGE]: ICustomPage;
  [SanityDocumentSchema.FAQ_LIST]: IFaqList;
  [SanityDocumentSchema.FAQ_LIST_ITEM]: IFaqListItem;
  [SanityDocumentSchema.FIGURE]: IFigure;
  [SanityDocumentSchema.FILM]: IFilm;
  [SanityDocumentSchema.GENRE]: TGenre;
  [SanityDocumentSchema.LANGUAGE]: TLanguage;
  [SanityDocumentSchema.LIVE_STREAM]: ILiveStream;
  [SanityDocumentSchema.MARKETING_BANNER]: IMarketingBanner;
  [SanityDocumentSchema.MEDIA_TYPE]: TMediaType;
  [SanityDocumentSchema.MUX_ASSET]: IVideoAsset;
  [SanityDocumentSchema.NFT_CARD]: INftCard;
  [SanityDocumentSchema.PERIOD]: TPeriod;
  [SanityDocumentSchema.PERSON]: IPerson;
  [SanityDocumentSchema.PROJECT]: IProject;
  [SanityDocumentSchema.PROJECT_PRIZE]: IProjectPrize;
  [SanityDocumentSchema.PROMO_BOX]: IPromoBox;
  [SanityDocumentSchema.PROMO_CAROUSEL]: IPromoCarousel;
  [SanityDocumentSchema.RARITY]: TRarity;
  [SanityDocumentSchema.RATING]: TRating;
  [SanityDocumentSchema.REGION]: TRegion;
  [SanityDocumentSchema.RESOLUTION]: TResolution;
  [SanityDocumentSchema.ROLE]: TRole;
  [SanityDocumentSchema.SERIES]: ISeries;
  [SanityDocumentSchema.SITE_CONFIG]: ISiteConfig;
  [SanityDocumentSchema.TAG]: TTag;
  [SanityDocumentSchema.TIMELINE]: ITimeline;
  [SanityDocumentSchema.TRANSCRIPT_TYPE]: TTranscriptType;
  [SanityDocumentSchema.TYPE_FORM]: ITypeForm;
  [SanityDocumentSchema.UA_PAGE]: IUaPage;
  [SanityDocumentSchema.VIDEO]: IVideo;
  [SanityDocumentSchema.VIDEO_LIVE_STREAM]: IVideoLiveStream;
  [SanityDocumentSchema.POSTER]: IPoster;
  [SanityDocumentSchema.SCENE]: IScene;
}

export type ISanityDocuments = {
  [K in SanityDocumentSchema]: SanityDocument<
    ISanityDocumentSchemaInterfaceMap[K] & { _type: K }
  >;
};

export interface IAuthConfig {
  redirect?: ISanityObjects[SanityObjectSchema.RELATIVE_URL_OR_REFLINK];
  slug?: string;
  theme?: ISanityObjects[SanityObjectSchema.AUTH_THEME];
}

export interface IAssetSet {
  title: string;
  ctaCards: ICtaCard[];
  heroImage: SanityImageObject;
}

export interface ICtaCard {
  type: string;
  title: string;
  text: string;
  logo: SanityImageObject;
  backgroundImage: SanityImageObject;
}

export interface IArticle {
  body?: TSanityBlockContent;
  documentReference?: SanityReference;
  publishedAt?: string;
  title?: string;
}

export interface ICustomPage {
  pageAvailability?: ISanityObjects[SanityObjectSchema.PAGE_AVAILABILITY];
  redirectIfNotQualifiedUrl?: string;
  restrictionId?: string;
  requiresLogin?: boolean;
  rows?: Array<Record<string, any>>;
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  slug?: string;
  theme?: ThemeName;
  title?: string;
}

export interface IFaqList {
  slug?: string;
  limit?: number;
  title?: string;
  showMore?: string;
  showLess?: string;
  faqs: SanityReference[];
}

export interface IFaqListItem {
  question: TSanityBlockContent;
  answer: TSanityBlockContent;
}

export interface IFigure {
  description?: TSanityBlockContent;
  image?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  mediaType?: SanityReference;
  slug?: string;
  title?: string;
}

export interface IFilm {
  activeCampaignId?: string;
  castMembers?: Array<ISanityObjects[SanityObjectSchema.CAST_MEMBER]>;
  content?: SanityReference;
  contentAvailability?: ISanityObjects[SanityObjectSchema.CONTENT_AVAILABILITY];
  crewMembers?: Array<ISanityObjects[SanityObjectSchema.CREW_MEMBER]>;
  extraMedia?: SanityReference[];
  genres?: SanityReference[];
  overline?: string;
  overview?: TSanityBlockContent;
  pageHeroImage?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  pageAvailability?: ISanityObjects[SanityObjectSchema.PAGE_AVAILABILITY];
  promoCard?: ISanityObjects[SanityObjectSchema.PROMO_CARD];
  releaseDate?: string;
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  slug?: string;
  title?: string;
  titleBadges?: SanityReference[];
  trailer?: SanityReference;
  uaPage?: SanityReference;
}

interface IItemWithTitle {
  title?: string;
  titleAbbrev?: string;
  titleShort?: string;
  titlePlural?: string;
}

interface IItemWithSlug {
  slug: string;
}

export interface ILiveStream {
  activeCampaignId?: string;
  castMembers?: Array<ISanityObjects[SanityObjectSchema.CAST_MEMBER]>;
  content?: SanityReference;
  genres?: SanityReference[];
  overview?: TSanityBlockContent;
  pageHeroImage?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  pageAvailability?: ISanityObjects[SanityObjectSchema.PAGE_AVAILABILITY];
  releaseDate?: string;
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  slug?: string;
  title?: string;
}

export interface IMarketingBanner {
  ctaText?: string;
  ctaUrl?: ISanityObjects[SanityObjectSchema.URL_OR_REFLINK];
  landscapeBannerImage?: SanityImageObject;
  logo?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  portraitBannerImage?: SanityImageObject;
  slug?: string;
  title?: string;
}

export interface INftCard {
  animationUrl?: string;
  badge?: SanityReference;
  description?: TSanityBlockContent;
  discountName?: string;
  documentReference?: SanityReference;
  image?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  rarity?: SanityReference;
  restrictionId?: string;
  rewards?: Array<ISanityObjects[SanityObjectSchema.REWARD_ITEM]>;
  shopifyProductId?: string;
  shouldShowRewards?: boolean;
  shouldShowSupplyLimit?: boolean;
  slug?: string;
  title?: string;
  unlockableContent?: SanityReference;
  isOpenable?: boolean;
  openableType?: string;
}

export interface IPerson {
  name?: string;
  slug?: string;
  image?: SanityImageObject;
}

export interface IProject {
  activeCampaignId?: string;
  auth?: SanityReference;
  avatar?: SanityImageObject;
  castMembers?: Array<ISanityObjects[SanityObjectSchema.CAST_MEMBER]>;
  collectionPage?: SanityReference;
  contents?: SanityReference[];
  contentAvailability?: ISanityObjects[SanityObjectSchema.CONTENT_AVAILABILITY];
  crewMembers?: Array<ISanityObjects[SanityObjectSchema.CREW_MEMBER]>;
  details?: TSanityBlockContent;
  enableFilmBoost?: boolean;
  faqs?: SanityReference;
  figures?: SanityReference[];
  genres?: SanityReference[];
  heroVideo?: ISanityObjects[SanityObjectSchema.ROW_HERO_VIDEO];
  logo?: SanityImageObject;
  overview?: TSanityBlockContent;
  overviewTags?: SanityReference[];
  pageHeroImage?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  pageAvailability?: ISanityObjects[SanityObjectSchema.PAGE_AVAILABILITY];
  prizesList?: SanityReference[];
  projectTimeline?: ISanityObjects[SanityObjectSchema.PROJECT_TIMELINE];
  promoCard?: ISanityObjects[SanityObjectSchema.PROMO_CARD];
  releaseDate?: string;
  releaseDateDisplayText?: string;
  restrictionId?: string;
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  slug?: string;
  saleName?: string;
  saleEndsAt?: string;
  saleProgressBarDisplayThreshhold?: number;
  saleStartsAt?: string;
  saleText?: TSanityBlockContent;
  title?: string;
  titleBadges?: SanityReference[];
  trailer?: SanityReference;
  uaPage?: SanityReference;
  updates?: SanityReference[];
  videos?: SanityReference[];
  showUnopenedBoxesPulsingDot?: boolean;
  showRewardsOnFilmPage?: boolean;
}

export interface IProjectPrize {
  title?: string;
  image?: SanityImageObject;
}

export interface IPromoCarousel {
  slides?: Array<ISanityObjects[SanityObjectSchema.PROMO_CAROUSEL_ITEM]>;
  slug?: string;
  title?: string;
}

export interface IPromoBox {
  auth?: SanityReference;
  activeCampaignId?: string;
  endsAt?: string;
  exchangeRevealVideoUrl?: string;
  explainerContent?: ISanityObjects[SanityObjectSchema.PROMO_BOX_CONTENT];
  explainerCtaText?: string;
  explainerImage?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  explainerListItems?: string[];
  footerLogo?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  hasTopMargin?: boolean;
  heroImageLandscape?: ISanityImageWithObjectPosition;
  heroImagePortrait?: ISanityImageWithObjectPosition;
  isEnabled?: boolean;
  isRedeemable?: boolean;
  landingPageRedirectOnClose?: ISanityObjects[SanityObjectSchema.URL_OR_REFLINK];
  logo?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  pageAvailability?: ISanityObjects[SanityObjectSchema.PAGE_AVAILABILITY];
  redeemableContent?: ISanityObjects[SanityObjectSchema.PROMO_BOX_CONTENT];
  redeemableCtaText?: string;
  redeemedContent?: ISanityObjects[SanityObjectSchema.PROMO_BOX_CONTENT];
  redeemedCta?: ISanityObjects[SanityObjectSchema.URL_OR_REFLINK];
  redeemedCtaText?: string;
  redemptionCode?: string;
  redemptionExpiredContent?: ISanityObjects[SanityObjectSchema.PROMO_BOX_CONTENT];
  redemptionExpiredCta?: ISanityObjects[SanityObjectSchema.URL_OR_REFLINK];
  redemptionExpiredCtaText?: string;
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  slug?: string;
  startsAt?: string;
  utmContent?: ISanityObjects[SanityObjectSchema.UTM_CONTENT];
}

export interface ISeries {
  contents?: SanityReference[];
  overview?: TSanityBlockContent;
  pageAvailability?: ISanityObjects[SanityObjectSchema.PAGE_AVAILABILITY];
  promoCard?: ISanityObjects[SanityObjectSchema.PROMO_CARD];
  restrictionId?: string;
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  slug?: string;
  title?: string;
}

export interface ISiteConfig extends IFeatureFlags {
  chat?: ISanityObjects[SanityObjectSchema.CHAT_CONFIG];
  pageAvailabilityPerSchema?: Partial<
    Record<TSanityDocumentPageSchemas, { pageAvailability?: IPageAvailability }>
  > & {
    evergreenUa?: { pageAvailability?: IPageAvailability };
  };
  uaPage?: SanityReference;
  homepageTakeoverProject?: SanityReference;
  homePageBanners?: SanityReference[];
  homePageHeroVideo?: SanityReference;
  redirects?: Array<{
    redirectFrom?: string;
    redirectTo?: ISanityObjects[SanityObjectSchema.REDIRECT_TO];
  }>;
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  auth?: SanityReference;
  cgTicketsDonated?: number; // temporary for common ground campaign
}

export interface ITimeline {
  timelinePeriods?: Array<ISanityObjects[SanityObjectSchema.TIMELINE_PERIOD]>;
  slug?: string;
  title?: string;
}

export interface ITypeForm {
  pageAvailability?: ISanityObjects[SanityObjectSchema.PAGE_AVAILABILITY];
  redirectAfterFormSubmissionUrl?: string;
  redirectIfNotQualifiedUrl?: string;
  restrictionId?: string;
  requiresLogin?: boolean;
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  slug?: string;
  title?: string;
  typeFormId?: string;
}

export interface IUaPage {
  activeCampaignId?: string;
  ctaText?: string;
  disclaimer?: string;
  logo?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  overline?: string;
  pageHeroImage?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  title?: string;
  teaser?: SanityReference;
  teaserButtonText?: string;
  uaSuccess?: {
    body?: TSanityBlockContent;
    campaignTitle?: string;
    gleamUrl?: string;
    shouldShowGleamCampaign?: boolean;
    title?: string;
  };
  valuePropositionSection?: ISanityObjects[SanityObjectSchema.ROW_TEXT_WITH_IMAGE];
}

export interface IVideoAsset {
  assetId?: string;
  data?: IMuxAssetData;
  filename?: string;
  playbackId?: string;
  thumbTime?: number;
}

export interface IVideo {
  collections: SanityReference[];
  completionReward?: SanityReference;
  completionTime?: ISanityObjects[SanityObjectSchema.RUN_TIME];
  contentDetails?: string;
  contentRating?: ISanityObjects[SanityObjectSchema.CONTENT_RATING];
  dcdnEnabled?: boolean;
  dcdnId?: string;
  description?: TSanityBlockContent;
  documentReference?: SanityReference;
  languagesAudio?: SanityReference[];
  maxResolution?: SanityReference;
  mediaType?: SanityReference;
  musicToken: string;
  pageAvailability?: ISanityObjects[SanityObjectSchema.PAGE_AVAILABILITY];
  poster?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  regionOfOrigin?: SanityReference;
  releaseDate?: string;
  restrictionId?: string;
  runTime?: ISanityObjects[SanityObjectSchema.RUN_TIME];
  seo?: ISanityObjects[SanityObjectSchema.SEO];
  slug?: string;
  title?: string;
  transcripts?: Array<ISanityObjects[SanityObjectSchema.TRANSCRIPT]>;
  video?: {
    asset?: SanityReference;
  };
}

export interface IVideoLiveStream {
  documentReference?: SanityReference;
  mediaType?: SanityReference;
  poster?: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  restrictionId?: string;
  slug?: string;
  title?: string;
  video?: {
    playbackId?: string;
  };
}

export interface IVideoWithExpandedAsset extends Omit<IVideo, 'video'> {
  video?: {
    asset?: IVideoAsset;
  };
}

export type TCollection = Pick<IItemWithTitle, 'title'> & IItemWithSlug;
export type TGenre = Pick<IItemWithTitle, 'title'> & IItemWithSlug;
export type TLanguage = Pick<IItemWithTitle, 'title'> & IItemWithSlug;
export type TMediaType = Pick<IItemWithTitle, 'title' | 'titlePlural'> &
  IItemWithSlug;
export type TPeriod = Pick<IItemWithTitle, 'title'> & IItemWithSlug;
export type TRarity = Pick<IItemWithTitle, 'title'> & IItemWithSlug;
export type TRating = Pick<IItemWithTitle, 'title' | 'titleAbbrev'> &
  IItemWithSlug;
export type TRegion = Pick<IItemWithTitle, 'title' | 'titleAbbrev'> &
  IItemWithSlug;
export type TResolution = Pick<IItemWithTitle, 'title' | 'titleAbbrev'> &
  IItemWithSlug;
export type TRole = Pick<IItemWithTitle, 'title' | 'titlePlural'> &
  IItemWithSlug;
export type TTag = Pick<IItemWithTitle, 'title'> & IItemWithSlug;
export type TTranscriptType = Pick<
  IItemWithTitle,
  'title' | 'titleAbbrev' | 'titlePlural'
> &
  IItemWithSlug;

export type TVideoType =
  | SanityDocumentSchema.VIDEO
  | SanityDocumentSchema.VIDEO_LIVE_STREAM;

export type TVideoParentType =
  | SanityDocumentSchema.FILM
  | SanityDocumentSchema.LIVE_STREAM
  | SanityDocumentSchema.PROJECT;

export enum ProjectPageVariation {
  V1 = 'v1',
  V2 = 'v2',
}
