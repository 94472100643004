
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TSiteConfigState, ThemeName } from '~/store/types';

@Component
export default class DashboardMain extends Vue {
  @Getter('siteConfig')
  readonly siteConfigState!: TSiteConfigState;

  delay = 500;
  showLoadingIndicator = false;
  themes = ThemeName;
  timeout?: NodeJS.Timeout;

  @Watch('siteConfigState.isLoading', { immediate: true })
  handleChange(current: boolean) {
    if (current) {
      this.showLoadingIndicator = false;
      this.timeout = setTimeout(() => {
        this.showLoadingIndicator = true;
        this.timeout = undefined;
      }, this.delay);
    } else {
      this.showLoadingIndicator = false;
      !!this.timeout && clearTimeout(this.timeout);
    }
  }
}
