import {
  TAdaptor,
  IHydrateAdaptorPayload,
  IHydratedAdaptor,
  EAdaptors,
  TInit,
  TTrackEvent,
  EEventTypes,
  TTRACKED_EVENTS,
  TSetUser,
} from '../types';
import hydrateAdaptor from '../hydrateAdaptor';

const TRACKED_EVENTS: TTRACKED_EVENTS = {
  [EEventTypes.PURCHASE_ITEM]: true,
  [EEventTypes.PURCHASE_NODE]: true,
  [EEventTypes.PURCHASE_GALA_GOLD]: true,
};

interface ITrackConvPayload {
  order_id: string;
  value: string;
  currency: string;
  email: string;
  offer_code: string;
}

type TTrackConv = (payload: ITrackConvPayload) => void;

export const addShoppersAdaptor: TAdaptor = (adaptorPayload) => {
  const { enabled, accountId } = adaptorPayload.config;

  if (!enabled || !accountId) {
    return undefined;
  }

  const { getCurrentUserEmail } = adaptorPayload;

  const trackConv: TTrackConv = (payload) => {
    (window as any).AddShoppersConversion = payload;
    (window as any).AddShoppersWidget.track_conv();
  };

  const init: TInit = () => {
    const AddShoppersWidgetOptions = { loadCss: false, pushResponse: false };
    (window as any).AddShoppersWidgetOptions = AddShoppersWidgetOptions;

    const scriptId = 'AddShoppers';
    let script = document.getElementById(scriptId) as HTMLScriptElement;
    if (!script) {
      script = document.createElement('script') as HTMLScriptElement;
      script.type = 'text/javascript';
      script.id = scriptId;
      script.async = true;
      script.src = `https://shop.pe/widget/widget_async.js#${accountId}`;
      document.head.appendChild(script);
    }
  };

  const trackEvent: TTrackEvent = (payload) => {
    const { eventName } = payload;
    if (!(eventName in TRACKED_EVENTS)) {
      return;
    }

    const { eventValue } = payload;

    try {
      trackConv({
        order_id: eventValue?.orderId,
        value: eventValue?.total,
        currency: eventValue?.currency,
        email: getCurrentUserEmail() as string,
        offer_code: eventValue?.priceAdjustmentCode,
      });
    } catch (e) {
      // add_shoppers error, bailing
      console.log('##ADD_SHOPPERS ERROR', e);
    }
  };

  const hydrateAdaptorPayload: IHydrateAdaptorPayload = {
    init,
    trackEvent,
  };

  const baseAdaptor: IHydratedAdaptor = hydrateAdaptor(hydrateAdaptorPayload);

  const setUser: TSetUser = (currentUser) => {
    baseAdaptor.trackSetUserEvent(currentUser);
  };

  const finalAdaptor: IHydratedAdaptor = {
    ...baseAdaptor,
    setUser,
  };

  return finalAdaptor;
};

addShoppersAdaptor.NAME = EAdaptors.ADD_SHOPPERS;

export default addShoppersAdaptor;
