
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import SanityPromoItemNav from '~/mixins/SanityPromoItemNav';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';
import SanityReference from '~/components/Sanity/SanityReference.vue';

@Component({
  components: {
    SanityReference,
  },
})
export default class PromoListItem extends mixins(SanityPromoItemNav) {
  @Prop(Object) readonly doc!: ISanityDocuments[SanityDocumentSchema.PROJECT];
  @Prop(Boolean) readonly loading!: boolean;

  schemas = SanityDocumentSchema;

  get badge() {
    return this.item?.badges?.[0] || undefined;
  }
}
