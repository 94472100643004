import Vue from 'vue';
import addFilters from '@gala/vue-filters';
import { format, formatDistance } from 'date-fns';
import _startCase from 'lodash.startcase';
import _uppercase from 'lodash.uppercase';
import { formatNumber } from '~/utils/format';

Vue.filter('formatNumber', formatNumber);

Vue.filter(
  'localCurrency',
  (value: number, currency: string = 'USD', decimals: number = 2) => {
    if (value < 0.01) {
      decimals = 4;
    }
    return value.toLocaleString(undefined, {
      localeMatcher: 'best fit',
      style: 'currency',
      currency,
      maximumFractionDigits: decimals,
    });
  },
);

Vue.filter(
  'formatDate',
  (value: number, formatString: string = 'MMMM d, yyyy') => {
    return format(value, formatString);
  },
);
Vue.filter('formatTimeDistance', (value: number) => {
  return formatDistance(value, new Date(), { addSuffix: true });
});
Vue.filter('startCase', (value: string) => {
  return _startCase(value);
});
Vue.filter('uppercase', (value: string) => {
  return _uppercase(value);
});
Vue.filter('pluralize', (value: string) => {
  const patterns: { [key: string]: string } = {
    '(quiz)$': '$1zes',
    '^(ox)$': '$1en',
    '([m|l])ouse$': '$1ice',
    '(matr|vert|ind)ix|ex$': '$1ices',
    '(x|ch|ss|sh)$': '$1es',
    '([^aeiouy]|qu)y$': '$1ies',
    '(hive)$': '$1s',
    '(?:([^f])fe|([lr])f)$': '$1$2ves',
    '(shea|lea|loa|thie)f$': '$1ves',
    sis$: 'ses',
    '([ti])um$': '$1a',
    '(tomat|potat|ech|her|vet)o$': '$1oes',
    '(bu)s$': '$1ses',
    '(alias)$': '$1es',
    '(octop)us$': '$1i',
    '(ax|test)is$': '$1es',
    '(us)$': '$1es',
    '([^s]+)$': '$1s',
  };
  const irregular: { [key: string]: string } = {
    move: 'moves',
    foot: 'feet',
    goose: 'geese',
    sex: 'sexes',
    child: 'children',
    man: 'men',
    tooth: 'teeth',
    person: 'people',
  };
  const uncountable = [
    'sheep',
    'fish',
    'deer',
    'moose',
    'series',
    'species',
    'money',
    'rice',
    'information',
    'equipment',
    'gala',
  ];
  // save some time in the case that singular and plural are the same
  if (uncountable.indexOf(value.toLowerCase()) >= 0) {
    return value;
  }

  // check for irregular forms
  for (const word in irregular) {
    if (irregular.hasOwnProperty(word)) {
      const pattern = new RegExp(word + '$', 'i');
      const replace = irregular[word];

      if (pattern.test(value)) {
        return value.replace(pattern, replace);
      }
    }
  }

  // check for matches using regular expressions
  for (const reg in patterns) {
    if (patterns.hasOwnProperty(reg)) {
      const pattern = new RegExp(reg, 'i');

      if (pattern.test(value)) {
        return value.replace(pattern, patterns[reg]);
      }
    }
  }

  return value;
});

Vue.filter('sanitizeStringToNumber', (value: string | number) => {
  const sanitized = value.toString().replace(/,/g, '');
  return +sanitized;
});

Vue.filter('filterSymbolText', (value: string) => {
  const filtered = value.replace(/gyri/i, 'Gala Chain');
  return filtered.replace(/gyri/i, filtered) ?? '';
});

addFilters(Vue);
