import { ActionTree } from 'vuex';
import { IGetUFCEventPayload, IUFCState } from './types';
import { IRootState } from '../types';
import { IUFCEvent } from '~/types/ufc';

export const actions: ActionTree<IUFCState, IRootState> = {
  async getUFCEvent({ commit }, payload: IGetUFCEventPayload) {
    const event: IUFCEvent = await this.$filmApiService.getUFCEvent(
      payload.eventSlug,
    );
    commit('updateEvent', event);
  },
};
