/**
 * code adapted from https://hdoro.dev/performant-sanity-io-images
 */

import {
  SanityAsset,
  SanityImageObject,
  SanityImageSource,
  SanityImageWithAssetStub,
  SanityReference,
} from '@sanity/image-url/lib/types/types';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';

const isSanityAsset = (data: any): data is SanityAsset => {
  return (
    typeof data === 'object' &&
    typeof data._id === 'string' &&
    typeof data.assetId === 'string'
  );
};

const isSanityReference = (data: any): data is SanityReference => {
  return typeof data === 'object' && typeof data._ref === 'string';
};

const isSanityImageWithAssetStub = (
  image: SanityImageSource,
): image is SanityImageWithAssetStub => {
  return (
    typeof image === 'object' &&
    'asset' in image &&
    typeof image.asset.url === 'string' &&
    !isSanityAsset(image.asset)
  );
};

const isSanityImageObject = (
  image: SanityImageSource,
): image is SanityImageObject => {
  return (
    typeof image === 'object' &&
    'asset' in image &&
    (isSanityAsset(image.asset) || isSanityReference(image.asset))
  );
};

const LARGEST_VIEWPORT = 1920; // Retina sizes will take care of 4k (2560px) and other huge screens

const DEFAULT_MIN_STEP = 0.1; // 10%
const DEFAULT_WIDTH_STEPS = [400, 600, 850, 1000, 1150]; // arbitrary
// Based on statcounter's most common screen sizes: https://gs.statcounter.com/screen-resolution-stats
const DEFAULT_FULL_WIDTH_STEPS = [360, 414, 768, 1366, 1536, 1920];

export interface IConfig {
  // The image source.
  image: SanityImageSource;
  // Number of the largest width it can assume in the design
  // or "100vw" if it occupies the whole width
  maxWidth: number | '100vw';
  // The minimal width difference, in PERCENTAGE (decimal), between the image's srcSet variations.
  // -> 0.10 (10%) by default.
  minimumWidthStep?: number;
  // List of width sizes to use in the srcSet (NON-RETINA)
  customWidthSteps?: number[];
  // Custom <img> element's `sizes` attribute
  sizes?: string;
}

export interface IResponsiveImageProps {
  src: string;
  srcset: string;
  sizes: string;
  width: number;
  height: number;
}

export const getImageId = (image: SanityImageSource) => {
  if (typeof image === 'string') {
    return image;
  }

  if (isSanityReference(image)) {
    return image._ref;
  }

  if (isSanityAsset(image)) {
    return image._id;
  }

  if (isSanityImageWithAssetStub(image)) {
    return image.asset.url.split('/').pop();
  }

  if (isSanityImageObject(image)) {
    if (isSanityReference(image.asset)) {
      return image.asset._ref;
    }

    if (isSanityAsset(image.asset)) {
      return image.asset._id;
    }
  }
};

export const getImageAssetDimensions = (image: SanityImageSource) => {
  const id = getImageId(image);

  if (!id) {
    return;
  }

  const dimensions = id.split('-')[2];
  const [width, height] = dimensions.split('x').map(Number);

  if (!width || !height || Number.isNaN(width) || Number.isNaN(height)) {
    return;
  }

  return {
    width,
    height,
    aspectRatio: width / height,
  };
};

export const getImageDimensions = (image: SanityImageSource) => {
  const asset = getImageAssetDimensions(image);

  if (!asset) {
    return;
  }

  if (isSanityImageObject(image)) {
    const imgCrop = {
      left: image.crop?.left || 0,
      top: image.crop?.top || 0,
      right: image.crop?.right || 0,
      bottom: image.crop?.bottom || 0,
    };

    const cropLeft = Math.round(imgCrop.left * asset.width);
    const cropTop = Math.round(imgCrop.top * asset.height);
    const width = Math.round(
      asset.width - imgCrop.right * asset.width - cropLeft,
    );
    const height = Math.round(
      asset.height - imgCrop.bottom * asset.height - cropTop,
    );

    return {
      width,
      height,
      aspectRatio: width / height,
    };
  } else {
    return asset;
  }
};

export const getImageProps = (
  {
    image,
    maxWidth: userMaxWidth,
    minimumWidthStep = DEFAULT_MIN_STEP,
    customWidthSteps,
    sizes,
  }: IConfig,
  imageBuilder: ImageUrlBuilder,
): Pick<IResponsiveImageProps, 'src'> | IResponsiveImageProps | undefined => {
  // Check if this is a valid Sanity image source
  const imageId = getImageId(image);

  if (!imageId) {
    return;
  }

  const maxWidth =
    typeof userMaxWidth === 'number' ? userMaxWidth : LARGEST_VIEWPORT;

  // For all image variations, we'll use an auto format and prevent scaling it over its max dimensions
  const builder = imageBuilder.image(image).fit('max').auto('format');

  const imageDimensions = getImageDimensions(image);

  if (!imageDimensions) {
    return {
      src: builder.width(maxWidth).url(),
    };
  }

  // Width sizes the image could assume
  const baseSizes = [
    maxWidth,
    ...(customWidthSteps ||
      (typeof userMaxWidth === 'number'
        ? DEFAULT_WIDTH_STEPS
        : DEFAULT_FULL_WIDTH_STEPS)),
  ];
  const retinaSizes = Array.from(
    // De-duplicate sizes with a Set
    new Set([
      ...baseSizes,
      ...baseSizes.map((size) => size * 2),
      ...baseSizes.map((size) => size * 3),
    ]),
  )
    .sort((a, b) => a - b) // Lowest to highest
    .filter(
      (size) =>
        // Exclude sizes 10% or more larger than the image itself. Sizes slightly larger
        // than the image are included to ensure we always get closest to the highest
        // quality for an image. Sanity's CDN won't scale the image above its limits.
        size <= imageDimensions.width * 1.1 &&
        // Exclude those larger than maxWidth's retina (x3)
        size <= maxWidth * 3,
    )

    // Exclude those with a value difference to their following size smaller than `minimumWidthStep`
    // This ensures we don't have too many srcSet variations, polluting the HTML
    .filter((size, i, arr) => {
      const nextSize = arr[i + 1];
      if (nextSize) {
        return nextSize / size > minimumWidthStep + 1;
      }

      return true;
    });

  return {
    // Use the original image as the `src` for the <img>
    src: builder.width(maxWidth).url(),

    // Build a `{URL} {SIZE}w, ...` string for the srcset
    srcset: retinaSizes
      .map((size) => `${builder.width(size).url()} ${size}w`)
      .join(', '),
    sizes:
      userMaxWidth === '100vw'
        ? '100vw'
        : sizes || `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`,

    // Let's also tell the browser what's the size of the image so it can calculate aspect ratios
    width: retinaSizes[0],
    height: retinaSizes[0] / imageDimensions.aspectRatio,
  };
};
