
import { Component, Prop, Vue } from 'nuxt-property-decorator';
@Component
export default class DiscordIcon extends Vue {
  @Prop({ type: String })
  readonly title!: string;
  @Prop({ type: Number, default: 19 })
  readonly height!: number;
  @Prop({ type: Number, default: 24 })
  readonly width!: number;
}
