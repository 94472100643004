
import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import { IDiscordProfile } from '~/store/profile/types';
import DiscordIcon from '~/components/SvgIcons/DiscordIcon.vue';
import DiscordDialog from './DiscordDialog.vue';
import DiscordConnectingDialog from './DiscordConnectingDialog.vue';

@Component({
  components: {
    DiscordDialog,
    DiscordConnectingDialog,
    DiscordIcon,
  },
})
export default class DiscordConnect extends Vue {
  @State((profile) => profile.user.discordProfile, { namespace: 'profile' })
  readonly discordProfile!: IDiscordProfile;
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  readonly loggedIn!: boolean;
  @State('showDiscordDialog') readonly showDiscordDialogState!: boolean;
  @State('discordLink') readonly discordLink!: string;

  get connected() {
    return !!this.discordProfile;
  }

  @Mutation toggleDiscordDialog!: (args: any) => void;
}
