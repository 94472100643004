
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SanityIframeSerializer extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(String) readonly src!: string;
  @Prop(String) readonly height!: string;
  @Prop(String) readonly width!: string;

  get props() {
    return {
      title: this.title,
      src: this.src,
      height: this.containsOnlyNumbers(this.height) ? this.height : undefined,
      width: this.containsOnlyNumbers(this.width) ? this.width : undefined,
      style: {
        height: !this.containsOnlyNumbers(this.height)
          ? this.height
          : undefined,
        width: !this.containsOnlyNumbers(this.width) ? this.width : undefined,
      },
    };
  }

  containsOnlyNumbers(value: string) {
    return /^\d+$/.test(value);
  }
}
