
import type MuxPlayerElement from '@mux/mux-player';
import {
  Action,
  Component,
  mixins,
  Prop,
  State,
  Watch,
} from 'nuxt-property-decorator';
import SanityMuxData from '~/mixins/SanityMuxData';
import { IProfileState } from '~/store/profile/types';
import {
  ISanityDocuments,
  IVideo,
  IVideoLiveStream,
  IVideoWithExpandedAsset,
  SanityDocumentSchema,
  TVideoType,
  TVideoParentType,
} from '~/types/sanity-documents';
import {
  SanityObjectSchema,
  ISanityObjects,
  IVideoTime,
} from '~/types/sanity-objects';
import {
  IVideoPlayerOptions,
  IVideoMetadata,
  MuxStreamTypes,
  TStreamTypeValues,
} from '~/types/mux-player';
import isQualified from '~/queries/isQualified.gql';
import { ThemeName } from '~/store/types';
import SanityDocument from '~/components/Sanity/SanityDocument.vue';
import AuthActions from '~/mixins/AuthActions';
import SanityDocumentMixin from '~/mixins/SanityDocument';
import VideoPlayerError from './VideoPlayerError.vue';
import VideoPlayerLoading from './VideoPlayerLoading.vue';
import VideoPlayerRaw from './VideoPlayerRaw.vue';
import { SanityReference } from '@sanity/image-url/lib/types/types';
import { TAsyncStateWithDoc } from '~/store/documents/types';
import {
  isRestrictionSuccess,
  getFirstRestrictionSuccessResultByFilter,
} from '~/utils/parseRestrictionResults';
import { VideoWatchEventTypes } from '~/types/event-restriction';
import { IntervalTimer } from '~/utils/intervalTimer';
import VideoPlayerRawDCDN from './VideoPlayerRawDCDN.vue';

export interface IMuxSuccessReasonDetails {
  isSigned: boolean;
  expiration?: string;
  token?: string;
  statsToken?: string;
  thumbnailToken?: string;
}

export interface IVideoPlayerWithMuxPlayer extends Vue {
  muxPlayer?: MuxPlayerElement;
}

export interface IVideoPlayer extends Vue {
  getVideoDuration: () => number;
  getVideoCurrentTime: () => number;
  isPaused: () => boolean;
  pause: () => void;
  play: () => void;
  setCurrentTime: (time: number) => void;
}

const defaultVideoProgressTrackingIntervalMs = +(
  process.env.videoProgressTrackingIntervalMs || 1000
);
const endOfVideoResumeCutoffThresholdMs = +(
  process.env.endOfVideoResumeCutoffThresholdMs || 15000
);

@Component({
  components: {
    SanityDocument,
    VideoPlayerError,
    VideoPlayerLoading,
    VideoPlayerRaw,
    VideoPlayerRawDCDN,
  },
  inheritAttrs: false,
})
export default class VideoPlayer extends mixins(
  AuthActions,
  SanityDocumentMixin,
  SanityMuxData,
) {
  @Prop(Boolean) readonly loading!: boolean;
  @Prop({ type: String, default: MuxStreamTypes.ON_DEMAND })
  readonly streamType!: TStreamTypeValues;
  @Prop(Object)
  readonly poster!: ISanityObjects[SanityObjectSchema.IMAGE_WITH_ALT_TEXT];
  @Prop(String) readonly slug!: string;
  @Prop({ type: String, default: SanityDocumentSchema.VIDEO })
  readonly type!: TVideoType;
  @Prop(String) readonly id!: string;
  @Prop({ type: Boolean, default: false })
  readonly uaExperience!: boolean;
  @Prop(Number) readonly uaVideoCutoffSeconds!: number;
  @Prop({ type: Number, default: -1 })
  readonly startTimeSeconds!: number;

  @Prop({
    type: Object,
    default: () => ({
      player_name: 'Gala-Film-Player',
      player_version: '0.1',
    }),
  })
  readonly videoMetadata!: Partial<IVideoMetadata>;

  @Prop({
    type: Object,
    default: () => ({
      shouldShowControls: true,
      shouldShowTitle: false,
    }),
  })
  readonly videoOptions!: Partial<IVideoPlayerOptions>;

  @Prop({ type: Boolean, default: true })
  readonly useTracking!: boolean;

  // @Prop({ type: Boolean, default: false })
  // readonly useNodes!: boolean;

  @State((profile: IProfileState) => profile.user.email, {
    namespace: 'profile',
  })
  email!: string;
  @State((profile: IProfileState) => profile.user.loggedIn, {
    namespace: 'profile',
  })
  loggedIn!: boolean;

  @State((profile) => profile.user.id, { namespace: 'profile' })
  userId!: string;

  @Action('handleVideoWatchCompletionRewards', { namespace: 'rewards' })
  private handleVideoWatchCompletionRewards!: (video: IVideo) => void;

  muxPlayer: MuxPlayerElement | undefined = undefined;

  videoPlayer: IVideoPlayer | undefined = undefined;

  theme = ThemeName.DARK;
  muxAssetSchema = SanityDocumentSchema.MUX_ASSET;
  parentSchemas = [
    SanityDocumentSchema.PROJECT,
    SanityDocumentSchema.FILM,
    SanityDocumentSchema.LIVE_STREAM,
  ];
  schemas = SanityDocumentSchema;

  isLoadingToken = false;
  isTokenRequired = true;
  isDeterminingStartTime = false;
  token = '';
  statsToken = '';
  thumbnailToken = '';
  hasFirstVideoStartOccurred: boolean = false;
  hasFirstVideoEndOccurred: boolean = false;
  startTime: number = 0;
  videoProgressTrackingIntervalMs: number = this.uaExperience
    ? 1000
    : defaultVideoProgressTrackingIntervalMs;
  intervalTimer = new IntervalTimer(
    () => this.createProgressTrackingEvent(),
    this.videoProgressTrackingIntervalMs,
  );

  handlePlay() {
    (this.$refs?.player as Vue)?.$el?.classList?.remove('hide-controls');

    if (this.useTracking) {
      if (!this.hasFirstVideoStartOccurred) {
        this.countVideoEvent(VideoWatchEventTypes.VIDEO_WATCH_STARTED);

        this.$ua.trackPress({
          interactionTargetId: 'video-player-play-button',
          context: this.slug,
        });
        this.$ua.trackVideoWatchEvent({
          videoWatchEventType: VideoWatchEventTypes.VIDEO_WATCH_STARTED,
          videoSlug: this.slug,
        });

        this.hasFirstVideoStartOccurred = true;
      }
      this.intervalTimer.resume();
    }
  }

  handlePause() {
    this.handleProgressIntervalReset();
  }

  handleSeeked() {
    this.handleProgressIntervalReset();
  }

  handleProgressIntervalReset() {
    if (!this.useTracking) return;

    this.createProgressTrackingEvent();
    this.intervalTimer.reset();
    if (this.isPaused()) {
      this.intervalTimer.pause();
    }
  }

  handleEnded() {
    (this.$refs?.player as Vue)?.$el?.classList?.add('hide-controls');
    if (this.useTracking) {
      this.handleVideoCompletion();
      this.intervalTimer.clear();
    }
  }

  getVideoCurrentTime() {
    return this.videoPlayer?.getVideoCurrentTime() || 0;
  }

  getVideoDuration() {
    return this.videoPlayer?.getVideoDuration();
  }

  isPaused() {
    return this.videoPlayer?.isPaused();
  }

  pause() {
    this.videoPlayer?.pause();
  }

  play() {
    this.videoPlayer?.play();
  }

  setCurrentTime(time: number) {
    this.videoPlayer?.setCurrentTime(time);
  }

  async createProgressTrackingEvent() {
    const videoCurrentTime = this.getVideoCurrentTime();
    const videoDuration = this.getVideoDuration();
    const bucket = this.getVideoRestrictionEventBucket();

    if (!videoDuration) {
      return;
    }

    // only save watch time if user is logged in
    if (this.userId) {
      await this.$restrictionsService.upsertEvent({
        id: this.getVideoRestrictionUpsertEventId(
          VideoWatchEventTypes.VIDEO_WATCH_PROGRESS,
        ),
        type: VideoWatchEventTypes.VIDEO_WATCH_PROGRESS,
        userId: this.userId,
        bucket,
        value: {
          videoCurrentTime,
        },
        persist: true,
      });

      this.$ua.trackVideoWatchEvent({
        videoWatchEventType: VideoWatchEventTypes.VIDEO_WATCH_PROGRESS,
        videoSlug: this.slug,
        videoCurrentTimeSeconds: videoCurrentTime,
      });
    } else if (this.uaExperience && this.uaVideoCutoffSeconds > 0) {
      if (videoCurrentTime >= this.uaVideoCutoffSeconds) {
        this.pause();
        this.$emit('trigger-ua-login');
      }
    }

    if (!this.hasFirstVideoEndOccurred) {
      if (videoCurrentTime >= this.videoCompletionTimeInSeconds) {
        this.handleVideoCompletion();
      } else {
        const remainingTimeInVideo = videoDuration - videoCurrentTime;
        const videoProgressTrackingIntervalSec =
          this.videoProgressTrackingIntervalMs / 1000;
        if (remainingTimeInVideo <= videoProgressTrackingIntervalSec) {
          this.handleVideoCompletion();
        }
      }
    }
  }

  handleVideoCompletion() {
    if (!this.hasFirstVideoEndOccurred) {
      this.countVideoEvent(VideoWatchEventTypes.VIDEO_WATCH_COMPLETED);

      this.$ua.trackVideoWatchEvent({
        videoWatchEventType: VideoWatchEventTypes.VIDEO_WATCH_COMPLETED,
        videoSlug: this.slug,
      });

      if (this.userId) {
        const videoData = this.videoState?.data as IVideo;
        this.handleVideoWatchCompletionRewards(videoData);

        this.$emit('video-completed');
      }

      this.hasFirstVideoEndOccurred = true;
    }
  }

  async countVideoEvent(type: string) {
    const bucket = this.getVideoRestrictionEventBucket();
    await this.$restrictionsService.upsertEvent({
      type,
      userId: this.userId,
      bucket,
      persist: true,
    });
  }

  @Watch('slug', { immediate: true })
  async handleVideoTrackingDataFetchBySlug(current: string, previous: string) {
    if (this.slug) {
      await this.determineStartTime();
    }
  }

  @Watch('videoState.data.video.asset', { immediate: true })
  handleAssetDataChange(current: SanityReference, previous: SanityReference) {
    const assetId = (current?._ref as string) || '';
    if (
      this.$sanityLookup.getDocumentFromReference({ _ref: assetId }, true)?.data
    ) {
      return;
    }

    const prevAssetId = (previous?._ref as string) || '';
    if (assetId === prevAssetId) {
      return;
    }

    this.$sanityLookup.fetchDocument({
      _type: this.muxAssetSchema,
      _id: assetId,
    });
  }

  @Watch('videoData', { immediate: true })
  async handleVideoDataChange(
    current?: IVideo | IVideoLiveStream,
    previous?: IVideo | IVideoLiveStream,
  ) {
    if (!current) {
      return;
    }

    if (current && !current.restrictionId) {
      if (this.isTokenRequired) {
        this.isTokenRequired = false;
      }
      return;
    }

    const restrictionId = current?.restrictionId;
    const playbackId =
      (current as IVideoWithExpandedAsset)?.video?.asset?.playbackId ||
      (current as IVideoLiveStream)?.video?.playbackId;
    if (!playbackId || !restrictionId) {
      return;
    }

    this.isRestrictedCheck(restrictionId, playbackId);
  }

  async determineStartTime() {
    this.isDeterminingStartTime = true;
    let calculatedStartTime = 0;
    if (this.userId) {
      const lastWatchedTime =
        this.startTimeSeconds > -1
          ? this.startTimeSeconds
          : await this.getTrackedVideoProgress();
      if (lastWatchedTime < this.videoCompletionTimeInSeconds) {
        calculatedStartTime = lastWatchedTime;
      }
    }
    this.startTime = calculatedStartTime;
    this.isDeterminingStartTime = false;
  }

  goToLoginService() {
    return this.promptToLogin({
      redirectAfterLoginPath: this.$route.fullPath,
    });
  }

  handlePlayerMounted() {
    if (this.useNodes) {
      this.videoPlayer = this.$refs.nodeVideoPlayer as IVideoPlayer;
    } else {
      if ((this.$refs.player as IVideoPlayerWithMuxPlayer)?.muxPlayer) {
        this.muxPlayer = (
          this.$refs.player as IVideoPlayerWithMuxPlayer
        ).muxPlayer;
        this.videoPlayer = this.$refs.player as IVideoPlayer;
      }
    }
  }

  beforeDestroy() {
    this.intervalTimer.clear();
  }

  get hasError() {
    return (
      !this.isLoading &&
      (!this.playbackId || (this.isTokenRequired && !this.token))
    );
  }

  get isLoading() {
    return (
      this.loading ||
      this.$fetchState.pending ||
      this.videoState?.isLoading ||
      this.videoAssetState?.isLoading ||
      this.isLoadingToken ||
      this.isDeterminingStartTime
    );
  }

  get videoData() {
    const video = this.videoState?.data;
    if (video?._type === SanityDocumentSchema.VIDEO_LIVE_STREAM) {
      return video;
    }

    if (video?._type === SanityDocumentSchema.VIDEO) {
      const asset = this.videoAssetState?.data;
      return asset?._id
        ? {
            ...video,
            video: {
              asset: {
                ...asset,
              },
            },
          }
        : video;
    }
  }

  get parentState():
    | TAsyncStateWithDoc<ISanityDocuments[TVideoParentType]>
    | undefined {
    const parentRef = this.videoState?.data?.documentReference?._ref;
    return parentRef
      ? this.$sanityLookup.getDocumentFromReference({
          _ref: parentRef,
        })
      : {
          isLoading: false,
          hasError: false,
          data: undefined,
        };
  }

  get videoState(): TAsyncStateWithDoc<ISanityDocuments[TVideoType]> {
    return (
      this.$sanityLookup.getDocumentFromIdOrSlug({
        _id: this.id,
        slug: this.slug,
        _type: this.type,
      }) || {
        isLoading: false,
        hasError: false,
        data: undefined,
      }
    );
  }

  get videoAssetState() {
    const videoData = this.videoState?.data as IVideo;
    const defaultState = {
      isLoading: false,
      hasError: false,
      data: undefined,
    };

    return videoData?.video?.asset?._ref
      ? this.$sanityLookup.getDocumentFromId<
          ISanityDocuments[SanityDocumentSchema.MUX_ASSET]
        >({
          _id: videoData.video.asset._ref as string,
          _type: SanityDocumentSchema.MUX_ASSET,
        }) || defaultState
      : defaultState;
  }

  get videoCompletionTimeInSeconds() {
    const videoData = this.videoState?.data as IVideo;
    const completionTime = videoData?.completionTime;
    if (completionTime) {
      return this.convertVideoTimeToSeconds(completionTime);
    } else {
      const videoLength = this.getVideoDuration();
      const endOfVideoResumeCutoffThresholdSec =
        endOfVideoResumeCutoffThresholdMs / 1000;
      return videoLength ? videoLength - endOfVideoResumeCutoffThresholdSec : 0;
    }
  }

  get useNodes() {
    const videoData = this.videoState?.data as IVideo;
    return videoData?.dcdnEnabled ?? false;
  }

  get dcdnVideoId() {
    const videoData = this.videoState?.data as IVideo;
    return videoData?.dcdnId ?? this.slug;
  }

  async fetch() {
    if (!((this.slug || this.id) && this.type)) {
      return;
    }

    if (!this.videoState?.data) {
      await this.$sanityLookup.fetchDocument({
        _id: this.id,
        slug: this.slug,
        _type: this.type,
      });
    }
  }

  async getTrackedVideoProgress() {
    try {
      const eventId = this.getVideoRestrictionEventId(
        VideoWatchEventTypes.VIDEO_WATCH_PROGRESS,
      );
      const viewRestrictionEvent = await this.$restrictionsService.getEvent(
        eventId,
      );
      return viewRestrictionEvent?.value?.videoCurrentTime ?? 0;
    } catch (error) {
      console.error(error);
    }
    return 0;
  }

  convertVideoTimeToSeconds(videoTime: IVideoTime) {
    const minutes = (videoTime.hours ?? 0) * 60 + (videoTime.minutes ?? 0);
    return minutes * 60 + (videoTime.seconds ?? 0);
  }

  getVideoRestrictionUpsertEventId(eventType: VideoWatchEventTypes) {
    return `${eventType}|${this.videoData?._type || this.type}|${
      this.videoData?.slug || this.slug
    }|$USER_ID`;
  }

  getVideoRestrictionEventId(eventType: VideoWatchEventTypes) {
    return `${eventType}|${this.videoData?._type || this.type}|${
      this.videoData?.slug || this.slug
    }|${this.userId}`;
  }

  getVideoRestrictionEventBucket() {
    return `${this.videoData?._type || this.type}|${
      this.videoData?.slug || this.slug
    }`;
  }

  async isRestrictedCheck(restrictionId: string, playbackId: string) {
    this.isLoadingToken = true;
    try {
      const payload = {
        playbackId,
        thumbnailTimeSeconds: Math.round(this.thumbnailTime ?? 0),
      };
      const { data } = await this.$apollo.query({
        query: isQualified,
        variables: {
          id: restrictionId,
          payload,
        },
        fetchPolicy: 'network-only',
      });

      const isSuccess = isRestrictionSuccess(data.isQualified);
      if (isSuccess) {
        const reasonDetails =
          getFirstRestrictionSuccessResultByFilter<IMuxSuccessReasonDetails>(
            data.isQualified,
            'isMuxPlaybackId',
          );

        if (!reasonDetails) {
          return;
        }

        if (reasonDetails.isSigned === false) {
          this.isTokenRequired = false;
        }

        if (!!reasonDetails?.isSigned) {
          this.token = reasonDetails.token || '';
        }

        if (reasonDetails.statsToken) {
          this.statsToken = reasonDetails.statsToken;
        }

        if (reasonDetails.thumbnailToken) {
          this.thumbnailToken = reasonDetails.thumbnailToken;
        }
      } else {
        const reason = data?.isQualified?.reason;
        if (reason === 'INVALID_FILTER_CLAUSE' && !this.loggedIn) {
          this.goToLoginService();
        }
      }
    } catch (e) {}
    this.isLoadingToken = false;
  }

  get metadata() {
    const videoContentType =
      this.parentState?.data?._type && this.videoData?._type
        ? `${this.parentState.data._type}.${this.videoData._type}`
        : this.videoData?._type;
    const metadata = {
      ...this.videoMetadata,
      // video_series: '', // i.e. 'Season 1',
      video_title:
        this.getVideoTitle(this.videoData, this.parentState?.data) || '',
      video_content_type: videoContentType,
      video_language_code: 'en', // need to set this based on video language?
      viewer_user_id: this.email, // we should be using anonymized data here
    };

    if (this.videoAsset?.data?.duration) {
      metadata.video_duration = this.videoAsset.data.duration;
    }

    return metadata;
  }

  get options() {
    return {
      ...this.videoOptions,
      aspectRatio: this.videoAsset?.data?.aspect_ratio || '16:9',
    };
  }

  get playbackId() {
    return (
      (this.videoData as IVideoWithExpandedAsset)?.video?.asset?.playbackId ||
      (this.videoData as IVideoLiveStream)?.video?.playbackId ||
      undefined
    );
  }

  get playbackToken() {
    return this.token;
  }

  get posterImage() {
    return this.videoData?.poster || this.poster || undefined;
  }

  get posterImageUrl() {
    if (!this.posterImage) {
      return undefined;
    }

    const w = 1200;
    const h = Math.round(w * (9 / 16));
    return this.$sanityImage
      .urlFor(this.posterImage)
      .width(w)
      .height(h)
      .fit('crop')
      .url();
  }

  get thumbnailTime() {
    if (this.startTime > 0) {
      return this.startTime;
    }
    return this.videoAsset?.thumbTime || undefined;
  }

  get videoAsset() {
    return this.videoAssetState?.data;
  }

  get errorKey() {
    return !this.playbackId
      ? `missingPlaybackId`
      : this.isTokenRequired && !this.token
      ? `unauthorized`
      : `default`;
  }
}
