import {
  TAdaptor,
  IHydrateAdaptorPayload,
  IHydratedAdaptor,
  EAdaptors,
  ITrackPurchaseItemEventPayload,
  EEventTypes,
} from '../types';
import { v4 as uuidv4 } from 'uuid';
import hydrateAdaptor from '../hydrateAdaptor';
import {
  GalaAnalytics,
  GalEvents,
  PageViewDTO,
  SessionStartDTO,
  SessionEndDTO,
  PurchaseAttemptDTO,
  GalBaseDTO,
  RegisterDTO,
  ErrorDTO,
  LoginDTO,
  LogoutDTO,
  EmailVerificationCompleteDTO,
  EmailSignupDTO,
  TwoFactorSetupDTO,
  TwoFactorVerifyDTO,
  EmailVerificationSendDTO,
  WalletCreateDTO,
  GameplayBeginDTO,
  GameGiveAllowanceDTO,
  UninformedErrorDTO,
  LauncherDownloadStartDTO,
  PurchaseCompleteDTO,
  GameDownloadDTO,
  GamePayloadType,
  NavigateDTO,
  CheckoutFlowEnteredDTO,
  CurrencySelectorOpenedDTO,
  SelectedCurrencyChangedDTO,
  SelectedTransactionFeeChangedDTO,
  PressDTO,
  FTUEDTO,
  GameEventDTO,
} from '@gala-analytics/core';

const CURRENCY_USD_SYMBOL = 'USD';
const ETH_PREFIX_REPLACE = '[ETH]';

import cryptocompareService from '~/services/cryptocompare.service';

export enum ClientDefinedSessionType {
  CheckoutSession = 'CheckoutSession',
}

const activeClientDefinedSessionIds: Record<string, string> = {};

const startNewClientDefinedSession = (
  sessionType: ClientDefinedSessionType,
) => {
  const sessionId = uuidv4();
  activeClientDefinedSessionIds[sessionType] = sessionId;
  return sessionId;
};

const endClientDefinedSession = (sessionType: ClientDefinedSessionType) => {
  delete activeClientDefinedSessionIds[sessionType];
};

const getSessionEventExtras = () => {
  return activeClientDefinedSessionIds;
};

const getFPEndpoint = (endpoints: string[]) => {
  return endpoints.find((endpoint) => {
    const domainExtension = endpoint.split('.')?.pop();

    return domainExtension
      ? window?.location?.hostname.endsWith(domainExtension)
      : endpoints[0];
  });
};

const unknownToString = (unknown: any): string => {
  if (typeof unknown === 'string') {
    return unknown;
  }

  if (unknown === undefined) {
    return unknown;
  }

  try {
    return JSON.stringify(unknown);
  } catch (err) {}

  try {
    return unknown.toString?.();
  } catch (err) {}

  return '';
};

const getErrorMessage = (err: any): string | undefined => {
  if (err?.message) {
    return err.message;
  }

  if (err?.error && typeof err.error === 'string') {
    return err.error;
  }

  if (err?.error && err?.error.message) {
    return err?.error.message;
  }

  if (err?.results) {
    const resultMessages = Object.values(err.results)
      .map((result: any) => result?.message ?? '')
      .filter((r) => r.length)
      .join(', ');
    if (resultMessages.length) {
      return resultMessages;
    }
  }

  if (err?.result?.message) {
    return err.result.message;
  }
};

const getErrorCode = (err: any, fallbackCode: string): string => {
  if (!err) {
    return '';
  }

  return err.code ? `${err?.code}` : fallbackCode;
};

const payloadToContext = (payload: any): Record<string, unknown> => {
  try {
    return JSON.parse(JSON.stringify(payload));
  } catch (err) {
    return {};
  }
};

const galaAnalyticsAdapter: TAdaptor = (adapterPayload) => {
  if (!adapterPayload?.config?.enabled) {
    return undefined;
  }

  let galaAnalytics: GalaAnalytics;

  const {
    publisherId,
    reportingEndpoint,
    publisherGroup,
    fpGamesEndpoint,
    fpComEndpoint,
    environment,
  } = adapterPayload.config;

  const {
    UNKNOWN_ERROR_CODE,
    UNKNOWN_MONETARY_VALUE,
    UNKNOWN_NUMBER_VALUE,
    UNKNOWN_STRING_VALUE,
  } = GalaAnalytics.constants;

  const { onError, getCurrentUserId } = adapterPayload;
  const events: IHydrateAdaptorPayload = {};

  // wrap with error handling, always set user
  const report = <T extends GalBaseDTO>(payload: T, beacon?: boolean) => {
    try {
      const payloadWithGlobalExtras = {
        ...payload,
        extra: {
          ...getSessionEventExtras(),
          ...payload.extra,
        },
      };
      const userId = getCurrentUserId();
      if (userId) {
        galaAnalytics?.setUserId(userId);
      }

      galaAnalytics?.report<T>(payloadWithGlobalExtras, beacon);
    } catch (err) {
      onError(err);
    }
  };

  /********************
    INITIALIZE 
   ********************/
  events.init = () => {
    const fpEndpoint = getFPEndpoint([fpComEndpoint, fpGamesEndpoint]);

    try {
      galaAnalytics = new GalaAnalytics({
        user: getCurrentUserId(),
        reportingUri: reportingEndpoint,
        publisherGroup,
        publisherId,
        fpEndpoint,
        environment,
      });

      report<SessionStartDTO>({
        type: GalEvents.SessionStartDTO,
      });
    } catch (err) {
      onError(err);
    }
  };

  events.close = () => {
    report<SessionEndDTO>({ type: GalEvents.SessionEndDTO });
  };

  /********************
    USER 
   ********************/

  const setUser = (userId: string) => {
    try {
      galaAnalytics?.setUserId(userId);
    } catch (err) {
      onError(err);
    }
  };

  events.trackSetUserEvent = ({ id }) => setUser(id);

  /********************
    AUTHENTICATION 
   ********************/

  events.trackLoginEvent = (payload) => {
    // since the event isn't passed an id currently,
    // attempt to pull from store as that's presumably set
    const currentUser = getCurrentUserId();

    if (currentUser) {
      setUser(currentUser);
    }

    report<LoginDTO>({
      type: GalEvents.LoginDTO,
      extra: { utmCampaign: payload.utmCampaign ?? null },
    });
  };

  events.trackLogoutEvent = () =>
    report<LogoutDTO>({
      type: GalEvents.LogoutDTO,
    });

  events.trackRegistrationCompleteEvent = (payload) => {
    setUser(payload.id);
    report<RegisterDTO>({
      type: GalEvents.RegisterDTO,
      language: payload.language,
      displayMode: payload.userAgentInfo?.displayMode,
      currency: payload.currency,
      communicationConsent: payload.communicationConsent,
      referral: unknownToString(payload.referralContext),
      extra: { utmCampaign: payload.referralContext.utm_campaign ?? null },
    });
  };

  events.trackRegistrationErrorEvent = (payload) =>
    report<ErrorDTO>({
      type: GalEvents.ErrorDTO,
      failedType: GalEvents.RegisterDTO,
      message: getErrorMessage(payload) ?? UNKNOWN_STRING_VALUE,
      code: getErrorCode(payload, EEventTypes.REGISTRATION_ERROR),
      context: payloadToContext(payload),
    });

  events.trackTwoFAVerificationCompleteEvent = () =>
    report<TwoFactorVerifyDTO>({
      type: GalEvents.TwoFactorVerifyDTO,
    });

  events.trackTwoFAVerificationErrorEvent = (payload) =>
    report<ErrorDTO>({
      type: GalEvents.ErrorDTO,
      failedType: GalEvents.TwoFactorVerifyDTO,
      message: getErrorMessage(payload) ?? UNKNOWN_STRING_VALUE,
      code: getErrorCode(payload, EEventTypes.TWO_FA_VERIFICATION_ERROR),
      context: payloadToContext(payload),
    });

  events.trackTwoFAVerificationInvalidCodeEvent = () =>
    report<ErrorDTO>({
      type: GalEvents.ErrorDTO,
      failedType: GalEvents.TwoFactorVerifyDTO,
      message: EEventTypes.TWO_FA_VERIFICATION_INVALID_CODE,
      code: UNKNOWN_ERROR_CODE,
    });

  events.trackTwoFASetupCompleteEvent = () =>
    report<TwoFactorSetupDTO>({
      type: GalEvents.TwoFactorSetupDTO,
    });

  events.trackFirstTimeUserExperienceEvent = (payload) =>
    report<FTUEDTO>({
      type: GalEvents.FTUEDTO,
      action: payload.userAction,
    });

  /********************
    PAGE 
   ********************/

  events.trackPageViewEvent = (payload) =>
    report<PageViewDTO>({
      type: GalEvents.PageViewDTO,
      page: payload.url,
      from: payload.referrer ?? UNKNOWN_STRING_VALUE,
      extra: { utmCampaign: payload.query.utm_campaign },
    });

  events.trackModalViewEvent = (payload) =>
    report<PageViewDTO>({
      type: GalEvents.PageViewDTO,
      page: window.location.href,
      extra: {
        modal: true,
        ...payload,
      },
    });

  events.trackNavigationEvent = (payload) =>
    report<NavigateDTO>({
      type: GalEvents.NavigateDTO,
      from: payload.from ?? UNKNOWN_STRING_VALUE,
      to: payload.to ?? UNKNOWN_STRING_VALUE,
    });

  /********************
    GENERIC INTERACTIONS 
   ********************/

  events.trackPress = (payload) =>
    report<PressDTO>({
      type: GalEvents.PressDTO,
      id: payload.interactionTargetId,
      context: payload.context,
    });

  /********************
    EMAIL 
   ********************/

  events.trackSendEmailVerificationEmailEvent = () =>
    report<EmailVerificationSendDTO>({
      type: GalEvents.EmailVerificationSendDTO,
    });

  events.trackEmailVerificationCompleteEvent = () =>
    report<EmailVerificationCompleteDTO>({
      type: GalEvents.EmailVerificationCompleteDTO,
    });

  events.trackEmailSignupCompleteEvent = () =>
    report<EmailSignupDTO>({
      type: GalEvents.EmailSignupDTO,
    });

  events.trackEmailSignupErrorEvent = (payload) =>
    report<ErrorDTO>({
      type: GalEvents.ErrorDTO,
      failedType: GalEvents.EmailSignupDTO,
      message: getErrorMessage(payload) ?? UNKNOWN_STRING_VALUE,
      code: getErrorCode(payload, EEventTypes.EMAIL_SIGNUP_ERROR),
      context: payloadToContext(payload),
    });

  /********************
    TRANSACTIONS 
   ********************/

  const trackPurchaseItem = async (
    input: ITrackPurchaseItemEventPayload,
  ): Promise<void> => {
    let usdPrice = 0;
    let usdTotal = '';

    try {
      const currency = input?.currency
        ?.replace(ETH_PREFIX_REPLACE, '')
        ?.toUpperCase();
      const usdConversion = await cryptocompareService.getPriceMulti(
        [currency],
        CURRENCY_USD_SYMBOL,
      );

      if (usdConversion?.[currency]?.[CURRENCY_USD_SYMBOL]) {
        usdPrice = usdConversion?.[currency]?.[CURRENCY_USD_SYMBOL].PRICE;
        if (input.total) {
          usdTotal = `${Number(input.total) * usdPrice}`;
        }
      }
    } catch (err) {
      onError(err);
    }

    report<PurchaseAttemptDTO>({
      type: GalEvents.PurchaseAttemptDTO,
      total: Number(input.total),
      currency: input.currency,
      productId: input.item?.productId ?? UNKNOWN_STRING_VALUE,
      quantity: input.item?.quantity ?? UNKNOWN_NUMBER_VALUE,
      unitPrice: input.item?.unitPrice ?? UNKNOWN_MONETARY_VALUE,
      transactionFee: input.item?.transactionFee ?? UNKNOWN_MONETARY_VALUE,
      totalUSDValue: usdTotal.length ? usdTotal : UNKNOWN_MONETARY_VALUE,
      currencyUSDValue: usdPrice ? `${usdPrice}` : UNKNOWN_MONETARY_VALUE,
    });
  };

  events.trackPurchaseGalaGoldEvent = trackPurchaseItem;
  events.trackPurchaseItemEvent = trackPurchaseItem;
  events.trackPurchaseNodeEvent = trackPurchaseItem;

  events.trackPurchaseErrorEvent = (payload) =>
    report<ErrorDTO>({
      type: GalEvents.ErrorDTO,
      failedType: GalEvents.PurchaseFulfillDTO,
      message: getErrorMessage(payload) ?? UNKNOWN_STRING_VALUE,
      code: getErrorCode(payload, EEventTypes.PURCHASE_ERROR),
      context: payloadToContext(payload),
    });

  events.trackPurchaseCompleteEvent = (payload) => {
    if (payload?.isSuccessful) {
      report<PurchaseCompleteDTO>({
        type: GalEvents.PurchaseCompleteDTO,
        transactionId: payload.paymentHash,
      });
    }
  };

  events.trackCheckoutFlowEnteredEvent = (payload) => {
    startNewClientDefinedSession(ClientDefinedSessionType.CheckoutSession);

    report<CheckoutFlowEnteredDTO>({
      type: GalEvents.CheckoutFlowEnteredDTO,
      currency: payload.currency,
      productId: payload.productId,
      unitPrice: payload.unitPrice,
    });
  };

  events.trackCheckoutFlowExitedEvent = () => {
    endClientDefinedSession(ClientDefinedSessionType.CheckoutSession);
  };

  events.trackCurrencySelectorEnteredEvent = (payload) => {
    report<CurrencySelectorOpenedDTO>({
      type: GalEvents.CurrencySelectorOpenedDTO,
      selectedCurrency: payload.selectedCurrency,
      currencyOptions: payload.currencyOptions,
      productId: payload.productId,
    });
  };

  events.trackCurrencyChangedEvent = (payload) => {
    report<SelectedCurrencyChangedDTO>({
      type: GalEvents.SelectedCurrencyChangedDTO,
      previousSelectedCurrency: payload.previousSelection,
      newSelectedCurrency: payload.newSelection,
      productId: payload.productId,
    });
  };

  events.trackTransactionFeeSliderMovedEvent = (payload) => {
    report<SelectedTransactionFeeChangedDTO>({
      type: GalEvents.SelectedTransactionFeeChangedDTO,
      previousValue: payload.previousSelection,
      newValue: payload.newSelection,
    });
  };

  /********************
    WALLET 
   ********************/

  events.trackWalletCreationCompleteEvent = () =>
    report<WalletCreateDTO>({
      type: GalEvents.WalletCreateDTO,
    });

  events.trackWalletCreationErrorEvent = (payload) =>
    report<ErrorDTO>({
      type: GalEvents.ErrorDTO,
      failedType: GalEvents.WalletCreateDTO,
      message: getErrorMessage(payload) ?? UNKNOWN_STRING_VALUE,
      code: getErrorCode(payload, EEventTypes.WALLET_CREATION_ERROR),
      context: payloadToContext(payload),
    });

  /********************
    GAME 
   ********************/

  events.trackApkDownloadEvent = (payload) =>
    report<GameDownloadDTO>({
      type: GalEvents.GameDownloadDTO,
      game: payload.game,
      payloadType: GamePayloadType.APK,
    });

  events.trackGamePlayBeginEvent = (payload) =>
    report<GameplayBeginDTO>({
      type: GalEvents.GameplayBeginDTO,
      game: unknownToString(payload.game),
      platform: payload.platform,
      launcherVersion: payload.launcherCurrentVersion ?? UNKNOWN_STRING_VALUE,
    });

  events.trackGameGiveAllowanceCompleteEvent = (payload) =>
    report<GameGiveAllowanceDTO>({
      type: GalEvents.GameGiveAllowanceDTO,
      game: unknownToString(payload.game),
      platform: payload.platform,
      metadata: {
        message: payload.message ?? UNKNOWN_STRING_VALUE,
      },
    });

  events.trackGameGiveAllowanceErrorEvent = (payload) =>
    report<ErrorDTO>({
      type: GalEvents.ErrorDTO,
      failedType: GalEvents.GameGiveAllowanceDTO,
      message: getErrorMessage(payload) ?? UNKNOWN_STRING_VALUE,
      code: getErrorCode(payload, EEventTypes.GAME_GIVE_ALLOWANCE_ERROR),
      context: payloadToContext(payload),
    });

  events.trackGameActionErrorEvent = (payload) =>
    report<UninformedErrorDTO>({
      type: GalEvents.UninformedErrorDTO,
      message: getErrorMessage(payload) ?? UNKNOWN_STRING_VALUE,
      code: getErrorCode(payload, EEventTypes.GAME_ACTION_ERROR),
      context: payloadToContext(payload),
    });

  events.trackLauncherInitialDownloadBeginEvent = (payload) =>
    report<LauncherDownloadStartDTO>({
      type: GalEvents.LauncherDownloadStartDTO,
      lastConnected: unknownToString(payload.lastConnected),
      currentVersion: payload.currentVersion ?? undefined,
    });

  /********************
  VIDEO 
  ********************/

  events.trackVideoWatchEvent = (payload) =>
    report<GameEventDTO>({
      type: GalEvents.GameEventDTO,
      subeventName: payload.videoWatchEventType,
      extra: {
        videoSlug: payload.videoSlug,
        videoCurrentTimeSeconds: payload.videoCurrentTimeSeconds,
      },
    });

  const finalAdaptor: IHydratedAdaptor = hydrateAdaptor(events);

  return finalAdaptor;
};

galaAnalyticsAdapter.NAME = EAdaptors.GALA_ANALYTICS;

export default galaAnalyticsAdapter;
