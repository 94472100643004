import { Component, Prop, Vue } from 'vue-property-decorator';
import { TSanityBlockContent, TSanityBlockSerializers } from '~/types/sanity';
import SanityCta from '~/components/Sanity/serializers/SanityCta.vue';
import SanityLink from '~/components/Sanity/serializers/SanityLink.vue';
import SanityInlineBlock from '~/components/Sanity/serializers/SanityInlineBlock.vue';
import SanityIframeSerializer from '~/components/Sanity/serializers/SanityIframeSerializer.vue';
import SanityImageSerializer from '~/components/Sanity/serializers/SanityImageSerializer.vue';
import SanityTypography from '~/components/Sanity/serializers/SanityTypography.vue';
import Underline from '~/components/Sanity/serializers/DecoratorUnderline.vue';
import Striked from '~/components/Sanity/serializers/DecoratorStriked.vue';
import SanityMediaSlideshow from '~/components/Sanity/SanityMediaSlideshow.vue';
import SanityVideoSerializer from '~/components/Sanity/serializers/SanityMuxVideoSerializer.vue';

@Component
export default class SanityBlockContent extends Vue {
  @Prop(Boolean)
  readonly inlineOnly!: boolean;
  @Prop(Boolean)
  readonly nonInteractive!: boolean;
  @Prop(Array)
  readonly blocks!: TSanityBlockContent;
  @Prop(Object)
  readonly serializers!: TSanityBlockSerializers;

  get mergedSerializers() {
    return {
      ...this.serializers,
      styles: {
        ...this.serializers?.styles,
        normal: this.inlineOnly ? SanityInlineBlock : 'p',
      },
      marks: {
        ...this.serializers?.marks,
        underline: Underline,
        'strike-through': Striked,
        link: this.nonInteractive ? 'span' : SanityLink,
        cta: this.nonInteractive ? 'span' : SanityCta,
        typography: SanityTypography,
      },
      types: {
        iframe: SanityIframeSerializer,
        image: SanityImageSerializer,
        video: SanityVideoSerializer,
        readMoreDivider: null,
        mediaSlideshow: SanityMediaSlideshow,
        ...this.serializers?.types,
      },
    };
  }
}
