import { KeyedObject, TypedObject } from '@sanity/types';
import { SanityContent } from '@nuxtjs/sanity/dist/components/sanity-content';

export enum SanityDataset {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export type TSanityFieldTypeSingle =
  | string
  | number
  | boolean
  | TypedObject
  | KeyedObject;
export type TSanityField = TSanityFieldTypeSingle | TSanityFieldTypeSingle[];

export type TSanityBlockContent = InstanceType<typeof SanityContent>['blocks'];

export type TSanityBlockSerializers = InstanceType<
  typeof SanityContent
>['serializers'];
