
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { CopyToClipboard } from '~/mixins';

@Component({})
export default class QuickWalletAddress extends Mixins(CopyToClipboard) {
  @Prop(String)
  readonly ethWalletAddress!: string;
  @Prop(String)
  readonly gyriWalletAddress!: string;

  truncatedAddress(walletAddress: string | null) {
    const address = walletAddress;
    if (address) {
      const string = `${address.slice(0, 12)}....${address.substr(
        address.length - 4,
        address.length,
      )}`;

      return string;
    }

    return '';
  }
}
