import { ActionTree } from 'vuex';
import { IMyTheaterState } from './types';
import { IRootState } from '../types';
import { ITheaterRewardDetails, ITheaterScreenNode } from '~/types/my-theater';
import { IRewardDistributionCounts } from '~/types/rewards';

export const actions: ActionTree<IMyTheaterState, IRootState> = {
  async getScreenNodes({ commit, dispatch, rootState }) {
    const screenNodes: ITheaterScreenNode[] = rootState.profile?.user.loggedIn
      ? await this.$filmApiService.get<ITheaterScreenNode[]>(
          'my-theater/screen-nodes',
        )
      : [];
    commit('updateScreenNodes', screenNodes);

    dispatch('getRewardSummary');
    dispatch('getRewardDetails');
  },
  async getRewardSummary({ commit, rootState }) {
    const rewardDistributionCounts: IRewardDistributionCounts | null = rootState
      .profile?.user.loggedIn
      ? await this.$filmApiService.get<IRewardDistributionCounts>(
          'my-theater/reward-summary',
        )
      : null;
    commit('updateTheaterRewardSummary', rewardDistributionCounts);
  },
  async getRewardDetails({ commit, rootState }) {
    const theaterRewardDetails: ITheaterRewardDetails | null = rootState.profile
      ?.user.loggedIn
      ? await this.$filmApiService.get<ITheaterRewardDetails>(
          'my-theater/reward-details',
        )
      : null;

    commit(
      'updateDailyRewardDistributions',
      theaterRewardDetails?.dailyRewardDistributions ?? [],
    );
    commit(
      'updateTotalRewardDistributions',
      theaterRewardDetails?.totalRewardDistributions ?? [],
    );
  },
};
