
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import VerifyEmailAddressButton from './VerifyEmailAddressButton.vue';
import sendVerifyEmailMutation from '~/mutations/sendVerifyEmail.gql';
import shouldNag, { setTimeModalWasDismissed } from '~/utils/nagModalUtils';
import { IUser } from '~/store/profile/types';

@Component({
  components: { FullScreenDialog, VerifyEmailAddressButton },
})
export default class VerifyEmailAddress extends Vue {
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State((profile) => profile.user.emailVerified, { namespace: 'profile' })
  emailVerified!: boolean;
  @State((profile) => profile.user.email, { namespace: 'profile' })
  email!: boolean;
  @State((profile) => profile.user, { namespace: 'profile' })
  currentUser!: IUser;

  modalControl = true;
  sending = false;
  sent = false;
  sendError = '';
  isUserSettingUp2FA = true;
  modalClosedMarker = 'verifyEmailModalClosed';

  get showModal() {
    const timestampRecordPrefix = this.currentUser?.id;
    const shouldShowNagModal =
      timestampRecordPrefix &&
      shouldNag(timestampRecordPrefix, this.modalClosedMarker);

    return (
      this.modalControl &&
      this.loggedIn &&
      this.email &&
      !this.emailVerified &&
      this.emailVerified !== undefined &&
      this.$route.name !== 'login' &&
      this.$route.name !== 'register' &&
      !this.isUserSettingUp2FA &&
      shouldShowNagModal
    );
  }

  created() {
    if (this.$route.query.component !== 'TwoFa') {
      this.isUserSettingUp2FA = false;
    }
  }

  async handleSendVerificationEmail() {
    try {
      this.sending = true;
      const { data } = await this.$apollo.mutate({
        mutation: sendVerifyEmailMutation,
        variables: { platform: 'film' },
      });

      this.sending = false;

      if (data.sendVerifyEmail && data.sendVerifyEmail.success) {
        // UA tracking
        this.$ua.trackSendEmailVerificationEmailEvent();

        this.sent = true;
      } else {
        throw new Error('failed to save acceptance');
      }
    } catch (error) {
      this.$sentry.captureException(error);
      this.sending = false;
      this.sendError = this.$t(
        'components.emailVerification.verifyEmailAddress.sendError',
      ) as string;
    }
  }

  handleUpdateEmail() {
    this.modalControl = false;
    this.$router.push('/account?settings=email');
  }

  @Watch('modalControl')
  resetState(val: boolean, oldVal: boolean) {
    if (oldVal === true && val === false) {
      this.sending = false;
      this.sent = false;
      this.sendError = '';

      if (this.currentUser?.id) {
        setTimeModalWasDismissed(this.currentUser.id, this.modalClosedMarker);
      }
    }
  }
}
