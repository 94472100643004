
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class Checkbox extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly value!: boolean;
  @Prop({ type: String, default: 'accentPurple' })
  readonly color!: string;

  get localValue() {
    return this.value;
  }

  set localValue(isChecked: boolean) {
    this.$emit('input', isChecked);
  }
}
