
import { LocaleObject } from '@nuxtjs/i18n';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import CountryFlag from 'vue-country-flag';
import SortDrawer from '~/components/SortDrawer.vue';
import BottomDrawerDialog from '~/components/UIComponents/BottomDrawerDialog.vue';
import LanguageSelect from '~/components/LanguageSwitcher/LanguageSelect.vue';
import {
  availableLocales as getAvailableLocales,
  DEFAULT_LOCALE,
  getCurrentLocale,
} from '~/utils/locales';
import { ThemeName } from '~/store/types';

@Component({
  components: { SortDrawer, BottomDrawerDialog, CountryFlag, LanguageSelect },
})
export default class LanguageSwitcher extends Vue {
  @Prop(String) readonly ariaLabel!: string;
  @Prop(String) readonly label!: string;
  @Prop(String) readonly theme!: ThemeName;

  @Action('getGameTextByLocale', { namespace: 'games' })
  private getGameTextByLocale!: () => void;

  selectedLocale = DEFAULT_LOCALE;
  themes = ThemeName;

  created() {
    const localeInfo = {
      localeCookie: this.$i18n.getLocaleCookie(),
      browserLocale: this.$i18n.getBrowserLocale(),
      locales: this.$i18n.locales,
    };
    this.selectedLocale = getCurrentLocale(localeInfo);
  }

  get availableLocales() {
    return getAvailableLocales(this.$i18n.locales as LocaleObject[]).map(
      ({ code, displayText, flagCode, name }) => ({
        code,
        displayText,
        flagCode,
        name,
      }),
    );
  }

  handleLanguageSelected(locale: LocaleObject) {
    this.$i18n.setLocale(locale.code);
    setTimeout(() => {
      const localeInfo = {
        localeCookie: this.$i18n.getLocaleCookie(),
        browserLocale: this.$i18n.getBrowserLocale(),
        locales: this.$i18n.locales,
      };
      this.selectedLocale = getCurrentLocale(localeInfo);
      // getGameTextByLocale updates the language of game info text that's stored in state
      this.getGameTextByLocale();
    }, 200);
  }
}
