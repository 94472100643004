import { Plugin } from '@nuxt/types';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import {
  SanityImageSource,
  SanityProjectDetails,
} from '@sanity/image-url/lib/types/types';
import { getImageProps, IConfig } from '~/utils/sanityImage';

export interface ISanityImageBuilder {
  builder: ImageUrlBuilder;
  urlFor: (source: SanityImageSource) => ImageUrlBuilder;
  imageProps: (config: IConfig) => ReturnType<typeof getImageProps>;
}

declare module 'vue/types/vue' {
  // tslint:disable-next-line: interface-name
  interface Vue {
    $sanityImage: ISanityImageBuilder;
  }
}

declare module '@nuxt/types' {
  // tslint:disable-next-line: interface-name
  interface NuxtAppOptions {
    $sanityImage: ISanityImageBuilder;
  }

  // tslint:disable-next-line: interface-name
  interface Context {
    $sanityImage: ISanityImageBuilder;
  }
}

declare module 'vuex/types/index' {
  // tslint:disable-next-line: interface-name
  interface Store<S> {
    $sanityImage: ISanityImageBuilder;
  }
}

export const createBuilder = (config: SanityProjectDetails) => {
  return imageUrlBuilder(config);
};

export const getUrlFor = (builder: ImageUrlBuilder) => {
  return (source: SanityImageSource) => {
    return builder.image(source).auto('format');
  };
};

const sanityImageBuilderPlugin: Plugin = (ctx, inject) => {
  const builder = createBuilder(ctx.app.$sanity.config as SanityProjectDetails);
  const urlFor = getUrlFor(builder);
  const imageProps = (config: IConfig) => getImageProps(config, builder);
  inject('sanityImage', { builder, urlFor, imageProps });
};

export default sanityImageBuilderPlugin;
