import { computed, Ref, watch } from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import { SanityDocument } from '@sanity/client';
import { IGetDocParams } from '~/plugins/sanityLookup/types';

export const useSanityDocument = <TDoc extends SanityDocument = SanityDocument>(
  params: Ref<IGetDocParams>,
  withReferences = false,
) => {
  const { $sanityLookup } = useContext();
  const docState = computed(() =>
    $sanityLookup.getDocument<TDoc>({
      _id: params.value._id,
      _type: params.value._type,
      slug: params.value.slug,
    }),
  );

  const fetchDocument = () =>
    $sanityLookup.fetchDocument({
      _id: params.value._id,
      _type: params.value._type,
      slug: params.value.slug,
      fetchReferences: withReferences,
    });

  watch(
    params,
    (newParams, oldParams) => {
      if (!newParams._type && !(newParams._id || newParams.slug)) {
        return;
      }

      if (
        newParams._id !== oldParams?._id ||
        newParams._type !== oldParams?._type ||
        newParams.slug !== oldParams?.slug
      ) {
        fetchDocument();
      }
    },
    { immediate: true },
  );

  return {
    doc: computed(() => docState.value?.data),
    error: computed(() => docState.value?.error),
    hasError: computed(() => docState.value?.hasError),
    isLoading: computed(() => docState.value?.isLoading),
    fetchDocument,
  };
};
