import { Plugin } from '@nuxt/types';
import { RestrictionsService } from './restrictionsService';

export type IRestrictionsServiceLoader = InstanceType<
  typeof RestrictionsService
>;

declare module 'vue/types/vue' {
  // tslint:disable-next-line: interface-name
  interface Vue {
    $restrictionsService: IRestrictionsServiceLoader;
  }
}

declare module '@nuxt/types' {
  // tslint:disable-next-line: interface-name
  interface NuxtAppOptions {
    $restrictionsService: IRestrictionsServiceLoader;
  }

  // tslint:disable-next-line: interface-name
  interface Context {
    $restrictionsService: IRestrictionsServiceLoader;
  }
}

declare module 'vuex/types/index' {
  // tslint:disable-next-line: interface-name
  interface Store<S> {
    $restrictionsService: IRestrictionsServiceLoader;
  }
}

const restrictionsServicePlugin: Plugin = (ctx, inject) => {
  const plugin = new RestrictionsService(ctx);
  inject('restrictionsService', plugin);
};

export default restrictionsServicePlugin;
