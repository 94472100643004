import { MutationTree } from 'vuex';
import { IReferencesState, TReferencePayloads } from './types';

export const mutations: MutationTree<IReferencesState> = {
  clearCache(state, payload: TReferencePayloads['clearCache']) {
    if (payload.key) {
      const { [payload.key]: removedItem, ...restOfRefs } = state.references;
      state.references = restOfRefs;
    } else {
      state.references = {};
    }
  },

  updateReferences(state, payload: TReferencePayloads['updateReferences']) {
    if (payload.references) {
      state.references = {
        ...state.references,
        ...payload.references,
      };
    }
  },
};
