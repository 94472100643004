// temp file for helper function to get stores with composition api

import { useStore as useVuexStore } from '@nuxtjs/composition-api';
import { computed } from 'vue';
import { TSiteConfigState } from './types';
import { IAppStates } from '~/types/vuex-state';

export const useStore = () => useVuexStore<IAppStates>();
export const useSiteConfigState = () => {
  const store = useStore();
  return computed<TSiteConfigState>(() => store.getters['siteConfig']);
};
