
import { Component, mixins, Prop, State } from 'nuxt-property-decorator';
import { IProfileState } from '~/store/profile/types';
import { TextButton } from '~/components/UIComponents/FormElements';
import FullScreenError from '~/components/UIComponents/FullScreenError.vue';
import AuthActions from '~/mixins/AuthActions';

@Component({
  components: {
    FullScreenError,
    TextButton,
  },
})
export default class VideoPlayerLoading extends mixins(AuthActions) {
  @Prop(String) readonly errorKey!: string | number;
  @Prop(String) readonly poster!: string;

  @State((profile: IProfileState) => profile.user.loggedIn, {
    namespace: 'profile',
  })
  loggedIn!: boolean;

  goToLoginService() {
    this.promptToLogin({
      redirectAfterLoginPath: this.$route.fullPath,
    });
  }

  get errorText() {
    const errorKeyPath = `components.player.videoPlayer.error.${this.errorKey}`;
    const defaultPath = `components.player.videoPlayer.error.default`;
    return {
      title: this.$te(`${errorKeyPath}.title`)
        ? this.$t(`${errorKeyPath}.title`)
        : this.$t(`${defaultPath}.title`),
      message: this.$te(`${errorKeyPath}.message`)
        ? this.$t(`${errorKeyPath}.message`)
        : this.$t(`${defaultPath}.message`),
    };
  }
}
