import { Plugin } from '@nuxt/types';
import { WalletHelper } from './walletHelper';

export type IWalletHelper = InstanceType<typeof WalletHelper>;

declare module 'vue/types/vue' {
  // tslint:disable-next-line: interface-name
  interface Vue {
    $walletHelper: IWalletHelper;
  }
}

declare module '@nuxt/types' {
  // tslint:disable-next-line: interface-name
  interface NuxtAppOptions {
    $walletHelper: IWalletHelper;
  }

  // tslint:disable-next-line: interface-name
  interface Context {
    $walletHelper: IWalletHelper;
  }
}

declare module 'vuex/types/index' {
  // tslint:disable-next-line: interface-name
  interface Store<S> {
    $walletHelper: IWalletHelper;
  }
}

const walletHelperPlugin: Plugin = (ctx, inject) => {
  const plugin = new WalletHelper(ctx.store, ctx.app.i18n);
  inject('walletHelper', plugin);
};

export default walletHelperPlugin;
