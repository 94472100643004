
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Swiper, { A11y, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { Reference } from '@sanity/types';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import SanityBlockContent from '~/components/Sanity/SanityBlockContent.vue';
import SanityReference from '~/components/Sanity/SanityReference.vue';
import WatchVideoDialog from '~/components/Sanity/WatchVideoDialog.vue';
import ViewFigureDialog from '~/components/Sanity/ViewFigureDialog.vue';

@Component({
  components: {
    SanityBlockContent,
    SanityReference,
    ViewFigureDialog,
    WatchVideoDialog,
  },
})
export default class SanityMediaSlideshow extends Vue {
  @Prop({ type: Array, default: [] })
  readonly items!: Reference[];
  @Prop(String) readonly title!: string;
  @Prop({ type: Number, default: 1 })
  readonly aspectRatio!: number;
  @Prop({ type: Number, default: 280 })
  readonly width!: number;
  @Prop(Boolean)
  readonly pagination!: boolean;
  @Prop({ type: String, default: 'p' })
  readonly titleTag!: 'p' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  @Prop({ type: String, default: `font--title-medium mb-6` })
  readonly titleClass!: string;

  schemas = SanityDocumentSchema;

  async fetch() {
    const ids = this.items.map((ref) => ref._ref);
    if (ids.length) {
      this.$sanityLookup.fetchReference({
        _id: ids,
        _type: [this.schemas.VIDEO, this.schemas.FIGURE],
      });
    }
  }

  mounted() {
    const el = this.$refs?.swiperContainer as HTMLElement;
    if (!el) {
      return;
    }

    new Swiper(el, {
      slidesPerView: 'auto',
      modules: [A11y, Navigation, Pagination],
      navigation: {
        nextEl: `#${this.$id('next-btn')}`,
        prevEl: `#${this.$id('prev-btn')}`,
      },
      pagination: this.pagination
        ? {
            clickable: true,
            el: `#${this.$id('pagination')}`,
            type: 'bullets',
          }
        : false,
      a11y: {
        nextSlideMessage: this.$t(
          'components.films.filmMediaTab.nextSlide',
        ) as string,
        prevSlideMessage: this.$t(
          'components.films.filmMediaTab.prevSlide',
        ) as string,
        paginationBulletMessage: this.$t(
          'components.films.filmMediaTab.goToSlide',
          { i: '{{index}}' },
        ) as string,
        slideLabelMessage: this.$t('components.films.filmMediaTab.slideLabel', {
          current: '{{index}}',
          total: '{{slidesLength}}',
        }) as string,
      },
    });
  }
}
