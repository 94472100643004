import { MaybeRef } from '@vueuse/core';
import { useContext } from '@nuxtjs/composition-api';
import { computed, isRef, watch } from 'vue';
import { ISanityObjects, SanityObjectSchema } from '~/types/sanity-objects';

export const useSanityCta = (
  obj: MaybeRef<ISanityObjects[SanityObjectSchema.URL_OR_REFLINK] | undefined>,
) => {
  const urlOrReflink = computed(() => (isRef(obj) ? obj.value : obj));
  const { $sanityLookup } = useContext();

  const refDoc = computed(() =>
    urlOrReflink.value?.linkType === 'docRef' &&
    urlOrReflink.value?.documentReference?._ref
      ? $sanityLookup.getDocumentFromReference({
          _ref: urlOrReflink.value?.documentReference?._ref,
        })
      : undefined,
  );

  const href = computed(() => {
    if (urlOrReflink.value?.linkType === 'docRef') {
      const path = $sanityLookup.getPageRoutePath({
        slug: refDoc.value?.data?.slug,
        schema: refDoc.value?.data?._type ?? '',
      });

      if (!path) {
        return '';
      }

      const suffix =
        urlOrReflink.value?.reflinkSuffix &&
        urlOrReflink.value?.reflinkSuffix.match(/^#|\?|\//)
          ? urlOrReflink.value?.reflinkSuffix
          : '';

      return path + suffix;
    }

    return urlOrReflink.value?.href ?? '';
  });

  const isExternalLink = computed(() => {
    if (urlOrReflink.value?.linkType === 'docRef') {
      return false;
    }

    if (
      /^https?:\/\//.test(urlOrReflink.value?.href ?? '') ||
      /^(tel)|(mailto):/.test(urlOrReflink.value?.href ?? '')
    ) {
      return true;
    }

    return false;
  });

  const target = computed(() => {
    return urlOrReflink.value?.blank ? '_blank' : '_top';
  });

  watch(
    [
      () => urlOrReflink.value?.linkType,
      () => urlOrReflink.value?.documentReference?._ref,
    ],
    ([currentLinkType, currentRef]) => {
      if (currentLinkType !== 'docRef' || !currentRef) {
        return;
      }
      $sanityLookup.fetchReference({ _id: currentRef });
    },
    { immediate: true },
  );

  return {
    href,
    isExternalLink,
    isLoading: computed(() => !!refDoc.value?.isLoading),
    target,
  };
};
