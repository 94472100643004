
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { InputValidationRules } from 'vuetify/types';
import { IProfileState } from '~/store/profile/types';
import TextField from './TextField.vue';

@Component({
  components: { TextField },
  inheritAttrs: false,
})
export default class CurrencyInput extends Vue {
  @Prop([String, Number]) readonly inputValue!: string | number;
  @Prop({ type: Boolean, default: false }) readonly isInputFiat!: boolean;
  @Prop(Number) readonly maxAvailable?: number;
  @Prop(Number) readonly price?: number;
  @Prop({ type: Array, default: () => [] })
  readonly rules!: InputValidationRules;
  @Prop(String) readonly symbol!: string;

  value: string | number | null = null;
  isFiat: boolean = false;

  @State((profile: IProfileState) => profile.user.preferences.currency, {
    namespace: 'profile',
  })
  readonly currency!: string;

  created() {
    this.value = this.inputValue;
    this.isFiat = this.isInputFiat;
  }

  @Watch('inputValue')
  handleInputValueChange() {
    this.value = this.inputValue;
  }

  @Watch('value')
  handleValueChange(val: string | number) {
    this.$emit('update:inputValue', val);
  }

  @Watch('isFiat')
  handleIsFiatChange(val: boolean) {
    this.value = '';
    this.$emit('update:isInputFiat', val);
  }

  get passThroughProps() {
    const props = { ...this.$attrs, ...this.$props };
    ['isInputFiat', 'maxAvailable', 'price', 'symbol'].forEach(
      (key) => delete props[key],
    );
    return props;
  }

  get convertedAmount() {
    if (!this.price || !this.$options.filters) {
      return '';
    }

    if (!this.value) {
      return '0';
    }

    return this.isFiat
      ? this.$options.filters.cryptoFormat(+this.value / this.price)
      : this.$options.filters.fiatFormat(+this.value * this.price);
  }

  get inputHint() {
    if (!this.convertedAmount || !this.$options.filters) {
      return '';
    }

    return this.isFiat
      ? `${this.convertedAmount} ${this.$options.filters.filterSymbolText(
          this.symbol,
        )}`
      : this.$options.filters.localCurrency(
          this.$options.filters.sanitizeStringToNumber(this.convertedAmount),
          this.currency,
        );
  }

  get maxAvailableFiat() {
    if (this.maxAvailable === undefined || this.price === undefined) {
      return undefined;
    }

    return this.maxAvailable * this.price;
  }

  get validationRules() {
    const validationRules = [];
    if (this.maxAvailable !== undefined) {
      validationRules.push(this.maxValue);
    }
    if (this.$attrs.hasOwnProperty('required')) {
      validationRules.push(this.positiveValue);
    }
    return [...validationRules, ...this.rules];
  }

  handleSwapCurrency() {
    this.isFiat = !this.isFiat;
    this.$emit('swapped');
  }

  maxValue(val: string | number) {
    val = +val;
    const message = this.$t(
      'components.wallet.sendFunds.insufficientFundsMsg',
      {
        coin:
          this.$options?.filters?.filterSymbolText(this.symbol) ?? this.symbol,
      },
    );

    return this.isFiat
      ? val <= (this.maxAvailableFiat || 0) || message
      : val <= (this.maxAvailable || 0) || message;
  }

  positiveValue(val: string) {
    return +val > 0 || this.$t('components.wallet.sendFunds.positiveValue');
  }

  get currencySymbol() {
    if (!this.$options.filters) {
      return this.isFiat ? this.currency : this.symbol;
    }

    return this.$options.filters.filterSymbolText(
      this.isFiat ? this.currency : this.symbol,
    );
  }
}
