import { ActionTree } from 'vuex';
import {
  ICustomRevealState,
  IExchangeReward,
  EExchangeState,
  EVideoState,
} from './types';
import { IRootState } from '../types';

export const actions: ActionTree<ICustomRevealState, IRootState> = {
  async loadCard({ commit, state }, val: string) {
    if (!state.loaded.includes(val)) {
      commit('updateLoadedCards', val);
    }
  },
  async revealCard({ commit, state }, val: string) {
    if (!state.revealed.includes(val)) {
      commit('updateRevealedCards', val);
    }
  },
  async setTotalCards({ commit }, val: number) {
    commit('updateTotalCards', val);
  },
  async setRewards({ commit }, val: IExchangeReward[]) {
    commit('updateRewards', val);
  },
  async setShowCards({ commit }, val: boolean) {
    commit('updateShowCards', val);
  },
  async setExchangeState({ commit }, val: EExchangeState) {
    commit('updateExchangeState', val);
  },
  async setVideoBoxType({ commit }, val: EVideoState) {
    commit('updateVideoBoxType', val);
  },
  async setVideoState({ commit }, val: string) {
    commit('updateVideoState', val);
  },
  async setVideoReady({ commit }, val: boolean) {
    commit('updateVideoReady', val);
  },
  async setNewlyCompletedSets({ commit }, val: boolean) {
    commit('updateNewlyCompletedSets', val);
  },
  async setShowCompletedSets({ commit }, val: boolean) {
    commit('updateShowCompletedSets', val);
  },
  async resetCustomReveal({ commit }) {
    commit('$reset');
  },
};
