
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import AvatarIcon from '~/components/UIComponents/AvatarIcon.vue';

@Component({
  components: {
    AvatarIcon,
  },
})
export default class ProfileProgressIcon extends Vue {
  @Prop({ type: String, default: 'white' }) readonly backgroundColor!: string;
  @Prop({ type: String, default: 'var(--v-accentPurple-base)' })
  readonly borderColor!: string;
  @Prop({ type: String, default: 'var(--v-accentPurple-base)' })
  readonly color!: string;
  @Prop(String) readonly profilePhotoUrl!: number;
  @Prop({ type: Number, default: 40 }) readonly size!: number;

  get styles() {
    return {
      ['--g-profile-border']: this.borderColor,
      boxSizing: 'border-box',
      height: `${this.size}px`,
      width: `${this.size}px`,
    };
  }

  get iconSize() {
    return Math.round(this.size / 1.8);
  }
}
