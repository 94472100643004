
import {
  Action,
  Component,
  Emit,
  mixins,
  Prop,
  State,
} from 'nuxt-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import BottomDrawerDialog from '~/components/UIComponents/BottomDrawerDialog.vue';
import AuthActions from '~/mixins/AuthActions';
import { ICreditCardData, ICreditCards } from '~/store/profile/types';
import countriesJson from '~/utils/countries.json';
import territoriesJson from '~/utils/territories.json';
import checkCreditCardKeydown from '~/utils/checkCreditCardKeydown';

@Component({ components: { BottomDrawerDialog } })
export default class CreditCardEditor extends mixins(AuthActions) {
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;

  @Action('createCard', { namespace: 'profile' })
  private createCard!: (args: any) => {
    success: boolean;
    message: string;
    data: ICreditCards;
  };

  @Prop(String) readonly editorType!: string;
  @Prop({ type: Boolean, default: false })
  readonly isInModal!: string;

  creditCard: ICreditCardData = this.fixCreditCard(null);
  country: string = this.creditCard?.billingDetails?.country || '';
  optionsDrawer = false;
  cardNumber: string = '';
  cardCVV: string = this.creditCard?.cardDetails?.cvv || '';
  cardExpirey: string = this.getExpirey();
  creditCardName: string = '';
  idempotencyKey: string = uuidv4();
  countries = this.fixCountries(countriesJson);
  district: string = this.creditCard?.billingDetails?.district || '';
  districts = this.getDistricts();
  districtLabel = this.getDistrictLabel();
  availableDistricts = this.getAvailableDistricts(territoriesJson);
  errors: string[] = [];
  errorTypes: { [Key: string]: string } = {
    'Request failed with status code 400': 'unknown',
    unknown: 'unknown',
    badform: 'badform',
  };
  errorDefault = 'unknown';
  unverifiedFields: { [Key: string]: boolean } = {};
  isDisabled = false;
  mastercardIcon = require('@/assets/logos/mastercard-icon-trimmed.png');
  visaIcon = require('@/assets/logos/visa-icon-trimmed.png');

  resetForm() {
    this.clearError();
    this.idempotencyKey = uuidv4();
    if (this.editorType === 'add') {
      this.cardNumber = '';
      this.country = '';
      this.cardNumber = '';
      this.creditCard = this.fixCreditCard(null);
      this.cardCVV = this.creditCard?.cardDetails?.cvv || '';
      this.cardExpirey = this.getExpirey();
      this.creditCardName = '';
      this.district = this.creditCard?.billingDetails?.district || '';
    }
  }

  handleOpen() {
    if (!this.loggedIn) {
      this.promptToRegister({ redirectAfterLoginPath: this.$route.fullPath });
    } else {
      this.resetForm();
      this.optionsDrawer = !this.optionsDrawer;
    }
  }

  checkField(fieldValue: any, fieldName: string) {
    if (!fieldValue) {
      this.addUnverifiedField(fieldName);
    }
  }

  checkFullName(fieldValue: any, fieldName: string) {
    if (fieldValue.trim().split(' ').length < 2) {
      this.addUnverifiedField(fieldName);
    }
  }

  checkValidDate(fieldValue: any, fieldName: string) {
    if (fieldValue < new Date().getFullYear()) {
      this.addUnverifiedField(fieldName);
    }
  }

  checkValidCreditCardNumberInput(
    event: KeyboardEvent,
    inputLength: number,
    maxLength: number,
  ) {
    checkCreditCardKeydown(event, inputLength, maxLength);
  }

  verifyCard() {
    this.clearUnverifiedFields();
    this.checkField(this.creditCard.billingDetails.name, 'cardName');
    this.checkFullName(this.creditCard.billingDetails.name, 'cardName');
    this.checkField(this.creditCard.billingDetails.city, 'city');
    this.checkField(this.creditCard.billingDetails.country, 'country');
    this.checkField(this.creditCard.billingDetails.line1, 'addressLine1');
    this.checkField(this.creditCard.billingDetails.postalCode, 'postalCode');
    this.checkField(this.creditCard.cardDetails?.number, 'cardNumber');
    this.checkField(this.creditCard.cardDetails?.cvv, 'cvv');
    this.checkField(
      this.creditCard.expMonth && this.creditCard.expYear,
      'expirey',
    );
    this.checkValidDate(this.creditCard.expYear, 'expirey');
    return Object.keys(this.unverifiedFields).length === 0;
  }

  @Emit('saveCard')
  savedCard(creditCard: ICreditCards) {
    this.handleOpen();
    return creditCard;
  }

  clearError(errorName: string = '') {
    if (errorName) {
      this.errors = this.errors.filter((error) => error !== errorName);
    } else {
      this.errors = [];
      this.clearUnverifiedFields();
    }
  }

  clearUnverifiedFields(fieldName: string = '') {
    if (fieldName) {
      delete this.unverifiedFields[fieldName];
    } else {
      this.unverifiedFields = {};
    }
  }

  addUnverifiedField(fieldName: string) {
    this.unverifiedFields[fieldName] = true;
  }

  addError(errorName: string) {
    if (errorName in this.errorTypes) {
      errorName = this.errorTypes[errorName];
    } else {
      errorName = this.errorDefault;
    }
    if (!this.errors.includes(errorName)) {
      this.errors.push(errorName);
    }
    this.errors.sort();
  }

  async saveCard() {
    this.isDisabled = true;
    this.clearError();
    this.creditCard.idempotencyKey = this.idempotencyKey;
    if (this.creditCardName) {
      this.creditCard.nickname = this.creditCardName;
    } else {
      delete this.creditCard.nickname;
    }
    if (this.verifyCard()) {
      const result = await this.createCard(this.creditCard);
      if (!!result?.success) {
        this.savedCard(result.data);
      } else {
        this.addError('unknown');
      }
    } else {
      this.addError('badform');
    }
    this.isDisabled = false;
  }

  fixCountries(countries: { [key: string]: string }) {
    const result: Array<{ [key: string]: string }> = [];
    Object.keys(countries).map((abv: string) => {
      const country = this.$t(
        `components.wallet.editCreditCard.countries.${abv}`,
      ) as string;
      result.push({
        text: country ? country : countries[abv],
        value: abv,
      });
    });
    result.sort((a, b) => (a.value > b.value ? 1 : 0));
    this.districts = this.getDistricts();
    return result;
  }

  setCountry(country: string) {
    if (this.country !== country) {
      if (this.country) {
        this.setDistrict('');
      }
      this.country = country;
      this.districts = this.getDistricts();
      if (this.creditCard) {
        this.creditCard.billingDetails.country = country;
      }
    }
  }

  setDistrict(district: string) {
    this.district = district;
    if (this.creditCard) {
      this.creditCard.billingDetails.district = district;
    }
  }

  getAvailableDistricts(territories: {
    [key: string]: { [key: string]: string };
  }) {
    return this.country in territoriesJson ? territories[this.country] : {};
  }

  getDistricts() {
    const result: Array<{ [key: string]: string }> = [];
    this.districtLabel = this.getDistrictLabel();
    this.availableDistricts = this.getAvailableDistricts(territoriesJson);
    Object.keys(this.availableDistricts).map((abv) => {
      const district = this.$t(
        `components.wallet.editCreditCard.territories.${this.country}.${abv}`,
      ) as string;
      result.push({
        text: district ? district : this.availableDistricts[abv],
        value: abv,
      });
    });
    return result;
  }

  getDistrictLabel() {
    switch (this.country) {
      case 'CA': {
        return this.$t(
          `components.wallet.editCreditCard.placeholders.billingProvince`,
        ) as string;
      }
      case 'US': {
        return this.$t(
          `components.wallet.editCreditCard.placeholders.billingState`,
        ) as string;
      }
      default: {
        return this.$t(
          `components.wallet.editCreditCard.placeholders.billingTerritory`,
        ) as string;
      }
    }
  }

  getInitialCardNumber() {
    return this.creditCard?.last4
      ? `xxxx xxxx xxxx ${this.creditCard.last4}`
      : '';
  }

  setCardNumber(number: string) {
    const cardNumber = number.replace(/[^0-9.]/g, '').slice(0, 16);
    if (!this.creditCard?.cardDetails) {
      this.creditCard = this.fixCreditCard(null);
    }
    if (this.creditCard?.cardDetails) {
      this.creditCard.cardDetails.number =
        cardNumber.length === 16 ? cardNumber : '';
    }
    this.cardNumber = cardNumber.replace(/\d{4}(?=.)/g, '$& ').trim();
  }

  setExpirey(expirey: string) {
    const tempExpirey = expirey.replace(/[^0-9.]/g, '').slice(0, 4);
    const cardMonth = parseInt(tempExpirey.slice(0, 2));
    const cardYear = parseInt(tempExpirey.slice(2, 4));
    this.creditCard.expMonth = cardMonth > 12 ? 12 : cardMonth ? cardMonth : 0;
    this.creditCard.expYear = cardYear ? cardYear + 2000 : 0;
    this.cardExpirey = this.processExpirey(
      this.creditCard.expMonth,
      this.creditCard.expYear,
      expirey[0] === '0',
      expirey[3] === '0',
    );
  }

  getExpirey() {
    if (this.creditCard) {
      return this.processExpirey(
        this.creditCard.expMonth,
        this.creditCard.expYear,
      );
    }
    return '';
  }

  processExpirey(
    expMonth: number,
    expYear: number,
    startsWithZero: boolean = false,
    yearWithZero: boolean = false,
  ) {
    const processYear = expYear >= 2000 ? expYear - 2000 : expYear;
    if (expMonth) {
      const strMonth = `${startsWithZero ? '0' : ''}${expMonth}`;
      const strYear = `${yearWithZero ? '0' : ''}${
        processYear ? processYear : ''
      }`;
      if (strYear) {
        return `${strMonth}/${strYear}`;
      } else if (strMonth.length >= 2) {
        return `${strMonth}/`;
      } else {
        return `${strMonth}`;
      }
    }
    return startsWithZero ? '0' : '';
  }

  setCVV(cvv: string) {
    const cardCVV = cvv.replace(/[^0-9.]/g, '').slice(0, 3);
    if (!this.creditCard?.cardDetails) {
      this.creditCard = this.fixCreditCard(null);
    }
    if (this.creditCard?.cardDetails) {
      this.creditCard.cardDetails.cvv = cardCVV.length >= 3 ? cardCVV : '';
    }
    this.cardCVV = cardCVV;
  }

  fixCreditCard(creditCard: any = null) {
    if (creditCard === null) {
      creditCard = {
        expMonth: 0,
        expYear: 0,
        cardDetails: {
          number: '',
          cvv: '',
        },
        last4: '',
        billingDetails: {
          name: '',
          city: '',
          country: '',
          line1: '',
          line2: '',
          district: '',
          postalCode: '',
        },
      };
    }
    if (!creditCard.cardDetails) {
      creditCard.cardDetails = {
        number: '',
        cvv: '',
      };
    }
    if (!creditCard.billingDetails) {
      creditCard.billingDetails = {
        name: '',
        city: '',
        country: '',
        line1: '',
        line2: '',
        district: '',
        postalCode: '',
      };
    }
    if (!creditCard.last4) {
      creditCard.last4 = '';
    }
    const idempotencyKey = '';
    const sessionId = '';
    const email = '';
    const ipAddress = '';
    creditCard = {
      ...creditCard,
      idempotencyKey,
      metadata: {
        email,
        sessionId,
        ipAddress,
      },
    };
    this.country = creditCard.billingDetails.country;
    return creditCard;
  }
}
