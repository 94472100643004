import {
  IPlugin,
  ITrackPageViewEventPayload,
  EAdaptors,
  TOnError,
  IAdaptorPayload,
  IHydratedAdaptor,
  TGetCurrentUser,
  TGetCurrentUserId,
  TGetCurrentUserEmail,
  TGetCurrentDate,
  TGetCurrentISODate,
  TInstantiatedAdaptor,
} from './types';
import { Plugin } from '@nuxt/types';
import { NavigationGuard, Route } from 'vue-router';
import adaptors from './adaptors';
import { generateProxy, IGenerateProxyPayload } from './generateProxy';

declare module 'vue/types/vue' {
  // tslint:disable-next-line: interface-name
  interface Vue {
    $ua: IPlugin;
  }
}

declare module '@nuxt/types' {
  // tslint:disable-next-line: interface-name
  interface NuxtAppOptions {
    $ua: IPlugin;
  }

  // tslint:disable-next-line: interface-name
  interface Context {
    $ua: IPlugin;
  }
}

declare module 'vuex/types/index' {
  // tslint:disable-next-line: interface-name
  interface Store<S> {
    $ua: IPlugin;
  }
}

const getCurrentDate: TGetCurrentDate = () => new Date();
const getCurrentISODate: TGetCurrentISODate = () =>
  getCurrentDate().toISOString();

interface IAdaptorsConfig extends Record<EAdaptors, any> {}
const userAttributionPlugin: Plugin = (ctx, inject) => {
  const onError: TOnError = ctx.app.$sentry.captureException;
  const adaptorsConfig: IAdaptorsConfig =
    ctx.env.userAttributionPlugin.adaptors;
  const getCurrentUser: TGetCurrentUser = () => ctx.store.state?.profile.user;
  const getCurrentUserId: TGetCurrentUserId = () => getCurrentUser()?.id;
  const getCurrentUserEmail: TGetCurrentUserEmail = () =>
    getCurrentUser()?.email;

  // skip ua initialization if prerendering
  const isPrerender = navigator?.userAgent?.includes(
    'Prerender (+https://github.com/prerender/prerender)',
  );
  const hydratedAdaptors = isPrerender
    ? []
    : (adaptors
        .map((adaptor) => {
          const adaptorPayload: IAdaptorPayload = {
            config: adaptorsConfig[adaptor.NAME],
            onError,
            getCurrentUser,
            getCurrentUserId,
            getCurrentUserEmail,
            getCurrentDate,
            getCurrentISODate,
            ctx,
          };

          const instantiatedAdaptor: TInstantiatedAdaptor =
            adaptor(adaptorPayload);

          return instantiatedAdaptor;
        })
        .filter(Boolean) as IHydratedAdaptor[]);

  const generateProxyPayload: IGenerateProxyPayload = {
    hydratedAdaptors,
    onError,
  };

  const rootProxy: IPlugin = generateProxy(generateProxyPayload);

  rootProxy.init();
  inject('ua', rootProxy);
  document.onvisibilitychange = () => {
    if (document.visibilityState === 'hidden') {
      rootProxy.close();
    }
  };

  const { router } = ctx.store.app;
  if (router) {
    const beforeEachCallback: NavigationGuard = (to, from, next) => {
      const compileFullUrl = (route: Route): string => {
        const url = window?.location.origin + route.fullPath;
        const query = Object.entries(route.query ?? {})
          .map((e) => e.join('='))
          .join('&');
        const hash = route.hash ? `#${route.hash}` : ``;
        return query.length ? `${url}?${query}${hash}` : `${url}${hash}`;
      };

      const pageViewEventPayload: ITrackPageViewEventPayload = {
        url: window.location.origin + to.fullPath,
        fullPath: to.fullPath,
        path: to.path,
        name: to.name,
        query: to?.query,
        params: to?.params,
        hash: to?.hash,
        referrer: from ? compileFullUrl(from) : document?.referrer,
      };

      rootProxy.trackPageViewEvent(pageViewEventPayload);
      next();
    };

    router.beforeEach(beforeEachCallback);
  }
};

export default userAttributionPlugin;
