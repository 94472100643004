
import { LocaleObject } from '@nuxtjs/i18n';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ThemeName } from '~/store/types';
import LanguageSelectItem from './LanguageSelectItem.vue';

@Component({ components: { LanguageSelectItem } })
export default class LanguageSwitcher extends Vue {
  @Prop(String)
  readonly theme!: ThemeName;
  @Prop(Object)
  readonly initialValue!: LocaleObject;
  @Prop({ type: Array, default: [] })
  readonly locales!: LocaleObject[];

  showLanguagesDropdown = false;
  themes = ThemeName;
  selectedLocale: LocaleObject | undefined = undefined;

  created() {
    this.selectedLocale = this.initialValue;
  }
}
