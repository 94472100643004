import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { IProfileState } from './types';
import { IRootState } from '../types';

export const state: IProfileState = {
  user: {
    preferences: {
      currency: 'USD',
      language: 'english',
      notifications: true,
    },
    profilePhotoUrl: '',
    id: '',
    email: '',
    emailVerified: undefined,
    phone: '',
    firstName: '',
    lastName: '',
    fullNameNotProvided: true,
    displayName: '',
    role: '',
    permissions: [],
    walletConnected: null,
    walletExists: null,
    twoFaAuthenticated: false,
    twoFaEnabled: false,
    loggedIn: false,
    gameToken: '',
    goldMember: false,
    discordProfile: undefined,
    networkNotifications: {},
    referralCode: '',
    isInBlockedCountry: false,
    shouldBlockGameplay: false,
    filmDistributionRewards: [],
    creditCards: [],
    hasGyriPassphrase: false,
    auth0UserIdentities: [],
  },
  gameTokenMaxAge: 24 * 60 * 60 * 1000,
  gameTokenTimeSet: 0,
  activated: false,
  shareUrl: '',
  error: false,
  isFetching: false,
};

const namespaced = true;

const profile: Module<IProfileState, IRootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};

export default profile;
