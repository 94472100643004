import { MutationTree } from 'vuex';
import Vue from 'vue';
import {
  EExchangeState,
  EVideoState,
  ICustomRevealState,
  IExchangeReward,
  IHydratedSet,
} from './types';

export const mutations: MutationTree<ICustomRevealState> = {
  updateExchangeState(state, val: EExchangeState) {
    state.currentExchangeState = val;
  },
  updateVideoState(state, val: EVideoState) {
    state.currentVideoState = val;
  },
  updateVideoReady(state, val: boolean) {
    state.videoReady = val;
    Vue.set(state, 'videoReady', val);
  },
  updateVideoBoxType(state, val: string) {
    state.videoBoxType = val;
  },
  updateLoop(state, val: boolean) {
    state.shouldLoop = val;
  },
  updateTotalCards(state, val: number) {
    state.totalCards = val;
  },
  updateRewards(state, val: IExchangeReward[]) {
    state.rewards = val;
  },
  updateLoadedCards(state, item: string) {
    state.loaded.push(item);
  },
  updateRevealedCards(state, item: string) {
    state.revealed.push(item);
  },
  updateShowCards(state, value: boolean) {
    state.showCards = value;
  },
  updateNewlyCompletedSets(state, value: IHydratedSet[]) {
    state.newlyUnlockedSets = value;
  },
  updateShowCompletedSets(state, value: boolean) {
    state.showCompletedSets = value;
  },
  $reset(state) {
    state.loaded = [];
    state.revealed = [];
    state.showCards = false;
    state.currentVideoState = EVideoState.LOADING;
    state.currentExchangeState = EExchangeState.NOT_EXCHANGING;
    state.rewards = [];
    state.shouldLoop = false;
    state.totalCards = 0;
    state.videoReady = false;
    state.newlyUnlockedSets = [];
    state.showCompletedSets = false;
  },
};
