import { Context } from '@nuxt/types';

export default function (context: Context) {
  const { redirect, route } = context;
  let newRoute = '/';
  const queryParams = Object.entries({ ...route.query });

  if (route.name) {
    queryParams.unshift([route.name, '1']);
  }

  for (let i = 0; i < queryParams.length; i++) {
    const param = `${i === 0 ? '?' : '&'}${queryParams[i][0]}=${
      queryParams[i][1]
    }`;
    newRoute += param;
  }

  redirect(newRoute);
}
