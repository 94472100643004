var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-layout',{staticClass:"my-1",attrs:{"align-start":""}},[_c('Checkbox',{attrs:{"aria-label":_vm.agreementTextA11y ||
      _vm.agreementText ||
      _vm.$t(
        'components.legalAgreements.agreementStatement.defaultAgreementA11y',
        { name: _vm.documentName },
      ),"disabled":_vm.checkboxDisabled,"id":_vm.$id('agreement-statement'),"hide-details":"auto","color":_vm.checkboxColor},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}),_vm._v(" "),_c('div',{class:{
      'pt-1': true,
      'g-text-tertiary': _vm.checkboxDisabled,
      'g-text-primary': !_vm.checkboxDisabled,
    }},[_c('label',{attrs:{"for":_vm.$id('agreement-statement')}},[_vm._v("\n      "+_vm._s(_vm.agreementText ||
        _vm.$t('components.legalAgreements.agreementStatement.defaultAgreement'))+"\n    ")]),_vm._v(" "),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }