import {
  TAdaptor,
  TTrackEvent,
  TSetUser,
  IHydrateAdaptorPayload,
  IHydratedAdaptor,
  EAdaptors,
} from '../types';
import hydrateAdaptor from '../hydrateAdaptor';

type TLog = (name: string, payload: any) => void;
const log: TLog = (name, payload) => {
  const message =
    typeof payload === 'string' ? payload : JSON.stringify(payload);
  console.log(`##UA-DEBUG - ${name}`, message);
};

export const debugAdaptor: TAdaptor = (adaptorPayload) => {
  const { enabled } = adaptorPayload.config;
  const { getCurrentUserId, getCurrentISODate } = adaptorPayload;

  if (!enabled) {
    return undefined;
  }

  const setUser: TSetUser = (currentUser) => {
    log('setUser', { userId: currentUser.id, eventDate: getCurrentISODate() });
  };

  const trackEvent: TTrackEvent = (payload) => {
    const finalPayload = {
      ...payload,
      eventValue: {
        ...(payload.eventValue || {}),
      },
    };

    finalPayload.eventValue.eventDate = getCurrentISODate();

    const userId = getCurrentUserId();
    if (userId) {
      finalPayload.eventValue.userId = userId;
    }

    log('trackEvent', finalPayload);
  };

  const hydrateAdaptorPayload: IHydrateAdaptorPayload = {
    setUser,
    trackEvent,
  };

  const finalAdaptor: IHydratedAdaptor = hydrateAdaptor(hydrateAdaptorPayload);

  return finalAdaptor;
};

debugAdaptor.NAME = EAdaptors.DEBUG;

export default debugAdaptor;
