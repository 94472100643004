import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';

export enum EExchangeState {
  NOT_EXCHANGING = 'NOT_EXCHANGING',
  ENTER_PASSWORD = 'ENTER_PASSWORD',
  EXCHANGE_IN_FLIGHT = 'EXCHANGE_IN_FLIGHT',
  EXCHANGE_COMPLETE = 'EXCHANGE_COMPLETE',
}

export enum EVideoState {
  LOADING = 'loading',
  OPENING = 'opening',
  REVEALED = 'revealed',
}

export interface ICustomRevealState {
  currentVideoState: EVideoState;
  currentExchangeState: EExchangeState;
  videoBoxType: string;
  shouldLoop: boolean;
  totalCards: number;
  rewards: IExchangeReward[];
  loaded: string[];
  revealed: string[];
  showCards: boolean;
  videoReady: boolean;
  newlyUnlockedSets: IHydratedSet[];
  showCompletedSets: boolean;
}

export interface IExchangeReward {
  name: string;
  game: string;
  image: string;
  quantity: string;
  instance: string;
  rarityLabel: string;
  collection: string;
  category: string;
  type: string;
  additionalKey: string;
}

export interface ISetItem {
  gyriId: string;
  quantity: number;
}

export interface ISet {
  id: string;
  items: ISetItem[];
}

export interface IHydratedSet extends ISet {
  document?:
    | ISanityDocuments[SanityDocumentSchema.POSTER]
    | ISanityDocuments[SanityDocumentSchema.SCENE];
  redirectUrl: string;
}

export enum EUnlockableSets {
  'POSTER' = SanityDocumentSchema.POSTER,
  'SCENE' = SanityDocumentSchema.SCENE,
}

export enum ERestrictionType {
  'COMPLETED_SET' = 'completed_sets',
  'COMPLETED_SET_TEST' = 'COMPLETION_TEST_rzr_2',
  'HAS_MYSTERY_BOXES_TO_OPEN' = 'HAS_MYSTERY_BOXES_TO_OPEN',
}
