import { render, staticRenderFns } from "./LanguageSelect.vue?vue&type=template&id=8e4aaf16&scoped=true"
import script from "./LanguageSelect.vue?vue&type=script&lang=ts"
export * from "./LanguageSelect.vue?vue&type=script&lang=ts"
import style0 from "./LanguageSelect.vue?vue&type=style&index=0&id=8e4aaf16&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e4aaf16",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSelectItem: require('/builds/gala-games/gala-film/gala-film/src/components/LanguageSwitcher/LanguageSelectItem.vue').default})
