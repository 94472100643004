export enum IntervalState {
  IDLE = 'IDLE',
  RUNNING = 'RUNNING',
  PAUSED = 'PAUSED',
  RESUMED = 'RESUMED',
}

export class IntervalTimer {
  timerId: number | undefined;
  startTime = 0;
  remaining = 0;
  state: IntervalState = IntervalState.IDLE;
  callback: () => void;
  interval: number;

  constructor(callback: () => void, interval: number) {
    this.callback = callback;
    this.interval = interval;
  }

  public reset() {
    this.clear();
    this.startTime = Date.now();
    this.timerId = window.setInterval(() => this.callback(), this.interval);
    this.state = IntervalState.RUNNING;
  }

  public pause() {
    if (this.state !== IntervalState.RUNNING) {
      return;
    }

    this.remaining = this.interval - (Date.now() - this.startTime);
    window.clearInterval(this.timerId);
    this.state = IntervalState.PAUSED;
  }

  public resume() {
    if (this.state === IntervalState.IDLE) {
      this.reset();
    }
    if (this.state !== IntervalState.PAUSED) {
      return;
    }

    this.state = IntervalState.RESUMED;
    window.setTimeout(() => this.timeoutCallback(), this.remaining);
  }

  private timeoutCallback() {
    if (this.state !== IntervalState.RESUMED) {
      return;
    }

    this.callback();

    this.startTime = Date.now();
    this.timerId = window.setInterval(() => this.callback(), this.interval);
    this.state = IntervalState.RUNNING;
  }

  public clear() {
    this.state = IntervalState.IDLE;
    window.clearInterval(this.timerId);
    this.timerId = undefined;
  }
}
