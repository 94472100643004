import {
  TAdaptor,
  IHydrateAdaptorPayload,
  IHydratedAdaptor,
  EAdaptors,
  TInit,
  TTrackEvent,
  EEventTypes,
  TTRACKED_EVENTS,
  TSetUser,
  ITrackPurchaseItemEventPayload,
} from '../types';
import hydrateAdaptor from '../hydrateAdaptor';
import { getCookie } from '../../../utils';
import { getUrlParam } from '../../../utils/getUrlParam';
import { formatFbclid } from '../../../utils/formatFbclid';
import { setFbcCookie } from '../../../utils/setFbcCookie';
import { hashData } from '../../../utils/hashData';

const TRACKED_EVENTS: TTRACKED_EVENTS = {
  [EEventTypes.REGISTRATION_COMPLETE]: true,
  [EEventTypes.PURCHASE_ITEM]: true,
  [EEventTypes.PURCHASE_NODE]: true,
  [EEventTypes.SET_USER]: true,
};

export const metaAdaptor: TAdaptor = (adaptorPayload) => {
  const { enabled, accountId } = adaptorPayload.config;

  if (!enabled || !accountId) {
    return undefined;
  }

  const fetchFbclid = () => {
    const currentFblcid = getUrlParam('fbclid');
    if (currentFblcid) {
      const newOne = formatFbclid(currentFblcid);
      setFbcCookie(newOne);
      return newOne;
    }

    // check if we have a previous one stored
    const fbclid = getCookie('_fbc');
    return fbclid ?? '';
  };

  const fetchFbp = () => {
    return getCookie('_fbp') ?? '';
  };

  const { getCurrentUserEmail, getCurrentUserId } = adaptorPayload;

  const getExtraParams = () => {
    const ret: Record<string, string> = {};

    const fbclid = fetchFbclid();
    const fbp = fetchFbp();
    const userId = getCurrentUserId();
    const email = getCurrentUserEmail();

    if (fbclid) {
      ret.fbclid = fbclid;
    }
    if (fbp) {
      ret.fbp = fbp;
    }
    if (userId) {
      ret.external_id = userId;
    }
    if (email) {
      ret.em = hashData(email.trim().toLowerCase());
    }

    return ret;
  };

  const init: TInit = () => {
    let n: any;
    if ((window as any).fbq) {
      return;
    }

    n = (window as any).fbq = (...args: any[]) => {
      n.callMethod ? n.callMethod.apply(n, args) : n.queue.push(args);
    };

    if (!(window as any)._fbq) {
      (window as any)._fbq = n;
    }

    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];

    const t = document.createElement('script');
    t.async = true;
    t.src = 'https://connect.facebook.net/en_US/fbevents.js';

    const s = document.getElementsByTagName('script')[0];
    s.parentNode!.insertBefore(t, s);

    n('init', accountId, getExtraParams());
    n('track', 'PageView', getExtraParams());
  };

  const logEvent = (eventName: string, eventValue: any) => {
    const fbq = (window as any).fbq;
    fbq('track', eventName, eventValue);
  };

  const logEventCustom = (eventName: string, eventValue: any) => {
    const fbq = (window as any).fbq;
    fbq('trackCustom', eventName, eventValue);
  };

  const trackEvent: TTrackEvent = (payload) => {
    const { eventName } = payload;
    if (!(eventName in TRACKED_EVENTS)) {
      return;
    }

    const { eventValue } = payload;

    switch (eventName) {
      case EEventTypes.SET_USER: {
        const fbq = (window as any).fbq;
        fbq('init', accountId, getExtraParams());

        logEventCustom('Login', {
          ...getExtraParams(),
        });
        break;
      }
      case EEventTypes.REGISTRATION_COMPLETE: {
        const extras = {
          ...getExtraParams(),
          em: hashData((eventValue?.email as string).trim().toLowerCase()),
          external_id: eventValue?.id as string,
        };
        const fbq = (window as any).fbq;
        fbq('init', accountId, extras);

        logEvent('CompleteRegistration', extras);
        break;
      }
      case EEventTypes.PURCHASE_NODE: {
        const { total, currency, item, orderId } =
          eventValue as ITrackPurchaseItemEventPayload;

        logEvent('Purchase', {
          order_id: orderId,
          value: total,
          currency,
          content_ids: [item.productId],
          content_type: 'FILM-NODE',
          content_name: 'Film Node',
          contents: [
            {
              id: 'film-node',
              quantity: item.quantity,
              item_price: item.unitPrice,
            },
          ],
          num_items: item.quantity,
          ...getExtraParams(),
        });
        break;
      }
      case EEventTypes.PURCHASE_ITEM: {
        const typedEventValue = eventValue as ITrackPurchaseItemEventPayload;
        const { total, currency, item, orderId } = typedEventValue;

        logEvent('Purchase', {
          order_id: orderId,
          value: total,
          currency,
          content_ids: [item.productId],
          content_type: 'FILM-ITEM',
          content_name: item.productId,
          contents: [
            {
              id: item.productId,
              quantity: item.quantity,
              item_price: item.unitPrice,
            },
          ],
          num_items: item.quantity,
          ...getExtraParams(),
        });
        break;
      }
    }
  };

  const hydrateAdaptorPayload: IHydrateAdaptorPayload = {
    init,
    trackEvent,
  };

  const baseAdaptor: IHydratedAdaptor = hydrateAdaptor(hydrateAdaptorPayload);

  const setUser: TSetUser = (currentUser) => {
    baseAdaptor.trackSetUserEvent(currentUser);
  };

  const finalAdaptor: IHydratedAdaptor = {
    ...baseAdaptor,
    setUser,
  };

  return finalAdaptor;
};

metaAdaptor.NAME = EAdaptors.META;

export default metaAdaptor;
