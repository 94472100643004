import { Context } from '@nuxt/types';

export default async function (context: Context) {
  const { store, params, redirect } = context;

  const game = store.state.games.games.find(
    (g: any) => g.route === params.name,
  );

  if (!game || !game.showGamePage) {
    return redirect('/my-collection');
  }
}
