import { MutationTree } from 'vuex';
import { kebabToCamelCase } from '~/utils/kebabToCamelCase';
import { IGamesState, IGameItemForSaleByGala } from './types';

import _startCase from 'lodash.startcase';

export const mutations: MutationTree<IGamesState> = {
  updateItemsForSaleByGala(
    gamesState: IGamesState,
    payload: IGameItemForSaleByGala[],
  ) {
    gamesState.itemsForSaleByGala = payload;
  },

  updateItemsForSaleState(
    gamesState: IGamesState,
    payload: { isLoading: boolean; hasError: boolean },
  ) {
    gamesState.itemsForSaleState = payload;
  },

  updateGameTextByLocale(gamesState: IGamesState, payload) {
    const games = gamesState.games.filter((game) => {
      return game.showGamePage;
    });
    const defaultGameText = payload.defaultGameText;
    const gameTranslation = payload.gameTranslation;

    for (const game of games) {
      let translatedText;
      let defaultText;

      switch (game.codeName) {
        case 'spider-tanks':
          translatedText = gameTranslation.spiderTankProject;
          defaultText = defaultGameText.spiderTankProject;
          break;
        case 'mirandus':
          translatedText = gameTranslation.mirandus;
          defaultText = defaultGameText.mirandus;
          break;
        case 'townstar':
          translatedText = gameTranslation.townStar;
          defaultText = defaultGameText.townStar;
          break;
        case 'fortitude':
          translatedText = gameTranslation.fortitude;
          defaultText = defaultGameText.fortitude;
          break;
        case 'echoesofempire':
          translatedText = gameTranslation.echoesOfEmpire;
          defaultText = defaultGameText.echoesOfEmpire;
          break;
        case 'galaverse-tickets':
          translatedText = gameTranslation.galaverseTickets;
          defaultText = defaultGameText.galaverseTickets;
          break;
        case 'thewalkingdead':
          translatedText = gameTranslation.theWalkingDead;
          defaultText = defaultGameText.theWalkingDead;
          break;
        case 'legacy':
          translatedText = gameTranslation.legacy;
          defaultText = defaultGameText.legacy;
          break;
        case 'lastexpedition':
          translatedText = gameTranslation.lastExpedition;
          defaultText = defaultGameText.lastExpedition;
          break;
        case 'legends-reborn':
          translatedText = gameTranslation.legendsReborn;
          defaultText = defaultGameText.legendsReborn;
          break;
        case 'town-crush':
          translatedText = gameTranslation.townCrush;
          defaultText = defaultGameText.townCrush;
          break;
        case 'superior':
          translatedText = gameTranslation.superior;
          defaultText = defaultGameText.superior;
          break;
        default:
          const inferredKey = kebabToCamelCase(game.codeName);
          translatedText = gameTranslation[inferredKey];
          defaultText = defaultGameText[inferredKey];
      }

      if (translatedText) {
        game.displayName = translatedText.displayName
          ? translatedText.displayName
          : defaultText.displayName;
        game.developer = translatedText.developer
          ? translatedText.developer
          : defaultText.developer;
        game.genre = translatedText.genre
          ? translatedText.genre
          : defaultText.genre;
        game.bannerCtaText = translatedText.bannerCtaText
          ? translatedText.bannerCtaText
          : defaultText.bannerCtaText;
        game.developmentStatus = translatedText.developmentStatus
          ? translatedText.developmentStatus
          : defaultText.developmentStatus;

        const displayedPlatform: any[] = [];
        game.platform.forEach((platform) => {
          if (gameTranslation.platforms) {
            displayedPlatform.push(
              gameTranslation.platforms[platform] || platform,
            );
          } else {
            displayedPlatform.push(platform);
          }
        });

        game.displayedPlatform = displayedPlatform.join('/');

        game.taglineHome = translatedText.taglineHome
          ? translatedText.taglineHome
          : defaultText.taglineHome;
        game.longDescription = translatedText.longDescription
          ? translatedText.longDescription
          : defaultText.longDescription;
        game.descriptionText = translatedText.descriptionText
          ? translatedText.descriptionText
          : defaultText.descriptionText;

        game.inventoryDisclaimer = translatedText.inventoryDisclaimer
          ? translatedText.inventoryDisclaimer
          : defaultText.inventoryDisclaimer;

        if (game.comingSoon) {
          game.comingSoon = gameTranslation.common?.comingSoon
            ? gameTranslation.common.comingSoon
            : defaultGameText.comingSoon;
        }
      } else {
        game.displayName = defaultText.displayName;
        game.developer = defaultText.developer;
        game.genre = defaultText.genre;
        game.bannerCtaText = defaultText.bannerCtaText;
        game.developmentStatus = defaultText.developmentStatus;
        game.taglineHome = defaultText.taglineHome;
        game.longDescription = defaultText.longDescription;
        game.descriptionText = defaultText.descriptionText;
        game.inventoryDisclaimer = defaultText.inventoryDisclaimer;

        if (game.comingSoon && defaultGameText.common) {
          game.comingSoon = defaultGameText.common.comingSoon;
        }

        game.displayedPlatform = game.platform.join('/');
      }

      game.displayedPlatform =
        game.displayedPlatform ||
        payload.i18n.t('store.games.index.common.TBA');
    }
  },
};
