import { ICreditCards } from '../profile/types';

export enum GamePlatform {
  Mac = 'Mac',
  Win = 'PC',
  Linux = 'Linux',
  Browser = 'Browser',
  iOS = 'iOS',
  Android = 'Android',
}

export interface IGameProduct {
  baseId: string;
  game: string;
  name: string;
  coin?: string;
  description: string;
  image: string;
  rarity: IGameItemRarity;
}

export interface IGameItemRarity {
  icon: string;
  hexcode?: string;
  label?: string;
  supplyLimit?: number;
}

export enum GameItemProductPriceTransactionType {
  TRANSFER = 'transfer',
  BURN = 'burn',
}

export interface IGameItemProductPrice {
  price: string;
  basePrice: string;
  usdPriceInCents: number;
  usdBasePriceInCents: number;
  symbol: string;
  transactionType: GameItemProductPriceTransactionType;
  creditCard?: boolean;
  creditCardData?: ICreditCards | null;
}

export interface IGameItemProductTier {
  tier: number;
  supply: number;
}

export interface IGameItemForSaleByGala extends IGameProduct {
  productId: string;
  prices: IGameItemProductPrice[];
  galaGoldOnly: boolean;
  nodeOwnerOnly: boolean;
  galaGoldOrNodeOwnerOnly: boolean;
  maxQuantityPerOrder: number;
  invoiceAddress: string;
  qtyLeft: number;
  purchasingDisabled: boolean;
  expiresAt?: number;
  category?: string;
  images: Array<{
    url: string;
    caption?: string;
    thumbnail?: string;
    hasAudio?: boolean;
  }>;
  countdown: IGameItemCountdown;
  currentTier: number;
  tiers: IGameItemProductTier[];
  restrictionId: string;
  priceAdjustmentId: string;
  quantityMultiplier?: number;
  gyriExchanges?: Array<{ id: number }>;
  autoOpenAsGyriExchange?: boolean;
  displayName?: string;
  filmNftSlug?: string;
  burnsAt?: number;
  priceChangesAt?: number;
  isBurned?: boolean;
  gyriTestersOnly?: boolean;
  tokens?: Array<{ quantity: number; baseId: string }>;
  type: string;
  hasRebate?: boolean;
  expiresInMonths?: number;
  galachainInvoiceAccountKey?: string;
}

interface IGameItemCountdown {
  target: number;
  runningLabel: string;
}

export interface IGameDescriptionAsset {
  thumbnail: string;
  medium: string;
  large?: string;
  poster?: string;
  type: 'img' | 'video';
}

export enum AllowanceType {
  TRANSFER = 3,
}

export interface IRequiredAllowance {
  type: string;
  tokenImg: string;
  tokenId: string;
  action: AllowanceType;
  amount: string;
  ownerKey: string;
  uses: string;
  category: string;
  additionalKey: string;
}

export interface IUserAllowance {
  action: AllowanceType;
  amount: string;
  amountSpent: string;
  created: 1645742505789;
  expires: 0;
  instanceId: string;
  issuerKey: string;
  tokenId: string;
  uses: string;
  usesSpent: string;
}

export interface IGame {
  name: string;
  displayName: string;
  showGamePage: boolean;
  codeName: string;
  developer: string;
  genre: string;
  developmentStatus: string;
  platform: GamePlatform[];
  displayedPlatform?: string;
  isReleased: boolean;
  isReleasedInternally?: boolean;
  bannerImageCarousel: string;
  bannerImageCarouselMin: string;
  bannerImageHome?: string;
  bannerImage?: string;
  bannerImageDesktop?: string;
  descriptionAssets: IGameDescriptionAsset[];
  inventoryImage: string;
  aboutUsImage: string;
  linkImage: string;
  descriptionText: string;
  taglineHome: string;
  longDescription: string;
  screenshots: string[];
  currency: string;
  route: string;
  playLink: string;
  coin: string;
  logo: string;
  croppedLogo?: string;
  isMacReleased?: boolean;
  isWinReleased?: boolean;
  isInstalled?: boolean;
  installedVersion?: string;
  latestVersion?: string;
  installerUrlMac?: string;
  installerUrlWin?: string;
  websiteLink?: string;
  discordLink?: string;
  downloadLink?: string;
  activeStartTimestamp?: number;
  activeEndTimestamp?: number;
  comingSoon?: string;
  bannerLogo?: string;
  bannerCtaText?: string;
  bannerCtaLink?: string;
  gamePageBackground?: string;
  gamePageHeaderLogo?: string;
  showNodePromotion?: boolean;
  emphasizedStoreCategory?: string;
  requireAuthToPlay?: boolean;
  bannerImageClass?: string;
  usesGalaChain?: boolean;
  collection: string;
  requiredAllowances?: IRequiredAllowance[];
  allowancesGiven?: IUserAllowance[];
  narrowText?: boolean;
  showCarouselBanner?: boolean;
  updatedAt?: number; // milliseconds since January 1, 1970 00:00:00 UTC (Date.now())
  inventoryDisclaimer?: string;
}

export interface IGamesState {
  games: IGame[];
  itemsForSaleByGala: IGameItemForSaleByGala[];
  itemsForSaleState: {
    isLoading: boolean;
    hasError: boolean;
  };
}

export interface IPurchaseAgreement {
  label: string;
  link: string;
  checked: boolean;
  content: string;
}

export interface IFetchItemsForSalePayload {
  baseId?: string;
  category?: string;
  game?: string;
  topItems?: number;
}

export interface ITokenClassKey {
  collection: string;
  category: string;
  type: string;
  additionalKey: string;
}
