var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-stepper-content',{attrs:{"step":_vm.step}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("\n    "+_vm._s(_setup.i18n.t(
        'components.wallet.connectWeb3Wallet.importGalaWalletIntoProvider',
      ))+"\n  ")]),_vm._v(" "),_c('p',[_vm._v("\n    Using your recovery phrase or the private key you just downloaded in the\n    previous step, import your Gala wallet into your Web3 wallet.\n  ")]),_vm._v(" "),_c('p',{staticClass:"font-weight-bold mb-2"},[_vm._v("Helpful support links:")]),_vm._v(" "),_c('ul',{staticClass:"support-articles"},[_c('li',[_c('a',{attrs:{"href":_setup.web3WalletSupportLinks.importPhraseCoinbase,"target":"_blank"}},[_vm._v("\n        "+_vm._s(_setup.i18n.t(
            'components.wallet.connectWeb3Wallet.importRecoveryPhraseToCoinbase',
          ))+"\n      ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_setup.web3WalletSupportLinks.importPhraseMetaMask,"target":"_blank"}},[_vm._v("\n        "+_vm._s(_setup.i18n.t(
            'components.wallet.connectWeb3Wallet.importRecoveryPhraseToMetamask',
          ))+"\n      ")])]),_vm._v(" "),_c('li',[_c('a',{attrs:{"href":_setup.web3WalletSupportLinks.importPrivateKeyMetaMask,"target":"_blank"}},[_vm._v("\n        "+_vm._s(_setup.i18n.t(
            'components.wallet.connectWeb3Wallet.importPrivateKeyToMetamask',
          ))+"\n      ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }