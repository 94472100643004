
import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { ICustomRevealState } from '~/store/customReveal/types';

@Component({})
export default class CustomExchangeRevealItemCard extends Vue {
  @Prop(String) image!: string;
  @Prop(String) name!: string;
  @Prop(String) ind!: string;
  @Prop(String) rarity!: string;

  canReveal = false;

  @State((customReveal) => customReveal, { namespace: 'customReveal' })
  customReveal!: ICustomRevealState;

  @Action('loadCard', { namespace: 'customReveal' })
  private loadCard!: (payload: string) => void;

  @Action('revealCard', { namespace: 'customReveal' })
  private revealCard!: (payload: string) => void;

  handleAnimationEnd(e: AnimationEvent) {
    if (!e.target) {
      return;
    }
    const elem = e.target as HTMLElement;
    if (e.animationName.indexOf('cardSettle') !== -1) {
      elem.classList.add('loaded');
      this.canReveal = true;
      this.loadCard(this.ind);
    }
  }

  handleCardClick(e: MouseEvent) {
    if (!this.canReveal) {
      return;
    }
    e.stopPropagation();
    (e.currentTarget as HTMLElement)?.classList.add('revealed');
    this.revealCard(this.ind);
  }

  get isLoaded() {
    return this.customReveal.loaded.includes(this.ind);
  }

  get isRevealed() {
    return this.customReveal.revealed.includes(this.ind);
  }
}
