import { Context } from '@nuxt/types';

export default async function (context: Context) {
  const utmVariables = localStorage.getItem('utm-variables');
  let utmCampaign;

  if (utmVariables) {
    try {
      const parsed = JSON.parse(utmVariables);

      if (parsed.utm_campaign) {
        utmCampaign = parsed.utm_campaign;
      }
    } catch {}
  }
  // UA tracking
  context.app.$ua.trackLoginEvent({ utmCampaign });
}
