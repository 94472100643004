import { Store } from 'vuex';
import { Route } from 'vue-router';
import { SanityDocument } from '@sanity/client';
import { NuxtI18nInstance } from '@nuxtjs/i18n';
import { documentStoreTypes } from '~/store/documents';
import { TDocStoreActionPayloads } from '~/store/documents/types';
import {
  IGetterParams as IGetRefParams,
  ILookupParams as IFetchRefParams,
  TReferencePayloads,
} from '~/store/reference/types';
import { SupportedLocales } from '~/utils/locales';
import {
  getDocumentRefIds,
  getFieldRefIds,
  transformDocument,
  transformField,
  pageTypes,
  getPageRoute,
  getPageRouteParams,
  getPageRoutePath,
} from '~/utils/sanity';
import { TSanityField } from '~/types/sanity';
import {
  ISanityDocuments,
  SanityDocumentSchema,
  TSanityDocumentPageSchemas,
  TSanityDocumentSellableSchemas,
} from '~/types/sanity-documents';
import {
  IFetchDocParams,
  IGetDocParams,
  TDocumentState,
  TReferenceState,
  TSlugState,
} from './types';

export class SanityLookup {
  store: Store<any>;
  i18n: NuxtI18nInstance;
  pageTypes: Partial<
    Record<SanityDocumentSchema, boolean | string | string[]>
  > = pageTypes;
  routePathTypeAliases: Record<string, SanityDocumentSchema> = {};
  storeTypes: Partial<Record<SanityDocumentSchema, boolean>> = {};

  constructor(store: Store<any>, i18n: NuxtI18nInstance) {
    this.store = store;
    this.i18n = i18n;

    documentStoreTypes.forEach(
      (type) => (this.storeTypes[type as SanityDocumentSchema] = true),
    );

    Object.keys(this.pageTypes).forEach((type) => {
      const docType = type as SanityDocumentSchema;
      const routeConfig = this.pageTypes[docType];
      if (typeof routeConfig === 'boolean') {
        return;
      }
      if (typeof routeConfig === 'string') {
        this.routePathTypeAliases[routeConfig] = docType;
      } else if (Array.isArray(routeConfig)) {
        routeConfig.forEach(
          (alias) => (this.routePathTypeAliases[alias] = docType),
        );
      }
    });
  }

  /**
   * Store action helpers
   */

  /**
   * Fetch Sanity documents by schema + _id or slug
   * @param params - The parameters to pass to the fetchDocuments store action.
   *    Either _id or slug must be provided as well as the _type.
   * @param params._id - one or more sanity document _ids
   * @param params._type - the sanity document schema to fetch
   * @param params.slug - one or more sanity document slugs
   * @param params.fetchReferences - whether to look up & fetch the initially fetched document(s) refs
   * @param params.bypassCache - if true, will call sanity.fetch even if the data has already been fetched
   */
  async fetchDocument(params: IFetchDocParams) {
    if (!params._type || !(params._id || params.slug)) {
      return;
    }

    if (!this.storeTypes[params._type]) {
      return;
    }

    await this.store.dispatch<TDocStoreActionPayloads['fetchDocuments']>({
      type: `documents.${params._type}/fetchDocuments`,
      _id: params._id,
      slug: params.slug,
      fetchReferences: params?.fetchReferences || false,
      bypassCache: params?.bypassCache || false,
    });
  }

  /**
   * Fetch Sanity documents when only the _id is known, and possibly the schema.
   * @param params - The parameters to pass to the fetchReferences store action.
   *    The _id must be provided, and _type is recommended (if known) for query performance.
   * @param params._id - one or more sanity document _ids
   * @param params._type - one or more types to filter by
   * @param params.fetchReferences - whether to look up & fetch the initially fetched document(s) refs
   * @param params.bypassCache - if true, will call sanity.fetch even if the data has already been fetched
   */
  async fetchReference(params: IFetchRefParams) {
    if (!params._id) {
      return;
    }

    await this.store.dispatch<TReferencePayloads['fetchReferences']>({
      type: `reference/fetchReferences`,
      _id: params._id,
      _type: params._type?.length ? params._type : undefined,
      fetchReferences: params?.fetchReferences || false,
      bypassCache: params?.bypassCache || false,
    });
  }

  /**
   * Store getter helpers
   */

  getDocument<TDoc extends SanityDocument = SanityDocument>(
    params: IGetDocParams,
  ): TDocumentState<TDoc> {
    return params._type && this.storeTypes[params._type]
      ? this.store.getters[`documents.${params._type}/getDocument`]?.(params)
      : undefined;
  }

  getReference(params: IGetRefParams): TReferenceState | undefined {
    return params._ref
      ? this.store.getters[`reference/getReference`]?.(params)
      : undefined;
  }

  getSlug(
    params: Pick<IGetDocParams, '_type' | 'slug'>,
  ): TSlugState | undefined {
    if (!params?.slug || !params._type || !this.storeTypes[params._type]) {
      return;
    }
    return this.store.getters[`documents.${params._type}/getSlug`]?.({
      slug: params.slug,
    });
  }

  /**
   * Lookup helpers
   */

  // get the document context from route params
  getRouteDocument(route: Route) {
    if (!route.params?.slug || !route.params?.schema) {
      return;
    }

    const schema = this.getSchemaFromRouteParams(route.params);
    if (!schema) {
      return;
    }

    const slug = route.params.slug;
    return this.getDocumentFromSlug({
      slug,
      _type: schema,
    });
  }

  // Get document from lookup parameters.
  getDocumentFromIdOrSlug<TDoc extends SanityDocument = SanityDocument>(
    params: IGetDocParams,
    useDraft?: boolean,
  ): TDocumentState<TDoc> {
    return params?.slug
      ? this.getDocumentFromSlug(params, useDraft)
      : this.getDocumentFromId(params, useDraft);
  }

  // Look up a document from an _id + type.
  getDocumentFromId<TDoc extends SanityDocument = SanityDocument>(
    params: IGetDocParams,
    useDraft?: boolean,
  ): TDocumentState<TDoc> {
    if (!params?._id || !params?._type) {
      return;
    }

    return useDraft === undefined
      ? this.getDraftOrPublished(params)
      : useDraft
      ? this.getPublishedVersion(params)
      : this.getDraftVersion(params);
  }

  // Look up a document from a _ref id.
  getDocumentFromReference<TDoc extends SanityDocument = SanityDocument>(
    params: IGetRefParams,
    useDraft?: boolean,
  ): TDocumentState<TDoc> {
    const ref = params?._ref
      ? this.getReference({ _ref: params._ref })
      : undefined;

    if (!ref?.data?._type || !ref?.data?._id) {
      return;
    }

    const docLookup = {
      _id: ref.data?._id,
      _type: ref.data?._type,
    };

    return useDraft === undefined
      ? this.getDraftOrPublished<TDoc>(docLookup)
      : useDraft
      ? this.getPublishedVersion<TDoc>(docLookup)
      : this.getDraftVersion<TDoc>(docLookup);
  }

  // Look up a document from a slug + type.
  getDocumentFromSlug<TDoc extends SanityDocument = SanityDocument>(
    params: IGetDocParams,
    useDraft?: boolean,
  ): TDocumentState<TDoc> {
    if (!params?.slug || !params?._type) {
      return;
    }

    const slug = this.getSlug(params);
    if (!slug?.key) {
      return;
    }

    const docLookup = {
      _id: slug.key,
      _type: params._type,
    };

    return useDraft === undefined
      ? this.getDraftOrPublished(docLookup)
      : useDraft
      ? this.getPublishedVersion(docLookup)
      : this.getDraftVersion(docLookup);
  }

  getSiteConfig() {
    const siteConfigSlug = this.store.state.siteConfigSlug;
    if (!siteConfigSlug) {
      return;
    }
    return this.getDocumentFromSlug<
      ISanityDocuments[SanityDocumentSchema.SITE_CONFIG]
    >({
      slug: siteConfigSlug,
      _type: SanityDocumentSchema.SITE_CONFIG,
    });
  }

  // Gets draft version of a document if it exists & drafts are enabled.
  // If draft does not exist, will return published version.
  getDraftOrPublished<TDoc extends SanityDocument = SanityDocument>(
    params: Omit<IGetDocParams, 'slug'>,
  ): TDocumentState<TDoc> {
    if (!this.store.state.debugSettings?.shouldShowDrafts) {
      return this.getPublishedVersion<TDoc>(params);
    }
    const draftVersion = this.getDraftVersion<TDoc>(params);

    return draftVersion?.data
      ? draftVersion
      : this.getPublishedVersion<TDoc>(params);
  }

  // Always returns draft version of a document if it exists.
  getDraftVersion<TDoc extends SanityDocument = SanityDocument>(
    params: Omit<IGetDocParams, 'slug'>,
  ): TDocumentState<TDoc> {
    if (!params._id || !params._type) {
      return;
    }
    return this.getDocument({
      _id: this.isDraftId(params._id) ? params._id : `drafts.${params._id}`,
      _type: params._type,
    });
  }

  // Always returns published version of a document if it exists.
  getPublishedVersion<TDoc extends SanityDocument = SanityDocument>(
    params: Omit<IGetDocParams, 'slug'>,
  ): TDocumentState<TDoc> {
    if (!params._id || !params._type) {
      return;
    }

    return this.getDocument({
      _id: params._id.replace(/^drafts./, ''),
      _type: params._type,
    });
  }

  // Check if the ID is a draft ID.
  isDraftId(id?: string) {
    return !!id?.match(/^drafts./);
  }

  /**
   * Route helpers
   */

  /**
   * Get the page route for a schema + slug.
   * @param params - A slug and a sanity document schema
   * @returns A route object for a document page
   */
  getPageRoute(...args: Parameters<typeof getPageRoute>) {
    const [params] = args;
    return getPageRoute(params);
  }

  /**
   * Get path for a document page
   * @param params - A slug and a sanity document schema
   * @returns The path for a document page
   */
  getPageRoutePath(...args: Parameters<typeof getPageRoutePath>) {
    const [params] = args;
    return getPageRoutePath(params);
  }

  async getPageRoutePathFromRef(id: string) {
    await this.fetchReference({ _id: id });
    const refDoc = this.getDocumentFromReference({ _ref: id });
    if (!refDoc?.data || !refDoc?.data?.slug) {
      return;
    }
    return this.getPageRoutePath({
      slug: refDoc.data.slug,
      schema: refDoc.data._type,
    });
  }

  /**
   * Get document page route params from a slug + schema
   * @param params - A slug and a sanity document schema
   * @returns The params for a document page route
   */
  getPageRouteParams(...args: Parameters<typeof getPageRouteParams>) {
    const [params] = args;
    return getPageRouteParams(params);
  }

  /**
   * Get document schema from route schema param
   * @param params - Vue router route params
   * @returns the Sanity document schema for the "schema" route param
   */
  getSchemaFromRouteParams(
    params: Route['params'],
  ): SanityDocumentSchema | undefined {
    if (!params?.schema) {
      return;
    }

    if (this.pageTypes[params.schema as SanityDocumentSchema]) {
      return params.schema as SanityDocumentSchema;
    }

    return this.routePathTypeAliases[params.schema];
  }

  /**
   * Check if a document has an enabled landing page
   * @param document - the document to check
   * @returns Whether a landing page is enabled
   */
  hasLandingPage(document: ISanityDocuments[TSanityDocumentPageSchemas]) {
    const pageAvailability = document.pageAvailability;
    if (
      !pageAvailability ||
      (!pageAvailability.isLandingPageEnabled &&
        !pageAvailability.isCreateAccountPageEnabled &&
        !pageAvailability.isUaPageEnabled)
    ) {
      return false;
    }

    const schemaPageAvailability =
      this.getSiteConfig()?.data?.pageAvailabilityPerSchema?.[document._type]
        ?.pageAvailability;
    if (
      !schemaPageAvailability ||
      (!schemaPageAvailability.isLandingPageEnabled &&
        !schemaPageAvailability.isCreateAccountPageEnabled &&
        !schemaPageAvailability.isUaPageEnabled)
    ) {
      return false;
    }

    return true;
  }

  /**
   * Check whether a document is currently on sale
   * @param document - The sanity document to check
   * @returns Whether the given document is currently on sale
   */
  isCurrentlyOnSale(
    document: ISanityDocuments[TSanityDocumentSellableSchemas],
  ) {
    if (!document.contentAvailability?.isBuyable) {
      return false;
    }
    if (!document.saleEndsAt && !document.saleStartsAt) {
      return true;
    }
    const now = Date.now();
    const startsAt = document.saleStartsAt
      ? new Date(document.saleStartsAt).getTime()
      : undefined;
    const endsAt = document.saleEndsAt
      ? new Date(document.saleEndsAt).getTime()
      : undefined;
    return (!startsAt || now > startsAt) && (!endsAt || now < endsAt);
  }

  /**
   * General util functions
   */

  getDocumentRefIds(document: SanityDocument) {
    return getDocumentRefIds(document);
  }

  getFieldRefIds(field: TSanityField) {
    const fieldRefs: { refs?: Record<string, boolean> } = {};
    getFieldRefIds(field, fieldRefs);
    return fieldRefs.refs ? fieldRefs : undefined;
  }

  transformDocument(document: SanityDocument, locale: SupportedLocales) {
    return transformDocument(document, locale);
  }

  transformField(field: TSanityField, locale: SupportedLocales) {
    return transformField(field, locale);
  }

  translateSchema(schema: SanityDocumentSchema) {
    const pathPrefix = 'common.schemas';
    const pathToSchemaType = `${pathPrefix}.types.${schema}`;
    return this.i18n.te(pathToSchemaType)
      ? this.i18n.t(pathToSchemaType)
      : this.i18n.t(`${pathPrefix}.default`);
  }
}
