
import { Vue, Component, Prop } from 'vue-property-decorator';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import { sanitizeLegalDocumentHtml } from '../../utils';

@Component({
  components: { FullScreenDialog },
})
export default class AgreementDialog extends Vue {
  @Prop({ type: String, default: 'accentPurple' })
  readonly buttonColor!: string;
  @Prop({ type: String, required: true })
  readonly htmlContent!: any;
  @Prop({ type: Boolean, default: false }) readonly closeOnClickAway!: boolean;

  dialogOpen = false;

  get sanitizedHtmlContent() {
    return sanitizeLegalDocumentHtml(this.htmlContent);
  }
}
