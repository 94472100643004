var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isDebugToolDisplayed)?_c('div',{class:{ debug: true, 'is-enabled': _vm.savedOptions.debugSettingsEnabled }},[_c('v-dialog',{attrs:{"transition":_vm.$vuetify.breakpoint.xs ? 'dialog-bottom-transition' : 'fade',"max-width":600},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"font--body-small debug-tool-trigger rounded-0",attrs:{"dark":"","depressed":"","small":"","color":_vm.savedOptions.debugSettingsEnabled
            ? 'accentPurple'
            : 'pageBackgroundDark'}},'v-btn',attrs,false),on),[(_vm.savedOptions.debugSettingsEnabled)?[_vm._v("\n          Settings:\n          "),_c('strong',[_vm._v(_vm._s(_vm.savedOptions.settingsDataset))]),_vm._v("\n           — Content:\n          "),_c('strong',[_vm._v(_vm._s(_vm.savedOptions.contentDataset))]),_vm._v("\n           / Drafts:\n          "),_c('strong',[_vm._v(_vm._s(_vm.savedOptions.shouldShowDrafts ? `On` : `Off`))])]:[_vm._v("Debug off - click for options")]],2)]}},{key:"default",fn:function(dialog){return [_c('v-card',{attrs:{"rounded":"xl","outlined":"","flat":""}},[_c('v-card-title',[_c('div',{staticClass:"d-flex full-width align-center justify-space-between"},[_c('h2',{staticClass:"font--headline-xs"},[_vm._v("Debug Options")]),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","aria-label":"close debug options"},on:{"click":() => (dialog.value = false)}},[_c('v-icon',[_vm._v("close")])],1)],1)]),_vm._v(" "),_c('v-card-text',[_c('v-divider',{staticClass:"mb-4"}),_vm._v(" "),_c('v-switch',{staticClass:"reverse-input ml-2",attrs:{"inset":"","color":"accentPurple","label":"Enable debug options?","aria-describedby":_vm.$id('enableDebugDesc'),"hide-details":""},on:{"change":(v) => {
                _vm.updateOptions({
                  ..._vm.options,
                  debugSettingsEnabled: v,
                });
              }},model:{value:(_vm.options.debugSettingsEnabled),callback:function ($$v) {_vm.$set(_vm.options, "debugSettingsEnabled", $$v)},expression:"options.debugSettingsEnabled"}}),_vm._v(" "),_c('p',{staticClass:"my-0 ml-2 font--body-small pr-16",attrs:{"id":_vm.$id('enableDebugDesc')}},[_vm._v("\n            "+_vm._s(_vm.options.debugSettingsEnabled
                ? `Debug options are enabled.`
                : `Debug options are disabled.`)+"\n          ")]),_vm._v(" "),_c('v-expand-transition',[(_vm.options.debugSettingsEnabled)?_c('fieldset',{staticClass:"debug-tool-fieldset"},[_c('legend',{staticClass:"sr-only"},[_vm._v("Debug Settings")]),_vm._v(" "),_c('v-divider',{staticClass:"my-4"}),_vm._v(" "),_c('h3',{staticClass:"font--subtitle-large font-weight-bold mb-4"},[_vm._v("\n                Sanity options\n              ")]),_vm._v(" "),_c('v-select',{attrs:{"outlined":"","label":"Settings Environment","items":_vm.datasetOptions,"rounded":"","hide-details":"auto"},on:{"input":(v) => {
                    _vm.updateOptions({
                      ..._vm.options,
                      settingsDataset: v,
                    });
                  }},model:{value:(_vm.options.settingsDataset),callback:function ($$v) {_vm.$set(_vm.options, "settingsDataset", $$v)},expression:"options.settingsDataset"}}),_vm._v(" "),_c('v-select',{staticClass:"mt-4",attrs:{"outlined":"","label":"Content Environment","items":_vm.datasetOptions,"rounded":"","hide-details":"auto"},on:{"input":(v) => {
                    _vm.updateOptions({
                      ..._vm.options,
                      contentDataset: v,
                    });
                  }},model:{value:(_vm.options.contentDataset),callback:function ($$v) {_vm.$set(_vm.options, "contentDataset", $$v)},expression:"options.contentDataset"}}),_vm._v(" "),_c('v-switch',{staticClass:"reverse-input mt-4 ml-2",attrs:{"inset":"","color":"accentPurple","label":"Show drafts?","aria-describedby":_vm.$id('showRevisionsDesc'),"hide-details":""},on:{"change":(v) => {
                    _vm.updateOptions({
                      ..._vm.options,
                      shouldShowDrafts: v,
                    });
                  }},model:{value:(_vm.options.shouldShowDrafts),callback:function ($$v) {_vm.$set(_vm.options, "shouldShowDrafts", $$v)},expression:"options.shouldShowDrafts"}}),_vm._v(" "),_c('p',{staticClass:"mt-2 ml-2 mb-0 font--body-small pr-16",attrs:{"id":_vm.$id('showRevisionsDesc')}},[_vm._v("\n                "+_vm._s(_vm.options.shouldShowDrafts
                    ? `Unpublished revisions (drafts) will be shown.`
                    : `Unpublished revisions (drafts) will not be shown.`)+"\n                "),(_vm.options.shouldShowDrafts)?[_c('br'),_vm._v(" "),_c('strong',[_vm._v("\n                    You must be\n                    "),_c('a',{staticClass:"accentPurple--text",attrs:{"target":"_blank","href":_vm.sanityLoginUrl}},[_vm._v("\n                      logged into Sanity\n                    ")]),_vm._v("\n                    as a user with permission to see drafts.\n                  ")])]:_vm._e()],2)],1):_vm._e()]),_vm._v(" "),_c('v-divider',{staticClass:"mt-6"})],1),_vm._v(" "),_c('v-card-actions',{staticClass:"debug-tool-actions pb-4"},[_c('v-btn',{staticClass:"accentPurple font--button rounded-pill px-4",attrs:{"disabled":!_vm.hasChanged,"large":"","depressed":"","dark":_vm.hasChanged},on:{"click":() => {
                _vm.handleSave();
                dialog.value = false;
              }}},[_vm._v("\n            Save changes\n          ")]),_vm._v(" "),_c('v-btn',{staticClass:"font--button rounded-pill px-4 ml-4",attrs:{"disabled":!_vm.hasChanged,"text":"","large":"","depressed":"","outlined":"","color":"accentGrayDark"},on:{"click":_vm.resetOptions}},[_vm._v("\n            Reset changes\n          ")]),_vm._v(" "),_c('v-btn',{staticClass:"font--button rounded-pill px-4 ml-4",attrs:{"text":"","large":"","depressed":"","outlined":"","color":"accentGrayDark"},on:{"click":_vm.resetToDefault}},[_vm._v("\n            Use defaults\n          ")])],1)],1)]}}],null,false,812201115)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }