
import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { IUser } from '~/store/profile/types';
import VerifyEmailAddress from '~/components/EmailVerification/VerifyEmailAddress.vue';
import AcceptTermsAndConditions from '~/components/Home/AcceptTermsAndConditions.vue';
import GalaChainMigrationAlert from '~/components/Home/GalaChainMigrationAlert.vue';
import CreateWalletModalPrompt from '~/components/NewWallet/CreateWalletModalPrompt.vue';
import TwoFactorAuthPrompt from '~/components/ModalPrompts/TwoFactorAuthSetupPrompt.vue';
import ConnectWeb3WalletErrorDialog from '~/components/Web3Wallet/ConnectWeb3WalletErrorDialog.vue';
import PromoBoxDialog from '~/components/PromoBox/PromoBoxDialog.vue';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import RewardsMysteryBoxModal from '~/components/Rewards/RewardsMysteryBoxModal.vue';

@Component({
  components: {
    AcceptTermsAndConditions,
    ConnectWeb3WalletErrorDialog,
    CreateWalletModalPrompt,
    GalaChainMigrationAlert,
    RewardsMysteryBoxModal,
    TwoFactorAuthPrompt,
    VerifyEmailAddress,
  },
})
export default class DashboardModals extends Vue {
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  readonly loggedIn!: boolean;
  @State((profile) => profile.user, { namespace: 'profile' })
  readonly currentUser!: IUser;

  get canShowPromoDialog() {
    if (this.$route.name === 'schema-slug') {
      const schema = this.$sanityLookup.getSchemaFromRouteParams(
        this.$route.params,
      );
      return schema !== SanityDocumentSchema.PROMO_BOX;
    }
    return true;
  }
}
