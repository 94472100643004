import { GetterTree } from 'vuex';
import { IProfileState } from './types';
import { IRootState } from '../types';

export const getters: GetterTree<IProfileState, IRootState> = {
  hasUsernamePasswordIdentity(state: IProfileState) {
    return state.user.auth0UserIdentities.some(
      (identity) => identity.connection === 'Username-Password-Authentication',
    );
  },
};
