import numeral from 'numeral';

export const formatNumber = (value: number, formatString: string) => {
  const valWithMin = +value >= 1e-6 ? +value : 0;
  const result = numeral(valWithMin.toFixed(8)).format(formatString);
  return result;
};

export function formatChainSymbol(chain: string) {
  const filtered = chain?.replace?.(/gyri/i, 'Gala Chain');
  return filtered?.replace?.(/gyri/i, filtered) ?? '';
}

export function cutNumberDecimals(value?: number, decimalPlaces = 2) {
  return +Number(value ?? 0)?.toFixed?.(decimalPlaces) || 0;
}

export function formatNumberWithCommas(
  num: number | null | undefined,
  decimals = 2,
) {
  return (num || 0).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function getPercFormat(value: number, decimals = 2) {
  const sign = value >= 0 ? '+' : '';
  return sign + formatNumberWithCommas(value || 0, decimals) + '%';
}

export function secsToDays(secs: number) {
  const days = (secs || 0) / (60 * 60 * 24);
  return days;
}

export function getDecimalForm(num: number, decimal: number = 2) {
  const value = isNaN(num) ? 0 : num || 0;
  return parseFloat(value?.toFixed?.(decimal));
}
