var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c('div',{staticClass:"d-flex flex-column align-center justify-center fill-height"},[_c('div',{staticClass:"box-content text-center"},[_c('v-skeleton-loader',{staticClass:"loading-overline mb-7 mx-auto",attrs:{"type":"text","width":"65%"}}),_vm._v(" "),_c('v-skeleton-loader',{staticClass:"loading-title mx-auto",attrs:{"type":"heading","width":"100%"}}),_vm._v(" "),_c('v-skeleton-loader',{staticClass:"loading-title mt-2 mx-auto",attrs:{"type":"heading","width":"85%"}}),_vm._v(" "),_c('v-skeleton-loader',{staticClass:"loading-actions mt-8 mx-auto",attrs:{"type":"button","width":"100%"}})],1)]):_c('div',{staticClass:"d-flex flex-column align-center justify-space-between fill-height position-relative"},[_c(_setup.PromoBackground,{attrs:{"imageLandscape":_setup.props.doc?.heroImageLandscape,"imagePortrait":_setup.props.doc?.heroImagePortrait,"overlay":true}}),_vm._v(" "),_c('div',{class:{
      'position-relative box-logos d-flex flex-column align-center justify-center px-6 pb-6 px-sm-8 pb-sm-8 px-md-13': true,
      'pt-6 pt-sm-8': !_vm.doc?.logo || _vm.doc?.hasTopMargin,
    }},[(_vm.doc?.logo)?_c('img',{staticClass:"box-logo",attrs:{"src":_vm.$sanityImage.urlFor(_vm.doc.logo).url(),"alt":_vm.doc?.logo?.alt || ''}}):_vm._e(),_vm._v(" "),_c(_setup.LogoGalaFilm)],1),_vm._v(" "),_c('div',{staticClass:"position-relative box-content text-center pa-6"},[(_setup.content?.overline)?_c('p',{staticClass:"font--body-large font-weight-bold mb-8"},[_vm._v("\n      "+_vm._s(_setup.content.overline)+"\n    ")]):_vm._e(),_vm._v(" "),(_setup.content?.title)?_c(_vm.titleTag,{tag:"component",staticClass:"box-title"},[_vm._v("\n      "+_vm._s(_setup.content.title)+"\n    ")]):_vm._e(),_vm._v(" "),(_setup.content?.body?.length)?_c('SanityBlockContent',{staticClass:"box-body g-text-secondary mt-6",attrs:{"blocks":_setup.content?.body}}):_vm._e(),_vm._v(" "),(
        _vm.redemptionState === _setup.IRedemptionState.REDEEMABLE ||
        (_setup.urlOrReflink && _setup.ctaText)
      )?_c('div',{staticClass:"mt-8"},[(_vm.redemptionState === _setup.IRedemptionState.REDEEMABLE)?_c('v-btn',_vm._b({attrs:{"disabled":!_vm.canRedeem ||
          _vm.redeeming ||
          !_setup.props.doc?.redemptionCode ||
          !_setup.props.doc?.exchangeRevealVideoUrl,"loading":_vm.redeeming},on:{"click":function($event){return _setup.emit('redeem')}}},'v-btn',_setup.ctaProps,false),[_vm._v("\n        "+_vm._s(_vm.redeeming ? _vm.$t('common.misc.opening') : _setup.ctaText)+"\n      ")]):_c('v-btn',_vm._b({attrs:{"nuxt":"","to":_setup.isExternalLink ? undefined : _setup.href,"href":_setup.isExternalLink ? _setup.href : undefined,"target":_setup.target}},'v-btn',_setup.ctaProps,false),[_vm._v("\n        "+_vm._s(_setup.ctaText)+"\n      ")])],1):_vm._e(),_vm._v(" "),(
        _vm.redemptionState === _setup.IRedemptionState.REDEEMABLE ||
        _vm.redemptionState === _setup.IRedemptionState.EXPIRED
      )?_c('div',{staticClass:"mt-8"},[_c('button',{staticClass:"underline",on:{"click":function($event){return _setup.emit('info')}}},[_vm._v("\n        "+_vm._s(_vm.doc?.explainerCtaText ||
          _vm.$i18n.t('components.promoBox.promoBox.explainerCta'))+"\n      ")])]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"position-relative full-width"},[(
        _vm.redemptionState === _setup.IRedemptionState.REDEEMED &&
        _vm.doc?.slug &&
        _vm.doc?.pageAvailability?.isLandingPageEnabled
      )?_c(_setup.PromoBoxShare,{attrs:{"heading":_vm.$i18n.t('components.promoBox.promoBox.shareHeading').toString(),"schema":_vm.doc?._type,"slug":_vm.doc?.slug,"utm-content":_vm.doc?.utmContent}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"box-footer-logos pa-6 pa-sm-8",class:_vm.doc?.footerLogo ? 'multiple-logos' : ''},[_c(_setup.LogoPoweredByGala,{staticClass:"box-footer-logo"}),_vm._v(" "),(_vm.doc?.footerLogo)?_c('img',{staticClass:"box-footer-logo",attrs:{"src":_vm.$sanityImage.urlFor(_vm.doc.footerLogo).url(),"alt":_vm.doc?.footerLogo?.alt || ''}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }