var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"payment-picker px-4 py-2 rounded-pill d-flex align-center",attrs:{"align-center":"","role":"button","tabindex":"0"},on:{"click":_vm.handleOpen,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.handleOpen.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.handleOpen.apply(null, arguments)}]}},[(_vm.isLoading)?_c('div',{staticClass:"d-flex full-width align-center"},[_c('v-skeleton-loader',{staticClass:"loading-icon mr-2",attrs:{"type":"avatar","height":"32px","width":"32px"}}),_vm._v(" "),_c('div',{staticClass:"flex-grow-1"},[_c('v-skeleton-loader',{staticClass:"loading-title",attrs:{"type":"text"}}),_vm._v(" "),_c('v-skeleton-loader',{staticClass:"loading-subtitle",attrs:{"type":"text"}})],1)],1):(_vm.selectedItem)?_c('v-layout',{attrs:{"align-center":""}},[_c('img',{staticClass:"mr-2 rounded-lg",attrs:{"height":"32px","src":_vm.selectedItem.icon,"alt":_vm.selectedItem.name ? _vm.filterSymbol(_vm.selectedItem.name) : ''}}),_vm._v(" "),_c('div',[_c('p',{staticClass:"fs18 font-weight-bold mb-0"},[_vm._v("\n          "+_vm._s(_vm.filterSymbol(_vm.selectedItem.name || ''))+"\n        ")]),_vm._v(" "),(
            _vm.loggedIn && _vm.wallets.length && _vm.selectedCoin && _vm.selectedCoin.network
          )?_c('p',{staticClass:"small-font mb-0"},[_vm._v("\n          "+_vm._s(_vm.$t('components.wallet.paymentPicker.balance'))+"\n          "+_vm._s(_vm._f("cryptoFormat")(_vm.coinBalance(_vm.selectedCoin)))+"\n        ")]):_vm._e()])]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-icon',{staticClass:"accentMediumDarkGray--text",attrs:{"size":"38"}},[_vm._v("\n      chevron_right\n    ")])],1),_vm._v(" "),_c('BottomDrawerDialog',{model:{value:(_vm.optionsDrawer),callback:function ($$v) {_vm.optionsDrawer=$$v},expression:"optionsDrawer"}},[_c('div',{staticClass:"secondary lighten-2 options-drawer pb-10 pt-4 px-12 payment-picker-options"},[_c('h2',{staticClass:"options-header text-center mb-4 pt-3 pb-2 px-4 pt-sm-5 pb-sm-4"},[_vm._v("\n        "+_vm._s(_vm.$t('components.wallet.paymentPicker.selectPaymentMethod'))+"\n      ")]),_vm._v(" "),(!_vm.siteConfigState.isLoading && !_vm.showWalletOptions)?_c('div',{staticClass:"full-width pb-4"},[_c('p',{class:{
            'text-left px-4 pb-3 mb-0': true,
            'medium-font': _vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('components.wallet.paymentPicker.selectGalaWallet'))+"\n        ")])]):_vm._e(),_vm._v(" "),(!_vm.siteConfigState.isLoading && _vm.showWalletOptions)?_c('div',{staticClass:"select-payment-modal secondary lighten-2 options-drawer",class:{
          'px-0 pb-2': _vm.$vuetify.breakpoint.xs,
        }},[(_vm.creditCardsAllowed)?_c('p',{class:{
            'text-left px-4 pb-3 mb-0': true,
            'medium-font': _vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('components.wallet.paymentPicker.cc'))+"\n        ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.fiatWallets),function(wallet,index){return (_vm.creditCardsAllowed)?[_c('button',{key:wallet.symbol,staticClass:"payment-method d-flex align-center full-width text-left mb-6 pa-2 px-sm-5 py-sm-3 rounded-pill",class:{
              'selected-coin': wallet.name === _vm.selectedItem?.name,
            },on:{"click":function($event){return _vm.handleOptionSelect(wallet)}}},[_c('span',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-3 rounded-lg",attrs:{"src":wallet.icon || wallet.imageUrl,"alt":""}}),_vm._v(" "),_c('span',{staticClass:"d-block"},[_c('span',{staticClass:"d-block text-no-wrap wallet-name"},[_vm._v("\n                  "+_vm._s(_vm.filterSymbol(wallet.name))+"\n                ")]),_vm._v(" "),(wallet.network)?_c('span',{staticClass:"d-block small-font mb-0"},[_vm._v("\n                  "+_vm._s(_vm.$t('components.wallet.paymentPicker.balance'))+"\n                  "+_vm._s(_vm._f("cryptoFormat")(_vm.coinBalance(wallet)))+"\n                ")]):_vm._e()])]),_vm._v(" "),_c('v-spacer',{attrs:{"tag":"span"}}),_vm._v(" "),(_vm.selectedItem?.name && wallet.name === _vm.selectedItem.name)?_c('v-icon',{attrs:{"color":"accentPurple"}},[_vm._v("\n              check\n            ")]):_vm._e()],1)]:_vm._e()}),_vm._v(" "),_c('p',{class:{
            'text-left px-5 mb-0': true,
            'pt-3 pb-1': _vm.$vuetify.breakpoint.xs,
            'pt-3 pb-1 medium-font': _vm.$vuetify.breakpoint.smAndUp,
            'mt-4': _vm.creditCardsAllowed,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('components.wallet.paymentPicker.selectGalaWallet'))+"\n        ")]),_vm._v(" "),_vm._l((_vm.paymentOptionWallets),function(wallet){return [_c('button',{key:wallet.symbol,staticClass:"payment-method d-flex align-center full-width text-left mb-6 pa-2 px-sm-5 py-sm-3 rounded-pill",class:{
              'selected-coin':
                _vm.selectedCoin && wallet.name === _vm.selectedCoin.name,
            },on:{"click":function($event){return _vm.handleOptionSelect(wallet)}}},[_c('span',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-3",attrs:{"src":wallet.icon || wallet.imageUrl,"alt":""}}),_vm._v(" "),_c('span',{staticClass:"d-block"},[_c('span',{staticClass:"d-block wallet-name"},[_vm._v("\n                  "+_vm._s(_vm.filterSymbol(wallet.name))+"\n                ")]),_vm._v(" "),(wallet.network)?_c('span',{staticClass:"d-block small-font mb-0"},[_vm._v("\n                  "+_vm._s(_vm.$t('components.wallet.paymentPicker.balance'))+"\n                  "+_vm._s(_vm._f("cryptoFormat")(_vm.coinBalance(wallet)))+"\n                ")]):_vm._e()])]),_vm._v(" "),_c('v-spacer',{attrs:{"tag":"span"}}),_vm._v(" "),(_vm.selectedCoin && wallet.name === _vm.selectedCoin.name)?_c('v-icon',{attrs:{"color":"accentPurple"}},[_vm._v("\n              check\n            ")]):_vm._e()],1)]})],2):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }