import {
  TAdaptor,
  TTrackEvent,
  TSetUser,
  IHydrateAdaptorPayload,
  IHydratedAdaptor,
  EAdaptors,
  EEventTypes,
  TTRACKED_EVENTS,
  TInit,
  ITrackPurchaseItemEventPayload,
} from '../types';
import hydrateAdaptor from '../hydrateAdaptor';
import { hashData } from '../../../utils/hashData';

declare const window: Window & { dataLayer: Array<Record<string, unknown>> };

const TRACKED_EVENTS: TTRACKED_EVENTS = {
  [EEventTypes.REGISTRATION_COMPLETE]: 'registrationComplete',
  [EEventTypes.PURCHASE_ITEM]: 'purchaseItem',
  [EEventTypes.PURCHASE_NODE]: 'purchaseNode',
  // [EEventTypes.EVERGREEN_UA_REGISTRATION_COMPLETE]: ['config', 'AW-*'],
  // [EEventTypes.EVERGREEN_UA_EMAIL_SIGNUP_COMPLETE]: ['config', 'AW-*'],
  // [EEventTypes.PROJECT_SPECIFIC_UA_REGISTRATION_COMPLETE]: ['config', 'AW-*'],
  // [EEventTypes.PROJECT_SPECIFIC_UA_EMAIL_SIGNUP_COMPLETE]: ['config', 'AW-*'],
};

export const gtmAdaptor: TAdaptor = (adaptorPayload) => {
  const { enabled } = adaptorPayload.config;
  if (!enabled) {
    return undefined;
  }

  const { getCurrentUserEmail, getCurrentUserId } = adaptorPayload;

  const logEvent = (action: string, eventName: string, eventValue: any) => {
    return (window as any).gtag(action, eventName, eventValue);
  };

  const init: TInit = () => {
    const email = getCurrentUserEmail();
    if (email) {
      console.log('GTM init with email: ', email);
      logEvent('set', 'user_data', {
        sha256_email_address: [hashData(email.trim().toLowerCase())],
      });
    }
  };

  const trackEvent: TTrackEvent = (payload) => {
    const { eventName, eventValue } = payload;
    if (!(eventName in TRACKED_EVENTS)) {
      return;
    }
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    let eventContext = eventValue;
    switch (TRACKED_EVENTS[eventName]) {
      case 'registrationComplete':
        eventContext = {
          userId: eventValue?.id,
          referral: eventValue?.referralContext,
        };

        logEvent('set', 'user_data', {
          sha256_email_address: [
            hashData((eventValue?.email as string).trim().toLowerCase()),
          ],
        });

        logEvent('event', 'conversion', {
          send_to: 'AW-961300089/QP6bCKCNsbwZEPmMscoD',
          user_id: eventValue?.id,
          user_data: {
            sha256_email_address: [
              hashData((eventValue?.email as string).trim().toLowerCase()),
            ],
          },
        });

        window.dataLayer.push({
          event: TRACKED_EVENTS[eventName],
          context: eventContext,
        });
        break;
      case 'purchaseItem': {
        const { total, currency, item, orderId } =
          eventValue as ITrackPurchaseItemEventPayload;

        logEvent('event', 'purchase', {
          send_to: 'AW-961300089/qtEaCN7HhrwZEPmMscoD',
          user_id: getCurrentUserId(),
          user_data: {
            sha256_email_address: [
              hashData((getCurrentUserEmail() as string).trim().toLowerCase()),
            ],
          },
          transaction_id: orderId,
          value: total,
          currency,
          items: [
            {
              item_id: item.productId,
              item_name: item.productId,
              item_category: 'FILM-ITEM',
              quantity: item.quantity,
              price: item.unitPrice,
            },
          ],
        });

        break;
      }

      case 'purchaseNode': {
        const { total, currency, item, orderId } =
          eventValue as ITrackPurchaseItemEventPayload;

        logEvent('event', 'purchase', {
          send_to: 'AW-961300089/qtEaCN7HhrwZEPmMscoD',
          user_id: getCurrentUserId(),
          user_data: {
            sha256_email_address: [
              hashData((getCurrentUserEmail() as string).trim().toLowerCase()),
            ],
          },
          transaction_id: orderId,
          value: total,
          currency,
          items: [
            {
              item_id: item.productId,
              item_name: item.productId,
              item_category: 'FILM-NODE',
              quantity: item.quantity,
              price: item.unitPrice,
            },
          ],
        });

        break;
      }
    }
  };

  const hydrateAdaptorPayload: IHydrateAdaptorPayload = {
    init,
    trackEvent,
  };

  const baseAdaptor: IHydratedAdaptor = hydrateAdaptor(hydrateAdaptorPayload);

  const setUser: TSetUser = (currentUser) => {
    const userId = currentUser.id;
    if (userId && !window.dataLayer.some((item: any) => item.user_id)) {
      window.dataLayer.push({ user_id: userId || null });
      logEvent('set', 'user_data', {
        sha256_email_address: [
          hashData((currentUser?.email as string).trim().toLowerCase()),
        ],
      });
    }
  };

  const finalAdaptor: IHydratedAdaptor = {
    ...baseAdaptor,
    setUser,
  };

  return finalAdaptor;
};

gtmAdaptor.NAME = EAdaptors.GTM;

export default gtmAdaptor;
