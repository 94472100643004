import { Context } from '@nuxt/types';
import { TPromptToLoginDispatch } from '~/store/types';

export default async function (context: Context) {
  const { app, route, store, redirect } = context;
  const { dispatch } = store;

  const requireAuthentication = route.query.requireAuthentication === 'true';

  if (requireAuthentication) {
    const redirectPath = removeRequireAuthenticationParamFromRoute();

    const loggedIn = await app.$auth.isLoggedIn();
    if (loggedIn) {
      redirect(redirectPath);
    } else {
      await dispatch<TPromptToLoginDispatch>({
        type: 'promptToLogin',
        redirectAfterLoginPath: redirectPath,
      });
    }
  }
}

function removeRequireAuthenticationParamFromRoute() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(window.location.search);
  params.delete('requireAuthentication');

  const cleanedRoute =
    url.pathname + (params.toString() ? `?${params.toString()}` : '');
  return cleanedRoute;
}
