
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VuetifyParsedThemeItem } from 'vuetify/types/services/theme';
import { ThemeName } from '~/store/types';

type CSSVars = Record<string, string>;

@Component
export default class ThemeProvider extends Vue {
  @Prop({ type: String, default: 'div' })
  readonly tag!: keyof HTMLElementTagNameMap;

  @Prop({ type: String, default: ThemeName.DARK })
  readonly theme!: ThemeName;

  @Prop({ type: Boolean, default: true })
  readonly fillBackground!: boolean;

  themeNames = ThemeName;

  get style(): CSSVars | undefined {
    const bgColorVars = this.getCssVars('pageBackground', 'background', true);
    const textColorVars = this.getCssVars('textColor', 'text');
    const accentVars = this.getAccentVars();

    return {
      ...bgColorVars,
      ...textColorVars,
      ...accentVars,
    };
  }

  getColor(colorName: string) {
    const palette = this.$vuetify.theme.themes[this.theme];
    if (!palette[colorName] || typeof palette[colorName] === 'number') {
      return;
    }
    return palette[colorName];
  }

  getAccentVars() {
    const style: CSSVars = {};

    const accentColorText = this.getColor(
      this.isDark ? 'accentPurpleAccessible' : 'accentPurple',
    );
    if (accentColorText) {
      style[`--g-text-accent`] =
        typeof accentColorText === 'string'
          ? (accentColorText as string)
          : (accentColorText as Partial<VuetifyParsedThemeItem>)?.base || '';
    }
    const accentColorTextMuted = this.getColor('accentPurpleMuted');
    if (accentColorTextMuted) {
      style[`--g-text-accentMuted`] =
        typeof accentColorTextMuted === 'string'
          ? (accentColorTextMuted as string)
          : (accentColorTextMuted as Partial<VuetifyParsedThemeItem>)?.base ||
            '';
    }

    const accentColorBg = this.getColor('accentPurple');
    if (accentColorBg) {
      style[`--g-background-accent`] =
        typeof accentColorBg === 'string'
          ? (accentColorBg as string)
          : (accentColorBg as Partial<VuetifyParsedThemeItem>)?.base || '';
    }

    const accentColorBgMuted = this.getColor('accentPurpleLight');
    if (accentColorBgMuted) {
      style[`--g-background-accentMuted`] =
        typeof accentColorBgMuted === 'string'
          ? (accentColorBgMuted as string)
          : (accentColorBgMuted as Partial<VuetifyParsedThemeItem>)?.base || '';
    }

    return style;
  }

  getCssVars(
    colorName: string,
    cssVarPrefix: string,
    isBackgroundColor = false,
  ) {
    const themeColor = this.getColor(colorName);

    if (!themeColor) {
      return;
    }

    if (typeof themeColor === 'string') {
      return {
        // --g-background-primary, --g-text-primary
        [`--g-${cssVarPrefix}-primary`]: themeColor,
      };
    }

    const color = themeColor as Partial<VuetifyParsedThemeItem>;
    const style: CSSVars = {
      [`--g-${cssVarPrefix}-primary`]: color.base || '',
    };

    switch (true) {
      case this.isDark && isBackgroundColor:
      case !this.isDark && !isBackgroundColor:
        style[`--g-${cssVarPrefix}-secondary`] = color.lighten1 || '';
        style[`--g-${cssVarPrefix}-tertiary`] = color.lighten2 || '';
        break;

      case !this.isDark && isBackgroundColor:
      case this.isDark && !isBackgroundColor:
        style[`--g-${cssVarPrefix}-secondary`] = color.darken1 || '';
        style[`--g-${cssVarPrefix}-tertiary`] = color.darken2 || '';
        break;
    }

    return style;
  }

  get isDark() {
    return this.theme === ThemeName.DARK;
  }
}
