import { UserItem } from '~/types/user-items';
import { ChainNetwork, EthereumTokenStandard } from '~/types/chain';

export function getUniqueInventoryPath(item: UserItem) {
  if (item.network === ChainNetwork.ETHEREUM && item.fungible) {
    return `eth_ft_${item.ethereumContractAddress}_${item.ethereumBaseId}`.toLowerCase();
  }

  if (
    item.network === ChainNetwork.ETHEREUM &&
    item.ethereumTokenStandard === EthereumTokenStandard.ERC1155
  ) {
    return `eth_nft_${item.ethereumContractAddress}_${item.ethereumFullId}`.toLowerCase();
  }

  if (
    item.network === ChainNetwork.ETHEREUM &&
    item.ethereumTokenStandard === EthereumTokenStandard.ERC721
  ) {
    return `eth_nft_${item.ethereumContractAddress}_${item.nonFungibleInstanceId}`.toLowerCase();
  }

  if (item.network === ChainNetwork.ETH_TREASURE_CHEST) {
    return `tc_${item.treasureChestExportDetails[0].contractAddress}_${item.ethereumBaseId}`.toLowerCase();
  }

  if (item.network === ChainNetwork.GYRI) {
    return `gyri_${item.gyriTokenClassKey.collection}_${item.gyriTokenClassKey.category}_${item.gyriTokenClassKey.type}_${item.gyriTokenClassKey.additionalKey}_${item.nonFungibleInstanceId}`.toLowerCase();
  }

  if (item.network === ChainNetwork.OFF_CHAIN_TOKEN) {
    return `off_chain_token_${item.gyriTokenClassKey.collection}_${item.gyriTokenClassKey.category}_${item.gyriTokenClassKey.type}_${item.gyriTokenClassKey.additionalKey}_${item.nonFungibleInstanceId}`.toLowerCase();
  }

  if (item.network === ChainNetwork.GYRI_TREASURE_CHEST) {
    return `galachain_allowance_${item.allowanceType}_${item.gyriTokenClassKey.collection}_${item.gyriTokenClassKey.category}_${item.gyriTokenClassKey.type}_${item.gyriTokenClassKey.additionalKey}`.toLowerCase();
  }

  console.warn(`Could not calculate a unique inventory path for`, item);

  return '';
}

export function getItemGyriId(item: UserItem, includeInstanceId = true) {
  if (
    (item?.network !== ChainNetwork.GYRI &&
      item?.network !== ChainNetwork.OFF_CHAIN_TOKEN) ||
    !item?.gyriTokenClassKey
  ) {
    return;
  }

  const { collection, category, type, additionalKey } = item.gyriTokenClassKey;

  const gyriId = `${collection}|${category}|${type}|${additionalKey}`;
  if (includeInstanceId) {
    return `${gyriId}|${item.nonFungibleInstanceId}`;
  }
  return gyriId;
}
