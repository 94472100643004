
import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
import SanityPromoItemNav from '~/mixins/SanityPromoItemNav';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';
import SanityReference from '~/components/Sanity/SanityReference.vue';

@Component({
  components: {
    SanityReference,
  },
})
export default class PromoMenuItem extends mixins(SanityPromoItemNav) {
  @Prop(Object) readonly doc!: ISanityDocuments[SanityDocumentSchema.PROJECT];
  @Prop(Boolean) readonly loading!: boolean;
  @Prop({ type: Number, default: 100 })
  readonly height!: number;
  @Prop({ type: Number, default: 232 })
  readonly width!: number;

  schemas = SanityDocumentSchema;

  get heroImage() {
    return this.doc?.promoCard?.heroImageLandscape;
  }

  get logo() {
    return this.doc?.promoCard?.logo;
  }

  get badge() {
    return this.item?.badges?.[0] || undefined;
  }

  @Watch('ctaUrl', { immediate: true })
  handleCtaUrlChange(
    current: string | undefined,
    previous: string | undefined,
  ) {
    if (!current || current === previous) {
      return;
    }
    this.$emit('nav-item', current);
  }
}
