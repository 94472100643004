
import { Component, Vue, Prop } from 'vue-property-decorator';
import { State, Getter, Mutation } from 'vuex-class';
import { ICreateWalletPrompt, WalletType } from '~/store/types';
import { InputSize } from '~/types/vuetify';

@Component({})
export default class TriggerWalletCreationBtn extends Vue {
  @State((profile) => profile.user.walletConnected, { namespace: 'profile' })
  walletConnected!: boolean;

  @Prop({ type: String, default: 'white' })
  readonly color!: string;
  @Prop({ type: String, default: 'large' })
  readonly size!: InputSize;
  @Prop({ type: String, default: 'black' })
  readonly textColor!: string;
  @Prop({ type: String, default: () => WalletType.ETH })
  readonly walletType!: WalletType;

  @Mutation('toggleCreateWalletPrompt')
  private toggleCreateWalletPrompt!: (
    payload?: Partial<ICreateWalletPrompt>,
  ) => void;

  @Getter('w3wConnectionActive', { namespace: 'web3Wallet' })
  readonly w3wConnectionActive!: boolean;

  showWalletModals = false;
  showWeb3WalletModals = false;
  walletTypes = WalletType;
  w3wConnectionEnabled = process.env.w3wConnectionEnabled;
  walletExists = true;

  get buttonText() {
    return this.walletType === WalletType.GYRI
      ? this.$t('components.newWallet.triggerWalletCreationBtn.setTransferCode')
      : this.walletType === WalletType.ETH
      ? this.$t('components.newWallet.triggerWalletCreationBtn.connectWallet')
      : this.$t('components.newWallet.triggerWalletCreationBtn.finish');
  }

  async created() {
    const { createWallet } = this.$route.query;
    if (createWallet) {
      this.showWalletModals = true;
    }
  }

  handleClick() {
    this.$emit('clicked');
    this.toggleCreateWalletPrompt({
      isStarted: true,
      show: true,
      walletType: this.walletType,
    });
  }
}
