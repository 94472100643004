import { Context } from '@nuxt/types';
export default function (context: Context) {
  const { from } = context;
  const redirects = [
    {
      from: '/collections/spider-tank-project',
      to: '/collections/spider-tanks',
    },
    {
      from: '/collections/spider-tank-project/',
      to: '/collections/spider-tanks/',
    },
    {
      from: '/collections/spider-tank-project/your-items',
      to: '/collections/spider-tanks/your-items',
    },
    {
      from: '/submit-a-support-ticket/',
      to: '/contact-support',
    },
    {
      from: '/submit-a-support-ticket',
      to: '/contact-support',
    },
  ];
  const redirectPath = redirects.find((r) => r.from === from.path);
  if (redirectPath) {
    window.location.pathname = redirectPath.to;
  }
}
