import { WalletConnectionErrorCode } from '~/store/web3_wallet/types';
import { IVueI18n } from 'vue-i18n';

export class WalletConnectionError extends Error {
  cause?: WalletConnectionErrorCode;
  code?: number;

  constructor(message: string, cause?: WalletConnectionErrorCode) {
    super(message);
    this.cause = cause;
  }
}

export const walletErrorTitleFromCode = (
  code: WalletConnectionErrorCode,
  i18n: IVueI18n,
  provider = '',
) => {
  switch (code) {
    case WalletConnectionErrorCode.ADDRESS_MISMATCH:
    case WalletConnectionErrorCode.GALA_WALLET_HOLDER:
      return i18n
        .t(
          'components.wallet.connectWeb3Wallet.errorMessages.addressMismatchShort',
        )
        .toString();

    case WalletConnectionErrorCode.INVALID_NETWORK:
      return i18n
        .t('components.wallet.connectWeb3Wallet.errorMessages.invalidNetwork')
        .toString();

    case WalletConnectionErrorCode.MISSING_BROWSER_CONNECTION:
      return i18n
        .t(
          'components.home.walletConnectionErrorBanner.missingBrowserConnectionShort',
        )
        .toString();

    case WalletConnectionErrorCode.MISSING_PROVIDER:
      return provider
        ? i18n
            .t(
              'components.wallet.connectWeb3Wallet.installProviderDialog.titleWithName',
              { provider },
            )
            .toString()
        : i18n
            .t(
              'components.wallet.connectWeb3Wallet.installProviderDialog.title',
            )
            .toString();
  }

  return '';
};
