/**
 * These utils exist for 2 purposes:
 * 1. Symbols (for product prices) are stored in the database differently from how they are returned by wallet service.
 *    - In the db, ETH prices are stored as `symbol`, but the wallet service returns them as `symbol[ETH]`. In other words, no suffix is assumed to be ETH.
 *    - In wallet service, all symbols are suffixed with the network they are on.
 * 2. A user needs to have a wallet of a certain type to be able to pay with a given currency, or perform an action on a given network.
 */
import { IGameItemProductPrice } from '~/store/games/types';
import { IWallet } from '~/store/inventory/types';
import { WalletType } from '~/store/types';
import { ChainNetwork } from '~/types/chain';

export type FormattedSymbol =
  | 'ETH'
  | `${string}[ETH]`
  | `${string}[GC]`
  | `${string}[GYRI]`;

export const isFormattedSymbol = (
  symbol: string,
): symbol is FormattedSymbol => {
  return (
    symbol === 'ETH' ||
    symbol.endsWith('[ETH]') ||
    symbol.endsWith('[GC]') ||
    symbol.endsWith('[GYRI]')
  );
};

export const formatSymbol = (symbol: string) => {
  if (isFormattedSymbol(symbol)) {
    return symbol;
  }
  // assume ETH if not formatted
  return `${symbol}[ETH]` as FormattedSymbol;
};

export const isCreditCard = (symbol: string) => {
  return symbol.startsWith('CC');
};

const creditCardWallet: Partial<IWallet> = {
  name: 'Credit Card',
  // icon: require(`@/assets/logos/USD-icon.png`),
  icon: require(`@/assets/logos/credit-card.svg`),
  symbol: 'CC-STRIPE',
};

const creditCardPrice = (price: IGameItemProductPrice) => {
  const priceValue = price.usdPriceInCents
    ? price.usdPriceInCents
    : price.usdBasePriceInCents;
  const priceString = (Math.round(priceValue * 100) / 100 / 100)
    .toFixed(2)
    .toString();
  const basePriceValue = price.usdBasePriceInCents;
  const basePriceString = (Math.round(basePriceValue * 100) / 100 / 100)
    .toFixed(2)
    .toString();

  return {
    price: priceString,
    basePrice: basePriceString,
    usdPriceInCents: priceValue,
    usdBasePriceInCents: basePriceValue,
    symbol: 'CC',
    imageURL: '',
    creditCard: true,
    creditCardData: null,
  };
};

export const findWalletBySymbol = (symbol: string, wallets: IWallet[]) => {
  if (!symbol) {
    return;
  }

  if (isCreditCard(symbol)) {
    return creditCardWallet;
  }

  const formattedSymbol = formatSymbol(symbol);
  return wallets.find(
    (wallet) => !!formattedSymbol && formattedSymbol === wallet.symbol,
  );
};

export const findPriceBySymbol = (
  symbol: string,
  prices: IGameItemProductPrice[],
) => {
  if (!symbol) {
    return;
  }

  if (isCreditCard(symbol)) {
    return creditCardPrice(prices[0]);
  }

  const formattedSymbol = formatSymbol(symbol);
  return prices.find((price) => {
    const formattedPriceSymbol = formatSymbol(price.symbol);
    return formattedSymbol === formattedPriceSymbol;
  });
};

export const symbolToNetwork = (symbol?: string) => {
  if (!symbol || isCreditCard(symbol)) {
    return;
  }
  if (isFormattedSymbol(symbol)) {
    if (symbol === 'ETH' || symbol.endsWith('[ETH]')) {
      return ChainNetwork.ETHEREUM;
    }
    // soon [GC] will be GYRI allowances
    if (symbol.endsWith('[GC]')) {
      return ChainNetwork.ETH_TREASURE_CHEST;
    }
    if (symbol.endsWith('[GYRI]')) {
      return ChainNetwork.GYRI;
    }
  }
  // assume ETH if not formatted
  return ChainNetwork.ETHEREUM;
};

export const requiredWalletTypeByNetwork = (network?: ChainNetwork) => {
  if (!network) {
    return;
  }
  if (
    network === ChainNetwork.ETHEREUM ||
    network === ChainNetwork.ETH_TREASURE_CHEST
  ) {
    return WalletType.ETH;
  }
  if (network === ChainNetwork.GYRI) {
    return WalletType.GYRI;
  }
  return;
};

export const requiredWalletTypeBySymbol = (symbol?: string) => {
  const network = symbolToNetwork(symbol);
  return requiredWalletTypeByNetwork(network);
};
