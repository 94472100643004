
import { Component, Vue, Prop } from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag';

@Component({
  components: { CountryFlag },
})
export default class LanguageSelectItem extends Vue {
  @Prop({ type: String, required: true })
  readonly code!: string;
  @Prop({ type: String, required: true })
  readonly displayText!: string;
  @Prop({ type: String, required: true })
  readonly flagCode!: string;
  @Prop({ type: String, required: true })
  readonly name!: string;

  get label() {
    const i18nPath = `components.languageSwitcher.languageAbbreviations.${this.code}`;
    return this.$te(i18nPath) ? this.$t(i18nPath) : this.displayText;
  }

  get labelSr() {
    const i18nPath = `components.languageSwitcher.languageNames.${this.code}`;
    return this.$te(i18nPath) ? this.$t(i18nPath) : this.name;
  }
}
