import { Context } from '@nuxt/types';
import { ReCaptchaInstance } from '@nuxtjs/recaptcha';
import { RecaptchaActionType } from '~/types/recaptcha';

export class RecaptchaService {
  context: Context;

  constructor(context: Context) {
    this.context = context;
  }

  async execute(action: RecaptchaActionType) {
    const recaptcha: ReCaptchaInstance = this.context.app.$recaptcha;
    return recaptcha.execute(action);
  }
}
