var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sanity-media-carousel"},[(_vm.title)?_c(_vm.titleTag,{tag:"component",class:_vm.titleClass},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"my-n2",style:({
      '--slide-aspect-ratio': _vm.aspectRatio,
      '--slide-width': `${_vm.width}px`,
      position: 'relative',
    })},[_c('div',{ref:"swiperContainer",staticClass:"swiper-container overflow-hidden py-2"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.items),function(item,i){return _c('div',{key:`slide-${i}`,class:{
            'swiper-slide': true,
            'last-in-carousel': i === _vm.items.length - 1,
          }},[_c('SanityReference',{attrs:{"id":item._ref,"types":[_vm.schemas.VIDEO, _vm.schemas.FIGURE]},scopedSlots:_vm._u([{key:"default",fn:function({ doc, isLoading }){return [_vm._t("slide",function(){return [(!isLoading)?_c('div',[(doc._type === _vm.schemas.FIGURE)?[_c('ViewFigureDialog',{attrs:{"thumb-aspect-ratio":_vm.aspectRatio,"thumb-width":_vm.width,"figure-id":doc._id,"figure-data":doc,"activatorLabel":_vm.$t(
                          'components.sanity.viewFigureDialog.activatorLabelWithTitle',
                          { title: doc.title },
                        )}})]:_vm._e(),_vm._v(" "),(doc._type === _vm.schemas.VIDEO)?[_c('WatchVideoDialog',{attrs:{"thumb-aspect-ratio":_vm.aspectRatio,"thumb-width":_vm.width,"id":doc._id,"activator-type":'thumbnail',"activatorLabel":_vm.$t(
                          'components.sanity.watchVideoDialog.activatorLabelWithTitle',
                          { title: doc.title },
                        )}})]:_vm._e()],2):_vm._e()]},{"doc":doc,"isLoading":isLoading})]}}],null,true)})],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"swiper-controls"},[_c('v-btn',{staticClass:"rounded-circle swiper-nav-button sanity-media-swiper-button-prev",attrs:{"color":"transparent","fab":"","small":"","depressed":"","id":_vm.$id('prev-btn')}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_vm._v(" "),(_vm.pagination)?_c('div',{staticClass:"mx-4",attrs:{"id":_vm.$id('pagination')}}):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"rounded-circle swiper-nav-button sanity-media-swiper-button-next",attrs:{"color":"transparent","fab":"","small":"","depressed":"","id":_vm.$id('next-btn')}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }