import { Context } from '@nuxt/types';

export default async function (context: Context) {
  const { redirect, query, store } = context;

  const { session_token, state } = query;
  try {
    if (!session_token || !state) {
      redirect('/');
    }

    // check if user is logged in
    const userId = store?.state?.profile?.user?.id;

    if (userId) {
      redirect('/');
    }
  } catch (error) {
    redirect('/');
  }
}
