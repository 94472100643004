import { Plugin } from '@nuxt/types';
import { RecaptchaService } from './recaptchaService';

export type IRecaptchaServiceLoader = InstanceType<typeof RecaptchaService>;

declare module 'vue/types/vue' {
  // tslint:disable-next-line: interface-name
  interface Vue {
    $recaptchaService: IRecaptchaServiceLoader;
  }
}

declare module '@nuxt/types' {
  // tslint:disable-next-line: interface-name
  interface NuxtAppOptions {
    $recaptchaService: IRecaptchaServiceLoader;
  }

  // tslint:disable-next-line: interface-name
  interface Context {
    $recaptchaService: IRecaptchaServiceLoader;
  }
}

declare module 'vuex/types/index' {
  // tslint:disable-next-line: interface-name
  interface Store<S> {
    $recaptchaService: IRecaptchaServiceLoader;
  }
}

const RecaptchaServicePlugin: Plugin = (ctx, inject) => {
  const plugin = new RecaptchaService(ctx);
  inject('recaptchaService', plugin);
};

export default RecaptchaServicePlugin;
