import { Context } from '@nuxt/types';
import { TPromptToLoginDispatch } from '~/store/types';

export default async function (context: Context) {
  const { app, route, store } = context;
  const { dispatch } = store;

  if (!(await app.$auth.isLoggedIn())) {
    await dispatch<TPromptToLoginDispatch>({
      type: 'promptToLogin',
      redirectAfterLoginPath: route.fullPath,
    });
  }
}
