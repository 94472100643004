import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class HoverActivation extends Vue {
  hoverActivationDelayClose = 400;
  hoverActivationDelayOpen = 50;
  hoverActivationTimeout: ReturnType<typeof setTimeout> | null = null;
  isHoverActivated = false;

  beforeDestroy() {
    this.cancelHoverActivationEvent();
  }

  cancelHoverActivationEvent() {
    if (this.hoverActivationTimeout) {
      clearTimeout(this.hoverActivationTimeout);
      this.hoverActivationTimeout = null;
    }
  }

  handleHoverActivationMouseEnter() {
    this.cancelHoverActivationEvent();
    if (!this.isHoverActivated) {
      this.hoverActivationTimeout = setTimeout(() => {
        this.isHoverActivated = true;
        this.hoverActivationTimeout = null;
      }, this.hoverActivationDelayOpen);
    }
  }

  handleHoverActivationMouseLeave() {
    this.cancelHoverActivationEvent();
    if (this.isHoverActivated) {
      this.hoverActivationTimeout = setTimeout(() => {
        this.isHoverActivated = false;
        this.hoverActivationTimeout = null;
      }, this.hoverActivationDelayClose);
    }
  }
}
