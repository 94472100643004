
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class TwoFactorBox extends Vue {
  @Prop(String) readonly value!: string;
  @Prop({ type: Boolean, default: false }) readonly autoFocus!: boolean;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  get validLength() {
    return this.value.length === 6;
  }

  mounted() {
    this.$emit('disable-btn', !this.validLength);
    if (this.autoFocus) {
      (this.$refs.twoFaInput as any).focus();
    }
  }

  validNumber(val: string) {
    const regex = /^\d*$/g;
    return (
      regex.test(val) ||
      this.$t('components.authentication.twoFactorBox.numericalPrompt')
    );
  }

  handleInput(val: any) {
    this.$emit('input', val);
  }

  @Watch('validLength')
  handleDisableBtn(val: boolean) {
    this.$emit('disable-btn', !val);
  }
}
