import {
  TAdaptor,
  IHydrateAdaptorPayload,
  IHydratedAdaptor,
  EAdaptors,
  TInit,
  TTrackEvent,
  EEventTypes,
  TTRACKED_EVENTS,
  TSetUser,
} from '../types';
import hydrateAdaptor from '../hydrateAdaptor';

const TRACKED_EVENTS: TTRACKED_EVENTS = {
  [EEventTypes.PAGE_VIEW]: true,
  [EEventTypes.SET_USER]: true,
};

type TVgo = (...params: any[]) => void;
export const activeCampaignAdaptor: TAdaptor = (adaptorPayload) => {
  const { enabled, accountId } = adaptorPayload.config;

  if (!enabled || !accountId) {
    return undefined;
  }

  const { getCurrentUserEmail } = adaptorPayload;

  const vgo: TVgo = (...params) => (window as any).vgo(...params);

  const init: TInit = () => {
    const snippet = `(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement('script');r.src=o;i=t.getElementsByTagName('script')[0];i.parentNode.insertBefore(r,i)})(window,document,'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js','vgo');`;

    const scriptId = 'activeCampaign';
    let script = document.getElementById(scriptId);
    if (!script) {
      script = document.createElement('script');
      script.id = scriptId;
      script.innerHTML = snippet;
      document.head.appendChild(script);
    }
  };

  const trackEvent: TTrackEvent = (payload) => {
    const { eventName } = payload;
    if (!(eventName in TRACKED_EVENTS)) {
      return;
    }

    try {
      const email = payload?.email ?? getCurrentUserEmail();
      vgo('setAccount', accountId);
      vgo('setTrackByDefault', true);
      if (email) {
        vgo('setEmail', email);
      }

      try {
        // AS: AC doesn't dynamically update their urls so need to manually do it
        // @ts-ignore
        window?.visitorGlobalObject?._visitors[accountId]?.updateUrl(
          payload?.eventValue?.url ?? window.location.href,
        );
      } catch (e) {
        // ignore
      }

      vgo('process');
    } catch (e) {
      // ac tracking not installed, bailing
      console.log('##AC ERROR', e);
    }
  };

  const hydrateAdaptorPayload: IHydrateAdaptorPayload = {
    init,
    trackEvent,
  };

  const baseAdaptor: IHydratedAdaptor = hydrateAdaptor(hydrateAdaptorPayload);

  const setUser: TSetUser = (currentUser) => {
    baseAdaptor.trackSetUserEvent(currentUser);
  };

  const finalAdaptor: IHydratedAdaptor = {
    ...baseAdaptor,
    setUser,
  };

  return finalAdaptor;
};

activeCampaignAdaptor.NAME = EAdaptors.ACTIVE_CAMPAIGN;

export default activeCampaignAdaptor;
