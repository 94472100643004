import { Context } from '@nuxt/types';
import { IGame } from '~/store/games/types';

export default async function (context: Context) {
  const { store, params, redirect } = context;

  const isValidItemCollection = store.state.games.games.some(
    (game: IGame) => game.collection === params.category,
  );

  if (!isValidItemCollection) {
    return redirect('/my-collection');
  }
}
