import { render, staticRenderFns } from "./PromoBoxExplainer.vue?vue&type=template&id=22a58d1b&scoped=true"
import script from "./PromoBoxExplainer.vue?vue&type=script&lang=ts&setup=true"
export * from "./PromoBoxExplainer.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./PromoBoxExplainer.vue?vue&type=style&index=0&id=22a58d1b&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a58d1b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SanityBlockContent: require('/builds/gala-games/gala-film/gala-film/src/components/Sanity/SanityBlockContent.vue').default})
