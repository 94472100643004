var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.fetching)?_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-progress-circular',{staticClass:"mb-6",attrs:{"color":"accentPurple","indeterminate":""}})],1):_c('div',[_c('v-layout',{attrs:{"mt-3":""}},[_c('div',{staticClass:"scroll-to-accept-wrapper"},[_c('div',{staticClass:"scroll-to-accept-container px-3 pt-2",staticStyle:{"padding-bottom":"100px"},domProps:{"innerHTML":_vm._s(_vm.sanitizedHtmlContent)},on:{"scroll":_vm.onScroll}})])]),_vm._v(" "),_c('v-layout',{attrs:{"mt-3":"","column":"","align":""}},[_c('v-row',{staticClass:"ma-0 mb-2",attrs:{"align":"center","justify":"center"}},[(_vm.checkboxDisabled)?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("\n          error_outline\n        ")]):_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("check_circle")]),_vm._v(" "),_c('p',{staticClass:"mb-0 ml-1 text-center text-caption",class:{
            'error--text': _vm.checkboxDisabled,
            'success--text': !_vm.checkboxDisabled,
          },attrs:{"id":_vm.$id('scroll-to-accept')}},[_vm._v("\n          "+_vm._s(_vm.$t('components.legalAgreements.scrollToAccept.scrollPrompt', {
              documentName: _vm.documentName,
            }))+"\n        ")])],1),_vm._v(" "),_c('AgreementStatement',{attrs:{"agreement-text":_vm.$t(
            'components.legalAgreements.agreementStatement.defaultAgreementA11y',
            { name: _vm.documentName },
          ),"aria-describedby":_vm.$id('scroll-to-accept'),"checkbox-disabled":_vm.checkboxDisabled},on:{"input":_vm.toggleOptIn},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }