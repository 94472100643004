var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-layout',{attrs:{"wrap":""}},[(!_vm.valid)?_c('v-flex',{staticClass:"confirm-mnemonic-container",attrs:{"xs12":"","text-center":""}},[(!_vm.confirmedWords.length)?_c('h1',[_vm._v("\n      "+_vm._s(_vm.$t('components.newWallet.newWallet.confirmRecoveryPhrase'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.confirmedWords.length === 1)?_c('h1',{staticClass:"mb-5"},[_vm._v("Correct!")]):_vm._e(),_vm._v(" "),(_vm.confirmedWords.length === 2)?_c('h1',{staticClass:"mb-5"},[_vm._v("You got it!")]):_vm._e(),_vm._v(" "),(_vm.confirmedWords.length === 0)?_c('p',{staticClass:"mt-0 px-12 confirm-message"},[_vm._v("\n      "+_vm._s(_vm.$t('components.newWallet.confirmMnemonic.confirm'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.wordsToConfirm.length)?_c('p',{staticClass:"notranslate font-weight-medium word-selection mt-7 px-12 mb-1"},[_vm._v("\n      "+_vm._s(_vm.$t('components.newWallet.confirmMnemonic.selectWord', {
          number: _vm.formattedNumber(
            _vm.wordsToConfirm[_vm.currentWordToConfirmIndex].order + 1,
          ),
        }))+"\n    ")]):_vm._e()]):_vm._e(),_vm._v(" "),(!_vm.valid)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"justify-center":"","pt-2":"","wrap":""}},_vm._l((_vm.randomWords),function(word){return _c('button',{key:word.word,class:{
          'gala-btn ma-2 recovery-phrase-button': true,
          notranslate: true,
          accentPurple: !_vm.incorrectGuess,
          'invalid error': word.word === _vm.incorrectGuess,
          'accentLight textColor--text':
            _vm.incorrectGuess && word.word !== _vm.incorrectGuess,
        },attrs:{"translate":"no","disabled":!!_vm.incorrectGuess},on:{"click":function($event){return _vm.checkIfCorrect(word)}}},[_vm._v("\n        "+_vm._s(word.word)+"\n      ")])}),0)],1):_vm._e(),_vm._v(" "),(_vm.incorrectGuess)?_c('v-flex',{attrs:{"text-center":""}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.tryAgain}},[_vm._v("\n      "+_vm._s(_vm.$t('components.newWallet.confirmMnemonic.tryAgain'))+"\n    ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }