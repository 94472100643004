
import { SanityDocument as SanityClientDocument } from '@sanity/client';
import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import SanityDocumentMixin from '~/mixins/SanityDocument';

@Component
export default class SanityDocument extends mixins(SanityDocumentMixin) {
  @Prop(String) readonly id!: string;
  @Prop(String) readonly slug!: string;
  @Prop(String) readonly type!: SanityDocumentSchema;

  addClasses = true;

  get docState() {
    if (!this.type) {
      return;
    }

    return this.$sanityLookup.getDocumentFromIdOrSlug(
      {
        _id: this.id,
        slug: this.slug,
        _type: this.type,
      },
      this.shouldUseDraft,
    );
  }

  get refState() {
    return this.$sanityLookup.getReference({ _ref: this.id });
  }

  get slugState() {
    return this.$sanityLookup.getSlug({ slug: this.slug, _type: this.type });
  }

  get isLoading() {
    return (
      this.docState?.isLoading ||
      this.slugState?.isLoading ||
      this.refState?.isLoading
    );
  }

  get doc(): SanityClientDocument {
    return (
      this.docState?.data || {
        _type: '',
        _id: '',
        _rev: '',
        _createdAt: '',
        _updatedAt: '',
      }
    );
  }

  render() {
    return this.$scopedSlots.default?.({
      docState: this.docState || {
        isLoading: false,
        hasError: false,
        data: undefined,
      },
      refState: this.refState || {
        isLoading: false,
        hasError: false,
        data: undefined,
      },
      slugState: this.slugState || {
        isLoading: false,
        hasError: false,
        key: undefined,
      },
      isLoading: this.isLoading || false,
      doc: this.doc,
    });
  }

  async fetch() {
    if (!this.type || !(this.id || this.slug)) {
      return;
    }

    await this.fetchDocument();
  }

  async fetchDocument() {
    const docLookup = {
      slug: this.slug,
      _id: this.id,
      _type: this.type,
    };

    if (!!this.$sanityLookup.getDocumentFromIdOrSlug(docLookup)?.data) {
      return;
    }

    this.$sanityLookup.fetchDocument({
      ...docLookup,
      fetchReferences: this.fetchDocumentReferences,
    });
  }

  @Watch('id')
  handleIdChange(current: string, previous: string) {
    if (current !== previous) {
      this.fetchDocument();
    }
  }

  @Watch('type')
  handleTypeChange(current: string, previous: string) {
    if (current !== previous) {
      this.fetchDocument();
    }
  }

  @Watch('slug')
  handleSlugChange(current: string, previous: string) {
    if (current !== previous) {
      this.fetchDocument();
    }
  }
}
