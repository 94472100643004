import { StreamTypes } from '@mux/playback-core';
import { IMuxAssetData } from '~/types/mux-asset';

export type TStreamTypeKeys = keyof typeof StreamTypes;
export type TStreamTypeValues = (typeof StreamTypes)[TStreamTypeKeys];
export const MuxStreamTypes = StreamTypes;

export interface IVideoPlayerOptions {
  aspectRatio: IMuxAssetData['aspect_ratio'];
  shouldShowControls: boolean;
  shouldShowTitle: boolean;
}

// Additional metadata that we can attach to the Mux player
// via the metadata prop on the mux-player element.

// Note: @mux/playback-core exports a "Metadata" type, but there's an issue
// that results in it being typed as "any" which isn't useful, so we write our own types.

// There are a lot of options; these are only the ones we're most likely to use.
// All metadata options can be found here:
// https://docs.mux.com/guides/data/make-your-data-actionable-with-metadata
export interface IVideoMetadata {
  page_type: 'watchpage' | 'iframe' | ''; // "watchpage" is a standalone page; "iframe" for embedded players
  player_init_time: number;
  player_name: string; // for internal data reporting
  player_version: string; // for internal data reporting
  video_content_type: string; // for internal data reporting
  video_duration: number;
  video_id: string; // internal ID
  video_language_code: string;
  video_producer: string;
  video_series: string;
  video_title: string;
  viewer_user_id: string; // anonymized viewer ID
}

// See https://github.com/muxinc/elements/tree/main/packages/mux-player#hiding-controls
type TAllControls = 'controls' | `${TSections}-controls`;
type TButtons =
  | 'airplay'
  | 'captions'
  | 'cast'
  | 'fullscreen'
  | 'mute'
  | 'play'
  | 'playback-rate'
  | 'pip'
  | 'seek-backward'
  | 'seek-forward'
  | 'seek-live';
type TButtonVars = `${TButtons}-button` | `${TSections}-${TButtons}-button`;
type TRangeControls = 'time' | 'volume';
type TRangeVars =
  | `${TRangeControls}-range`
  | `${TSections}-${TRangeControls}-range`;
type TDurationControls = 'duration' | 'time';
type TDurationVars =
  | `${TDurationControls}-duration`
  | `${TSections}-${TDurationControls}-duration`;
type TSections = 'bottom' | 'center' | 'top';
type TMuxAllVars =
  | `--${TButtonVars}`
  | `--${TRangeVars}`
  | `--${TDurationVars}`
  | `--${TAllControls}`;
export type TMuxToggleControls = Record<
  TMuxAllVars,
  CSSStyleDeclaration['display']
>; // e.g. 'none', 'inline-flex', etc.
