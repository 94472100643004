
import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import HoverActivation from '~/mixins/HoverActivation';
import SanityReference from '~/components/Sanity/SanityReference.vue';
import PromoList from '~/components/Sanity/PromoList.vue';
import PromoMenuItem from './PromoMenuItem.vue';

@Component({
  components: {
    PromoList,
    PromoMenuItem,
    SanityReference,
  },
})
export default class PromoMenu extends mixins(HoverActivation) {
  @Prop(String) readonly slug!: string;
  @Prop(String) readonly id!: string;

  refSchema = SanityDocumentSchema.PROJECT;
  hasActiveItem = false;
  childPaths: Record<string, boolean> = {};

  get isExpanded() {
    return this.isHoverActivated;
  }

  set isExpanded(value: boolean) {
    this.isHoverActivated = value;
  }

  get activatorAttrs() {
    return {
      id: this.$id('promo-nav-activator'),
      ['aria-expanded']: this.isExpanded ? 'true' : 'false',
      ['aria-controls']: this.$id('promo-nav-menu'),
    };
  }

  get activatorEvents() {
    return {
      click: this.handleClick,
    };
  }

  handleNavItem(path: string) {
    if (!this.childPaths[path]) {
      this.childPaths[path] = true;
    }
    this.setHasActiveItem(this.$route.path);
  }

  setHasActiveItem(path: string) {
    this.hasActiveItem = !!path && !!this.childPaths[path];
  }

  handleBlur(event: FocusEvent) {
    if (!this.isExpanded) {
      return;
    }

    if (!event.relatedTarget) {
      this.isExpanded = false;
      return;
    }

    const hasFocus = (this.$refs.menu as HTMLElement).contains(
      event.relatedTarget as Node,
    );
    if (!hasFocus) {
      this.isExpanded = false;
    }
  }

  handleClick() {
    this.cancelHoverActivationEvent();
  }

  @Watch('$route.path')
  handlePathChange(current: string | undefined, previous: string | undefined) {
    if (current && current !== previous) {
      this.setHasActiveItem(current);
    }
  }
}
