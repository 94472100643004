
import { Component, Vue } from 'vue-property-decorator';
import DashboardSnackbar from '~/components/Dashboard/DashboardSnackbar.vue';
import { buildLayoutMeta } from '~/utils/htmlHead';
import { captureReferrals } from '~/utils/captureReferrals';

@Component({
  head() {
    return buildLayoutMeta(this as ExternalLayout);
  },
  components: { DashboardSnackbar },
})
export default class ExternalLayout extends Vue {
  mounted() {
    captureReferrals(this.$route.query);
  }
}
