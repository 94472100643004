import { MutationTree } from 'vuex';
import { ICreditCards, IProfileState, IUser } from './types';

const initialUserState: IUser = {
  preferences: {
    currency: 'USD',
    language: 'english',
    notifications: true,
  },
  profilePhotoUrl: '',
  id: '',
  email: '',
  emailVerified: undefined,
  phone: '',
  firstName: '',
  lastName: '',
  fullNameNotProvided: true,
  displayName: '',
  role: '',
  permissions: [],
  walletConnected: false,
  walletExists: false,
  twoFaAuthenticated: false,
  twoFaEnabled: false,
  loggedIn: false,
  gameToken: '',
  goldMember: false,
  discordProfile: undefined,
  networkNotifications: {},
  referralCode: '',
  isInBlockedCountry: false,
  shouldBlockGameplay: false,
  filmDistributionRewards: [],
  creditCards: [],
  hasGyriPassphrase: false,
  auth0UserIdentities: [],
};

export const mutations: MutationTree<IProfileState> = {
  updateIsFetching(profileState: IProfileState, payload: boolean) {
    profileState.isFetching = payload;
  },
  createCard(profileState: IProfileState, creditCard: any) {
    profileState.user.creditCards = [
      ...profileState.user.creditCards,
      creditCard,
    ];
  },
  deleteCard(profileState: IProfileState, cardId: string) {
    profileState.user.creditCards = [
      ...profileState.user.creditCards.filter(
        (creditCard) => creditCard.cardId !== cardId,
      ),
    ];
  },
  getCreditCards(profileState: IProfileState, creditCards: ICreditCards[]) {
    profileState.user.creditCards = [...creditCards];
  },
  updateUser(profileState: IProfileState, userInfo: Partial<IUser>) {
    const shouldBlockGameplay =
      new Date() > new Date('2022-05-20T15:00:00.000Z');
    profileState.user = {
      ...profileState.user,
      loggedIn: true,
      ...userInfo,
      shouldBlockGameplay,
    };
  },
  updateGameJWT(
    profileState: IProfileState,
    payload: { token: string; timeSet: number },
  ) {
    Object.assign(profileState.user, {
      gameToken: payload.token,
    });

    profileState.gameTokenTimeSet = payload.timeSet;
  },

  clearUser(profileState: IProfileState) {
    Object.assign(profileState.user, initialUserState);
  },

  updateFilmDistributionRewards(
    profileState: IProfileState,
    payload: Array<{ quantity: number; rewardId: string }>,
  ) {
    profileState.user.filmDistributionRewards = payload;
  },
};
