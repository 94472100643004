import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';

@Component
export default class SanityDocument extends Vue {
  @Prop({ type: Boolean, default: undefined })
  readonly showDrafts!: boolean | undefined;
  @Prop({ type: Boolean, default: false })
  readonly fetchDocumentReferences!: boolean;

  addClasses = false;
  classPrefix = 'sanity-document';

  get shouldUseDraft(): boolean | undefined {
    // undefined => prefer drafts but use published if no draft exists
    // true => return draft version
    // false => return published version
    return this.showDrafts === undefined
      ? undefined
      : this.showDrafts && !!this.$sanity.config?.withCredentials;
  }

  mounted() {
    if (this.addClasses) {
      this.$el?.classList?.add(this.classPrefix);
    }
  }

  @Watch('doc._type', { immediate: true })
  handleDocTypeChange(current: string, previous: string) {
    if (!this.addClasses) {
      return;
    }
    if (previous) {
      this.$el?.classList?.remove(this.getClass(previous));
    }
    if (current) {
      this.$el?.classList?.add(this.getClass(current));
    }
  }

  @Watch('doc._id', { immediate: true })
  handleDraftStateChange(current: string) {
    if (!this.addClasses) {
      return;
    }
    const isDraft = this.$sanityLookup.isDraftId(current);
    const isDraftClass = this.getClass(`is-draft`);
    if (isDraft) {
      this.$el?.classList?.add(isDraftClass);
    } else {
      this.$el?.classList?.remove(isDraftClass);
    }
  }

  getClass(value?: string): string {
    if (!value) {
      return '';
    }

    return `${this.classPrefix}--${value}`;
  }
}
