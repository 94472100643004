import { ICTA } from './cta';
import { IGyriUserItem, UserItem, TCustomRevealUserItem } from './user-items';
import { ChainNetwork } from '~/types/chain';
import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { Component } from 'vue';

export type PosterOverview = Pick<
  IPoster,
  'posterId' | 'title' | 'subtitle' | 'completedImageUrl' | 'promotionImageUrl'
>;

export enum EPosterType {
  UNLOCKABLE_VIDEO = 'UnlockableVideo',
  REDEEMABLE_PRIZE = 'RedeemablePrize',
}

export interface IPoster {
  posterId: string;
  projectTitle: string;
  projectSlug: string;
  title: string;
  subtitle: string;
  isCompleted: boolean;
  unlockableVideoRef: ISanityVideoRef;
  clips: ISanityVideoRef[];
  promotionImage: SanityImageObject;
  promotionImageUrl: string;
  emptyImageUrl: string;
  completedImageUrl: string;
  projectCollectionsBackgroundUrl: string;
  mysteryBoxPromoImageUrl: string;
  shards: IShard[];
  rarity: IRarity;
  unlockedSetUrl: string;
  posterType?: EPosterType | null;
  exchangeId?: string | number;
}

export interface ISanityVideoRef {
  _id: string;
}

export interface IShard {
  gyriId: string;
  posterId: string;
  posterTitle: string;
  imageUrl: string;
  shardId: string;
  title: string;
  isOwned: boolean;
  totalOwned: number;
  overlayImageUrl: string;
  rarity: string;
  chain: ChainNetwork;
  item: UserItem;
  items: IGyriUserItem[];
}

export interface IPrize {
  gyriId: string;
  instanceId: string;
  title: string;
  rarity: IRarity;
  chain: ChainNetwork;
  imageUrl: string;
  projectSlug: string;
  projectTitle: string;
  item: UserItem;
  redeemed: boolean;
}

export interface IMysteryBox {
  gyriId: string;
  instanceId: string;
  title: string;
  rarity: IRarity;
  chain: ChainNetwork;
  imageUrl: string;
  projectSlug: string;
  projectTitle: string;
  item: TCustomRevealUserItem;
}

export interface IRarity {
  icon: string;
  type: string;
  hex?: string;
  label?: string;
}

export interface IScene {
  sceneSlug: string;
  projectTitle: string;
  projectSlug: string;
  title: string;
  subtitle: string;
  imageUrl: string;
  scenePageHeroImageUrl: string;
  isCompleted: boolean;
  video: ISanityVideoRef;
  videoTitle: string;
  moments: IMoment[];
  rarity: IRarity;
  unlockedSetUrl: string;
}

export interface IMoment {
  sceneSlug: string;
  sceneTitle: string;

  gyriId: string;
  imageUrl: string;
  secondaryImageUrl: string;
  momentSlug: string;
  title: string;
  isOwned: boolean;
  totalOwned: number;
  rarity: IRarity;
  chain: ChainNetwork;
  item: UserItem;
  items: IGyriUserItem[];
}

export interface IPack {
  gyriId: string;
  instanceId: string;
  title: string;
  rarity: IRarity;
  chain: ChainNetwork;
  imageUrl: string;
  projectSlug: string;
  projectTitle: string;
  item: TCustomRevealUserItem;
}

export interface ICollectible {
  gyriId: string;
  instanceId: string;
  title: string;
  rarity: IRarity;
  chain: ChainNetwork;
  imageUrl: string;
  projectSlug: string;
  projectTitle: string;
  item: UserItem;
}

export interface ISlider {
  redirectUrl: string;
  footerSidenote: string;
  images: IOverlayImage[];
  rarity: IRarity;
  showCenterImage: boolean;
  video: ISanityVideoRef;
}

export interface IOverlayImage {
  src: string;
  state?: EState;
}

export enum EState {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}

export type IPosterItem = ISlider & IPoster;
export type ISceneItem = ISlider & IScene;
export type ISliderItem = IPosterItem | ISceneItem;

export enum EStatsCardType {
  SPLIT = 'SPLIT',
  FULL = 'FULL',
}
export interface IStatsCard {
  type?: EStatsCardType.FULL;
  title: string;
  stats: string | number;
  statsClass?: string;
  icon: any;
  iconProps: any;
  mode: string;
  status?: string;
  class?: Record<string, boolean>;
  rightNote?: string;
  on?: Record<string, any>;
  rightSection?: Component;
  rightSectionProps?: Record<string, any>;
  cardContainerClass?: string;
  containerMinWidth?: string | number;
  containerMaxWidth?: string | number;
  containerWidth?: string | number;
}

export interface IStatsCardSplit {
  type?: EStatsCardType.SPLIT;
  upperTitle?: string;
  upperStatsText?: string;
  upperContainerClass?: string;
  lowerTitle?: string;
  lowerStatsText?: string;
  lowerContainerClass?: string;
  containerClass?: string;
  on?: Record<string, any>;
  lowClick?: () => void;
  lowerStatsLeftIcon?: Component;
  lowerStatsLeftIconProps?: Record<string, any>;
  lowerStatsRightIcon?: Component;
  lowerStatsRightIconProps?: Record<string, any>;
  upperStatsLeftIcon?: Component;
  upperStatsLeftIconProps?: Record<string, any>;
  upperContainerMinWidth?: number | string;
  lowerContainerMinWidth?: number | string;
  upperContainerMaxWidth?: number | string;
  lowerContainerMaxWidth?: number | string;
  containerMinWidth?: number | string;
  containerMaxWidth?: number | string;
  containerWidth?: number | string;
}

export type TStatsCard = IStatsCard | IStatsCardSplit;

export enum IInteractionState {
  COLLECTION = 'COLLECTION',
  CUSTOM_EXCHANGE = 'CUSTOM_EXCHANGE',
}

export interface ICtaCard {
  type: string;
  text: string;
  backgroundImage: string;
  logo: string;
  title?: string;
  cta?: ICTA;
}

export enum ECollectionCtaType {
  MYSTERY_BOX = 'mystery-box-card',
  MOMENT_PACK = 'moment-card',
  MB_COLLECTIBLES = 'mb-collectibles-cta',
  MB_COLLECTIBLES_COMPLETE = 'mb-collectibles-cta-complete',
  MOMENT_COLLECTIBLES = 'moment-collectibles-cta',
  MOMENT_COLLECTIBLES_COMPLETE = 'moment-collectibles-cta-complete',
  MB_UPSELL = 'mb-upsell-cta',
}
