import { Plugin } from '@nuxt/types';
import { MusicDataService } from './musicDataService';

export type IMusicDataServiceLoader = InstanceType<typeof MusicDataService>;

declare module 'vue/types/vue' {
  // tslint:disable-next-line: interface-name
  interface Vue {
    $musicDataService: IMusicDataServiceLoader;
  }
}

declare module '@nuxt/types' {
  // tslint:disable-next-line: interface-name
  interface NuxtAppOptions {
    $musicDataService: IMusicDataServiceLoader;
  }

  // tslint:disable-next-line: interface-name
  interface Context {
    $musicDataService: IMusicDataServiceLoader;
  }
}

declare module 'vuex/types/index' {
  // tslint:disable-next-line: interface-name
  interface Store<S> {
    $musicDataService: IMusicDataServiceLoader;
  }
}

const musicDataServicePlugin: Plugin = (ctx, inject) => {
  const plugin = new MusicDataService(ctx);
  inject('musicDataService', plugin);
};

export default musicDataServicePlugin;
