
import { Component, Vue } from 'nuxt-property-decorator';
import { State, Getter } from 'vuex-class';

import { IDebugOptions, IRootState } from '~/store/types';
import DebugTool from '~/components/Dashboard/DebugTool.vue';
import { TAsyncStateWithDoc } from '~/store/documents/types';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';

@Component({
  components: {
    DebugTool,
  },
})
export default class DebugToolBar extends Vue {
  @State((state: IRootState) => state.debugSettings)
  readonly savedOptions!: IDebugOptions;
  @State((state: IRootState) => state.sanityUser)
  readonly sanityUserState!: IRootState['sanityUser'];

  @Getter('siteConfig')
  readonly siteConfigState!: TAsyncStateWithDoc<
    ISanityDocuments[SanityDocumentSchema.SITE_CONFIG]
  >;

  get canShowDebugTool() {
    return this.siteConfigState?.data?.canShowDebugTool;
  }

  get debugQueryParam() {
    return this.siteConfigState?.data?.debugToolQueryParamValue;
  }

  get isDebugToolDisplayed() {
    return (
      this.$route.query?.debug === this.debugQueryParam ||
      this.savedOptions.debugSettingsEnabled
    );
  }

  get isLoading() {
    return this.sanityUserState?.isLoading || this.siteConfigState?.isLoading;
  }

  get canViewDrafts() {
    return this.sanityUserState?.data?.roles?.length;
  }

  get isUsingUnsavedSettings() {
    if (!this.siteConfigState?.data?._id) {
      return false;
    }

    return this.$sanityLookup.isDraftId(this.siteConfigState.data._id);
  }
}
