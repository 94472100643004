var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"brand-card-container"},[_c('v-card',{class:{
      'loginBox mx-auto': true,
      'px-4 py-6': !this.$vuetify.breakpoint.xs,
      'px-2 py-6': this.$vuetify.breakpoint.xs,
      accent: this.$vuetify.breakpoint.mdAndUp && _vm.darkInnerCard,
      modalBackground: this.$vuetify.breakpoint.smAndDown || !_vm.darkInnerCard,
    },style:(`width: 100%; max-width: ${_vm.maxWidth};`),attrs:{"flat":""}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }