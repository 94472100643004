var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fullscreen-dialog d-flex flex-column"},[(_vm.mobile)?_c('div',{staticClass:"d-flex toolbar py-2",class:{
      [`${_vm.color}`]: _vm.color,
      'py-10': _vm.title,
    }},[_c('v-btn',{attrs:{"icon":"","aria-label":'close'},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v(_vm._s(_vm.closeIcon))])],1),_vm._v(" "),_c('h1',{class:{
        'dialog-title text-capitalize': true,
        centered: !_vm.showBalance,
      }},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_vm._t("balance")],2):_vm._e(),_vm._v(" "),_c('div',{ref:"contentContainer",class:{
      [`${_vm.color}`]: _vm.mobile,
      'content-container': _vm.contained,
      'pa-4': !_vm.noPadding,
      'no-top-padding': !_vm.topPadding,
      'slot-container flex-grow-1': true,
      'd-flex align-center justify-center': !_vm.mobile,
    },on:{"click":_vm.closeIfClickAway}},[_c('div',{class:{ [`${_vm.color} mb-16 pa-6 desktop-container`]: !_vm.mobile }},[(!_vm.mobile)?_c('v-icon',{staticClass:"close-icon",attrs:{"size":"32"},on:{"click":_vm.closeDialog}},[_vm._v("\n        close\n      ")]):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),_vm._t("example-disclaimer")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }