import { TAdaptor } from '../types';
import debugAdaptor from './debugAdaptor';
import gtmAdaptor from './gtmAdaptor';
import activeCampaignAdaptor from './activeCampaignAdaptor';
import addShoppersAdaptor from './addShoppersAdaptor';
import galaAnalyticsAdaptor from './galaAnalyticsAdaptor';
import firebaseAdaptor from './firebaseAdaptor';
import metaAdaptor from './metaAdaptor';
import xAdaptor from './xAdaptor';

interface IAllAdaptors extends Array<TAdaptor> {}
const allAdaptors: IAllAdaptors = [
  debugAdaptor,
  gtmAdaptor,
  activeCampaignAdaptor,
  galaAnalyticsAdaptor,
  addShoppersAdaptor,
  firebaseAdaptor,
  metaAdaptor,
  xAdaptor,
];

export {
  debugAdaptor,
  gtmAdaptor,
  activeCampaignAdaptor,
  addShoppersAdaptor,
  galaAnalyticsAdaptor,
  firebaseAdaptor,
  metaAdaptor,
  xAdaptor,
};

export default allAdaptors;
