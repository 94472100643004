import { Action, Component, Vue } from 'nuxt-property-decorator';
import { IPromptToLoginPayload, IPromptToRegisterPayload } from '~/store/types';

@Component
export default class AuthActions extends Vue {
  @Action('promptToRegister')
  protected promptToRegister!: (options?: IPromptToRegisterPayload) => void;
  @Action('promptToLogin')
  protected promptToLogin!: (options?: IPromptToLoginPayload) => void;
}
