import Vue from 'vue';

export function deepSet(obj: Record<string, any>, path: string, value: any) {
  const keys = path.split('.'); // Split the path by dots (e.g., 'profile.address.zipcode')
  let current = obj;
  for (let i = 0; i < keys.length - 1; i++) {
    if (!current[keys[i]]) {
      Vue.set(current, keys[i], {}); // Create object if it doesn't exist
    }
    current = current[keys[i]]; // Move down the object tree
  }

  Vue.set(current, keys[keys.length - 1], value); // Set the final value
}
