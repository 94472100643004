import { Context } from '@nuxt/types';
import fetch from 'isomorphic-fetch';
import { HttpLink } from 'apollo-link-http';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { split, ApolloLink } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { onError } from 'apollo-link-error';
import introspectionQueryResultData from '../../fragmentTypes.json';

export default function ({ env, redirect, app, route, store }: Context) {
  const httpUri = env.gatewayUri;
  const wsOrWss = env.isProd ? 'wss' : 'ws';
  const wsUri = httpUri.replace(/https?/, wsOrWss);
  const httpLink = new HttpLink({
    uri: httpUri,
    credentials: 'include',
    async fetch(uri, options) {
      if (await app.$auth.isLoggedIn()) {
        const claims = { userId: true }; // TODO: Get claims from token

        if (claims.userId) {
          (options as RequestInit).headers = {
            ...options?.headers,

            Authorization: await app.$auth.getTokenSilently(),
          };
        }
      }

      return fetch(uri, options);
    },
  });

  const wsLink = new WebSocketLink({
    uri: wsUri,
    options: {
      reconnect: true,
      lazy: true,
      async connectionParams() {
        let token;

        if (await app.$auth.isLoggedIn()) {
          token = await app.$auth.getTokenSilently();
        }

        return { token };
      },
    },
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const errorHandlingLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        if (
          message?.toLowerCase().includes('authentication required') ||
          message === 'SESSION_REVOKED'
        ) {
          store.dispatch('profile/userLogout');
        }
      });
    }
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const cache = new InMemoryCache({ fragmentMatcher });

  return {
    link: ApolloLink.from([errorHandlingLink, link]),
    cache,
  };
}
