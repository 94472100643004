
import { Component, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { EVideoState, ICustomRevealState } from '~/store/customReveal/types';

@Component({})
export default class CustomExchangeRevealBackgroundVideo extends Vue {
  @State((customReveal) => customReveal, { namespace: 'customReveal' })
  readonly customReveal!: ICustomRevealState;

  @Getter('videoBoxType', { namespace: 'customReveal' }) videoBoxType!: string;

  @Action('setShowCards', { namespace: 'customReveal' })
  private setShowCards!: (payload: boolean) => void;

  @Action('setVideoReady', { namespace: 'customReveal' })
  private setVideoReady!: (payload: boolean) => void;

  @Action('setVideoState', { namespace: 'customReveal' })
  private setVideoState!: (payload: EVideoState) => void;

  vidDrop = 0;
  vidIdle = 4;
  vidOpen = 8;
  cardIn = 12;
  vidRevealed = 14;
  vidEnd = 16;

  created() {
    this.$watch('videoBoxType', (newVal: string, oldVal: string) => {
      const videoRef = this.$refs.videoRef as HTMLVideoElement;
      this.$nextTick(() => {
        if (!videoRef.paused) {
          videoRef.pause();
        }
        this.setVideoReady(false);
        videoRef.load();
      });
    });
  }

  checkPlayState(e: Event) {
    if (!e.target) {
      return;
    }
    const vid = e.target as HTMLVideoElement;
    switch (this.customReveal.currentVideoState) {
      case EVideoState.LOADING:
        vid.removeEventListener('ended', this.loopBack);
        if (
          vid.currentTime >= this.vidRevealed &&
          vid.currentTime <= this.vidEnd
        ) {
          vid.currentTime = this.vidDrop;
        }
        if (vid.currentTime >= this.vidOpen - 0.3) {
          vid.currentTime = this.vidIdle;
        }
        break;
      case EVideoState.OPENING:
        if (vid.currentTime < this.vidOpen) {
          vid.currentTime = this.vidOpen;
        }
        if (vid.currentTime >= this.cardIn) {
          this.setShowCards(true);
        }
        if (vid.currentTime >= this.vidRevealed) {
          this.setVideoState(EVideoState.REVEALED);
        }
        break;
      case EVideoState.REVEALED:
        vid.addEventListener('ended', this.loopBack);
        break;
    }
  }

  get videoSrc() {
    return this.customReveal.videoBoxType;
  }

  loopBack(e: Event) {
    if (!e.target) {
      return;
    }
    const vid = e.target as HTMLVideoElement;
    vid.currentTime = this.vidRevealed;
    vid.play();
  }

  handleCanPlay(e: Event) {
    if (!e.target) {
      return;
    }
    this.setVideoReady(true);
    (e.target as HTMLVideoElement).play();
  }
}
