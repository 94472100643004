import { render, staticRenderFns } from "./WalletCreationModalFlow.vue?vue&type=template&id=3a8c4515&scoped=true"
import script from "./WalletCreationModalFlow.vue?vue&type=script&lang=ts"
export * from "./WalletCreationModalFlow.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a8c4515",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewWallet: require('/builds/gala-games/gala-film/gala-film/src/components/NewWallet/NewWallet.vue').default,ResponsiveDialog: require('/builds/gala-games/gala-film/gala-film/src/components/ResponsiveDialog.vue').default})
