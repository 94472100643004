import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IRewardState } from './types';
import { IRootState } from '../types';

export const state: IRewardState = {
  showRewardSnackbar: undefined,
  currentReward: undefined,
  showRewardMysteryBoxModal: undefined,
  project: {},
};

const namespaced = true;

const rewards: Module<IRewardState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default rewards;
