export enum NodeLicenseNames {
  film = 'film-license',
}

export enum NodeTypes {
  film = 'film',
}

export enum FilmRewardId {
  WELCOME = 'welcome-popcorn-bucket',
  SMALL = 'small-popcorn-bucket',
  MEDIUM = 'medium-popcorn-bucket',
  LARGE = 'large-popcorn-bucket',
  EXTRALARGE = 'extra-large-popcorn-bucket',
  MEGA = 'mega-popcorn-bucket',
}

export const nodeLicenseTypeMap: Record<NodeLicenseNames, NodeTypes> = {
  [NodeLicenseNames.film]: NodeTypes.film,
};

export interface IUserNode {
  licenseName: NodeLicenseNames;
  name: string;
  quantity: number;
  type: NodeTypes;
}

export interface INodesState {
  nodesActive: number;
  nodesActiveByType: Record<NodeTypes, number>;
  nodesOwned: number;
  nodesOwnedByType: Record<NodeTypes, number>;
  userNodes: IUserNode[];
  workloadStreakRewards: IWorkloadStreakRewards;
}

export interface IStep {
  index: number;
  rewardId: FilmRewardId;
  progress: number;
}

export type TRewardsFrequency = Partial<Record<FilmRewardId, number>>;

export interface IWorkloadStreak {
  endedAt: Date;
  quantity: number;
  rewardsFrequency: TRewardsFrequency;
  startedAt: Date;
}

export interface IWorkloadStreakInProgress
  extends Omit<IWorkloadStreak, 'endedAt'> {
  days: number;
  nextRewardId: FilmRewardId;
  nextRewardQuantityDelta: number;
  rewardId: FilmRewardId;
}

export interface IWorkloadStreakReward {
  quantity: number;
  rewardId: FilmRewardId;
  threshold: number;
}

export interface IWorkloadStreakRewards {
  totalQuantity: number;
  dailyQuantity: number;
  firstCreatedDate?: Date;
  lastCreatedDate?: Date;
  currentStreak?: IWorkloadStreakInProgress;
  completedStreaks?: IWorkloadStreak[];
  rewards: IWorkloadStreakReward[];
  thresholds: Array<Omit<IWorkloadStreakReward, 'quantity'>>;
  additionalRewards: IWorkloadStreakReward[];
  rewardsFrequency: TRewardsFrequency;
}
