import { INetworkNotifications } from '~/types/notification';
import { IEnabledTwoFaCheckpoints } from '~/types/two-fa-checkpoints';
import { GraphQLErrorCodes } from '~/utils/errorCodes';

export interface IFilmDistributionReward {
  rewardId: string;
  quantity: number;
}
export interface IUser {
  preferences: { currency: string; language: string; notifications: boolean };
  email: string;
  emailVerified: boolean | undefined;
  phone: string;
  firstName: string;
  lastName: string;
  fullNameNotProvided: boolean;
  displayName: string;
  role: string;
  permissions: string[];
  walletConnected: boolean | null;
  walletExists: boolean | null;
  twoFaAuthenticated: boolean;
  twoFaEnabled: boolean;
  loggedIn: boolean;
  id: string;
  profilePhotoUrl: string | null;
  gameToken: string;
  goldMember: boolean;
  discordProfile: IDiscordProfile | undefined;
  networkNotifications: INetworkNotifications;
  referralCode: string;
  isInBlockedCountry: boolean;
  shouldBlockGameplay: boolean;
  connectedAccounts?: Array<{
    connectionName: string;
    accountId: string;
  }>;
  filmDistributionRewards: IFilmDistributionReward[];
  creditCards: ICreditCards[];
  hasGyriPassphrase: boolean;
  auth0UserIdentities: IAuth0UserIdentity[];
  enabledTwoFaCheckpoints?: IEnabledTwoFaCheckpoints;
}

export interface IAuth0UserIdentity {
  provider: string;
  connection: string;
  email: string;
  user_id: string;
  isSocial: string;
  socialName?: string;
}

export interface ICreditCards {
  last4: number | string;
  nickname?: string;
  network: 'visa' | 'mastercard';
  expMonth: number;
  expYear: number;
  cardId: string;
}

interface ICreditCardDataBillingDetails {
  name: string;
  city: string;
  country: string;
  line1: string;
  line2?: string;
  district?: string;
  postalCode: string;
}

interface ICreditCardDataCardDetails {
  number?: string;
  cvv?: string;
}

interface ICreditCardDataMeta {
  email: string;
  phoneNumber?: string;
  sessionId: string;
  ipAddress: string;
}

export interface ICreditCardData {
  idempotencyKey?: string;
  expMonth: number;
  expYear: number;
  network?: 'visa' | 'mastercard';
  cardDetails?: ICreditCardDataCardDetails;
  last4?: string;
  billingDetails: ICreditCardDataBillingDetails;
  metadata?: ICreditCardDataMeta;
  cardType?: string;
  nickname?: string;
  default?: boolean;
}

export const creditCardLimits = {
  // in USD
  transaction: 3000,
  daily: 3000,
  weekly: 5000,
  monthly: 10000,
};

export interface IProfileState {
  user: IUser;
  activated: boolean | undefined;
  shareUrl: string;
  error: boolean;
  isFetching: boolean;
  gameTokenMaxAge: number;
  gameTokenTimeSet: number;
}

export interface IDiscordProfile {
  id: string;
  username: string;
  avatar: string;
}

export interface IConnectWalletMutationOptions {
  authData: {
    ethAddress: string;
    signedContent: string;
    sig: string;
    timestamp: string;
  };
  walletProvider: string;
  totpToken: string;
}
export interface IUserRegisterErrorMessage {
  errorType: EErrorTypes;
  fields?: string[];
  checkMessage: string;
  snackbarMessage: string;
  snackbarTranslatable?: boolean;
}

export enum EErrorTypes {
  GLOBAL = 'GLOBAL',
  FIELD = 'FIELD',
}

export interface IUserRegisterFieldError {
  field: string;
  message: any;
}

export interface IUpdateUserPayload
  extends Partial<
    Pick<IUser, 'displayName' | 'email' | 'firstName' | 'lastName' | 'phone'>
  > {
  currentPassword?: string;
  ignoreSnackbar?: boolean;
  networkNotifications?: INetworkNotifications;
  password?: string;
  profilePhotoFilename?: string;
  profilePhotoUrl?: string;
  rethrowErrorCodes?: GraphQLErrorCodes[];
  totpToken: string;
}
