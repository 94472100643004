import { Store } from 'vuex';
import { NuxtI18nInstance } from '@nuxtjs/i18n';
import { WalletType } from '~/store/types';
import { ChainNetwork } from '~/types/chain';
import {
  isFormattedSymbol,
  formatSymbol,
  isCreditCard,
  findWalletBySymbol,
  findPriceBySymbol,
  symbolToNetwork,
  requiredWalletTypeByNetwork,
  requiredWalletTypeBySymbol,
  FormattedSymbol,
} from '~/utils/wallets';
import { IAppStates } from '~/types/vuex-state';

export class WalletHelper {
  store: Store<IAppStates>;
  i18n: NuxtI18nInstance;

  constructor(store: Store<IAppStates>, i18n: NuxtI18nInstance) {
    this.store = store;
    this.i18n = i18n;
  }

  isFormattedSymbol(symbol: string): symbol is FormattedSymbol {
    return isFormattedSymbol(symbol);
  }

  formatSymbol(...args: Parameters<typeof formatSymbol>) {
    return formatSymbol(...args);
  }

  isCreditCard(...args: Parameters<typeof isCreditCard>) {
    return isCreditCard(...args);
  }

  findWalletBySymbol(...args: Parameters<typeof findWalletBySymbol>) {
    const wallet = findWalletBySymbol(...args);
    if (!wallet?.symbol) {
      return wallet;
    }

    // Perform string alterations for translations
    if (this.isCreditCard(wallet.symbol)) {
      wallet.name = this.i18n
        .t('components.wallet.paymentPicker.cc')
        .toString();
    }

    return wallet;
  }

  findPriceBySymbol(...args: Parameters<typeof findPriceBySymbol>) {
    return findPriceBySymbol(...args);
  }

  symbolToNetwork(...args: Parameters<typeof symbolToNetwork>) {
    return symbolToNetwork(...args);
  }

  requiredWalletTypeByNetwork(
    ...args: Parameters<typeof requiredWalletTypeByNetwork>
  ) {
    return requiredWalletTypeByNetwork(...args);
  }

  requiredWalletTypeBySymbol(
    ...args: Parameters<typeof requiredWalletTypeBySymbol>
  ) {
    return requiredWalletTypeBySymbol(...args);
  }

  userHasWalletType(walletType: WalletType | undefined) {
    switch (walletType) {
      case WalletType.ETH:
        return (
          !!this.store.state.profile?.user?.walletExists ||
          !!(
            this.store.state.profile?.user?.walletConnected &&
            this.store.getters['web3Wallet/w3wConnectionActive']
          )
        );
      case WalletType.GYRI:
        return (
          !!this.store.state.profile?.user?.walletExists ||
          !!this.store.state.profile?.user?.hasGyriPassphrase
        );
      default:
        return true;
    }
  }

  missingWalletTypeByNetwork(network?: ChainNetwork) {
    const requiredWallet = this.requiredWalletTypeByNetwork(network);
    const isSetup = this.userHasWalletType(requiredWallet);
    return isSetup ? undefined : requiredWallet;
  }

  missingWalletTypeBySymbol(symbol?: string) {
    const requiredWallet = this.requiredWalletTypeBySymbol(symbol);
    const isSetup = this.userHasWalletType(requiredWallet);
    return isSetup ? undefined : requiredWallet;
  }
}
