
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';
import ThumbnailButton from '~/components/UIComponents/ThumbnailButton.vue';
import FullScreenMediaDialog from '~/components/FullScreenMediaDialog.vue';

@Component({
  components: {
    ThumbnailButton,
    FullScreenMediaDialog,
  },
})
export default class OpenFigureDialog extends Vue {
  @Prop(String) readonly activatorLabel!: string;
  @Prop(Object)
  readonly figureData!: ISanityDocuments[SanityDocumentSchema.FIGURE];
  @Prop(String) readonly figureId!: string;
  @Prop({ type: Number, default: 16 / 9 })
  readonly thumbAspectRatio!: number;
  @Prop({ type: Number, default: 284 })
  readonly thumbWidth!: number;

  isOpen = false;
  isFullSize = false;

  get fullScreenImageUrl() {
    if (!this.figureData?.image || !this.figureData?.image.asset._ref) {
      return '';
    }
    return this.$sanityImage
      .urlFor(this.figureData.image.asset)
      .width(1920)
      .fit('scale')
      .url();
  }

  get label() {
    return this.activatorLabel || this.figureData?.title
      ? this.$t('components.sanity.viewFigureDialog.activatorLabelWithTitle', {
          title: this.figureData.title,
        })
      : this.$t('components.sanity.viewFigureDialog.activatorLabel');
  }

  get thumbHeight() {
    return this.thumbAspectRatio
      ? Math.round(this.thumbWidth * (1 / this.thumbAspectRatio))
      : 0;
  }

  get thumbUrl() {
    return !this.figureData?.image
      ? ''
      : this.$sanityImage
          .urlFor(this.figureData.image)
          .width(this.thumbWidth)
          .height(this.thumbHeight)
          .fit('crop')
          .url();
  }

  get title() {
    return this.figureData?.title || '';
  }
}
