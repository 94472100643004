
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TextField from './TextField.vue';

@Component({
  components: { TextField },
  inheritAttrs: false,
})
export default class TransferCodeInput extends Vue {
  @Prop(String) readonly inputValue!: string;

  showPass = false;
  value: string | null = null;

  created() {
    this.value = this.inputValue;
  }

  @Watch('inputValue')
  handleInputValueChange() {
    this.value = this.inputValue;
  }

  @Watch('value')
  handleValueChange(val: string) {
    this.$emit('update:inputValue', val);
  }
}
