import { ActionTree } from 'vuex';
import { INodesState, IUserNode, IWorkloadStreakRewards } from './types';
import { IRootState } from '../types';
import getUserNodes from '~/queries/activeLicenseCount.gql';
import getActiveNodeCount from '~/queries/activeNodes.gql';
import getWorkloadStreakRewards from '~/queries/workloadStreakRewards.gql';

export const actions: ActionTree<INodesState, IRootState> = {
  async getUserNodes({ commit, rootState }) {
    if (this.app.apolloProvider && rootState.profile?.user.loggedIn) {
      const client = this.app.apolloProvider.clients.gateway;

      try {
        const results = await client.query<{
          activeLicenseCount: {
            all: Array<Omit<IUserNode, 'name'>>;
          };
        }>({
          query: getUserNodes,
          fetchPolicy: 'network-only',
        });

        commit('updateUserNodes', results.data.activeLicenseCount.all);
      } catch (e) {
        commit('toggleErrorSnackbar', null, { root: true });
      }
    }
  },

  async getActiveUserNodes({ commit, rootState }) {
    if (!this.app.apolloProvider || !rootState.profile?.user.loggedIn) {
      return;
    }

    const client = this.app.apolloProvider.clients.gateway;
    try {
      const { data } = await client.query<{
        activeNodeCount: Record<string, number>;
      }>({
        query: getActiveNodeCount,
        fetchPolicy: 'no-cache',
      });

      commit('updateActiveUserNodes', data.activeNodeCount);
    } catch (e) {
      commit('toggleErrorSnackbar', null, { root: true });
    }
  },

  async getWorkloadStreakRewards({ commit, rootState }) {
    if (!this.app.apolloProvider || !rootState.profile?.user.loggedIn) {
      return;
    }

    const client = this.app.apolloProvider.defaultClient;
    try {
      const { data } = await client.query<{
        workloadStreakRewards: IWorkloadStreakRewards;
      }>({
        query: getWorkloadStreakRewards,
        variables: {
          workloadStreakRewardType: 'film',
        },
        fetchPolicy: 'no-cache',
      });
      commit('updateWorkloadStreakRewards', data.workloadStreakRewards);
    } catch (e) {
      commit('toggleErrorSnackbar', null, { root: true });
    }
  },
};
