import { Context } from '@nuxt/types';
import gql from 'graphql-tag';
import { consumeRedirectAfterLogin } from '~/utils/redirectAfterLogin';
import { resetCache } from '~/utils';

export default async function (context: Context) {
  const { redirect, app } = context;

  if (app.apolloProvider) {
    const defaultClientCache = app.apolloProvider.defaultClient.cache;
    const gatewayClientCache = app.apolloProvider.clients.gateway.cache;

    await Promise.all([
      resetCache(defaultClientCache),
      resetCache(gatewayClientCache, false),
      app.apolloProvider.defaultClient.mutate({
        mutation: gql`
          mutation loggedIn {
            loggedIn {
              success
            }
          }
        `,
      }),
    ]);
  }

  const redirectTo = consumeRedirectAfterLogin();
  redirect(redirectTo || '/');
}
