
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICreditCards } from '~/store/profile/types';

@Component({})
export default class CreditCardImage extends Vue {
  @Prop(Object) card!: ICreditCards;
  @Prop({ type: String, default: 'mr-3' }) classes!: string;
  @Prop(String) styles!: string;

  get url() {
    switch (this.card?.network.toLowerCase()) {
      case 'mastercard':
        return require('@/assets/logos/mastercard-icon.png');
      case 'visa':
        return require('@/assets/logos/visa-icon.png');
      default:
        return require('@/assets/logos/CC-icon.png');
    }
  }

  get text() {
    return this.card?.nickname || this.card?.network;
  }
}
