
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { State, Getter, Action } from 'vuex-class';
import { ICreditCards } from '~/store/profile/types';
import { TSiteConfigState } from '~/store/types';
import CreditCardIcon from '~/components/CreditCards/Icon.vue';
import CreditCardInfo from '~/components/CreditCards/CreditCardInfo.vue';
// @ts-ignore
import CreditCardEditor from '~/components/CreditCards/Editor.vue';

@Component({
  components: {
    CreditCardEditor,
    CreditCardIcon,
    CreditCardInfo,
  },
})
export default class CreditCards extends Vue {
  @State((profile) => profile.user.creditCards, { namespace: 'profile' })
  creditCards!: ICreditCards[];

  @Getter('siteConfig')
  readonly siteConfigState!: TSiteConfigState;

  @Action('deleteCard', { namespace: 'profile' })
  private deleteCard!: (cardId: string) => boolean;

  @Prop() readonly selectedCredit!: ICreditCards;
  @Prop({ type: String, default: 'yes' }) readonly selection!: string;
  @Prop({ type: Boolean, default: false })
  readonly isInModal!: string;

  @Emit('card-delete')
  async removeCreditCard(cardId: string) {
    await this.deleteCard(cardId);
    return cardId;
  }

  allowSelect() {
    return this.selection !== 'no';
  }

  @Emit('card-select')
  handleCardSelect(creditCard: ICreditCards) {
    if (this.allowSelect()) {
      return creditCard;
    }
  }
}
