var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`promo-exchange fill-height full-width ${
    _setup.toolbarError ? 'has-error' : ''
  }`},[_c('div',{class:`toolbar-error position-relative full-width text-center error white--text font--body-medium font-weight-bold`},[_c('span',{staticClass:"ellipsis-text"},[_vm._v(_vm._s(_setup.toolbarError))])]),_vm._v(" "),_c('div',{staticClass:"close-btn"},[_vm._t("close-btn",function(){return [(_vm.closeBtn)?_c('v-btn',{attrs:{"fab":"","small":"","depressed":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('common.cta.close')))]),_vm._v(" "),_c('v-icon',{attrs:{"aria-hidden":"true"}},[_vm._v("close")])],1):_vm._e()]})],2),_vm._v(" "),_c(_setup.PromoBox,{staticClass:"promo-box-content",attrs:{"can-redeem":_setup.canRedeem && _setup.canUserRedeem,"doc":_vm.doc,"loading":_vm.loading,"redemption-state":_setup.currentState,"redeeming":_setup.isRedeeming,"title-tag":_vm.titleTag},on:{"info":function($event){_setup.shouldShowExplainerDialog = true},"redeem":_setup.handleRedeem}}),_vm._v(" "),_c(_setup.ResponsiveDialog,{attrs:{"theme":_setup.ThemeName.DARK,"max-width":"650px"},model:{value:(_setup.shouldShowExplainerDialog),callback:function ($$v) {_setup.shouldShowExplainerDialog=$$v},expression:"shouldShowExplainerDialog"}},[_c(_setup.PromoBoxExplainer,{attrs:{"image":_setup.props.doc?.explainerImage
          ? _setup.$sanityImage
              .urlFor(_setup.props.doc.explainerImage)
              .width(192)
              .height(192)
              .url()
          : '',"image-alt":_vm.doc?.explainerImage?.alt || '',"overline":_vm.doc?.explainerContent?.overline,"title":_vm.doc?.explainerContent?.title,"list-items":_vm.doc?.explainerListItems,"body":_vm.doc?.explainerContent?.body,"cta-text":!_setup.isLoggedIn
          ? 'Sign up now'
          : _setup.currentState === _setup.IRedemptionState.REDEEMABLE
          ? 'Open now'
          : '',"cta-disabled":!_setup.isLoggedIn
          ? false
          : _setup.currentState !== _setup.IRedemptionState.REDEEMABLE ||
            !_setup.canUserRedeem ||
            !_setup.canRedeem},on:{"click":_setup.handleExplainerCtaClick}})],1),_vm._v(" "),(_vm.doc?.redemptionCode && _vm.doc?.exchangeRevealVideoUrl)?_c('v-dialog',{attrs:{"eager":"","value":_setup.shouldShowCustomExchangeReveal,"fullscreen":true,"persistent":_setup.currentExchangeState === _setup.EExchangeState.EXCHANGE_IN_FLIGHT},on:{"click:outside":_setup.handleExchangeClosed,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _setup.handleExchangeClosed.apply(null, arguments)}}},[_c(_setup.CustomExchangePromoCode,{ref:"exchangeComponent",attrs:{"code":_vm.doc.redemptionCode,"show-errors":false,"video":_vm.doc.exchangeRevealVideoUrl,"mock":_setup.mock},on:{"close":_setup.handleExchangeClosed,"exchange-error":_setup.handleExchangeError,"user-qualified":_setup.handleUserQualified,"reset":_setup.handleRedeemed}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }