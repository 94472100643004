
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import GradientHexagon from '~/components/UIComponents/GradientHexagon.vue';
import shouldNag, { setTimeModalWasDismissed } from '~/utils/nagModalUtils';
import { IUser } from '~/store/profile/types';

@Component({
  components: { GradientHexagon, FullScreenDialog },
})
export default class TwoFactorAuthPrompt extends Vue {
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State((profile) => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;
  @State((profile) => profile.user.twoFaEnabled, { namespace: 'profile' })
  twoFaEnabled!: boolean;
  @State((profile) => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State((profile) => profile.user.walletConnected, { namespace: 'profile' })
  walletConnected!: boolean;

  securityIcon = require('@/assets/icons/icon_security_2FWA.png');
  modalControl = true;
  isInitialWalletCreation = true;
  isUserSettingUp2FA = true;
  modalClosedMarker = '2FAModalClosed';

  created() {
    if (this.walletExists || this.walletConnected) {
      this.isInitialWalletCreation = false;
    }

    if (this.$route.query.component !== 'TwoFa') {
      this.isUserSettingUp2FA = false;
    }
  }

  get showModal() {
    const timestampRecordPrefix = this.currentUser?.id;
    const shouldShowNagModal =
      timestampRecordPrefix &&
      shouldNag(timestampRecordPrefix, this.modalClosedMarker);

    return (
      this.modalControl &&
      this.loggedIn &&
      (this.walletExists || this.walletConnected) &&
      !this.twoFaEnabled &&
      !this.isInitialWalletCreation &&
      !this.isUserSettingUp2FA &&
      shouldShowNagModal
    );
  }

  handleSetupClick() {
    this.modalControl = false;
    this.$router.push('/account?settings=2fa');
  }

  @Watch('walletExists')
  onWalletExistsChange(val: boolean | null, oldValue: boolean | null) {
    // This condition is only met when a user who already has a wallet logs in.
    // On initial wallet creation, the oldValue for walletExists will be false rather than null.
    if (val === true && oldValue === null) {
      this.isInitialWalletCreation = false;
    }
  }

  @Watch('modalControl')
  setModalCloseDateTime(val: boolean, oldVal: boolean) {
    if (oldVal === true && val === false && this.currentUser?.id) {
      setTimeModalWasDismissed(this.currentUser.id, this.modalClosedMarker);
    }
  }
}
