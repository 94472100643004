
import { Component, Getter, Prop, Vue } from 'nuxt-property-decorator';
import { Mutation, State } from 'vuex-class';
import {
  IDebugOptions,
  DEBUG_TOOL_DEFAULTS as defaultOptions,
  IRootState,
} from '~/store/types';
import { SanityDataset } from '~/types/sanity';
import { debugToolSessionStorageKey } from '~/middleware/setDebugOverrides';
import { TAsyncStateWithDoc } from '~/store/documents/types';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';

@Component
export default class DebugTool extends Vue {
  @Prop(Boolean) readonly isEnabled!: boolean;

  datasetOptions = Object.values(SanityDataset);
  showDebugToolDialog = false;
  sanityLoginUrl = 'https://manage.sanity.io/';

  options: IDebugOptions = { ...defaultOptions };

  @State((state: IRootState) => state.debugSettings)
  readonly savedOptions!: IDebugOptions;

  @Mutation('updateDebugSettings')
  readonly updateDebugSettings!: (payload: IDebugOptions) => void;
  @Getter('siteConfig')
  readonly siteConfigState!: TAsyncStateWithDoc<
    ISanityDocuments[SanityDocumentSchema.SITE_CONFIG]
  >;

  hasChanged = false;
  defaultOptions = { ...defaultOptions };

  get debugQueryParam() {
    return this.siteConfigState?.data?.debugToolQueryParamValue;
  }

  get isDebugToolDisplayed() {
    return this.isEnabled;
  }

  created() {
    this.options = { ...this.savedOptions };
  }

  handleSave() {
    sessionStorage.setItem(
      debugToolSessionStorageKey,
      JSON.stringify(this.options),
    );
    this.updateDebugSettings({ ...this.options });
    this.hasChanged = false;
  }

  updateOptions(options: IDebugOptions) {
    this.options = options;
    this.hasChanged = true;
  }

  resetOptions() {
    this.options = { ...this.savedOptions };
    this.hasChanged = false;
  }

  resetToDefault() {
    this.options = { ...this.defaultOptions };
    this.hasChanged = true;
  }
}
