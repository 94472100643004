
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AvatarIcon extends Vue {
  @Prop({ type: String, default: 'white' }) readonly backgroundColor!: string;
  @Prop({ type: String, default: 'var(--v-accentPurple-base)' })
  readonly color!: string;
  @Prop({ type: Number, default: 40 }) readonly size!: number;

  get styles() {
    return {
      backgroundColor: this.backgroundColor,
      color: this.color,
    };
  }

  get iconSize() {
    return Math.round(this.size / 1.8);
  }
}
