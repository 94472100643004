
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PasswordInput } from '~/components/UIComponents/FormElements';

@Component({
  components: { PasswordInput },
  filters: {},
})
export default class RequirePasscode extends Vue {
  @Prop({ type: String, default: 'Complete' }) readonly buttonText!: string;
  @Prop(Function) readonly action!: (passcode: string) => any;
  @Prop(String) readonly subheading!: string;
  @Prop(Boolean) readonly hideHeading!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly rules!: any[];
  @Prop({ type: Boolean, default: false })
  readonly useOldActionHandling!: boolean;

  loading = false;
  encryptionPasscode = '';
  showPass = false;

  get buttonDisabled() {
    return !this.checkIfInputIsValid();
  }

  checkIfInputIsValid() {
    if (!this.encryptionPasscode) {
      return false;
    }
    if (this.rules.length) {
      return this.rules.every((rule) => {
        return rule(this.encryptionPasscode) === true;
      });
    }
    return true;
  }

  performAction() {
    if (this.useOldActionHandling) {
      return this.performActionV1();
    }

    // This new handling should be easier to use...
    // but two many things rely on the old handling
    // in different ways, so keeping it for not for
    // legacy code.
    return this.performActionV2();
  }

  async performActionV2() {
    if (!this.encryptionPasscode || !this.checkIfInputIsValid()) {
      this.$emit('action-error', {
        message: this.$t('components.wallet.requirePasscode.validPasscodeMsg'),
      });
      this.clearInput();
      return;
    }

    try {
      this.loading = true;
      const results = await this.action(this.encryptionPasscode);
      this.$emit('action-complete', results);

      this.loading = false;
      this.clearInput();
    } catch (error) {
      this.loading = false;
      this.$emit('action-error', {
        message:
          (error as any) && (error as any).message
            ? (error as any).message
            : '',
        error,
      });
      this.clearInput();
    }
  }

  async performActionV1() {
    if (!this.encryptionPasscode || !this.checkIfInputIsValid()) {
      this.$emit('action-complete', {
        success: false,
        message: 'You must enter a valid passcode',
      });
      this.clearInput();
      return;
    }

    try {
      this.loading = true;

      const results = await this.action(this.encryptionPasscode);
      if (results) {
        this.$emit('action-complete', { success: true, results: results.data });
      } else {
        this.$emit('action-complete', { success: false, message: 'Canceled' });
      }

      this.loading = false;
      this.clearInput();
    } catch (error) {
      this.$sentry.captureException(error);
      this.loading = false;
      this.$emit('action-complete', {
        success: false,
        message: 'Something went wrong',
      });
      this.clearInput();
    }
  }

  clearInput() {
    this.encryptionPasscode = '';
  }
}
