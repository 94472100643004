
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  SanityAsset,
  SanityImageCrop,
  SanityImageHotspot,
  SanityReference,
} from '@sanity/image-url/lib/types/types';

@Component
export default class SanityImageSerializer extends Vue {
  @Prop(String)
  readonly alt!: string;
  @Prop(Object)
  readonly asset!: SanityAsset | SanityReference;
  @Prop(Object)
  readonly crop!: SanityImageCrop;
  @Prop(Object)
  readonly hotspot!: SanityImageHotspot;

  get imageUrl() {
    if (
      !(
        (this.asset as SanityReference)?._ref ||
        (this.asset as SanityAsset)?.assetId
      )
    ) {
      return '';
    }

    const imageSource = {
      asset: this.asset,
      crop: this.crop,
      hotspot: this.hotspot,
    };

    return this.$sanityImage.urlFor(imageSource).url();
  }
}
