var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TextField',_vm._g(_vm._b({staticClass:"password-input",attrs:{"input-value":_vm.value,"type":_vm.showPass ? 'text' : 'password'},on:{"update:inputValue":function($event){_vm.value=$event},"update:input-value":function($event){_vm.value=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"v-input__icon v-input__icon--append"},[_c('v-icon',{staticClass:"toggle-pw-visibility",attrs:{"aria-label":_vm.showPass
            ? _vm.$t(
                'components.uiComponents.formElements.passwordInput.hidePassword',
              )
            : _vm.$t(
                'components.uiComponents.formElements.passwordInput.showPassword',
              )},on:{"click":function($event){$event.preventDefault();_vm.showPass = !_vm.showPass}}},[_vm._v("\n        "+_vm._s(_vm.showPass ? 'visibility_off' : 'visibility')+"\n      ")])],1)]},proxy:true}])},'TextField',{ ..._vm.$attrs, ..._vm.$props },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }