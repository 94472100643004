
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import { ExternalWallet, AuthQuery } from '~/mixins';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import ConnectWeb3WalletError from '~/components/Web3Wallet/ConnectWeb3WalletError.vue';
import { IWalletConnectionError } from '~/store/web3_wallet/types';
import { walletErrorTitleFromCode } from '~/utils/walletConnectionError';

@Component({ components: { ConnectWeb3WalletError, TwoFactorAuthInputPrompt } })
export default class WalletConnectionErrorBanner extends Mixins(
  ExternalWallet,
  AuthQuery,
) {
  @State((w3w) => w3w.walletConnectionError, { namespace: 'web3Wallet' })
  walletConnectionError!: IWalletConnectionError | null;

  @Mutation('toggleWeb3WalletErrorDialog')
  toggleErrorDialog!: (payload?: boolean) => void;

  get shortMessage() {
    if (this.walletConnectionError?.shortMessage) {
      return this.walletConnectionError.shortMessage;
    }

    return this.walletConnectionError?.code
      ? walletErrorTitleFromCode(
          this.walletConnectionError.code,
          this.$i18n,
          this.walletConnectionError.provider,
        )
      : this.walletConnectionError?.message;
  }

  get showResolveButton() {
    return (
      !!this.shortMessage &&
      this.shortMessage !== this.walletConnectionError?.message
    );
  }
}
