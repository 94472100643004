import { Component, Vue, Prop } from 'vue-property-decorator';
import { IGameItemForSaleByGala } from '~/store/games/types';
import {
  getAmountLeftInStore,
  getIsCompletelySoldOut,
  getSupplyInTier,
  getTotalSupply,
  getTotalTieredSupply,
} from '~/utils/productSupply';

@Component
export default class ProductSupplyMix extends Vue {
  @Prop(Object) readonly item!: IGameItemForSaleByGala;

  isTiered = false;

  public created() {
    this.isTiered = !!this.item?.tiers;
  }

  get rarityData() {
    return this.item ? this.item.rarity : undefined;
  }

  get supplyInTier() {
    return getSupplyInTier(this.item);
  }

  get totalTieredSupply() {
    return getTotalTieredSupply(this.item);
  }

  get supplyLimit() {
    return this.rarityData?.supplyLimit || 0;
  }

  get amountLeftInStore() {
    return getAmountLeftInStore(this.item);
  }

  get completelySoldOut() {
    return getIsCompletelySoldOut(this.item);
  }

  get amountLeft() {
    const filters = this.$options?.filters || {
      formatNumber: (num: number) => {
        return num;
      },
    };

    const totalInStore = getTotalSupply(this.item);

    if (!this.item.tiers) {
      return [
        filters.formatNumber(
          this.item.qtyLeft,
          this.item.qtyLeft > 100000 ? '0,0 a' : '0,0',
        ),
        '/',
        filters.formatNumber(
          totalInStore,
          totalInStore > 100000 ? '0,0 a' : '0,0',
        ),
        ' ',
        this.$t('common.transactions.nonTierItemsRemainingInStore.string'),
      ].join('');
    }

    const leftAvailableInTier = this.item.qtyLeft;
    return [
      filters.formatNumber(
        leftAvailableInTier,
        leftAvailableInTier > 100000 ? '0,0 a' : '0,0',
      ),
      '/',
      filters.formatNumber(
        this.supplyInTier,
        this.supplyInTier > 100000 ? '0,0 a' : '0,0',
      ),
      ' ',
      this.$t('common.transactions.itemsLeftInTier.string'),
      ' ',
      filters.formatNumber(
        this.amountLeftInStore,
        this.amountLeftInStore > 100000 ? '0,0 a' : '0,0',
      ),
      '/',
      filters.formatNumber(
        totalInStore,
        totalInStore > 100000 ? '0,0 a' : '0,0',
      ),
      ' ',
      this.$t('common.transactions.tierItemsRemainingInStore.string'),
    ].join('');
  }
}
