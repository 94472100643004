import { ActionTree } from 'vuex';
import { IGamesState, IFetchItemsForSalePayload } from './types';
import { IRootState } from '../types';
import itemsForSaleByGala from '~/queries/gameItemProducts.gql';

export const actions: ActionTree<IGamesState, IRootState> = {
  async fetchItemsForSaleByGala(
    { commit },
    gameItemProductsInput: IFetchItemsForSalePayload = {},
  ) {
    if (!this.app.apolloProvider) {
      return;
    }

    const client = this.app.apolloProvider.defaultClient;
    const results = (await client.query({
      query: itemsForSaleByGala,
      variables: {
        gameItemProductsInput,
      },
      fetchPolicy: 'no-cache',
    })) as { data: { gameItemProducts: any[] } };

    if (results?.data?.gameItemProducts) {
      commit('updateItemsForSaleByGala', results.data.gameItemProducts);
    }
  },

  async getItemsForSaleByGala(
    { commit, dispatch },
    gameItemProductsInput: IFetchItemsForSalePayload = {},
  ) {
    if (this.app.apolloProvider) {
      commit('updateItemsForSaleState', { isLoading: true, hasError: false });
      try {
        await dispatch('fetchItemsForSaleByGala', gameItemProductsInput);
        commit('updateItemsForSaleState', {
          isLoading: false,
          hasError: false,
        });
      } catch (e) {
        commit('updateItemsForSaleState', { isLoading: false, hasError: true });
      }
    }
  },

  async getGameTextByLocale({ commit }) {
    if (this.app.i18n) {
      const defaultGameInfo = this.app.i18n.t('store.games', 'en') as any;
      const translatedGameInfo = this.app.i18n.t('store.games') as any;
      commit('updateGameTextByLocale', {
        gameTranslation: translatedGameInfo.index,
        defaultGameText: defaultGameInfo.index,
        i18n: this.app.i18n,
      });
    }
  },
};
