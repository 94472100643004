
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Mutation, State, Action, Getter } from 'vuex-class';
import ExternalWallet from '~/mixins/ExternalWallet';
import { IUser } from '~/store/profile/types';
import {
  IProviderOption,
  IWalletConnectionError,
} from '~/store/web3_wallet/types';
import CaptureNameModal from '~/components/NewWallet/CaptureNameModal.vue';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import PrivateKeyButton from '~/components/Wallet/PrivateKeyButton.vue';
import ReplaceGalaWalletStepper from '~/components/Web3Wallet/ReplaceGalaWalletStepper.vue';
import ConnectWeb3Provider from '~/components/Web3Wallet/ConnectWeb3Provider.vue';
import { web3WalletSupportLinks } from '~/data/web3WalletSupportLinks';
import AuthQuery from '~/mixins/AuthQuery';
import AuthActions from '~/mixins/AuthActions';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import { twoFaErrorCodes } from '~/utils/errorCodes';
import { WalletConnectionError } from '~/utils/walletConnectionError';

@Component({
  components: {
    CaptureNameModal,
    ConnectWeb3Provider,
    PrivateKeyButton,
    ReplaceGalaWalletStepper,
    TwoFactorAuthInputPrompt,
  },
})
export default class ConnectWallet extends Mixins(
  ExternalWallet,
  AuthQuery,
  AuthActions,
) {
  @State((profile) => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State((state) => state.showWeb3WalletErrorDialog)
  showWeb3WalletErrorDialog!: boolean;

  @Getter('w3wConnectionActive', { namespace: 'web3Wallet' })
  readonly w3wConnectionActive!: boolean;

  @Mutation updateSnackbarErrorText!: (args: any) => void;
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation toggleWeb3WalletErrorDialog!: (payload?: boolean) => void;
  @Mutation('setWalletConnectionError', { namespace: 'web3Wallet' })
  setWalletConnectionError!: (payload?: IWalletConnectionError | null) => void;

  @Action('updateUser', { namespace: 'profile' })
  private updateUser!: (args: any) => void;

  selectedProviderOption: null | IProviderOption = null;
  redirectRoute = '/';
  savingName = false;
  supportLinks = web3WalletSupportLinks;

  get heading() {
    return this.w3wConnectionActive
      ? this.$t('components.wallet.connectWeb3Wallet.heading.connected')
      : this.$t('components.wallet.connectWeb3Wallet.heading.notConnected');
  }

  get description() {
    return this.w3wConnectionActive
      ? ''
      : this.$t('components.wallet.connectWeb3Wallet.body');
  }

  get missingFullName() {
    const { firstName, lastName, fullNameNotProvided } = this.currentUser;
    const regex = /gamer/i;
    return (
      fullNameNotProvided && (regex.test(firstName) || regex.test(lastName))
    );
  }

  created() {
    if (!this.loggedIn) {
      this.$emit('cancel');
      return;
    }
  }

  async handleFullNameSubmitted(firstName: string, lastName: string) {
    this.savingName = true;
    await this.doAuthQuery(async (totpToken) => {
      this.updateUser({
        firstName,
        lastName,
        ignoreSnackbar: true,
        rethrowErrorCodes: twoFaErrorCodes,
        totpToken,
      });
    }, TwoFaCheckpoint.accountModifications);
    this.savingName = false;
  }

  async onConnectClick(option: IProviderOption) {
    const passedAuthValidation = await this.validateAuthRequirement();
    if (!passedAuthValidation) {
      return;
    }
    try {
      this.selectedProviderOption = option;
      const { success, message, cause } = await this.connect(
        {
          label: option.label,
          connectToUser: true,
        },
        this.doAuthQuery,
      );

      if (success) {
        this.$emit('success', true);
        this.selectedProviderOption = null;
      } else {
        throw new WalletConnectionError(message || '', cause);
      }
    } catch (error) {
      const walletError = error instanceof WalletConnectionError ? error : null;
      const message = walletError?.message ?? (error as Error)?.message;

      if (!message) {
        return;
      }

      const shouldShowSnackbarError =
        !walletError?.cause ||
        walletError.cause === this.walletConnectionErrorCode.UNKNOWN ||
        walletError.cause ===
          this.walletConnectionErrorCode.W3W_FUNCTIONALITY_DISABLED;

      if (shouldShowSnackbarError) {
        this.updateSnackbarErrorText(walletError?.message);
        this.toggleErrorSnackbar();
        this.selectedProviderOption = null;
      } else {
        this.toggleWeb3WalletErrorDialog(true);
      }
    }
  }

  moveOn() {
    this.$emit('success', true);
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  async validateAuthRequirement() {
    if (!(await this.$auth.isLoggedIn())) {
      if (this.loggedIn) {
        window.location.assign(
          `${window.location.origin}${this.$route.path}?register=1`,
        );
      } else {
        this.promptToRegister({ redirectAfterLoginPath: this.$route.fullPath });
      }
      return false;
    }
    return true;
  }

  @Watch('showWeb3WalletErrorDialog', { immediate: true })
  handleShowWeb3WalletErrorDialogChange(
    newValue?: boolean,
    oldValue?: boolean,
  ) {
    if (oldValue && !newValue) {
      this.selectedProviderOption = null;
    }
  }
}
