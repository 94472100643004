import { ActionTree } from 'vuex';
import { SanityDocument } from '@sanity/client';
import { NuxtAppOptions } from '@nuxt/types';
import { groq } from '@nuxtjs/sanity';
import { IRootState } from '~/store/types';
import {
  IRefData,
  TReferencePayloads,
  IReferencesState,
} from '~/store/reference/types';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import { SupportedLocales } from '~/utils/locales';
import {
  ISanityDocumentState,
  TDocStoreActionPayloads,
  TDocStoreMutationPayloads,
} from './types';
import { RecaptchaActionType } from '~/types/recaptcha';

interface IFetchParams {
  id?: string[];
  slug?: string[];
  type: SanityDocumentSchema;
}

export const getIdAndDraftId = (documentId: string) => {
  const id = documentId.replace(/^drafts./, '');
  return {
    id,
    draftId: `drafts.${id}`,
  };
};

export const getSanityClient = (
  schema: SanityDocumentSchema,
  app: NuxtAppOptions,
) => {
  switch (schema) {
    case SanityDocumentSchema.SITE_CONFIG:
      return app.$sanity.settingsClient.client;
    default:
      return app.$sanity.client;
  }
};

export const getLookupIds = (documentId: string | string[]) => {
  const ids = new Set<string>();
  if (typeof documentId === 'string') {
    const { id, draftId } = getIdAndDraftId(documentId);
    ids.add(id);
    ids.add(draftId);
  } else {
    documentId?.forEach((docId) => {
      if (!docId) {
        return;
      }
      const { id, draftId } = getIdAndDraftId(docId);
      ids.add(id);
      ids.add(draftId);
    });
  }
  return Array.from(ids);
};

export const getStoreActions = (
  schema: SanityDocumentSchema,
): ActionTree<ISanityDocumentState, IRootState> => ({
  async clearCache({ commit }, payload: TDocStoreActionPayloads['clearCache']) {
    commit<TDocStoreMutationPayloads['clearCache']>({
      type: `clearCache`,
      key: payload?.key,
      namespace: payload?.namespace,
    });
  },

  async fetchDocuments(
    { commit, dispatch, rootGetters, state },
    payload: TDocStoreActionPayloads['fetchDocuments'],
  ) {
    const lookupById = !!payload._id?.length;
    const lookupBySlug = !!payload.slug?.length;
    if (!(lookupById || lookupBySlug) || !this.app.$sanity?.client) {
      return;
    }

    const params: IFetchParams = { type: schema };
    const filters = [`_type == $type`];

    const loadingState: Partial<ISanityDocumentState> &
      Partial<IReferencesState> = {};

    if (lookupById) {
      const ids = getLookupIds(payload._id!);
      const idsToFetch: string[] = [];

      ids.forEach((id) => {
        if (!!state.documents[id]?.isLoading) {
          return;
        }

        if (
          !payload?.bypassCache &&
          !!(
            state.documents[id] &&
            (state.documents[id]?.data?._id === id ||
              state.documents[id]?.data === undefined)
          )
        ) {
          return;
        }

        idsToFetch.push(id);

        if (!loadingState.documents) {
          loadingState.documents = {};
        }

        loadingState.documents[id] = {
          isLoading: true,
          hasError: false,
        };
      });

      if (idsToFetch.length) {
        filters.push(`_id in $id`);
        params.id = idsToFetch;
      }
    } else if (lookupBySlug) {
      const slugs =
        typeof payload.slug === 'string' ? [payload.slug] : payload.slug;
      const slugsToFetch: string[] = [];
      slugs?.forEach((slug) => {
        if (!!state.slugs[slug]?.isLoading) {
          return;
        }

        if (
          !payload?.bypassCache &&
          !!(state.slugs[slug] && 'key' in state.slugs[slug])
        ) {
          return;
        }

        slugsToFetch.push(slug);

        if (!loadingState.slugs) {
          loadingState.slugs = {};
        }

        loadingState.slugs[slug] = {
          isLoading: true,
          hasError: false,
        };
      });

      if (slugsToFetch.length) {
        filters.push(`slug.current in $slug`);
        params.slug = slugsToFetch;
      }
    }

    if (!params.id && !params.slug) {
      return;
    }

    if (loadingState.documents) {
      commit<TDocStoreMutationPayloads['updateDocuments']>({
        type: 'updateDocuments',
        documents: loadingState.documents,
      });
    }

    if (loadingState.slugs) {
      commit<TDocStoreMutationPayloads['updateSlugs']>({
        type: 'updateSlugs',
        slugs: loadingState.slugs,
      });
    }

    const query = groq`*[${filters.join(' && ')}]|order(_updatedAt desc)`;
    let res: SanityDocument[];
    try {
      res = await this.$filmApiService.get<SanityDocument[]>('cms', {
        params: { query, params },
        requireAuth: false,
        recaptchaAction: RecaptchaActionType.SANITY_FILM_CONTENT,
      });
    } catch (err) {
      // fallback to calling sanity directly
      const sanity = getSanityClient(schema, this.app);
      res = await sanity.fetch<SanityDocument[]>(query, params);
    }

    const newState: Partial<ISanityDocumentState> &
      Partial<IReferencesState> & {
        refsToFetch?: Record<string, boolean>;
      } = {};
    if (res && res.length) {
      res.forEach((result) => {
        const data = this.$sanityLookup.transformDocument(
          result,
          this.app.i18n.locale as SupportedLocales,
        );
        if (!data._id || !data._type) {
          return;
        }

        const publishedId = data._id.replace(/^drafts./, '');
        const referencedIds = !!payload.fetchReferences
          ? this.$sanityLookup.getDocumentRefIds(data)
          : undefined;

        if (referencedIds) {
          if (!newState?.refsToFetch) {
            newState.refsToFetch = {};
          }
          Object.assign(newState.refsToFetch, referencedIds);
        }

        if (!newState?.documents) {
          newState.documents = {};
        }

        newState.documents[data._id] = {
          data,
          isLoading: false,
          hasError: false,
        };

        if (data.slug && state.slugs?.[data.slug]?.key !== publishedId) {
          if (!newState?.slugs) {
            newState.slugs = {};
          }

          newState.slugs[data.slug] = {
            key: publishedId,
            isLoading: false,
            hasError: false,
          };
        }

        const refData = rootGetters?.['reference/getReference']?.({
          _ref: publishedId,
        })?.data as IRefData | undefined;
        if (refData?._id !== publishedId) {
          if (!newState?.references) {
            newState.references = {};
          }
          newState.references[publishedId] = {
            data: {
              _id: publishedId,
              _type: data._type as SanityDocumentSchema,
            },
            isLoading: false,
            hasError: false,
          };
        }
      });
    }

    // clean up loading state for anything that was queried for but didn't exist
    if (lookupById && params.id?.length) {
      params.id.forEach((id) => {
        if (!state.documents?.[id].isLoading) {
          return;
        }

        if (!newState.documents) {
          newState.documents = {};
        }

        if (!newState.documents[id]) {
          newState.documents[id] = {
            data: undefined,
            isLoading: false,
            hasError: false,
          };
        }
      });
    } else if (lookupBySlug && params?.slug?.length) {
      params.slug?.forEach((slug) => {
        if (!state.slugs?.[slug]?.isLoading) {
          return;
        }

        if (!newState.slugs) {
          newState.slugs = {};
        }

        if (!newState.slugs[slug]) {
          newState.slugs[slug] = {
            key: undefined,
            isLoading: false,
            hasError: false,
          };
        }
      });
    }

    if (newState.refsToFetch) {
      dispatch<TReferencePayloads['fetchReferences']>(
        {
          type: 'reference/fetchReferences',
          _id: Object.keys(newState.refsToFetch).filter(
            (key) => !!newState.refsToFetch?.[key],
          ),
        },
        { root: true },
      );
    }

    if (newState.documents) {
      commit<TDocStoreMutationPayloads['updateDocuments']>({
        type: 'updateDocuments',
        documents: newState.documents,
      });
    }

    if (newState.slugs) {
      commit<TDocStoreMutationPayloads['updateSlugs']>({
        type: 'updateSlugs',
        slugs: newState.slugs,
      });
    }

    if (newState.references) {
      commit<TReferencePayloads['updateReferences']>(
        {
          type: 'reference/updateReferences',
          references: newState.references,
        },
        { root: true },
      );
    }
  },
});
