import { SanityDocument } from '@sanity/client';
import { TypedObject } from '@sanity/types';
import { Route, RawLocation } from 'vue-router';
import { TSanityField, TSanityFieldTypeSingle } from '~/types/sanity';
import {
  SanityDocumentSchema,
  TSanityDocumentPageSchemas,
} from '../types/sanity-documents';
import { DEFAULT_LOCALE, SupportedLocales } from './locales';

export const getDocumentStub = (schema: SanityDocumentSchema) => {
  const doc: SanityDocument = {
    _type: schema,
    _id: '',
    _createdAt: '',
    _rev: '',
    _updatedAt: '',
  };
  return doc;
};

const addRefId = (
  fieldData: TSanityFieldTypeSingle,
  allRefIds: { refs?: Record<string, boolean> },
) => {
  const typedObjField = fieldData as TypedObject;
  if (typedObjField?._type !== 'reference' || !typedObjField?._ref) {
    return;
  }

  const refId = typedObjField._ref as string;
  if (!allRefIds?.refs?.[refId]) {
    if (!allRefIds?.refs) {
      allRefIds.refs = {};
    }
    allRefIds.refs[refId] = true;
  }
};

export const getFieldRefIds = (
  fieldData: TSanityField,
  allRefIds: { refs?: Record<string, boolean> },
) => {
  if (Array.isArray(fieldData)) {
    fieldData.forEach((field) => getFieldRefIds(field, allRefIds));
    return;
  }

  if (typeof fieldData === 'object') {
    if (fieldData?._type === 'reference') {
      addRefId(fieldData, allRefIds);
      return;
    }

    const typedObjField = fieldData as TypedObject;
    const excludeTypes: Record<string, boolean> = {
      image: true,
      imageWithAltText: true,
    };

    if (
      !fieldData._type ||
      (!excludeTypes[typedObjField?._type] &&
        !typedObjField._type.match(/^locale/))
    ) {
      Object.keys(typedObjField).forEach((key) => {
        const objFieldData = typedObjField[key] as TSanityField;
        if (Array.isArray(objFieldData)) {
          objFieldData.forEach((field) => getFieldRefIds(field, allRefIds));
        } else {
          addRefId(objFieldData, allRefIds);
        }
      });
    }
  }
};

export const getDocumentRefIds = (doc: SanityDocument) => {
  const docRefs: { refs?: Record<string, boolean> } = {};
  Object.keys(doc).forEach((key) => {
    const fieldData = doc[key] as TSanityField;

    if (!fieldData) {
      return;
    }

    getFieldRefIds(fieldData, docRefs);
  });

  return !docRefs?.refs ? undefined : docRefs.refs;
};

export const transformField = (
  fieldData: TSanityField,
  locale: SupportedLocales,
): TSanityField => {
  if (Array.isArray(fieldData)) {
    return fieldData.map((fieldValue) =>
      transformField(fieldValue as TSanityField, locale),
    ) as TSanityFieldTypeSingle[];
  }

  if (!!(fieldData as TypedObject)?._type) {
    const typedObjField = fieldData as TypedObject;

    if (typedObjField._type?.match(/^locale/)) {
      return (typedObjField[locale] ||
        typedObjField[DEFAULT_LOCALE.code]) as TSanityField;
    }

    if (typedObjField._type === 'slug') {
      return typedObjField.current as string;
    }

    if (typedObjField._type !== 'reference') {
      const empty: TypedObject = { _type: '' };
      return Object.keys(typedObjField).reduce((transformed, key) => {
        const value = transformField(
          typedObjField[key] as TSanityField,
          locale,
        );
        return {
          ...transformed,
          [key]: value,
        };
      }, empty);
    }
  }

  return fieldData;
};

export const transformDocument = (
  doc: SanityDocument,
  locale: SupportedLocales,
): SanityDocument => {
  return Object.keys(doc).reduce((transformed, key) => {
    const value = transformField(doc[key] as TSanityField, locale);
    return {
      ...transformed,
      [key]: value,
    };
  }, getDocumentStub(doc._type as SanityDocumentSchema));
};

export const pageTypes: Partial<
  Record<TSanityDocumentPageSchemas, boolean | string | string[]>
> = {
  [SanityDocumentSchema.CUSTOM_PAGE]: 'content',
  [SanityDocumentSchema.FILM]: true,
  [SanityDocumentSchema.LIVE_STREAM]: 'live',
  [SanityDocumentSchema.PROJECT]: 'films',
  [SanityDocumentSchema.PROMO_BOX]: 'promo',
  [SanityDocumentSchema.SERIES]: true,
  [SanityDocumentSchema.TYPE_FORM]: 'form',
  [SanityDocumentSchema.VIDEO]: 'watch',
};

export const isSanityDocumentPageSchema = (
  schema: string | undefined,
): schema is TSanityDocumentPageSchemas => {
  return !!schema && !!pageTypes[schema as TSanityDocumentPageSchemas];
};

/**
 * Get the schema param for a document route
 * @param schema - the sanity document schema
 * @returns the "schema" value for the document page route params, which could be an alias
 */
export const getSchemaOrAlias = (schema: string | SanityDocumentSchema) => {
  const routeConfig = isSanityDocumentPageSchema(schema)
    ? pageTypes[schema]
    : undefined;
  if (!routeConfig) {
    return;
  }

  if (typeof routeConfig === 'boolean') {
    return schema;
  }

  if (typeof routeConfig === 'string') {
    return routeConfig;
  }

  if (Array.isArray(routeConfig) && routeConfig.length) {
    return routeConfig[0];
  }
};

/**
 * Get document page route params from a slug + schema
 * @param params - A slug and a sanity document schema
 * @returns The params for a document page route
 */
export const getPageRouteParams = (params: {
  slug: string;
  schema: string;
}): Route['params'] | undefined => {
  if (
    !params?.slug ||
    !params?.schema ||
    !isSanityDocumentPageSchema(params.schema)
  ) {
    return;
  }

  const { slug, schema } = params;
  const schemaOrAlias = getSchemaOrAlias(schema);
  if (!schemaOrAlias) {
    return;
  }

  return { slug, schema: schemaOrAlias };
};

/**
 * Get the page route for a schema + slug.
 * @param params - A slug and a sanity document schema
 * @returns A route object for a document page
 */
export const getPageRoute = (params: {
  slug: string;
  schema: string;
}): RawLocation | undefined => {
  const routeParams = getPageRouteParams(params);
  if (!routeParams?.slug || !routeParams?.schema) {
    return;
  }

  return {
    name: 'schema-slug',
    params: {
      slug: routeParams.slug,
      schema: routeParams.schema,
    },
  };
};

/**
 * Get path for a document page
 * @param params - A slug and a sanity document schema
 * @returns The path for a document page
 */
export const getPageRoutePath = (params: {
  slug: string;
  schema: string;
}): string | undefined => {
  const routeParams = getPageRouteParams(params);
  if (!routeParams?.slug || !routeParams?.schema) {
    return;
  }
  return `/${routeParams.schema}/${routeParams.slug}`;
};
