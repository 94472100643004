import getCirclePublicKey from '~/queries/getCirclePublicKey.gql';
import * as openpgp from 'openpgp';

class CreditCardService {
  public async encrypt(client: any, payload: object) {
    // get the encryption keys
    const encryptData = await this.getEncryptionKey(client);
    if (!encryptData) {
      return {
        message: '',
        keyId: '',
      };
    }

    const key = encryptData.publicKey;
    const keyId = encryptData.keyId;

    // Decode public key obtained from Circle API
    const armoredKey = atob(key);

    // Load into openpgp
    const publicKeys = await openpgp.readKey({ armoredKey });

    // Create message for encryption
    const decrypted = await openpgp.createMessage({
      text: JSON.stringify(payload),
    });

    // Encrypt it
    const encrypted = await openpgp.encrypt({
      message: decrypted,
      encryptionKeys: publicKeys,
    });

    return {
      message: btoa(encrypted.toString()),
      keyId,
    };
  }
  public async getEncryptionKey(client: any) {
    try {
      const {
        data: { circlePublicKey },
      } = await client.query({
        query: getCirclePublicKey,
        fetchPolicy: 'network-only',
      });
      return circlePublicKey.keyId && circlePublicKey.publicKey
        ? circlePublicKey
        : false;
    } catch (error) {
      return false;
    }
  }
}

const creditCardService = new CreditCardService();

export default creditCardService;
