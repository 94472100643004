export enum VideoWatchEventTypes {
  VIDEO_WATCH_STARTED = 'VIDEO_WATCH_STARTED',
  VIDEO_WATCH_COMPLETED = 'VIDEO_WATCH_COMPLETED',
  VIDEO_WATCH_PROGRESS = 'VIDEO_WATCH_PROGRESS',
  VIDEO_WATCH_UA_TRIGGERED = 'VIDEO_WATCH_UA_TRIGGERED',
  VIDEO_WATCH_NEXT_EPISODE_TRIGGERED = 'VIDEO_WATCH_NEXT_EPISODE_TRIGGERED',
}

export enum CustomRevealEventTypes {
  OPEN_IN_PROGRESS = 'OPEN_IN_PROGRESS',
  SET_COMPLETED = 'SET_COMPLETED',
  COMPLETED_SET_REDIRECT = 'COMPLETED_SET_REDIRECT',
}

export interface IRestrictionEvent {
  _id: string;
  type: string;
  bucket?: string;
  userId?: string;
  source?: string;
  value?: object;
  meta?: object;
  createdAt?: Date;
  updatedAt?: Date;
  persist?: boolean;
}

export interface IRestrictionUpsertEvent
  extends Omit<IRestrictionEvent, '_id' | 'createdAt' | 'updatedAt'> {
  id?: string;
}
