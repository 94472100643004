import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IShareConfigState } from './types';
import { IRootState } from '../types';

export const state: IShareConfigState = {
  fetching: false,
  goldMember: false,
  shareUrl: '',
  referralCode: '',
  referrerReward: 0,
  companyFee: 0,
  rewardCurrency: '',
  rewardAmount: 0,
  userBalanceThreshold: 0,
  nodesOwned: 0,
  founderNodesOwned: 0,
  townstarNodesOwned: 0,
  filmNodesOwned: 0,
  referralOutcomes: {
    friendsJoined: 0,
    friendsPlayed: 0,
    goldMembers: 0,
    nodesPurchasedByReferrals: 0,
  },
  galaRewards: {
    earned: '0',
    usd: '0',
  },
  townStarRewards: [],
  upgradeBenefits: [],
  basicWalletBenefits: [],
};

const namespaced = true;

const share: Module<IShareConfigState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default share;
