
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class FullScreenDialog extends Vue {
  @Prop(String) readonly title!: string;
  @Prop({ type: Boolean, default: false }) readonly noPadding!: boolean;
  @Prop({ type: Boolean, default: true }) readonly topPadding!: boolean;
  @Prop({ type: Boolean, default: true }) readonly contained!: boolean;
  @Prop({ type: Boolean, default: false }) readonly alwaysFull!: boolean;
  @Prop({ type: Boolean, default: false }) readonly closeOnClickAway!: boolean;
  @Prop({ type: String, default: 'arrow_back_ios' }) closeIcon!: string;
  @Prop({ type: String, default: 'modalBackground' }) color!: string;
  @Prop(Function) readonly closeDialog!: Function;

  get mobile() {
    return this.$vuetify.breakpoint.xs || this.alwaysFull;
  }

  get showBalance() {
    if (this.$slots.balance) {
      return true;
    } else {
      return false;
    }
  }

  closeIfClickAway(event: any) {
    const container = this.$refs.contentContainer as Element;

    if (event.target === container && !this.mobile && this.closeOnClickAway) {
      this.closeDialog();
    }
  }
}
