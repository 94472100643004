
import { SanityReference } from '@sanity/image-url/lib/types/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  ISanityDocuments,
  TSanityDocumentPageSchemas,
} from '~/types/sanity-documents';

@Component
export default class SanityLink extends Vue {
  @Prop(String)
  readonly linkType!: 'url' | 'docRef';
  @Prop(String)
  readonly href!: string;
  @Prop(Boolean)
  readonly blank!: boolean;
  @Prop(Object)
  readonly documentReference!: SanityReference;
  @Prop(String)
  readonly reflinkSuffix!: string;

  getRefLink(document: ISanityDocuments[TSanityDocumentPageSchemas]) {
    if (!document?.slug) {
      return;
    }

    const path = this.$sanityLookup.getPageRoutePath({
      slug: document.slug,
      schema: document._type,
    });
    const suffix =
      this.reflinkSuffix && this.reflinkSuffix.match(/^#|\?|\//)
        ? this.reflinkSuffix
        : '';
    return path + suffix;
  }

  get isExternalLink() {
    if (this.linkType === 'docRef') {
      return false;
    }

    if (/^https?:\/\//.test(this.href) || /^(tel)|(mailto):/.test(this.href)) {
      return true;
    }

    return false;
  }

  get target() {
    return this.blank ? '_blank' : '_top';
  }
}
