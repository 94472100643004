
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { CopyToClipboard } from '~/mixins';
import { ISnackbarOptions } from '~/store/types';

@Component
export default class ShareButton extends Mixins(CopyToClipboard) {
  @Prop(String) readonly nativeShareLabel!: string;
  @Prop(String) readonly nativeShareText!: string;
  @Prop(String) readonly nativeShareTitle!: string;
  @Prop(String) readonly shareLabel!: string;
  @Prop(String) readonly shareText!: string;
  @Prop(String) readonly shareTitle!: string;
  @Prop(String) readonly shareUrl!: string;
  @Prop({ type: String, default: 'centered' })
  readonly snackbarPosition!: ISnackbarOptions['position'];

  get nativeShareSupported() {
    const share = !!(navigator as any).share;
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    const nonSafariOrIos = !isSafari || isIOS;
    return !!(share && nonSafariOrIos);
  }

  triggerNativeShare() {
    const nativeShare = (navigator as any).share;
    if (nativeShare && this.nativeShareText && this.shareUrl) {
      try {
        (navigator as any).share({
          title: this.nativeShareTitle,
          text: this.nativeShareText,
          url: this.shareUrl,
        });
      } catch (error) {
        this.$sentry.captureException(error);
        console.warn(error);
      }
    }
  }

  handleClick() {
    if (this.nativeShareSupported) {
      this.triggerNativeShare();
    } else {
      this.copyToClipboard(
        this.shareText,
        this.shareTitle,
        this.snackbarPosition,
      );
    }
  }
}
