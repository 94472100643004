import { Context } from '@nuxt/types';
import axios from 'axios';
import { RecaptchaActionType } from '~/types/recaptcha';

export class MusicDataService {
  context: Context;
  apiUrl: string;
  contractId: string;

  constructor(context: Context) {
    this.context = context;
    this.apiUrl = context.env.musicDataEndpoint;
    this.contractId = context.env.musicDataContractId;
  }

  private async getAuthHeader() {
    const token = await this.context.$auth.getTokenSilently();
    if (!token) {
      return {};
    }

    return { Authorization: 'Bearer ' + token };
  }

  async trackSongCompletionEvent(songToken: string): Promise<void> {
    try {
      const botToken = await this.context.$recaptchaService.execute(
        RecaptchaActionType.MUSIC_API_SONG_EVENT,
      );
      const trackSongPayload = {
        contractId: this.contractId,
        tokenId: songToken,
        eventType: 'QUALIFIES',
        playedFrom: 'gala-film',
        eventPlayHeadTime: '30',
        deviceType: 'film-backend',
        botToken,
        utm: null,
      };
      const results = await axios.post(
        `${this.apiUrl}/song-event`,
        trackSongPayload,
        {
          headers: {
            ...(await this.getAuthHeader()),
          },
        },
      );
      return results.data;
    } catch (err) {
      console.error('Song Completion Error:', err);
    }
  }
}
