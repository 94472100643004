
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NuxtError } from '@nuxt/types';
import { ThemeName } from '~/store/types';
import FullScreenError from '~/components/UIComponents/FullScreenError.vue';
import ThemeProvider from '~/components/ThemeProvider.vue';

export interface IError extends Error {
  code?: number;
}

@Component({
  components: { FullScreenError, ThemeProvider },
  meta: {
    theme: ThemeName.DARK,
  },
})
export default class ErrorPage extends Vue {
  @Prop(Object)
  readonly error!: NuxtError;

  theme = ThemeName.DARK;

  get errorTitleOrDefault() {
    const errorTitleKey = `pages.error.title.${
      this.error.statusCode || 'default'
    }`;
    return this.$te(errorTitleKey)
      ? this.$t(errorTitleKey)
      : this.$t('pages.error.title.default');
  }

  get errorMessageOrDefault() {
    const errorMessageKey = `pages.error.message.${
      this.error.statusCode || 'default'
    }`;
    return this.$te(errorMessageKey)
      ? this.$t(errorMessageKey)
      : this.$t('pages.error.message.default');
  }

  get errorDetails() {
    const errorCases = [
      {
        match: () =>
          this.$route.query.error_description ===
          'Failed to send IP verification email',
        title: () => this.$t('pages.error.title.ipVerificationSendFail'),
        message: () => this.$t('pages.error.message.ipVerificationSendFail'),
        extraButtons: [
          {
            key: 'ContactSupport',
            text: 'Contact Support',
            link: '/contact-support',
          },
        ],
      },
      {
        match: () => true,
        title: () => this.errorTitleOrDefault,
        message: () => this.errorMessageOrDefault,
        extraButtons: [],
      },
    ];

    const currentErrorDetails = errorCases.find((matcher) => matcher.match());
    if (!currentErrorDetails) {
      throw new Error('Unknown Error');
    }

    return {
      title: currentErrorDetails.title(),
      message: currentErrorDetails.message(),
      extraButtons: currentErrorDetails.extraButtons,
    };
  }
}
