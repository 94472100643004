
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import { ISnackbarOptions, ISnackbarPayload } from '~/store/types';
import RewardSnackbar from '~/components/Rewards/RewardSnackbar.vue';

@Component({
  components: { RewardSnackbar },
})
export default class DashboardSnackbar extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly shouldRenderIphoneSnackbar!: boolean;

  @State('snackbarSuccessText') snackbarSuccessText!: string;
  @State('snackbarErrorText') snackbarErrorText!: string;
  @State('showSuccessSnackbar') shouldShowSuccessSnackbar!: boolean;
  @State('showErrorSnackbar') shouldShowErrorSnackbar!: boolean;

  @State('showSnackbar') shouldShowSnackbar!: boolean;
  @State('snackbarOptions') snackbarOptions!: ISnackbarOptions;
  @State('snackbarText') snackbarText!: string;

  @Mutation('toggleSuccessSnackbar') toggleSuccessSnackbar!: (
    args: boolean,
  ) => void;
  @Mutation('toggleErrorSnackbar') toggleErrorSnackbar!: (
    args: boolean,
  ) => void;
  @Mutation('toggleSnackbar') toggleSnackbar!: (args?: boolean) => void;

  showIphoneSnackbar = false;

  get showSuccessSnackbar() {
    return this.shouldShowSuccessSnackbar;
  }

  set showSuccessSnackbar(value) {
    this.toggleSuccessSnackbar(value);
  }

  get showErrorSnackbar() {
    return this.shouldShowErrorSnackbar;
  }

  set showErrorSnackbar(value) {
    this.toggleErrorSnackbar(value);
  }

  get showSnackbar() {
    return this.shouldShowSnackbar;
  }

  set showSnackbar(value) {
    this.toggleSnackbar(value);
  }

  get snackbarProps() {
    const {
      icon,
      closeButton,
      color = 'success',
      position = 'top',
      ...options
    } = this.snackbarOptions;
    return {
      ...options,
      color,
      [position]: true,
    };
  }

  get snackbarCloseButton() {
    return this.snackbarOptions?.closeButton;
  }

  get snackbarIcon() {
    return this.snackbarOptions?.icon;
  }

  created() {
    if (!this.shouldRenderIphoneSnackbar) {
      return;
    }

    if (
      navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 &&
      !window.matchMedia('(display-mode: standalone)').matches &&
      !sessionStorage.getItem('hideIphonePopup') &&
      !this.$route.query.promo
    ) {
      this.showIphoneSnackbar = true;
    }
  }

  hideIphonePopup() {
    if (!this.shouldRenderIphoneSnackbar) {
      return;
    }

    this.showIphoneSnackbar = false;
    sessionStorage.setItem('hideIphonePopup', 'true');
  }
}
