import { ChainNetwork } from '~/types/chain';
import { CustomRevealEventTypes } from '~/types/event-restriction';
import { TCustomRevealUserItem } from '~/types/user-items';

export function getEventBucket(id: string) {
  return id.replace('/', '|');
}

export function getRestrictionUpsertEventId(
  eventType: CustomRevealEventTypes,
  id: string,
) {
  return `${eventType}|${id}|$USER_ID`.replace('/', '|'); // event|entityType|entitySlug|$USER_ID
}

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export function isItemExchangeable(item: TCustomRevealUserItem): boolean {
  return (
    (item.network === ChainNetwork.OFF_CHAIN_TOKEN && item.isExchangeable) ||
    item.network === ChainNetwork.GYRI
  );
}

export function isCustomRevealExchangeItem(item: TCustomRevealUserItem) {
  return (
    !!item.gyriTokenClassKey &&
    !!item.exchangeRevealVideo &&
    !!item.gyriExchanges?.length &&
    isItemExchangeable(item)
  );
}

export function isSimilarItem(
  selectedItem: TCustomRevealUserItem | null,
  item: TCustomRevealUserItem,
) {
  return (
    item.gyriTokenClassKey?.category ===
      selectedItem?.gyriTokenClassKey?.category &&
    item.gyriTokenClassKey?.collection ===
      selectedItem?.gyriTokenClassKey?.collection
  );
}

export function getRevealType(type: string) {
  const revealType = type?.toLocaleLowerCase()?.includes('mystery')
    ? 'mystery-box'
    : type?.toLocaleLowerCase()?.includes('moment')
    ? 'moment-pack'
    : '';
  return revealType;
}
