
import { Component, Getter, Vue } from 'nuxt-property-decorator';
import { TSiteConfigState } from '~/store/types';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';

@Component({
  components: {},
})
export default class UaTopToolbarLogo extends Vue {
  @Getter('siteConfig')
  readonly siteConfigState!: TSiteConfigState;

  schema = SanityDocumentSchema.UA_PAGE;

  get isLoading() {
    return this.uaPageState?.isLoading || this.relatedDocState?.isLoading;
  }

  get logoAlt() {
    if (this.uaPageState?.data?.logo) {
      return this.uaPageState.data.logo.alt || '';
    }

    if (this.relatedDocState?.data?.logo) {
      return this.relatedDocState.data.logo.alt || '';
    }
  }

  get logoUrl() {
    if (
      this.relatedDocState?.data?.uaPage?._ref &&
      this.uaPageState?.isLoading
    ) {
      return;
    }

    const image =
      this.uaPageState?.data?.logo || this.relatedDocState?.data?.logo;
    if (!image) {
      return;
    }

    return this.$sanityImage.urlFor(image).maxHeight(35).url();
  }

  get uaPageState() {
    if (this.$route.params?.schema && this.$route.params?.slug) {
      return this.relatedDocState?.data?.uaPage?._ref
        ? this.$sanityLookup.getDocumentFromId<
            ISanityDocuments[SanityDocumentSchema.UA_PAGE]
          >({
            _id: this.relatedDocState.data.uaPage._ref,
            _type: this.schema,
          })
        : undefined;
    } else if (this.siteConfigState?.data?.uaPage?._ref) {
      return this.$sanityLookup.getDocumentFromId<
        ISanityDocuments[SanityDocumentSchema.UA_PAGE]
      >({
        _id: this.siteConfigState.data.uaPage._ref,
        _type: this.schema,
      });
    }
  }

  get relatedDocState() {
    const params = this.$route.params;
    const schema = this.$sanityLookup.getSchemaFromRouteParams({
      schema: params?.schema,
    });
    if (schema && params.slug) {
      return this.$sanityLookup.getDocumentFromSlug({
        _type: schema as SanityDocumentSchema,
        slug: params.slug,
      });
    }
  }
}
