// These types also exist in https://git.netzilla.io/gala/gala-app-server
// and they should be identical.

export enum TwoFaCheckpoint {
  accountModifications = 'accountModifications',
  accountLogin = 'accountLogin',
  transactions = 'transactions',
  purchases = 'purchases',
  accountDeletion = 'accountDeletion',
  transferCodeRecovery = 'transferCodeRecovery',
  downloadPrivateKey = 'downloadPrivateKey',
  web3WalletConnection = 'web3WalletConnection',
}

export interface IEnabledTwoFaCheckpoints
  extends Record<TwoFaCheckpoint, boolean> {}

export const defaultTwoFaCheckpoints: IEnabledTwoFaCheckpoints = {
  [TwoFaCheckpoint.accountModifications]: false,
  [TwoFaCheckpoint.accountLogin]: true,
  [TwoFaCheckpoint.transactions]: false,
  [TwoFaCheckpoint.purchases]: false,
  [TwoFaCheckpoint.accountDeletion]: false,
  [TwoFaCheckpoint.transferCodeRecovery]: false,
  [TwoFaCheckpoint.downloadPrivateKey]: false,
  [TwoFaCheckpoint.web3WalletConnection]: true,
};
