import { SanityDocument } from '@sanity/client';
import { Component, Vue } from 'vue-property-decorator';
import {
  ISanityDocuments,
  TVideoType,
  TVideoParentType,
  IVideoWithExpandedAsset,
  SanityDocumentSchema,
} from '~/types/sanity-documents';

@Component
export default class SanityMuxData extends Vue {
  getVideoTitle(
    data?:
      | ISanityDocuments[TVideoType]
      | SanityDocument<
          IVideoWithExpandedAsset & { _type: SanityDocumentSchema.VIDEO }
        >,
    parent?: ISanityDocuments[TVideoParentType],
  ) {
    if (!data) {
      return '';
    }
    const shouldIncludeBothTitles =
      data.title && parent?.title && data.title !== parent.title;
    const videoTitle = shouldIncludeBothTitles
      ? (this.$t('components.player.videoPlayer.titleWithParentTitle', {
          title: data.title,
          parentTitle: parent.title,
        }) as string)
      : data.title;
    return videoTitle;
  }
}
