import { MutationTree } from 'vuex';
import { IUFCState } from './types';
import { IUFCEvent } from '~/types/ufc';

export const mutations: MutationTree<IUFCState> = {
  updateEvent(ufcState: IUFCState, event: IUFCEvent) {
    if (event) {
      const modifyEvents = { ...ufcState.events };
      modifyEvents[event.slug] = event;
      ufcState.events = modifyEvents;
    }
  },
};
