import { IGameItemForSaleByGala } from '~/store/games/types';

export const getSupplyInTier = (item: IGameItemForSaleByGala) => {
  return item.tiers[item.currentTier - 1].supply;
};

export const getTotalTieredSupply = (item: IGameItemForSaleByGala) => {
  return !item.tiers ? 0 : item.tiers.reduce((accum, a) => accum + a.supply, 0);
};

export const getTotalSupply = (item: IGameItemForSaleByGala) => {
  return !item.tiers
    ? item.rarity?.supplyLimit || 0
    : getTotalTieredSupply(item);
};

export const getAmountLeftInStore = (item: IGameItemForSaleByGala) => {
  return !item.tiers
    ? item.qtyLeft
    : item.tiers
        .slice(item.currentTier)
        .reduce((total, tier) => total + tier.supply, 0) + item.qtyLeft;
};

export const getIsCompletelySoldOut = (item: IGameItemForSaleByGala) => {
  if (item.tiers) {
    return item.currentTier === item.tiers.length && item.qtyLeft === 0;
  }

  return item.qtyLeft === 0;
};
