
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { ISortOption } from '~/types/user-items';

@Component
export default class SortDrawer extends Vue {
  @Prop(Array) readonly sortOptions!: ISortOption[];
  @Prop({ type: String, default: 'Sort results by' }) readonly heading!: string;
  @Prop({ type: String, default: '' }) readonly selected!: string;

  @Emit('sort-select')
  handleSortSelect(id: string) {
    return id;
  }
}
