import { MutationTree } from 'vuex';
import {
  IBoostState,
  IProjectBoostStats,
  IFilmBoostSupporter,
  IUserBoostHold,
  INormalizedChainBalance,
} from './types';

import { deepSet } from '~/store/store-utils';

export const mutations: MutationTree<IBoostState> = {
  updateProjectBoost(
    boostState: IBoostState,
    payload: { data: IProjectBoostStats },
  ) {
    if (payload?.data?.projectSlug) {
      const currentProjectBoostStats =
        boostState.byProject?.[payload.data.projectSlug]?.stats || {};
      const value = { ...currentProjectBoostStats, ...payload?.data };
      const key = `byProject.${payload.data.projectSlug}.stats`;
      deepSet(boostState, key, value);
    }
  },

  updateUserBoostHold(
    boostState: IBoostState,
    payload: { data: IUserBoostHold },
  ) {
    if (payload?.data?.projectSlug) {
      const currentHoldData =
        boostState.byProject[payload.data.projectSlug]?.userHold || {};
      const value = { ...currentHoldData, ...payload.data };
      const key = `byProject.${payload.data.projectSlug}.userHold`;
      deepSet(boostState, key, value);

      const supporter =
        boostState.byProject[payload.data.projectSlug]?.supporters || [];
      const ownSupporterIndx = supporter.findIndex(
        (s) => s.userId === payload.data.userId,
      );

      if (ownSupporterIndx > -1) {
        const supporterKey = `byProject.${payload.data.projectSlug}.supporters.${ownSupporterIndx}`;
        const supporterValue = {
          ...supporter[ownSupporterIndx],
          ...payload.data,
        };
        deepSet(boostState, supporterKey, supporterValue);
      }
    }
  },

  updateBoostSupporters(
    boostState: IBoostState,
    payload: { userHolds: IFilmBoostSupporter[]; projectSlug: string },
  ) {
    if (payload?.projectSlug) {
      // sort by descending userAmountHeld
      const value = (payload?.userHolds || []).sort(
        (a, b) => +b?.userAmountHeld - +a?.userAmountHeld,
      );
      const key = `byProject.${payload.projectSlug}.supporters`;
      deepSet(boostState, key, value);
    }
  },

  updateUserBalance(
    boostState: IBoostState,
    payload: {
      balance: INormalizedChainBalance;
    },
  ) {
    if (payload?.balance) {
      const value = { ...boostState.balance, ...payload.balance };
      deepSet(boostState, 'balance', value);
    }
  },
};
