import { Context } from '@nuxt/types';

export default async function (context: Context) {
  const { redirect, store } = context;
  const { state } = store;

  if (state.profile.user.walletExists || state.profile.user.walletConnected) {
    return;
  } else {
    await store.dispatch('profile/getUserData');
    if (
      !state.profile.user.walletExists &&
      !state.profile.user.walletConnected &&
      !state.profile.user.hasGyriPassphrase
    ) {
      redirect('/');
    }
  }
}
