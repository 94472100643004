import { Component, Prop, Vue } from 'nuxt-property-decorator';
import {
  ISanityDocuments,
  SanityDocumentSchema,
  TSanityDocumentPageSchemas,
} from '~/types/sanity-documents';
import { ISanityObjects, SanityObjectSchema } from '~/types/sanity-objects';

@Component
export default class SanityPromoItemNav extends Vue {
  @Prop(Object) item!: ISanityObjects[SanityObjectSchema.PROMO_CAROUSEL_ITEM];

  get ctaUrl() {
    if (this.item?.cta?.linkToDocumentRef) {
      if (!this.item?.documentReference?._ref) {
        return;
      }

      const docRef = this.$sanityLookup.getDocumentFromReference<
        ISanityDocuments[TSanityDocumentPageSchemas]
      >({
        _ref: this.item.documentReference._ref,
      });

      if (!docRef?.data?.slug || !docRef?.data?._type) {
        return;
      }

      if (this.$sanityLookup.hasLandingPage(docRef.data)) {
        return this.$sanityLookup.getPageRoutePath({
          slug: docRef.data?.slug,
          schema: docRef.data._type as SanityDocumentSchema,
        });
      }
    } else {
      return this.item?.cta?.url;
    }
  }

  get isNuxtLink() {
    if (!this.ctaUrl) {
      return false;
    }
    return !(
      /^https?:\/\//.test(this.ctaUrl) || /^(tel)|(mailto):/.test(this.ctaUrl)
    );
  }

  get isExternalLink() {
    if (!this.ctaUrl) {
      return false;
    }
    return !!/^https?:\/\//.test(this.ctaUrl);
  }

  handleNavigation() {
    if (!this.ctaUrl) {
      return;
    }

    if (/^https?:\/\//.test(this.ctaUrl)) {
      // Open external links in new window
      window.open(this.ctaUrl);
    } else if (/^(tel)|(mailto):/.test(this.ctaUrl)) {
      // Open tel or email links in same window
      window.location.href = this.ctaUrl;
    } else {
      // Nav without page load for relative links
      this.$router.push(this.ctaUrl);
    }
  }
}
