export interface IDocumentAccepted {
  documentType: string;
  required: boolean;
  versionAccepted: string;
}

export interface IDocumentsAccepted extends Array<IDocumentAccepted> {}

export interface ILegalDocument {
  content: string;
  documentType: string;
  version: string;
}

export const DEFAULT_LEGAL_DOCUMENT: ILegalDocument = {
  content: '',
  documentType: '',
  version: '',
};

export enum DocumentTypes {
  TERMS_AND_CONDITIONS = 'film_terms_and_conditions',
  PRIVACY_POLICY = 'privacy_policy',
  NODE_NON_INVESTMENT_STATEMENT = 'node_non_investment_statement',
  CREDIT_CARD_TERMS_AND_CONDITIONS = 'circle_terms_of_use',
}

export const DEFAULT_LEGAL_AGREEMENTS_PAYLOAD: ILegalAgreementsPayload = {
  documentsAccepted: [],
  allAccepted: false,
};

export interface ILegalAgreementsPayload {
  documentsAccepted: IDocumentsAccepted;
  allAccepted?: boolean;
}
