import { MutationTree } from 'vuex';
import { ISanityDocumentState, TDocStoreMutationPayloads } from './types';

export const getStoreMutations = (): MutationTree<ISanityDocumentState> => {
  return {
    clearCache(state, payload: TDocStoreMutationPayloads['clearCache']) {
      if (payload.namespace) {
        state[payload.namespace] = {};
      } else {
        state.documents = {};
        state.slugs = {};
      }
    },

    updateDocuments(
      state,
      payload: TDocStoreMutationPayloads['updateDocuments'],
    ) {
      if (payload.documents) {
        state.documents = {
          ...state.documents,
          ...payload.documents,
        };
      }
    },

    updateSlugs(state, payload: TDocStoreMutationPayloads['updateSlugs']) {
      if (payload.slugs) {
        state.slugs = {
          ...state.slugs,
          ...payload.slugs,
        };
      }
    },
  };
};
