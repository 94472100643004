import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import Pubnub from 'pubnub';

declare module 'vue/types/vue' {
  // tslint:disable-next-line: interface-name
  interface Vue {
    $pubnub: Pubnub;
  }
}

declare module '@nuxt/types' {
  // tslint:disable-next-line: interface-name
  interface NuxtAppOptions {
    $pubnub: Pubnub;
  }

  // tslint:disable-next-line: interface-name
  interface Context {
    $pubnub: Pubnub;
  }
}

declare module 'vuex/types/index' {
  // tslint:disable-next-line: interface-name
  interface Store<S> {
    $pubnub: Pubnub;
  }
}

const EMPTY_USERID = 'no-user';

export default defineNuxtPlugin((nuxtContext, inject) => {
  inject(
    'pubnub',
    new Pubnub({
      publishKey: nuxtContext.env.pubnubPublishKey || '',
      subscribeKey: nuxtContext.env.pubnubSubscribeKey || '',
      userId: EMPTY_USERID,
    }),
  );
});
