/* tslint:disable */
import {
  TAdaptor,
  IHydrateAdaptorPayload,
  IHydratedAdaptor,
  EAdaptors,
  TInit,
  TTrackEvent,
  EEventTypes,
  TTRACKED_EVENTS,
  TSetUser,
} from '../types';
import hydrateAdaptor from '../hydrateAdaptor';

const TRACKED_EVENTS: TTRACKED_EVENTS = {
  [EEventTypes.REGISTRATION_COMPLETE]: true,
};

export const xAdaptor: TAdaptor = (adaptorPayload) => {
  const { enabled, accountId } = adaptorPayload.config;

  if (!enabled || !accountId) {
    return undefined;
  }

  const init: TInit = () => {
    if ((window as any).twq) {
      return;
    }

    (function (e: any, t: any, n: any, s: any, u: any, a: any) {
      e.twq ||
        ((s = e.twq =
          function () {
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
          }),
        (s.version = '1.1'),
        (s.queue = []),
        (u = t.createElement(n)),
        (u.async = !0),
        (u.src = 'https://static.ads-twitter.com/uwt.js'),
        (a = t.getElementsByTagName(n)[0]),
        a.parentNode.insertBefore(u, a));
    })(window, document, 'script', undefined, undefined, undefined);

    (window as any).twq('init', accountId);
  };

  const logEvent = (eventName: string, eventValue: any) => {
    const twq = (window as any).twq;
    twq('track', eventName, eventValue);
  };

  const trackEvent: TTrackEvent = (payload) => {
    const { eventName } = payload;
    if (!(eventName in TRACKED_EVENTS)) {
      return;
    }

    const { eventValue } = payload;

    switch (eventName) {
      case EEventTypes.REGISTRATION_COMPLETE: {
        logEvent('tw-o9avg-om0ht', {
          email_address: (eventValue?.email as string).trim().toLowerCase(),
        });
        break;
      }
    }
  };

  const hydrateAdaptorPayload: IHydrateAdaptorPayload = {
    init,
    trackEvent,
  };

  const baseAdaptor: IHydratedAdaptor = hydrateAdaptor(hydrateAdaptorPayload);

  const setUser: TSetUser = (currentUser) => {
    baseAdaptor.trackSetUserEvent(currentUser);
  };

  const finalAdaptor: IHydratedAdaptor = {
    ...baseAdaptor,
    setUser,
  };

  return finalAdaptor;
};

xAdaptor.NAME = EAdaptors.X;

export default xAdaptor;
