import { NuxtError } from '@nuxt/types';
import { CurrentUser } from '@sanity/types/dist/dts';
import { IProfileState } from './profile/types';
import { IGamesState } from './games/types';
import { SanityDataset } from '~/types/sanity';
import { IFeatureFlagsState } from '~/utils/featureFlags';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';
import { ISanityObjects, SanityObjectSchema } from '~/types/sanity-objects';

export interface IRootState {
  version: string;
  showSnackbar: boolean;
  showSuccessSnackbar: boolean;
  showErrorSnackbar: boolean;
  createWalletPrompt: ICreateWalletPrompt;
  showWeb3WalletErrorDialog: boolean;
  showFilmEmailSignup: boolean;
  showDiscordDialog: boolean;
  showDiscordConnectingDialog: boolean;
  showReferralDialog: boolean;
  snackbarErrorText: string;
  snackbarSuccessText: string;
  snackbarText: string;
  snackbarOptions: ISnackbarOptions;
  currentPage: ICurrentPage;
  previousPage: IPreviousPage;
  profile?: IProfileState;
  games?: IGamesState;
  showHeader: boolean;
  showFooter: boolean;
  discordLink: string;
  userAgentInfo: IUserAgentInfo;
  apiErrorCodes: Array<{ id: string; timestamp: string }>;
  showResetDialog: boolean;
  theme: ThemeName;
  tokenMigrationStatus: TokenMigrationStatus;
  debugSettings: IDebugOptions;
  sanityUser: TSanityUserState;
  siteConfig: IFeatureFlagsState;
  siteConfigSlug: string;
  hasClosedToolbarMarketingBanner: boolean;
  redirects:
    | Record<string, ISanityObjects[SanityObjectSchema.REDIRECT_TO]>
    | false;
}

export enum TokenMigrationStatus {
  inactive = 1,
  locking,
  ready,
  active,
  complete,
  verified,
}

export enum ThemeName {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface IUserAgentInfo {
  language: string;
  displayMode: string;
  ip?: string;
}

export interface ICurrentPage {
  title: string;
  showBoxCoinBalance?: boolean;
  backIcon?: string;
  backRoute?: string;
  forceBackRoute?: boolean;
}
export interface IPreviousPage {
  path: string;
  sameAsCurrent: boolean;
}

export interface IBaseResponse {
  success: boolean | number;
  message?: string;
}

export type IBaseGqlResponse<K extends string, T = {}> = {
  [k in K]: IBaseResponse & T;
};

export interface ISnackbarOptions {
  closeButton?: boolean;
  color?: string;
  elevation?: number;
  icon?: string;
  light?: boolean;
  dark?: boolean;
  rounded?: boolean | 'sm' | 'lg' | 'xl' | 'pill';
  position?: 'bottom' | 'left' | 'right' | 'top' | 'centered';
}

export type ISnackbarPayload =
  | string
  | (ISnackbarOptions & {
      message?: string;
    });

export interface IDebugOptions {
  debugSettingsEnabled?: boolean;
  contentDataset?: SanityDataset;
  settingsDataset?: SanityDataset;
  shouldShowDrafts?: boolean;
}

export const DEBUG_TOOL_DEFAULTS: IDebugOptions = {
  debugSettingsEnabled: false,
  contentDataset: process.env.sanityDataset as SanityDataset,
  settingsDataset: process.env.sanityDataset as SanityDataset,
  shouldShowDrafts: !!process.env.sanityWithCredentials,
};

export type TSanityUserState = TAsyncStateWithData<Partial<CurrentUser>>;
export type TUpdateSanityUserPayload = TPayloadWithType<
  'updateSanityUser',
  TSanityUserState
>;
export type TClearDocumentsCachesPayload = TPayloadWithType<
  'clearDocumentsCaches',
  { types?: SanityDocumentSchema[] }
>;
export type TSiteConfigState = TAsyncStateWithData<
  ISanityDocuments[SanityDocumentSchema.SITE_CONFIG]
>;

export type TAsyncState<TData> = {
  isLoading: boolean;
  hasError: boolean;
  error?: string | NuxtError;
} & TData;

export type TAsyncStateWithData<TData> = TAsyncState<{ data?: TData }>;
export type TAsyncStateWithKey = TAsyncState<{ key?: string }>;

export type TPayloadWithType<
  TPayloadType extends string,
  TPayload,
> = TPayload & {
  type: TPayloadType;
};

export interface ICacheParams<TNamespace, TData = any> {
  namespace: TNamespace;
  key: string;
  data?: TData;
}

export enum UaVariations {
  NEW_ACCOUNT = 'newAccount',
  EMAIL = 'emailSignup',
  THANK_YOU = 'thankYou',
}

export interface IPromptToLoginPayload {
  redirectAfterLoginPath?: string;
  theme?: ISanityObjects[SanityObjectSchema.AUTH_THEME];
}
export interface IPromptToRegisterPayload extends IPromptToLoginPayload {
  activeCampaignId?: string;
  connection?: string;
}

export type TPromptToLoginDispatch = TPayloadWithType<
  'promptToLogin',
  IPromptToLoginPayload
>;
export type TPromptToRegisterDispatch = TPayloadWithType<
  'promptToRegister',
  IPromptToRegisterPayload
>;

export interface ICreateWalletPrompt {
  show: boolean;
  walletType: WalletType;
  title?: string;
  body?: string;
  isStarted?: boolean;
}

export enum WalletType {
  ETH = 'eth', // web3 wallet
  GALA = 'gala', // old gala wallet
  GYRI = 'gyri', // gyri wallet
  FIAT = 'fiat', // credit card
}
