import { providers } from 'ethers';
import { ExternalProvider } from '@ethersproject/providers';
import { CoinbaseWalletProvider } from '@coinbase/wallet-sdk';

export enum WalletConnectionErrorCode {
  ADDRESS_MISMATCH = 'ADDRESS_MISMATCH',
  GALA_WALLET_HOLDER = 'GALA_WALLET_HOLDER',
  INVALID_NETWORK = 'INVALID_NETWORK',
  MISSING_PROVIDER = 'MISSING_PROVIDER',
  MISSING_BROWSER_CONNECTION = 'MISSING_BROWSER_CONNECTION',
  W3W_FUNCTIONALITY_DISABLED = 'W3W_FUNCTIONALITY_DISABLED',
  UNKNOWN = 'UNKNOWN',
}
export interface IExternalWallet {
  ethAddress: string;
  walletProvider: string;
  connected?: boolean;
  timestamp?: Date;
}

export interface IProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}

export enum Network {
  mainnet = 1,
  ropsten = 3,
  rinkeby = 4,
  goerli = 5,
  sepolia = 11155111,
}
export interface IConnectedWallet {
  address: string;
  label: string;
}

export interface IConnectWalletMutationOptions {
  authData: {
    ethAddress: string;
    signedContent: string;
    sig: string;
    timestamp: string;
  };
  walletProvider: string;
  totpToken: string;
}

export interface IProviderOption {
  label: string;
  name: string;
  getInterface: () => ExternalProvider | CoinbaseWalletProvider | undefined;
  icon: string;
  missingProviderMobileLink: (dappPath: string) => string;
  missingProviderDesktopLink: string;
}

export interface IWalletConnectionError {
  code: WalletConnectionErrorCode;
  message: string;
  shortMessage?: string;
  provider?: string;
}

export interface IProviderWhitelist {
  providerLabel: string;
  allowedUserIds: string[];
}

export interface IW3wState {
  provider: ExternalProvider | null;
  web3Provider: providers.Web3Provider | null;
  externalWallets: IExternalWallet[];
  providerOptions: IProviderOption[];
  networks: Record<Network, string>;
  expectedNetwork: Network;
  connectedWallet: IConnectedWallet;
  connectedExternalWallet: IExternalWallet;
  walletConnectionError: IWalletConnectionError | null;
  providerWhitelists: IProviderWhitelist[];
}
