
import { Component, Vue, Prop } from 'vue-property-decorator';
import { parse } from 'eth-url-parser';
interface IScanResult {
  address: string;
  amount?: number;
}
@Component({
  components: {},
})
export default class ScanQr extends Vue {
  @Prop(String) readonly symbol!: string;

  error: string = '';
  result: any;
  loading: boolean = false;
  cameraReady: boolean = false;
  showQrCodeCapture = false;

  async onInit(promise: Promise<string>) {
    this.loading = true;
    try {
      await promise;
      this.loading = false;

      this.cameraReady = true;
    } catch (err) {
      const error = err as Error;
      this.loading = false;
      if (error.name === 'NotAllowedError') {
        this.error = this.$t(
          'components.wallet.scanQr.notAllowedErr',
        ) as string;
      } else if (error.name === 'NotFoundError') {
        this.error = this.$t('components.wallet.scanQr.notFoundErr') as string;
      } else if (error.name === 'NotSupportedError') {
        this.error = this.$t(
          'components.wallet.scanQr.notSupportedErr',
        ) as string;
      } else if (error.name === 'NotReadableError') {
        this.error = this.$t(
          'components.wallet.scanQr.cameraInUseErr',
        ) as string;
      } else if (error.name === 'OverconstrainedError') {
        this.error = this.$t(
          'components.wallet.scanQr.overconstrainedErr',
        ) as string;
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.showQrCodeCapture = true;
      } else {
        this.$sentry.captureException(error);
        this.showQrCodeCapture = true;
      }
    }
  }
  onDecode(result: string) {
    switch (this.symbol) {
      case 'BTC':
        this.result = this.parseBtc(result);
        break;
      case 'ETH':
        this.result = this.parseEth(result);
        break;
      default:
        this.result = this.parseErc20(result);
    }
    this.$emit('scanned', this.result);
  }

  parseEth(url: string) {
    let result: IScanResult;
    try {
      const {
        target_address,
        parameters: { value },
      } = parse(url);
      result = {
        address: target_address,
        amount: value / Math.pow(10, 18),
      };
    } catch (error) {
      result = { address: url };
    }
    return result;
  }

  parseErc20(url: string) {
    let result: IScanResult;
    try {
      const {
        parameters: { address, uint256 },
      } = parse(url);
      result = {
        address,
        amount: +uint256 / Math.pow(10, 8),
      };
    } catch (error) {
      result = { address: url };
    }
    return result;
  }

  parseBtc(url: string): IScanResult {
    const r = /^bitcoin:([a-zA-Z0-9]{27,34})(?:\?(.*))?$/;
    const match = r.exec(url);
    if (!match) return { address: url };

    const parsed: any = { address: '' };
    if (match[2]) {
      var queries = match[2].split('&');
      for (var i = 0; i < queries.length; i++) {
        var query = queries[i].split('=');
        if (query.length == 2) {
          parsed[query[0]] = decodeURIComponent(query[1].replace(/\+/g, '%20'));
        }
      }
    }
    parsed.address = match[1];
    return parsed;
  }
}
