var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading && _vm.doc.promoCard)?_c(_vm.ctaUrl ? 'button' : 'span',{tag:"component",staticClass:"promo-card pa-0 d-flex flex-column align-center justify-center mx-auto my-2",style:({
    ['--bg-image']: _vm.heroImage
      ? `url(${_vm.$sanityImage
          .urlFor(_vm.heroImage)
          .width(_vm.width)
          .height(_vm.height)
          .url()})`
      : '',
    width: `${_vm.width}px`,
    height: `${_vm.height}px`,
    position: 'relative',
  }),on:{"click":_vm.handleNavigation}},[_vm._t("default",function(){return [(_vm.logo)?_c('img',{staticClass:"d-block",staticStyle:{"max-height":"100%","width":"auto"},attrs:{"src":_vm.$sanityImage.urlFor(_vm.logo).height(52).url(),"height":"52","alt":_vm.doc.title}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"hover-overlay accentPurple d-flex align-center justify-center full-width fill-height",attrs:{"aria-hidden":"true"}},[_c('span',{staticClass:"mb-0 text-center font--title-large px-11"},[_vm._v("\n        "+_vm._s(_vm.doc.title)+"\n      ")])]),_vm._v(" "),(_vm.badge)?_c('SanityReference',{attrs:{"id":_vm.badge._ref,"types":[_vm.schemas.TAG]},scopedSlots:_vm._u([{key:"default",fn:function({ doc, isLoading }){return [(!isLoading && doc)?_c('span',{staticClass:"badge py-1 text-center full-width font--label-medium d-block"},[_vm._v("\n          "+_vm._s(doc.title)+"\n        ")]):_vm._e()]}}],null,false,1335336718)}):_vm._e()]},{"doc":_vm.doc,"loading":_vm.loading,"ctaUrl":_vm.ctaUrl,"badge":_vm.badge})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }