import { datadogRum } from '@datadog/browser-rum';

export default () => {
  const dataDogEnvConfig = process.env.datadogRumPlugin as any;
  if (!dataDogEnvConfig.enabled === true) {
    return;
  }

  datadogRum.init({
    applicationId: dataDogEnvConfig.applicationId,
    clientToken: dataDogEnvConfig.clientToken,
    service: 'gala-film-platform',
    env: process.env.nodeEnv,
    sessionSampleRate: 50,
    sessionReplaySampleRate: 5,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
  });

  datadogRum.startSessionReplayRecording();
};
