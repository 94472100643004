import { IExchangeReward } from '~/store/customReveal/types';

export const rewards: IExchangeReward[] = [
  {
    name: 'Common Ground - Unlockable Content #4',
    game: 'Gala Film',
    image:
      'https://tokens.gala.games/assets/gala-film/gala-film/common-ground/common-ground---unlockable-content--4.png',
    quantity: '1',
    collection: 'GalaFilm',
    category: 'common-ground',
    rarityLabel: 'bronze',
    type: 'ContentUnlock',
    additionalKey: 'common-ground-unlockable-4',
    instance: '21',
  },
  {
    name: 'Town Star - Common Ground Academy Marker',
    game: 'Gala Film',
    image:
      'https://tokens.gala.games/assets/gala-film/gala-film/marker/town-star---common-ground-academy-marker.png',
    quantity: '1',
    collection: 'GalaFilm',
    category: 'marker',
    rarityLabel: 'bronze',
    type: 'TownStarItem',
    additionalKey: 'marker-town-star-item-common-ground-academy',
    instance: '29',
  },
  {
    name: 'POPCORN! - Medium Bucket',
    game: 'Gala Film',
    image:
      'https://tokens.gala.games/assets/gala-film/gala-film/popcorn/popcorn----medium-bucket.png',
    quantity: '1',
    collection: 'GalaFilm',
    category: 'popcorn',
    rarityLabel: 'bronze',
    type: 'PopcornBucket',
    additionalKey: 'popcorn-medium-bucket',
    instance: '233',
  },
  {
    name: 'POPCORN! - Medium Bucket',
    game: 'Gala Film',
    image:
      'https://tokens.gala.games/assets/gala-film/gala-film/popcorn/popcorn----medium-bucket.png',
    quantity: '1',
    collection: 'GalaFilm',
    rarityLabel: 'bronze',
    category: 'popcorn',
    type: 'PopcornBucket',
    additionalKey: 'popcorn-medium-bucket',
    instance: '234',
  },
];
