import { MutationTree } from 'vuex';
import { IMyTheaterState } from './types';
import {
  ITheaterScreenNode,
  ITheaterScreenRewardDistribution,
} from '~/types/my-theater';
import { IRewardDistributionCounts } from '~/types/rewards';

export const mutations: MutationTree<IMyTheaterState> = {
  updateScreenNodes(
    myTheaterState: IMyTheaterState,
    screenNodes: ITheaterScreenNode[],
  ) {
    myTheaterState.screenNodes = screenNodes;
  },
  updateTheaterRewardSummary(
    myTheaterState: IMyTheaterState,
    rewardDistributionCounts: IRewardDistributionCounts,
  ) {
    myTheaterState.theaterRewardSummary = rewardDistributionCounts;
  },
  updateDailyRewardDistributions(
    myTheaterState: IMyTheaterState,
    dailyRewardDistributions: ITheaterScreenRewardDistribution[],
  ) {
    myTheaterState.dailyRewardDistributions = dailyRewardDistributions;
  },
  updateTotalRewardDistributions(
    myTheaterState: IMyTheaterState,
    totalRewardDistributions: ITheaterScreenRewardDistribution[],
  ) {
    myTheaterState.totalRewardDistributions = totalRewardDistributions;
  },
};
