import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IMyTheaterState } from './types';
import { IRootState } from '../types';

export const state: IMyTheaterState = {
  screenNodes: undefined,
  theaterRewardSummary: undefined,
  dailyRewardDistributions: undefined,
  totalRewardDistributions: undefined,
};

const namespaced = true;

const myTheater: Module<IMyTheaterState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default myTheater;
