
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { MuxStreamTypes } from '~/types/mux-player';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import SanityReference from '~/components/Sanity/SanityReference.vue';
import VideoPlayer from '~/components/Player/VideoPlayer.vue';

@Component({
  components: {
    SanityReference,
    VideoPlayer,
  },
})
export default class SanityMuxVideoSerializer extends Vue {
  @Prop(String)
  readonly _ref!: string;

  videoSchemas = [
    SanityDocumentSchema.VIDEO,
    SanityDocumentSchema.VIDEO_LIVE_STREAM,
  ];
  streamTypes = MuxStreamTypes;
  schemaTypes = SanityDocumentSchema;
}
