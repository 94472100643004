
import { SanityDocument } from '@sanity/client';
import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import SanityDocumentMixin from '~/mixins/SanityDocument';

@Component
export default class SanityReference extends mixins(SanityDocumentMixin) {
  @Prop(String) readonly id!: string;
  @Prop(Array) readonly types!: SanityDocumentSchema[];

  get docState() {
    if (!this.id) {
      return;
    }

    return this.$sanityLookup.getDocumentFromReference(
      {
        _ref: this.id,
      },
      this.shouldUseDraft,
    );
  }

  get refState() {
    return this.$sanityLookup.getReference({ _ref: this.id });
  }

  get isLoading() {
    return this.docState?.isLoading || this.refState?.isLoading;
  }

  get doc(): SanityDocument {
    return (
      this.docState?.data || {
        _type: '',
        _id: '',
        _rev: '',
        _createdAt: '',
        _updatedAt: '',
      }
    );
  }

  render() {
    return this.$scopedSlots.default?.({
      docState: this.docState || {
        isLoading: false,
        hasError: false,
        data: undefined,
      },
      refState: this.refState || {
        isLoading: false,
        hasError: false,
        data: undefined,
      },
      isLoading: this.isLoading || false,
      doc: this.doc,
    });
  }

  async fetch() {
    if (!this.id) {
      return;
    }

    if (
      !!this.$sanityLookup.getDocumentFromReference({ _ref: this.id }, true)
        ?.data
    ) {
      return;
    }

    await this.$sanityLookup.fetchReference({
      _id: this.id,
      _type: this.types,
    });
  }

  @Watch('id')
  async handleIdChange(current: string, previous: string) {
    if (current !== previous) {
      if (
        !!this.$sanityLookup.getDocumentFromReference({ _ref: this.id }, true)
          ?.data
      ) {
        return;
      }

      await this.$sanityLookup.fetchReference({
        _id: this.id,
        _type: this.types,
      });
    }
  }
}
