
import { Component, Mixins } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import { IDiscordProfile } from '~/store/profile/types';
import DiscordService from '~/services/discord.service';
import removeDiscordProfile from '~/mutations/removeDiscordProfile.gql';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import AuthQuery from '~/mixins/AuthQuery';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';

@Component({ components: { TwoFactorAuthInputPrompt } })
export default class DiscordConnect extends Mixins(AuthQuery) {
  @State((profile) => profile.user.discordProfile, { namespace: 'profile' })
  readonly discordProfile!: IDiscordProfile;
  @State('showDiscordDialog') readonly showDiscordDialogState!: boolean;

  connectionStarted = false;
  discordAuthUrl = '';
  disconnecting = false;
  error = false;
  message = '';
  title = '';
  disconnectTitle = '';
  disconnectMessage = '';
  errorMessage = '';

  get connected() {
    return !!this.discordProfile;
  }

  get showDiscordDialog() {
    return this.showDiscordDialogState;
  }
  set showDiscordDialog(value) {
    this.toggleDiscordDialog(value);
  }

  @Mutation('updateUser', { namespace: 'profile' })
  private updateUser!: (args: any) => void;
  @Mutation toggleDiscordDialog!: (args: any) => void;
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  mounted() {
    this.disconnectTitle = this.$t(
      'components.discord.discordDialog.disconnectTitle',
    ) as string;
    this.errorMessage = this.$t(
      'components.discord.discordDialog.disconnectErrorMessage',
    ) as string;
    this.disconnectMessage = this.$t(
      'components.discord.discordDialog.disconnectMessage',
    ) as string;
    this.title = this.disconnectTitle;
    this.message = this.disconnectMessage;
  }

  connectDiscord() {
    this.connectionStarted = true;
    window.location.href = `${
      process.env.discordConnectLink
    }&state=${DiscordService.createStateParam()}`;
  }

  async disconnectDiscord() {
    this.disconnecting = true;
    const apolloClient = this.$apolloProvider.clients.gateway;

    try {
      await this.doAuthQuery(async (totpToken) => {
        const { data } = await apolloClient.mutate({
          mutation: removeDiscordProfile,
          variables: { totpToken },
        });

        if (data.removeDiscordProfile?.success) {
          this.updateUser({ discordProfile: undefined });
          this.toggleDiscordDialog(false);
        } else {
          this.error = true;
          this.disconnectTitle = this.$t(
            'common.notifications.defaultErrorTitle',
          ) as string;
          this.disconnectMessage = this.errorMessage;
        }
      }, TwoFaCheckpoint.accountModifications);
    } catch (error) {
      const message = this.$t(
        'components.discord.discordDialog.disconnectErrorMessage',
      );
      this.updateSnackbarErrorText(message);
      this.toggleErrorSnackbar();
      console.error(message, error);
    }

    this.disconnecting = false;
  }
}
