export interface IUtmParams {
  utmCampaign?: string; // The UTM Campaign
  utmContent?: string; // The UTM Content
  utmMedium?: string; // The UTM Medium
  utmName?: string; // The UTM Name
  utmReferralCode?: string; // The ReferralCode ID
  utmSource?: string; // The UTM Source
  utmUserId?: string; // The UTM UserID
}

export interface IShareLinkConfig extends IUtmParams {
  url?: string; // The url to be shared - if none is passed it will take window location/pathname *no query params*
}

export const normalizeValue = (s?: string) => {
  return s?.toLowerCase().replace(/[^\d\w-]/g, '') || '';
};

export const utmContentValuesToString = (
  utmContentValues: Array<{ key?: string; value?: string }>,
) => {
  const utmContentParts: string[] = [];
  utmContentValues.forEach((utmContentValue) => {
    const key = normalizeValue(utmContentValue.key);
    const value = normalizeValue(utmContentValue.value);
    if (key && value) {
      utmContentParts.push(`${key}--${value}`);
    }
  });
  return utmContentParts.join('---');
};

export const buildShareLink = (config: IShareLinkConfig) => {
  const utmParamConfig: Record<
    keyof IUtmParams,
    { key: string; defaultValue?: string }
  > = {
    utmCampaign: { key: 'utm_campaign' },
    utmContent: { key: 'utm_content' },
    utmMedium: { key: 'utm_medium', defaultValue: 'social' },
    utmName: { key: 'utm_name' },
    utmReferralCode: { key: 'utm_referralcode' },
    utmSource: { key: 'utm_source', defaultValue: 'share-link' },
    utmUserId: { key: 'utm_userid' },
  };

  try {
    const url = new URL(
      config.url || window.location.pathname,
      window.location.origin,
    );

    // rewrite localhost -> stage site url
    if (url.hostname === 'localhost') {
      url.hostname = 'stage-film.gala.com';
      url.port = '';
    }

    for (const k in utmParamConfig) {
      if (!utmParamConfig.hasOwnProperty(k)) {
        continue;
      }
      const key = k as keyof typeof utmParamConfig;
      const param = utmParamConfig[key];
      const value = config[key] || param.defaultValue;
      if (value) {
        url.searchParams.set(param.key, value);
      }
    }
    return url.toString();
  } catch (e) {
    console.warn('Invalid URL');
  }
};
