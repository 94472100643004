import { setCookie } from '.';
import { formatFbclid } from './formatFbclid';
import { setFbcCookie } from './setFbcCookie';

export default function captureUtmVariables(queryObj: any) {
  const supportedUtmVariables = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_keyword',
    'utm_term',
    'utm_content',
    'utm_name',
  ];
  const utmVariables: any = {};
  const { offer, r, fbclid } = queryObj;
  if (queryObj) {
    for (const param in queryObj) {
      if (param.startsWith('utm_')) {
        if (supportedUtmVariables.includes(param)) {
          utmVariables[param] = queryObj[param];
        }
      }
    }
  }
  if (Object.getOwnPropertyNames(utmVariables).length) {
    localStorage.setItem('utm-variables', JSON.stringify(utmVariables));
    setCookie('utmVariables', JSON.stringify(utmVariables), 12);
  }
  if (offer && typeof offer === 'string') {
    localStorage.setItem('offer', offer);
  }
  if (r && typeof r === 'string') {
    localStorage.setItem('r', r);
  }
  if (fbclid && typeof fbclid === 'string') {
    const formatted = formatFbclid(fbclid);
    setFbcCookie(formatted);
  }
}
