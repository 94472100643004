
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WatchVideoDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly dialogState!: boolean;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly activatorLabel!: string;
  @Prop(String) readonly closeLabel!: string;
  @Prop({ type: Boolean, default: false })
  readonly showTitle!: boolean;

  get label() {
    return (
      this.activatorLabel ||
      this.$t('components.fullScreenMediaDialog.activatorLabel')
    );
  }

  get isOpen() {
    return this.dialogState;
  }

  set isOpen(value: boolean) {
    this.$emit('update:dialogState', value);
  }
}
