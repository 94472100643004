import { render, staticRenderFns } from "./QuickWalletAddress.vue?vue&type=template&id=6eab51d0&scoped=true"
import script from "./QuickWalletAddress.vue?vue&type=script&lang=ts"
export * from "./QuickWalletAddress.vue?vue&type=script&lang=ts"
import style0 from "./QuickWalletAddress.vue?vue&type=style&index=0&id=6eab51d0&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eab51d0",
  null
  
)

export default component.exports