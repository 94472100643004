import { GetterTree } from 'vuex';
import { IBoostState } from './types';
import { IRootState } from '../types';
import _countBy from 'lodash.countby';
import _flatten from 'lodash.flatten';

export const getters: GetterTree<IBoostState, IRootState> = {
  getProjectBoostStats: (state) => (projectSlug: string) => {
    return state.byProject[projectSlug]?.stats || null;
  },
  getAllProjectsBoostStats: (state) => () => {
    return Object.values(state.byProject).map((project) => project.stats);
  },
  getAllProjectsUserHolds: (state) => () => {
    return Object.values(state.byProject).map((project) => project.userHold);
  },
  getUserBoostHold: (state) => (projectSlug: string) => {
    return state.byProject[projectSlug]?.userHold || null;
  },
  getBoostSupporters: (state) => (projectSlug: string) => {
    const supporters = state.byProject[projectSlug]?.supporters || [];
    return supporters;
  },
  getTotalSupportersCount: (state) => (projectSlug: string) => {
    return state.byProject[projectSlug]?.supporters?.length || 0;
  },
  getAvailableTokenBalance: (state) => () => {
    return state.balance?.quantityAvailable || 0;
  },
};
