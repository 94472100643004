export const web3WalletSupportLinks = {
  connectWeb3Wallet: 'https://links.gala.com/connect-w3w',
  importPhraseCoinbase: 'https://links.gala.com/wp-cw-kb',
  importPhraseMetaMask: 'https://links.gala.com/wp-mm-kb',
  importPrivateKeyMetaMask: 'https://links.gala.com/pk-mm-kb',
  installCoinbaseWallet: 'https://links.gala.com/setup-cbwallet',
  installMetaMaskWallet: 'https://links.gala.com/setup-mm',
  replaceGalaWallet: 'https://links.gala.com/replace-w3w',
  whatIsWallet: 'https://links.gala.com/wallet-kb',
};
