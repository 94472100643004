var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.fetching)?_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('div',[(_vm.termsAndConditions === 'scroll-to-accept')?_c('ScrollToAccept',{attrs:{"documentName":_vm.$t('components.legalAgreements.legalAgreements.termsOfService'),"documentVersion":_vm.termsAndConditionsData.version,"htmlContent":_vm.termsAndConditionsData.content,"focusTrackName":"Terms of Service"},model:{value:(_vm.termsAndConditionsStatus),callback:function ($$v) {_vm.termsAndConditionsStatus=$$v},expression:"termsAndConditionsStatus"}}):(_vm.termsAndConditions === 'checkbox')?_c('AgreementStatement',{attrs:{"document-name":_vm.$t('components.legalAgreements.legalAgreements.termsOfService'),"checkbox-color":_vm.checkboxColor},model:{value:(_vm.termsAndConditionsStatus),callback:function ($$v) {_vm.termsAndConditionsStatus=$$v},expression:"termsAndConditionsStatus"}},[_c('AgreementDialog',{attrs:{"htmlContent":_vm.termsAndConditionsData.content,"closeOnClickAway":_vm.closeOnClickAway,"buttonColor":_vm.dialogButtonColor}},[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.legalAgreements.termsOfService'))+"\n      ")])],1):(_vm.termsAndConditionsWithPrivacyPolicy === 'checkbox')?_c('AgreementStatement',{attrs:{"agreementText":_vm.$t('components.legalAgreements.agreementStatement.simpleAgreement'),"agreementTextA11y":_vm.$t(
          'components.legalAgreements.agreementStatement.simpleAgreementA11y',
          {
            nameFirst: _vm.$t(
              'components.legalAgreements.legalAgreements.termsOfService',
            ),
            nameSecond: _vm.$t('common.misc.privacyPolicy'),
          },
        ),"checkbox-color":_vm.checkboxColor},model:{value:(_vm.termsAndConditionsWithPrivacyPolicyStatus),callback:function ($$v) {_vm.termsAndConditionsWithPrivacyPolicyStatus=$$v},expression:"termsAndConditionsWithPrivacyPolicyStatus"}},[_c('AgreementDialog',{attrs:{"htmlContent":_vm.termsAndConditionsData.content,"buttonColor":_vm.dialogButtonColor}},[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.legalAgreements.termsOfService'))+"\n      ")]),_vm._v(" "),_c('span',{staticClass:"vertical-align-middle"},[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.and'))+"\n      ")]),_vm._v(" "),_c('AgreementDialog',{attrs:{"htmlContent":_vm.privacyPolicyData.content,"buttonColor":_vm.dialogButtonColor}},[_vm._v("\n        "+_vm._s(_vm.$t('common.misc.privacyPolicy'))+".\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.privacyPolicy === 'checkbox' && _vm.privacyPolicyData.content)?_c('AgreementStatement',{attrs:{"document-name":_vm.$t('common.misc.privacyPolicy'),"checkbox-color":_vm.checkboxColor},model:{value:(_vm.privacyPolicyStatus),callback:function ($$v) {_vm.privacyPolicyStatus=$$v},expression:"privacyPolicyStatus"}},[_c('AgreementDialog',{attrs:{"htmlContent":_vm.privacyPolicyData.content,"closeOnClickAway":_vm.closeOnClickAway,"buttonColor":_vm.dialogButtonColor}},[_vm._v("\n        "+_vm._s(_vm.$t('common.misc.privacyPolicy'))+"\n      ")])],1):_vm._e(),_vm._v(" "),(
        _vm.nodeNonInvestmentStatement === 'checkbox' &&
        _vm.nodeNonInvestmentStatementData.content &&
        !_vm.isGameNodePurchase
      )?_c('AgreementStatement',{attrs:{"agreementText":_vm.$t('components.legalAgreements.legalAgreements.agreementTextPart1'),"agreementTextA11y":`${_vm.$t(
        'components.legalAgreements.legalAgreements.agreementTextPart1',
      )} ${_vm.$t(
        'components.legalAgreements.legalAgreements.agreementTextPart2',
      )}`,"checkbox-color":_vm.checkboxColor},model:{value:(_vm.nodeNonInvestmentStatus),callback:function ($$v) {_vm.nodeNonInvestmentStatus=$$v},expression:"nodeNonInvestmentStatus"}},[_c('a',{class:`${_vm.dialogButtonColor}--text`,attrs:{"href":"https://gogalagames.medium.com/reminder-gala-games-founders-nodes-are-not-investments-3904841fc2f1","target":"_blank","rel":"noreferer, ,noopener"}},[_vm._v("\n        "+_vm._s(_vm.$t('components.legalAgreements.legalAgreements.agreementTextPart2'))+"\n      ")])]):_vm._e(),_vm._v(" "),(
        _vm.nodeNonInvestmentStatement === 'checkbox' &&
        _vm.nodeNonInvestmentStatementData.content &&
        _vm.isGameNodePurchase
      )?_c('AgreementStatement',{attrs:{"agreementText":_vm.$t('components.legalAgreements.legalAgreements.filmNodeAgreement'),"checkbox-color":_vm.checkboxColor},model:{value:(_vm.nodeNonInvestmentStatus),callback:function ($$v) {_vm.nodeNonInvestmentStatus=$$v},expression:"nodeNonInvestmentStatus"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }