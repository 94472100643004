
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import RequirePasscode from '~/components/Wallet/RequirePasscode.vue';
import privateKeyQuery from '~/queries/privateKey.gql';
import SimpleCrypto from 'simple-crypto-js';
import AuthQuery from '~/mixins/AuthQuery';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import { InputSize } from '~/types/vuetify';

@Component({
  components: { FullScreenDialog, RequirePasscode, TwoFactorAuthInputPrompt },
})
export default class PrivateKeyButton extends Mixins(AuthQuery) {
  @Prop({ type: String, default: 'accentPurple' })
  readonly color!: string;
  @Prop({ type: String, default: '' })
  readonly icon!: string;
  @Prop({ type: Boolean, default: false })
  readonly outlined!: string;
  @Prop({ type: String, default: 'large' })
  readonly size!: InputSize;
  @Prop({ type: String, default: 'white' })
  readonly textColor!: string;

  @State((profile) => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  passcodePrompt = false;
  encryptionPasscode = '';
  isFetching = false;

  download(filename: string, text: string) {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text),
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  }

  async handleSubmit(encryptionPasscode: string) {
    this.encryptionPasscode = encryptionPasscode;

    try {
      this.isFetching = true;

      const response = await this.doAuthQuery(async (totpToken) => {
        return this.$apollo.query({
          query: privateKeyQuery,
          variables: {
            coinSymbol: 'ETH',
            walletPassword: encryptionPasscode,
            totpToken,
          },
          fetchPolicy: 'no-cache',
        });
      }, TwoFaCheckpoint.downloadPrivateKey);

      // User canceled
      if (!response) {
        return;
      }

      if (response.data.privateKey.success) {
        const crypto = new SimpleCrypto(encryptionPasscode);

        const encryptedKey = response.data.privateKey.result[0].key;
        const decryptedKey = crypto.decrypt(encryptedKey) as string;

        this.download('ethereum-key.txt', decryptedKey);
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      this.$sentry.captureException(error);
      console.warn(error);
      this.updateSnackbarErrorText(
        this.$t('components.settings.advancedSettings.keyErrorMsg'),
      );
      this.toggleErrorSnackbar();
    } finally {
      this.isFetching = false;
    }
  }
}
