
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IVideoPlayer } from '~/components/Player/VideoPlayer.vue';
import FullScreenMediaDialog from '~/components/FullScreenMediaDialog.vue';
import VideoPlayer from '~/components/Player/VideoPlayer.vue';
import ThumbnailButton from '~/components/UIComponents/ThumbnailButton.vue';
import IconPlay from '~/components/UIComponents/IconPlay.vue';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';

@Component({
  components: {
    FullScreenMediaDialog,
    VideoPlayer,
    ThumbnailButton,
    IconPlay,
  },
  inheritAttrs: false,
})
export default class WatchVideoDialog extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly autoplay!: boolean;
  @Prop({ type: Boolean, default: true })
  readonly closeOnEnd!: boolean;
  @Prop({ type: Number, default: 16 / 9 })
  readonly thumbAspectRatio!: number;
  @Prop({ type: Number, default: 284 })
  readonly thumbWidth!: number;
  @Prop({ type: Boolean, default: true })
  readonly enableActivatorBtnRipple!: number;
  @Prop(String) readonly id!: string;
  @Prop(String) readonly slug!: string;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly activatorType!: 'text' | 'thumbnail';
  @Prop(String) readonly playLogoType!: 'cta';
  @Prop({ type: Number, default: 80 })
  readonly playIconSize!: number;
  @Prop(String) readonly activatorLabel!: string;
  @Prop({ type: Boolean, default: false })
  readonly show!: boolean;

  schemas = SanityDocumentSchema;
  isOpen = false;
  videoPlayer: IVideoPlayer | undefined = undefined;

  @Watch('videoDoc.data.video.asset._ref', { immediate: true })
  handleAssetRefChange(
    current: string | undefined,
    previous: string | undefined,
  ) {
    if (current && current !== previous) {
      this.$sanityLookup.fetchDocument({
        _id: current,
        _type: this.schemas.MUX_ASSET,
      });
    }
  }

  @Watch('isOpen', { immediate: true })
  handleChange(currentState: boolean) {
    if (!currentState) {
      this.$emit('closed');
    }

    if (!this.videoPlayer) {
      return;
    }

    if (currentState) {
      this.autoplay && this.videoPlayer.play();
    } else {
      this.videoPlayer.pause();
    }
  }

  @Watch('show', { immediate: true })
  handleShowChange(currentState: boolean) {
    if (currentState) {
      this.isOpen = true;
    }
  }

  async created() {
    await this.fetch();
  }

  async fetch() {
    if (this.id || this.slug) {
      this.$sanityLookup.fetchDocument({
        _id: this.id,
        slug: this.slug,
        _type: this.schemas.VIDEO,
      });
    }
  }

  get muxAsset() {
    if (this.videoDoc?.data?.video?.asset?._ref) {
      return (
        this.$sanityLookup.getDocumentFromId<
          ISanityDocuments[SanityDocumentSchema.MUX_ASSET]
        >({
          _id: this.videoDoc.data.video.asset._ref,
          _type: this.schemas.MUX_ASSET,
        }) || {
          isLoading: false,
          hasError: false,
        }
      );
    } else {
      return {
        isLoading: false,
        hasError: false,
      };
    }
  }

  get videoDoc() {
    const videoDoc = this.$sanityLookup.getDocumentFromIdOrSlug<
      ISanityDocuments[SanityDocumentSchema.VIDEO]
    >({
      _type: this.schemas.VIDEO,
      _id: this.id,
      slug: this.slug,
    }) || {
      isLoading: false,
      hasError: false,
    };
    return videoDoc;
  }

  handlePlayerMounted() {
    if (this.$refs.video as IVideoPlayer) {
      this.videoPlayer = this.$refs.video as IVideoPlayer;
    }
  }

  handleCanPlay() {
    if (this.isOpen && this.videoPlayer) {
      this.autoplay && this.videoPlayer.play();
    }
  }

  handleEnded() {
    if (this.isOpen && this.closeOnEnd) {
      this.isOpen = false;
    }
  }

  get label() {
    return this.activatorLabel || this.title
      ? this.$t('components.sanity.watchVideoDialog.activatorLabelWithTitle', {
          title: this.title,
        })
      : this.$t('components.sanity.watchVideoDialog.activatorLabel');
  }

  get thumbHeight() {
    return this.thumbAspectRatio
      ? Math.round(this.thumbWidth * (1 / this.thumbAspectRatio))
      : 0;
  }

  get key() {
    return this.id ?? this.slug;
    // return `${this.videoDoc?.data?._id}.${this.muxAsset?.data?.playbackId}`;
  }

  get thumbUrl() {
    if (!this.videoDoc?.data?.poster && !this.muxAsset?.data?.playbackId) {
      return '';
    }

    if (this.videoDoc?.data?.poster) {
      return this.$sanityImage
        .urlFor(this.videoDoc.data.poster)
        .width(this.thumbWidth)
        .height(this.thumbHeight)
        .fit('crop')
        .url();
    }

    if (this.muxAsset?.data?.playbackId) {
      const queryParams = [
        `width=${this.thumbWidth}`,
        `height=${this.thumbHeight}`,
      ];
      if (this.muxAsset?.data?.thumbTime) {
        queryParams.push(`time=${this.muxAsset.data.thumbTime}`);
      }
      return `https://image.mux.com/${
        this.muxAsset.data.playbackId
      }/thumbnail.jpg?${queryParams.join('&')}`;
    }
  }
}
