import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IBoostState } from './types';
import { IRootState } from '../types';

export const state: IBoostState = {
  byProject: {},
  balance: null,
};

const namespaced = true;

const boosts: Module<IBoostState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default boosts;
