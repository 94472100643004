import { Module } from 'vuex';
import { IRootState } from '~/store/types';
import { SanityDocumentSchema } from '~/types/sanity-documents';
import { getStoreActions } from './actions';
import { getStoreMutations } from './mutations';
import { getStoreGetters } from './getters';
import { ISanityDocumentState } from './types';

const empty: Partial<Record<string, Module<ISanityDocumentState, IRootState>>> =
  {};

const modules = Object.values(SanityDocumentSchema).reduce(
  (allModules, schema) => {
    const docType = schema as SanityDocumentSchema;
    allModules[docType] = {
      namespaced: true,
      state: {
        documents: {},
        slugs: {},
      },
      actions: getStoreActions(docType),
      mutations: getStoreMutations(),
      getters: getStoreGetters(),
    };
    return allModules;
  },
  empty,
);

export const documentStoreTypes = Object.keys(modules);

export default modules;
