import { Context } from '@nuxt/types';
import { SanityProjectDetails } from '@sanity/image-url/lib/types/types';
import createClient from '@sanity/client';
import { createBuilder, getUrlFor } from '~/plugins/sanityImage';
import {
  IDebugOptions,
  DEBUG_TOOL_DEFAULTS as defaultDebugToolOptions,
} from '~/store/types';

export const debugToolSessionStorageKey = 'galaFilmDebugTool.options';

export default function (ctx: Context) {
  const maybeJsonString = sessionStorage.getItem(debugToolSessionStorageKey);

  if (!maybeJsonString) {
    return;
  }

  const { store } = ctx;

  try {
    const settingsFromSessionStorage = JSON.parse(maybeJsonString);
    const newConfig: IDebugOptions = {
      ...store.state.debugSettings,
      ...settingsFromSessionStorage,
    };

    const didSettingsChange = !!Object.keys(newConfig).find(
      (key) =>
        newConfig[key as keyof IDebugOptions] !==
        store.state.debugSettings[key],
    );

    if (!didSettingsChange) {
      return;
    }

    store.commit('updateDebugSettings', {
      ...store.state.debugSettings,
      ...settingsFromSessionStorage,
    });

    // @ts-ignore
    const { $sanityImage, $sanity } = ctx;

    const debugSettings = store.state.debugSettings;

    // Update content client
    const config = {
      ...$sanity.config,
      dataset:
        debugSettings.contentDataset || defaultDebugToolOptions.contentDataset,
      withCredentials:
        debugSettings.shouldShowDrafts !== undefined
          ? debugSettings.shouldShowDrafts
          : defaultDebugToolOptions.shouldShowDrafts,
    };
    $sanity.client = createClient(config);
    $sanity.config = config;

    // Update settings client
    const settingsConfig = {
      ...$sanity.settingsClient.config,
      dataset:
        debugSettings.settingsDataset ||
        defaultDebugToolOptions.settingsDataset,
      withCredentials:
        debugSettings.shouldShowDrafts !== undefined
          ? debugSettings.shouldShowDrafts
          : defaultDebugToolOptions.shouldShowDrafts,
    };
    $sanity.settingsClient.client = createClient(settingsConfig);
    $sanity.settingsClient.config = settingsConfig;

    // Update image builder
    const imgBuilder = createBuilder(config as SanityProjectDetails);
    $sanityImage.builder = imgBuilder;
    $sanityImage.urlFor = getUrlFor(imgBuilder);
  } catch (e) {}
}
