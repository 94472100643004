var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    !_vm.isLoading &&
    ((_vm.isUsingUnsavedSettings && _vm.canViewDrafts) ||
      (_vm.canShowDebugTool && _vm.isDebugToolDisplayed))
  )?_c('div',{staticClass:"site-info-message d-sm-flex justify-end align-center white--text text-center"},[(_vm.canShowDebugTool)?_c('DebugTool',{attrs:{"isEnabled":_vm.isDebugToolDisplayed}}):_vm._e(),_vm._v(" "),(_vm.isUsingUnsavedSettings)?_c('p',{class:{
      'font--body-small mb-0 pa-2': true,
      'py-sm-0': _vm.canShowDebugTool,
    }},[_vm._v("\n    Warning: currently viewing site with unsaved config\n  ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }