interface IRestrictionData<
  TReasonDetails extends Record<string, any> = Record<string, any>,
> {
  reason?: string;
  reasonDetails?: {
    id?: string;
    ruleSetType?: string;
    ruleResults?: Array<IRuleResult<TReasonDetails>>;
  };
  success?: boolean;
}

interface IRuleResult<
  TReasonDetails extends Record<string, any> = Record<string, any>,
> {
  filterResults?: Record<string, IFilterResult<TReasonDetails>>;
  ruleIdx?: number;
  success?: boolean;
}

interface IFilterResult<
  TReasonDetails extends Record<string, any> = Record<string, any>,
> {
  reason?: string;
  reasonDetails?: {
    failClauses?: any[];
    successClauses?: Array<ISuccessClause<TReasonDetails>>;
  };
  success?: boolean;
}

interface ISuccessClause<
  TReasonDetails extends Record<string, any> = Record<string, any>,
> {
  clauseIdx?: number;
  reason?: string;
  reasonDetails?: TReasonDetails;
}

export function isRestrictionSuccess(restrictionData: IRestrictionData) {
  return restrictionData?.success;
}

export function getFirstRestrictionSuccessResultByFilter<
  TReasonDetails extends Record<string, any> = Record<string, any>,
>(
  restrictionData: IRestrictionData<TReasonDetails>,
  filter: string,
): TReasonDetails | null {
  let results: TReasonDetails | null = null;
  if (restrictionData?.success) {
    restrictionData.reasonDetails?.ruleResults?.forEach((ruleResult) => {
      const restrictionFilterData = ruleResult?.filterResults?.[filter] ?? null;
      if (restrictionFilterData?.success) {
        const successData = restrictionFilterData.reasonDetails?.successClauses;
        if (successData?.[0]?.reasonDetails) {
          results = successData[0].reasonDetails;
        }
      }
    });
  }
  return results;
}

export function isSuccessfulPriceAdjustmentCodeBased(
  restrictionData: IRestrictionData,
): boolean {
  let isCodeBasedAdjustment = false;

  if (restrictionData?.success) {
    restrictionData.reasonDetails?.ruleResults?.forEach((ruleResult) => {
      const restrictionCodeData = ruleResult?.filterResults?.code ?? null;
      const restrictionPriceAdjustmentData =
        ruleResult?.filterResults?.priceAdjustment ?? null;

      if (
        restrictionPriceAdjustmentData?.success &&
        restrictionCodeData?.success
      ) {
        isCodeBasedAdjustment = true;
      }
    });
  }

  return isCodeBasedAdjustment;
}
