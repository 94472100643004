import { render, staticRenderFns } from "./FullScreenMediaDialog.vue?vue&type=template&id=0b327a87&scoped=true"
import script from "./FullScreenMediaDialog.vue?vue&type=script&lang=ts"
export * from "./FullScreenMediaDialog.vue?vue&type=script&lang=ts"
import style0 from "./FullScreenMediaDialog.vue?vue&type=style&index=0&id=0b327a87&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b327a87",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThemeProvider: require('/builds/gala-games/gala-film/gala-film/src/components/ThemeProvider.vue').default})
