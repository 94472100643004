import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IProjectState } from './types';
import { IRootState } from '../types';

export const state: IProjectState = {
  projects: {},
  projectSeriesState: {},
  showingVideo: false,
  viewingRestrictions: {},
  continueRzrEp1AfterUA: undefined,
  nftPromotionsState: {
    show: false,
    shownPromotionIds: [],
  },
  projectTimeline: {},
  currentEpisode: undefined,
  nextEpisode: undefined,
  projectsAutoPlayed: [],
};

const namespaced = true;

const project: Module<IProjectState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default project;
