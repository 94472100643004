var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.showRecoveryPhrase)?_c('v-layout',{attrs:{"column":"","align-center":"","justify-center":""}},[_c('v-flex',{staticClass:"text-left font--body-large"},[_c('p',{staticClass:"mt-4"},[_vm._v("\n        "+_vm._s(_vm.$t('components.newWallet.displayRecoveryPhrase.onlyBackup'))+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('components.newWallet.displayRecoveryPhrase.writeItDown'))+"\n      ")]),_vm._v(" "),_c('p',[_c('span',{staticClass:"text-capitalize"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'components.newWallet.displayRecoveryPhrase.responsiblityLine1.string',
            ))+"\n        ")]),_vm._v("\n        "+_vm._s(_vm.$t(
            'components.newWallet.displayRecoveryPhrase.responsiblityLine2.string',
          ))+"\n        "),_c('button',{staticClass:"underline font-weight-medium dialog-link",on:{"click":function($event){_vm.showInstructionsDialog = true}}},[_vm._v("\n          "+_vm._s(_vm.$t(
              'components.newWallet.displayRecoveryPhrase.responsiblityLine3.string',
            ))+"\n        ")])])]),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showInstructionsDialog),callback:function ($$v) {_vm.showInstructionsDialog=$$v},expression:"showInstructionsDialog"}},[_c('v-card',{staticClass:"pa-6",attrs:{"color":"modalBackground"}},[_c('h3',{staticClass:"text-center pt-3"},[_vm._v("\n          "+_vm._s(_vm.$t('components.newWallet.displayRecoveryPhrase.instructions'))+"\n        ")]),_vm._v(" "),_c('v-card-text',[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'components.newWallet.displayRecoveryPhrase.recoveryPhraseAlert',
              ))+"\n          ")]),_vm._v(" "),_vm._l((_vm.instructions),function(item,index){return _c('v-layout',{key:index,attrs:{"align-start":""}},[_c('v-icon',{staticClass:"pt-1",attrs:{"color":item.color,"small":""}},[_vm._v("\n              "+_vm._s(item.icon)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"pl-2"},[_vm._v("\n              "+_vm._s(item.text)+"\n            ")])],1)})],2),_vm._v(" "),_c('button',{staticClass:"gala-btn full-width",on:{"click":function($event){_vm.showInstructionsDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('common.cta.close'))+"\n        ")])],1)],1)],1):_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"unselectable pt-6",attrs:{"xs12":""}},[_c('v-tooltip',{attrs:{"left":"","max-width":"250px","color":"modalBackground"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"info-badge"},on),[_vm._v("?")])]}}])},[_vm._v(" "),_c('span',{staticClass:"white--text",domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'components.newWallet.displayRecoveryPhrase.recoveryPhraseStatement',
            )
          )}})]),_vm._v(" "),_c('v-divider',{staticClass:"accentGrayLight my-4"}),_vm._v(" "),_c('h1',{staticClass:"notranslate",staticStyle:{"word-spacing":"22px"},attrs:{"translate":"no"}},_vm._l((_vm.mnemonic),function(word,i){return _c('span',{key:i,staticClass:"notranslate recovery-phrase-word"},[_vm._v("\n          "+_vm._s(word)+"\n        ")])}),0)],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center text-left mt-3"},[_c('v-checkbox',{attrs:{"label":_vm.$t(
            'components.newWallet.displayRecoveryPhrase.recordedRecoveryPhrase',
          ),"hide-details":"auto"},model:{value:(_vm.writtenDown),callback:function ($$v) {_vm.writtenDown=$$v},expression:"writtenDown"}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-start text-left"},[_c('v-checkbox',{attrs:{"label":_vm.$t(
            'components.newWallet.displayRecoveryPhrase.recordingConfirmation',
          ),"hide-details":"auto"},model:{value:(_vm.doubleConfirm),callback:function ($$v) {_vm.doubleConfirm=$$v},expression:"doubleConfirm"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }