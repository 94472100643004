
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import GradientHexagon from '~/components/UIComponents/GradientHexagon.vue';
import { isTomorrow } from 'date-fns';
import { LocaleObject } from '@nuxtjs/i18n';

@Component({
  components: { GradientHexagon },
})
export default class GalaChainMigrationAlert extends Vue {
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  readonly loggedIn!: boolean;
  @State((profile) => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;

  @Getter('showItemLockingFeature') readonly showItemLockingFeature!: boolean;

  showModal = false;
  galaChainMigrationDate = process.env.galaChainMigrationDate;
  whiteHex = require('@/assets/icons/white_hexagon_outline.svg');

  get galaChainMigrationIsTomorrow() {
    return this.galaChainMigrationDate
      ? isTomorrow(new Date(+this.galaChainMigrationDate))
      : false;
  }

  get migrationDateLocaleString() {
    const { locale, locales } = this.$i18n;
    const localeIso = (locales as any).find(
      (l: LocaleObject) => l.code === locale,
    ).iso;
    return this.galaChainMigrationDate
      ? new Date(+this.galaChainMigrationDate).toLocaleString(localeIso, {
          month: 'short',
          day: 'numeric',
        })
      : undefined;
  }

  checkToDisplayModal() {
    const hasSeenModal = localStorage.getItem('gcMigrationAlertSeen');
    const hasSeenTomorrowModal = localStorage.getItem(
      'gcMigrationTomorrowAlertSeen',
    );
    if (this.showItemLockingFeature && this.walletExists) {
      if (
        !hasSeenModal ||
        (this.galaChainMigrationIsTomorrow && !hasSeenTomorrowModal)
      ) {
        this.showModal = true;
      }
    }
  }

  closeModal() {
    localStorage.setItem('gcMigrationAlertSeen', 'true');
    if (this.galaChainMigrationIsTomorrow) {
      localStorage.setItem('gcMigrationTomorrowAlertSeen', 'true');
    }
    this.showModal = false;
  }

  learnMore() {
    this.closeModal();
    // TODO: update this link to a blog post when it is ready
    window.open('https://gogalagames.medium.com/');
  }

  @Watch('loggedIn', { immediate: true })
  onLoggedIn(loggedIn: boolean) {
    if (loggedIn) {
      this.checkToDisplayModal();
    }
  }
}
